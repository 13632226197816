import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import IconButton from 'Common/Components/IconButton';
import { Edit } from '../../styled';
import { redirectToVulnDetail } from 'store/Manage/actions';

const EditButton = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector((state) => get(state, 'manage.vulnsSelected', []));
  const handleClick = () => dispatch(redirectToVulnDetail(vulnsSelected[0]._id));

  return (
    <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
  );
};

export default EditButton;
