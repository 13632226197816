import styled from 'styled-components';
import colors from 'Styles/colors';

export const Label = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  letter-spacing: 0.09px;
  color: ${colors.grey17};
  padding-bottom: 5px;
  padding-right: 15px;
`;
Label.displayName = 'Label';

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;

  &[required] {
    ${Label}::after {
      content: ' *';
      color: ${colors.redPink};
    }
  }
`;
CheckboxWrapper.displayName = 'FieldWrapper';
