import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const SearchAction = styled.div`
    font-size: 14.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c2243;
    padding: 8px 20px;
    cursor: pointer;
    border-top: solid 1px #c7d6e4;
    margin-left: -20px;
    margin-right: -20px;

    &:hover{
        background-color: #e4ecf3;
        box-shadow: inset 0 -1px 0 0 #afafaf;
        font-weight: 600;
    }
`;
SearchAction.displayName = 'SearchAction';

export const DataContainer = styled.div`
    width: 340px;
    max-height: 350px;
    border-radius: 2px;
    box-shadow: inset 0 -1px 0 0 #afafaf;
    background-color: #ffffff;
    overflow-y: auto;
    padding: 0 20px;
    position: absolute;
    z-index: 10;
    background-color: ${colors.iceBlue};
    
    
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`
    font-size: 13px;
    color: #1c2243;
    cursor: pointer;
    padding: 7px 20px 7px 20px;
    border-radius: 3px;
    margin-left: -20px;
    margin-right: -20px;
    text-align: left;
}

    &:hover{
        background-color: #e4ecf3;
        box-shadow: inset 0 -1px 0 0 #afafaf;
        font-weight: 600;
    }
`;
DataItem.displayName = 'DataItem';
