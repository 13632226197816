import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWorkspaceSelected
} from 'Screens/Workspaces/actions/Actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectIsFetching, selectWorkspacesSelected } from 'store/Workspace/selectors';

const DeleteConfirmationModal = ({ onCloseCallback }) => {
  const dispatch = useDispatch();
  const selectedWs = useSelector(selectWorkspacesSelected);
  const isFetching = useSelector(selectIsFetching);

  return (
    <ModalConfirmation
      show
      handleSubmit={ () => dispatch(deleteWorkspaceSelected(onCloseCallback)) }
      handleClose={ onCloseCallback }
      entity="workspace"
      count={ selectedWs.length }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
