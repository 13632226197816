import React from 'react';
import { useIntl } from 'react-intl';
import Wrapper from './styled';

const WebSiteButton = ({ url, toolName }) => {
  const intl = useIntl();
  return (
    <Wrapper id={ `modal-agents-tools-${toolName}-website` } href={ url } target="_blank">
      {intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.website' })}
    </Wrapper>
  );
};

export default WebSiteButton;
