import { css } from 'styled-components';
import colors from 'Styles/colors';

export const navMenuButton = css`
  height: 17px;
`;

export const customFieldContent = css`
  font-size: 12.5px;
  line-height: 1.76;
  color: ${colors.grey19};
  cursor: pointer;
`;

export const ellipsisText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
