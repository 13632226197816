import defaultFields from './fields';

const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  hostList: [],
  hostCount: 0,
  addColumnsOpened: false,
  mapGroupByIndex: {},
  fields: defaultFields,
  showModalEditCreate: false,
  hostsSelected: [],
  lastSelected: -1,
  lastHostSelected: {},
  lastIndexForRange: -1,
  showLeftFilters: false,
  showDeleteConfirmation: false
};

export default initialState;
