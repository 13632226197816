import React, { useState, useCallback } from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { PropTypes } from 'prop-types';
import useMouseHover from 'Hooks/useMouseHover';
import { menuItemHeight } from 'Common/Components/ContextMenu/components/MenuItem/styled';
import {
  RelativeWrapper, AbsoluteWrapper, SubMenuWrapper, subMenuWidth
} from './styled';

const ContextSubMenu = ({
  enabled, children, title, color, icon
}) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const positiveOffsetX = subMenuWidth - 1;
  const negativeOffsetX = -subMenuWidth - 1;
  const positiveOffsetY = -menuItemHeight - 1;
  const [xPos, setXPos] = useState(positiveOffsetX);
  const [yPos, setYPos] = useState(positiveOffsetY);

  const measuredRef = useCallback((ref) => {
    if (ref !== null) {
      const {
        width, height, x, y
      } = ref.getBoundingClientRect();
      const viewHeight = document.body.clientHeight;
      const viewWidth = document.body.clientWidth;
      const overflowY = y + height > viewHeight;
      const overflowX = x + width > viewWidth;
      const isInitialYPos = yPos === positiveOffsetY;
      const isInitialXPos = xPos === positiveOffsetX;

      if (isInitialYPos && overflowY) setYPos(-height);
      if (isInitialXPos && overflowX) setXPos(negativeOffsetX);
    }
  }, [negativeOffsetX, positiveOffsetX, positiveOffsetY, xPos, yPos]);

  const mouseEnter = () => setShowSubmenu(true);
  const mouseLeave = () => setShowSubmenu(false);

  const [mouseEnterDebounced, mouseLeaveDebounced] = useMouseHover(mouseEnter, mouseLeave, 300);

  if (!enabled) return null;
  return (
    <div
      onMouseEnter={ mouseEnterDebounced }
      onMouseLeave={ mouseLeaveDebounced }
    >
      <RelativeWrapper>
        <AbsoluteWrapper>
          <MenuItem title={ title } color={ color } icon={ icon } hasSubMenu />
          { showSubmenu && (
            <SubMenuWrapper ref={ measuredRef } xPos={ xPos } yPos={ yPos }>
              {children}
            </SubMenuWrapper>
          )}
        </AbsoluteWrapper>
      </RelativeWrapper>
    </div>
  );
};

ContextSubMenu.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool
};

ContextSubMenu.defaultProps = {
  title: '',
  color: '',
  icon: null,
  enabled: true
};

export default ContextSubMenu;
