const defaultFields = {
  selected: {
    order: 1, visible: true, maxWidth: 38, minWidth: 38, defaultMin: 38, locked: true
  },
  severity: {
    order: 2, visible: true, maxWidth: 55, minWidth: 55, defaultMin: 55, locked: true
  },
  confirmed: {
    order: 3, visible: true, maxWidth: 39, minWidth: 39, defaultMin: 39, locked: true
  },
  name: {
    order: 4, visible: true, maxWidth: 600, minWidth: 200, defaultMin: 400, locked: true
  },
  asset: {
    order: 5, visible: true, maxWidth: 150, minWidth: 120, defaultMin: 120
  },
  cvss: {
    order: 6, visible: false, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  cve: {
    order: 7, visible: false, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  cwe: {
    order: 8, visible: false, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  hostnames: {
    order: 9, visible: true, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  create_date: {
    order: 10, visible: true, maxWidth: 150, minWidth: 150, defaultMin: 150
  },
  service__name: {
    order: 11, visible: true, maxWidth: 150, minWidth: 120, defaultMin: 120
  },
  tool: {
    order: 12, visible: true, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  status: {
    order: 13, visible: true, maxWidth: 110, minWidth: 110, defaultMin: 110
  },
  id: {
    order: -1, visible: false, maxWidth: 70, minWidth: 70, defaultMin: 70
  },
  host__os: {
    order: -1, visible: false, maxWidth: 350, minWidth: 250, defaultMin: 250
  },
  easeofresolution: {
    order: -1, visible: false, maxWidth: 170, minWidth: 170, defaultMin: 170
  },
  website: {
    order: -1, visible: false, maxWidth: 350, minWidth: 250, defaultMin: 250
  },
  path: {
    order: -1, visible: false, maxWidth: 350, minWidth: 250, defaultMin: 250
  },
  status_code: {
    order: -1, visible: false, maxWidth: 150, minWidth: 150, defaultMin: 150
  },
  evidence: {
    order: -1, visible: false, maxWidth: 100, minWidth: 100, defaultMin: 100
  },
  impact: {
    order: -1, visible: false, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  method: {
    order: -1, visible: false, maxWidth: 350, minWidth: 250, defaultMin: 250
  },
  query: {
    order: -1, visible: false, maxWidth: 350, minWidth: 250, defaultMin: 250
  },
  web: {
    order: -1, visible: false, maxWidth: 50, minWidth: 50, defaultMin: 50
  },
  external_id: {
    order: -1, visible: false, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  owasp: {
    order: -1, visible: false, maxWidth: 120, minWidth: 120, defaultMin: 120
  },
  risk: {
    order: -1, visible: false, maxWidth: 350, minWidth: 120, defaultMin: 120
  },
  update_date: {
    order: -1, visible: false, maxWidth: 150, minWidth: 150, defaultMin: 150
  }
};

export default defaultFields;
