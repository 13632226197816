/* eslint-disable react/no-children-prop */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { Wrapper, Label, Content } from './styled';

const AvailableTools = ({ tools }) => {
  const intl = useIntl();
  const label = `${intl.formatMessage({ id: 'agents.item.availableTools' })}:`;
  const noTools = intl.formatMessage({ id: 'agents.item.availableTools.none' });
  const commaSeparatedTools = tools.map((t) => capitalize(t.name)).join(', ');

  return (
    <Wrapper>
      <Label children={ label } />
      <Content children={ isEmpty(tools) ? noTools : commaSeparatedTools } />
    </Wrapper>
  );
};

AvailableTools.propTypes = {
  tools: PropTypes.array.isRequired
};

export default AvailableTools;
