import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
Wrapper.displayName = 'Wrapper';

export const Label = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  padding-left: 9px;
  cursor: pointer;
`;
Label.displayName = 'Label';
