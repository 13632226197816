/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { VULNS_CONTEXT_MENU_FILTER_KEYS } from 'store/Filters/constants';
import { setContextMenuFilter } from 'store/Manage/filterActions';
import { useIntl } from 'react-intl';
import { showContextMenu } from 'store/Manage/actions';
import { FilterIcon } from '../styled';

export const Filter = () => {
  const vulnsSelected = useSelector(selectVulnsSelected);
  const dispatch = useDispatch();
  const title = useIntl().formatMessage({ id: 'manage.cm.filter' });
  const isBulk = useSelector(selectVulnsSelected).length > 1;
  const noVulnsSelected = vulnsSelected.length === 0;

  const setFilter = (filterName) => {
    dispatch(setContextMenuFilter(filterName, vulnsSelected[0]));
    dispatch(showContextMenu(false));
  };

  if (noVulnsSelected) return null;
  return (
    <ContextSubMenu
      title={ title }
      icon={ <FilterIcon /> }
      enabled={ !isBulk }
    >
      {
        VULNS_CONTEXT_MENU_FILTER_KEYS.map(
          (filter) => <MenuItem key={ filter.name } title={ filter.label } onClick={ () => setFilter(filter.name) } />
        )
      }
    </ContextSubMenu>
  );
};
