import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentPolicies, selectVulnDetail } from 'store/Manage/selectors';
import { updateVuln } from 'store/Manage/actions';
import CustomList from 'Common/Components/CustomList';
import Wrapper from './styled';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';

const TAB = 'general';
const ID = 'policies';

const Policies = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const list = useSelector(selectCurrentPolicies);
  const currentVuln = useSelector(selectVulnDetail);
  const isExpanded = useExpandable(TAB, ID, list);

  const onAdd = (valueAdded) => {
    const newRefs = [...list, valueAdded];
    dispatch(updateVuln(currentVuln, 'policyviolations', newRefs));
  };

  const onRemove = (indexToRemove) => {
    const newPolicies = list.filter((pol, index) => index !== indexToRemove);
    dispatch(updateVuln(currentVuln, 'policyviolations', newPolicies));
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="policies" title={ intl.formatMessage({ id: 'manage.detail.tab.general.policies' }) } tab={ TAB }>
      <Wrapper>
        <CustomList
          key="DETAIL_CREATION_MODAL"
          placeholder="Add a Policy Violation"
          addItem={ onAdd }
          removeItem={ onRemove }
          listData={ list }
          responsive
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Policies;
