import styled from 'styled-components';
import colors from 'Styles/colors';

const OutlinedButton = styled.button`
    height: 34px;
    min-width: 89px;
    border-radius: 2px;
    background-color: ${(props) => (props.disabled ? colors.grey26 : colors.white1)};
    border: solid 1px ${(props) => (props.disabled ? colors.grey27 : colors.dark2)};
    color: ${(props) => (props.disabled ? colors.grey27 : colors.dark2)};
    padding: 8px 21px 9px 22px;
    text-transform: none;
    font-size: 12.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    
    &:hover {
        background-color: ${(props) => (props.disabled ? '' : colors.iceBlue)};
    }

    &:active {
        background-color: ${colors.grey31};
    }
`;

OutlinedButton.displayName = 'OutlinedButton';

export default OutlinedButton;
