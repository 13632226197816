import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllVulns } from 'store/Manage/actions';
import Checkbox from 'Common/Components/Checkbox';
import { allVulnsAreSelected, someVulnIsSelected } from 'store/Manage/selectors';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'Images/arrow-down.svg';
import ConfirmedDropdown from './ConfirmedDropdown';

const Arrow = styled(ArrowIcon)`
  margin: auto auto auto 8px;
`;

const ArrowContainer = styled.div`
  height: 15px;
`;

const CheckHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckHeader = () => { // eslint-disable-line import/prefer-default-export
  const allVulnsSelected = useSelector(allVulnsAreSelected);
  const someVulnSelected = useSelector(someVulnIsSelected);
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const handleClick = () => {
    dispatch(selectAllVulns());
  };

  return (
    <CheckHeaderContainer title="Select">
      <Checkbox theme="grey" onChange={ handleClick } state={ allVulnsSelected } partialChecked={ someVulnSelected && !allVulnsSelected } />
      <ArrowContainer onClick={ () => setShowDropdown(!showDropdown) }>
        <Arrow />
      </ArrowContainer>
      <ConfirmedDropdown show={ showDropdown } hideDropdown={ () => setShowDropdown(false) } />
    </CheckHeaderContainer>
  );
};
