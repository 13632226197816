import React from 'react';
import moment from 'moment';
import colors from 'Styles/colors';
import {
  Wrapper, StringDate, VulnsWrapper,
  VulnType, VulnCount, Separator
} from './styled';
import formatNumber from 'Common/Functions/FormatNumber';

const Flyout = ({
  data, datum, x, y
}) => {
  const dateFormatter = (d) => moment(d).format('ll');
  return (
    data && data.map((d) => (
      <g key={ d.date }>
        <foreignObject x={ x - 95 } y={ y - 200 } width="190" height="190">
          <Wrapper xmlns="http://www.w3.org/1999/xhtml">
            <StringDate>{dateFormatter(datum.date)}</StringDate>
            <VulnsWrapper>
              <VulnType color={ colors.warmPurple }>Critical</VulnType>
              <VulnCount>{ formatNumber(datum.critical) }</VulnCount>
            </VulnsWrapper>
            <VulnsWrapper>
              <VulnType color={ colors.redPink }>High</VulnType>
              <VulnCount>{ formatNumber(datum.high) }</VulnCount>
            </VulnsWrapper>
            <VulnsWrapper>
              <VulnType color={ colors.tangerine }>Medium</VulnType>
              <VulnCount>{ formatNumber(datum.medium) }</VulnCount>
            </VulnsWrapper>
            <Separator />
            <VulnsWrapper>
              <VulnType color={ colors.grey19 }>Confirmed</VulnType>
              <VulnCount>{ formatNumber(datum.confirmed) }</VulnCount>
            </VulnsWrapper>
          </Wrapper>
        </foreignObject>
      </g>
    ))
  );
};

export default Flyout;
