import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { createService } from 'Screens/ManageEditCreate/actions/Actions';
import {
  Wrapper,
  Title,
  FieldsWrapper,
  BtnCreate,
  BtnCancel,
  ButtonsWrapper,
  CustomInput,
  TitleField,
  FieldItem
} from './styled';

function AddServicePanel ({ cancelAction, hostId }) {
  const [name, setName] = useState('');
  const [port, setPort] = useState('');
  const [protocol, setProtocol] = useState('');
  const dispatch = useDispatch();

  const isFormValid = () => (
    name !== '' && port !== '' && protocol !== '' && Number(port)
  );

  const onChangeHandlerPort = (evt) => {
    const v = (evt.target.validity.valid) ? evt.target.value : port;
    setPort(v);
  };

  const AddService = () => {
    const data = {
      name,
      port,
      protocol,
      parent: hostId
    };
    dispatch(createService(data));
    cancelAction();
  };

  return (
    <Wrapper>
      <Title>Add Service</Title>
      <FieldsWrapper>
        <FieldItem className="margin-right-21">
          <TitleField>Name*</TitleField>
          <CustomInput value={ name } placeholder="netbios" type="text" width="134px" height="34px" onChange={ (e) => setName(e.target.value) } />
        </FieldItem>

        <FieldItem className="margin-right-21">
          <TitleField>Port*</TitleField>
          <CustomInput pattern="[0-9]*" value={ port } placeholder="137" type="text" width="79px" height="34px" onChange={ (e) => onChangeHandlerPort(e) } />
        </FieldItem>

        <FieldItem>
          <TitleField>Protocol*</TitleField>
          <CustomInput value={ protocol } placeholder="tcp" type="text" width="60px" height="34px" onChange={ (e) => setProtocol(e.target.value) } />
        </FieldItem>

      </FieldsWrapper>
      <ButtonsWrapper>
        <BtnCancel onClick={ () => { cancelAction(); } }>Cancel</BtnCancel>
        <BtnCreate disabled={ !isFormValid() } onClick={ () => { AddService(); } }>Add</BtnCreate>
      </ButtonsWrapper>

    </Wrapper>
  );
}

AddServicePanel.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  hostId: PropTypes.number.isRequired
};

AddServicePanel.defaultProps = {

};
export default AddServicePanel;
