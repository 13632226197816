import React from 'react';
import get from 'lodash/get';
import last from 'lodash/last';
import { useDispatch, useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import { Base64 } from 'js-base64';
import { deleteAttachment } from 'Screens/ManageEditCreate/actions/Actions';
import { selectVulnDetailId } from 'store/Manage/selectors';
import copyToClipboard from 'utils/clipboard';
import {
  EvidenceImg, ImageWrapper, FileName, Options, AttachmentWrapper, DeleteEvidenceIcon,
  Clipboard, ImagePlaceholder, FileNameWrapper, FileExtension, DownloadIcon
} from './styled';
import { setImagePreview } from 'store/Manage/actions';

const Attachment = ({ deleteEvidence, evidence, name, isEditing }) => {
  const dispatch = useDispatch();
  const vulnDetailId = useSelector(selectVulnDetailId);

  const contentType = get(evidence, 'content_type', '');
  const data = get(evidence, 'data', '');

  const isAnImage = contentType.includes('image');
  const imageSrc = `data:${contentType};base64,${data}`;

  const filename = name.split('.').slice(0, -1).join('.');
  const extension = `.${last(name.split('.'))}`;

  const copyTextToClipboard = () => copyToClipboard(`(evidence:vulnerability:${vulnDetailId}:${name})`);

  const onDelete = () => {
    if (isEditing) dispatch(deleteAttachment(name));
    else deleteEvidence(name);
  };

  const download = () => {
    if (contentType.includes('image')) {
      Base64.extendString();
      const u8arr = Base64.toUint8Array(data);
      const file = new File([u8arr], filename, { type: contentType });
      FileSaver.saveAs(file, name);
    } else {
      Base64.extendString();
      const u8arr = data.toUint8Array();
      const file = new File([u8arr], filename, { type: contentType });
      FileSaver.saveAs(file, name);
    }
  };

  return (
    <AttachmentWrapper key={ `attachement_vuln_detail_${name}` }>
      <ImageWrapper>
        {isAnImage ? <EvidenceImg onClick={ () => dispatch(setImagePreview(imageSrc)) } src={ imageSrc } alt={ name } /> : <ImagePlaceholder />}
        <Options>
          { !isEditing && <DownloadIcon onClick={ download } title="Download" /> }
          { (!isEditing && isAnImage) && <Clipboard onClick={ copyTextToClipboard } title="Copy markdown reference" /> }
          <DeleteEvidenceIcon onClick={ onDelete } />
        </Options>
      </ImageWrapper>
      <FileNameWrapper>
        <FileName children={ filename } />
        <FileExtension children={ extension } />
      </FileNameWrapper>
    </AttachmentWrapper>
  );
};

export default Attachment;
