import styled from 'styled-components';
import { Default } from 'Common/Components/Button/styled';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: end;
  & > *:not(:last-child) {
    margin-right: 13px;
  }
`;

Wrapper.displayName = 'Wrapper';

export const CustomButton = styled(Default)`
  min-width: 100px;
  color: ${colors.darkBlueGrey};
`;

CustomButton.displayName = 'CustomButton';
