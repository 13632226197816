import React from 'react';
import Warning from 'Images/warning-delete.svg';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectVulnsSelected } from 'store/Manage/selectors';
import {
  Wrapper, Icon, Title
} from './styled';

const BulkTitle = () => {
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVulnsCount = vulnsSelected.length;

  return (
    <Wrapper>
      <Icon src={ Warning } />
      <Title>
        { selectedVulnsCount > 1 ? <FormattedMessage id="vulns.edit.bulkTitle" /> : <FormattedMessage id="vulns.edit.title" /> }
      </Title>
    </Wrapper>
  );
};

export default BulkTitle;
