/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  informational: colors.grey2,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
    color: ${({ color }) => color ? sevColor[color] : 'normal'};}
    font-size: 12px;
    font-weight: bold;
`;
Wrapper.displayName = 'Wrapper';
