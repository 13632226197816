import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteVulnTemplateSelected, hideVulnTemplateModalDelete
} from 'Screens/KnowledgeBase/actions/Actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectKnowledgeBaseProps } from 'store/KnowledgeBase/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const knowledgeBaseProps = useSelector(selectKnowledgeBaseProps);
  const { showDeleteConfirmation, templatesSelected } = knowledgeBaseProps;

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteVulnTemplateSelected()) }
      handleClose={ () => dispatch(hideVulnTemplateModalDelete()) }
      entity="template"
      count={ templatesSelected.length }
    />
  );
};

export default DeleteConfirmationModal;
