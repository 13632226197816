import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const PreviewWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #F4F7FA;
  padding: 18px 22px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  overflow: auto;
  ul, ol {
    padding-left: 0;
    list-style-position: inside;
  }
  code {
    color: inherit;
  }
  pre {
    display: block;
    padding: 20px;
    line-height: 28px;
    background-color: #f5f5f5;
  }

  blockquote {
    position: relative;
    margin: 16px 0;
    padding: 5px 8px 5px 30px;
    background: none repeat scroll 0 0 rgba(102,128,153,.05);
    border: none;
    color: #333;
    border-left: 10px solid #d6dbdf;
  }

  a {
    color: #007bff;
  }
`;
PreviewWrapper.displayName = 'PreviewWrapper';

export const StyledReactMarkdown = styled(ReactMarkdown)`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.69;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: normal;
  font-size: ${({ isEmpty }) => (isEmpty ? '12.5px' : '13px')};
  color: ${({ isEmpty }) => (isEmpty ? '#90a9c0' : '#63758d')};
  h1 { font-size: 21px; font-weight: bold; }
  h2 { font-size: 17px; font-weight: bold; }
  h3 { font-size: 15px; font-weight: bold; }
  h4 { font-size: 14px; font-weight: bold; }
  h5 { font-size: 13px; font-weight: bold; }
`;
StyledReactMarkdown.displayName = 'StyledReactMarkdown';

export const PlainText = styled.div`
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 1.69;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: normal;
  font-size: ${({ isEmpty }) => (isEmpty ? '12.5px' : '13px')};
  color: ${({ isEmpty }) => (isEmpty ? '#90a9c0' : '#63758d')};
`;
PlainText.displayName = 'PlainText';
