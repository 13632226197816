import React, { useState } from 'react';
// import { useIntl } from 'react-intl';
import Configuration from './components/Configuration';
import Tools from './components/Tools';
import SaveFinish from './components/SaveFinish';
import Requirements from './components/Requirements';

import {
  Wrapper,
  Tabs,
  Title,
  Item,
  TabContent,
  Container,
  MenuList,
  Footer,
  FooterLink,
  QuestionMark,
  Dashed
} from './styled';

const CreateAgentMenu = ({ onClose }) => {
  // const intl = useIntl(); ==> TODO
  // eslint-disable-next-line no-use-before-define
  const [visibleComponent, setVisibleComponent] = useState(<Configuration nextStep={ () => newStep(menuItems, 1) } onClose={ onClose } />);
  const [active, setActive] = useState('configuration');
  const click = (key, component) => { setActive(key); setVisibleComponent(component); };
  const newStep = (menu, i) => menu[i].click();

  const backStep = (menu) => menu.find((item) => item.key === active).click();

  const menuItems = [
    {
      key: 'configuration', title: 'Configuration', click: () => { click('configuration', <Configuration nextStep={ () => newStep(menuItems, 1) } onClose={ onClose } />); }
    },
    {
      key: 'tools', title: 'Selected Tools', click: () => { click('tools', <Tools nextStep={ () => newStep(menuItems, 2) } prevStep={ () => newStep(menuItems, 0) } onClose={ onClose } />); }
    },
    {
      key: 'save', title: 'Save and finish', click: () => { click('save', <SaveFinish prevStep={ () => newStep(menuItems, 1) } onClose={ onClose } />); }
    }
  ];

  return (
    <Container>
      <Wrapper>
        <Tabs>
          <Title>New Agent</Title>
          <MenuList>
            { menuItems.map((item) => (
              <Item key={ item.key } active={ active === item.key }>
                {item.title}
                <Dashed />
              </Item>
            ))}
          </MenuList>
          <Footer>
            <QuestionMark />
            <FooterLink onClick={ () => { click(active, <Requirements backStep={ () => backStep(menuItems) } />); } }>System requirements</FooterLink>
          </Footer>
        </Tabs>
        <TabContent>
          {visibleComponent}
        </TabContent>
      </Wrapper>
    </Container>
  );
};
export default CreateAgentMenu;
