import styled from 'styled-components';
import { Label } from '../styled';

export const StyledLabel = styled(Label)``;
StyledLabel.displayName = 'StyledLabel';

export const LoadingIcon = styled.img`
  border-radius: 3px;
  height: 5px;
`;
LoadingIcon.displayName = 'LoadingIcon';

export const LoadingWrapper = styled.div`
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex: 1 1 0;
  height: 25px;
  margin-left: 30px;
`;
LoadingWrapper.displayName = 'LoadingWrapper';
