import React from 'react';
import { Wrapper, ListItem } from './styled';
import ExpandableWrapper from '../ExpandableWrapper';

const Policies = ({
  value, title, id, onChange, selected
}) => (
  <ExpandableWrapper selected={ selected } id={ id } title={ title } onChange={ onChange }>
    <Wrapper>
      {value.map((name) => <ListItem key={ `${id}-${name}` } >{name}</ListItem>)}
    </Wrapper>
  </ExpandableWrapper>
);

export default Policies;
