import React from 'react';
import { useIntl } from 'react-intl';
import ExpandableWrapper from '../ExpandableWrapper';
import { DataContainer, DataItem, Placeholder } from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/CVE/styled';

const CVE = ({
  value, onChange, selected, expanded
}) => {
  const intl = useIntl();
  const isOdd = (n) => n % 2 !== 0;

  return (
    <ExpandableWrapper onChange={ onChange } selected={ selected } id="cve" title={ intl.formatMessage({ id: 'manage.detail.tab.general.cve' }) } expanded={ expanded }>
 <DataContainer>
        { value && value.map((name, i) => (
          <DataItem
            key={ `di_${Math.random().toString(36).substr(2, 9)}` }
            isOdd={ isOdd(i) }
          >
            <Placeholder href={ `https://www.cve.org/CVERecord?id=${name.toUpperCase()}` } target="_blank" rel="noopener noreferrer">{name}</Placeholder>
          </DataItem>
        )) }
 </DataContainer>
    </ExpandableWrapper>
  );
};

export default CVE;
