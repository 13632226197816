import React from 'react';
import { useIntl } from 'react-intl';
import ExpandableWrapper from '../ExpandableWrapper';
import { Wrapper, ImpactItem } from './styled';

const ImpactsTab = ({ value, onChange, selected }) => {
  const intl = useIntl();

  return (
    <ExpandableWrapper onChange={ onChange } selected={ selected } id="impact" title={ intl.formatMessage({ id: 'manage.detail.tab.general.impact' }) }>
      <Wrapper>
        <ImpactItem active={ value.accountability }>Accountability</ImpactItem>
        <ImpactItem active={ value.availability }>Availability</ImpactItem>
        <ImpactItem active={ value.integrity }>Integrity</ImpactItem>
        <ImpactItem active={ value.confidentiality }>Confidentiality</ImpactItem>
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default ImpactsTab;
