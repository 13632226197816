import get from 'lodash/get';

const getTrProps = (state, rowInfo, onSelectService) => {
  const selectedService = get(rowInfo, 'original', null);

  if (selectedService) {
    return {
      onDoubleClick: () => {
        onSelectService(selectedService);
      }
    };
  }
  return {};
};

export default getTrProps;
