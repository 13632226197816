import React from 'react';
import { selectIsLoggedIn } from 'store/Sesion/selectors';
import { selectLocation } from 'store/Router/selectors';
import { useSelector } from 'react-redux';
import Searcher from 'Common/Components/Searcher';
import WorkspaceSelector from './WorkspaceMenuSelector';
import User from './User/userContainer';
import ProcessingQueueButton from './ProcessingQueueButton';
import { Header, Wrapper } from './styled';

const NavMenu = () => {
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));
  const location = useSelector((state) => selectLocation(state));
  const showWorkspaceSelector = location.pathname !== '/api-definitions';

  return loggedIn && (
    <Header>
      { showWorkspaceSelector && <WorkspaceSelector /> }
      <Searcher />
      <Wrapper>
        <ProcessingQueueButton />
        <User />
      </Wrapper>
    </Header>
  );
};

export default NavMenu;
