import React from 'react';
import { useSelector } from 'react-redux';
import { selectCustomFilters } from 'store/Filters/selectors';
import { SEARCH_BY_ENTITIES } from 'store/Filters/entitiesConstants';
import CustomFilterButton from '../CustomFilterButton';
import { Subtitle } from '../styled';
import {
  SavedFiltersWrapper, Heading, CFListContainer, NoFilterLabel
} from './styled';

const SavedFilters = ({ reference, containerHeight }) => {
  const vulnsCustomFilters = useSelector((state) => selectCustomFilters(state, 'vulns'));
  const assetsCustomFilters = useSelector((state) => selectCustomFilters(state, 'assets'));

  return (
    <SavedFiltersWrapper ref={ reference } containerHeight={ containerHeight }>
      <Subtitle> Saved Filters </Subtitle>
      <CFListContainer>
        <div>
          <Heading> Vulnerabilities </Heading>
          {vulnsCustomFilters.length === 0 && <NoFilterLabel> No saved filters. </NoFilterLabel>}
          {vulnsCustomFilters.map((filter) => <CustomFilterButton filter={ filter } key={ filter.id } entity={ SEARCH_BY_ENTITIES[0] } />)}
        </div>
        <div>
          <Heading> Assets </Heading>
          {assetsCustomFilters.length === 0 && <NoFilterLabel> No saved filters. </NoFilterLabel>}
          {assetsCustomFilters.map((filter) => <CustomFilterButton filter={ filter } key={ filter.id } entity={ SEARCH_BY_ENTITIES[1] } />)}
        </div>
      </CFListContainer>
    </SavedFiltersWrapper>
  );
};

export default SavedFilters;
