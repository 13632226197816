import React from 'react';
import { useDispatch } from 'react-redux';
import AddButton from 'Common/Components/AddButton';
import { openModal } from 'store/modals/actions';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';

// eslint-disable-next-line import/prefer-default-export
export const WsAddButton = () => {
  const dispatch = useDispatch();
  return <AddButton text="Add Workspace" onClick={ () => dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT)) } />;
};
