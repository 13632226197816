import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  margin: 21px 0;
  background: ${colors.white};
  & > *:not(:last-child) {
    border-bottom: 1px solid ${colors.iceBlue};
  }
`;
Wrapper.displayName = 'Wrapper';

export const WrapperItem = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
`;
WrapperItem.displayName = 'WrapperItem';

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 21%;
  margin: 13px;
  text-align: center;
`;
Item.displayName = 'Item';

export const TotalBlack = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.dark2};
`;
TotalBlack.displayName = 'TotalBlack';

export const TotalGrey = styled(TotalBlack)`
  color: ${colors.blueGrey};
`;
TotalGrey.displayName = 'TotalGrey';

export const TotalPurple = styled(TotalBlack)`
  color: ${colors.warmPurple};
`;
TotalPurple.displayName = 'TotalPurple';

export const Label = styled.div`
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.grey6};
`;
Label.displayName = 'Label';
