import fetchApi from 'services/api/connector';

export const fetchVulns = (ws, target) => fetchApi(`ws/${ws}/vulns?target=${target}`);

export const fetchServices = (ws, host) => fetchApi(`ws/${ws}/hosts/${host}/services`);

export const fetchTools = (ws, host) => fetchApi(`ws/${ws}/hosts/${host}/tools_history`);

export const addService = (ws, parent, name, status, ports, protocol, version, description, owned) => fetchApi(`ws/${ws}/services`, {
  method: 'POST',
  data: {
    description,
    name,
    owned,
    owner: '',
    parent,
    ports,
    protocol,
    status,
    type: 'Service',
    version
  }
});

export const editService = (ws, id, name, status, ports, protocol, version, description, owned) => fetchApi(`ws/${ws}/services/${id}`, {
  method: 'PATCH',
  data: {
    name,
    status,
    ports,
    protocol,
    version,
    description,
    owned
  },
  version: 'v3'
});

export const fetchById = (ws, id) => fetchApi(`ws/${ws}/hosts/${id}`);
