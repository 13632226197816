import fetchApi from 'services/api/connector';

export const fetchHosts = (ws, queryParam) => fetchApi(`ws/${ws}/hosts/filter?q=${escape(JSON.stringify(queryParam))}`);

export const getHosts = (ws, stats) => fetchApi(`ws/${ws}/hosts?stats=${stats}`);

export const createHost = (ws, data) => fetchApi(`ws/${ws}/hosts`, { method: 'POST', data });

export const updateHost = (ws, data) => fetchApi(`ws/${ws}/hosts/${data.id}`, { method: 'PATCH', data });

export const deleteHost = (ws, ids) => fetchApi(`ws/${ws}/hosts`, { method: 'DELETE', data: { ids }, version: 'v3' });
