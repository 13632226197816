import React from 'react';
import { useDispatch } from 'react-redux';
import { showEditModal } from 'Screens/Workspaces/actions/Actions';
import IconButton from 'Common/Components/IconButton';
import { openModal } from 'store/modals/actions';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import Edit from './styled';

// eslint-disable-next-line import/prefer-default-export
export const WsEditButton = () => {
  const dispatch = useDispatch();

  const showEditionModal = () => {
    dispatch(showEditModal());
    dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));
  };

  return <IconButton icon={ <Edit /> } title="Edit" onClick={ showEditionModal } />;
};
