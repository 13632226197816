import styled from 'styled-components';
import colors from 'Styles/colors';

const WrapperButton = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
`;
WrapperButton.displayName = 'WrapperButton';

export default WrapperButton;
