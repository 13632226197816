import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import api from 'services/api';
import { setError, updateVuln } from 'store/Manage/actions';
import { selectVulnDetail, selectReadOnly, selectPermissions } from 'store/Manage/selectors';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectCsrfToken } from 'store/Sesion/selectors';

const TAB = 'general';
const ID = 'description';

const Description = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentVuln = useSelector(selectVulnDetail);
  const description = get(currentVuln, ID, '');
  const updateValue = (field, value) => dispatch(updateVuln(currentVuln, field, value));
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const disabled = readOnly || !permissions.update.allowed;
  const isExpanded = useExpandable(TAB, ID, description);
  const vulnId = get(currentVuln, '_id', 0);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const csrfToken = useSelector(selectCsrfToken);

  const onImageUpload = async (file) => {
    try {
      const filename = file.name.replace(/\s+/g, '');
      const renameEvidence = new File([file], filename);
      const fd = new FormData();
      fd.append('csrf_token', csrfToken);
      fd.append('file', renameEvidence);
      const response = await api.attachments.saveAttachments(workspaceSelected, vulnId, fd);
      if (response && (response.success !== false)) {
        return `/_api/v3/ws/${workspaceSelected}/vulns/${vulnId}/attachment/${filename}`;
      }
    } catch (e) {
      dispatch(setError(e.message));
    }
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.description' }) } tab={ TAB } id={ ID }>
      <MarkdownEditor
        value={ description }
        onBlur={ (value) => updateValue('desc', value) }
        disabled={ disabled }
        minHeight={ 170 }
        uploadImage
        onImageUpload={ onImageUpload }
      />
    </ExpandableWrapper>
  );
};

export default Description;
