import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const RedAster = styled.span`
  color: #ea3158;
  bottom: 12px;
  position: relative;
  left: 2px;
`;
RedAster.displayName = 'RedAster';
