import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { PropTypes } from 'prop-types';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  BlueText, Warning, ModalBody
} from './styled';
import Button from 'Common/Kit/Button';

const ConfirmationDialog = ({
  show, title, count, upperCaseTitle, handleClose, handleSubmit, isLoading
}) => {
  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>
            {title}
          </Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>You’re editing</BodyText>
          <BlueText>
            {`${count} ${upperCaseTitle}(s)`}
          </BlueText>
          <BodyText>and changing their properties. Are you sure?</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <Button onClick={ handleClose } color="white">
            Cancel
          </Button>
          <Button onClick={ handleSubmit } isLoading={ isLoading } >
            Save
          </Button>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

ConfirmationDialog.propTypes = {
  show: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  upperCaseTitle: PropTypes.string.isRequired
};

ConfirmationDialog.defaultProps = {
  show: true
};

export default ConfirmationDialog;
