import {
  ADD_COMMAND,
  UPDATE_COMMAND_LIST_STATUS,
  SHOW_PROCESSING_QUEUE,
  REMOVE_COMMAND,
  RESET_FILE_UPLOAD_CONTEXT,
  EXPAND_PROCESSING_QUEUE,
  SET_ERROR_IN_COMMAND,
  REMOVE_UNFINISHED_COMMANDS
} from 'Common/Components/FileUploadContext/actions';

const initialState = {
  commandsInProcess: [],
  showProcessingQueue: false
};

function fileUploaderContext (state = initialState, action) {
  switch (action.type) {
    case SHOW_PROCESSING_QUEUE: {
      return {
        ...state,
        showProcessingQueue: action.show,
        processingQueueisExpanded: action.show
      };
    }

    case EXPAND_PROCESSING_QUEUE: {
      return {
        ...state,
        processingQueueisExpanded: action.expand
      };
    }

    case ADD_COMMAND: {
      return {
        ...state,
        commandsInProcess: [
          ...state.commandsInProcess,
          action.command
        ]
      };
    }

    case UPDATE_COMMAND_LIST_STATUS: {
      return {
        ...state,
        commandsInProcess: action.data
      };
    }

    case REMOVE_COMMAND: {
      const commands = [...state.commandsInProcess];
      const filterList = action.mockCommandId ? commands.filter((command) => command.mockCommandId !== action.mockCommandId) : commands.filter((command) => command.command_id !== action.commandId);
      return {
        ...state,
        commandsInProcess: filterList
      };
    }

    case SET_ERROR_IN_COMMAND: {
      const commands = [...state.commandsInProcess];
      const filterList = action.mockCommandId ? commands.filter((command) => command.mockCommandId !== action.mockCommandId) : commands.filter((command) => command.command_id !== action.commandId);
      const command = {
        mockCommandId: action.mockCommandId,
        name: action.name,
        fileType: 'file',
        error: true,
        errorMessage: action.errorMessage,
        finished: true
      };

      return {
        ...state,
        commandsInProcess: [
          ...filterList,
          command
        ]
      };
    }

    case RESET_FILE_UPLOAD_CONTEXT: {
      return {
        ...state,
        commandsInProcess: initialState.commandsInProcess
      };
    }

    case REMOVE_UNFINISHED_COMMANDS: {
      const commands = [...state.commandsInProcess];
      const filteredCommands = commands.filter((command) => command.finished);

      return {
        ...state,
        commandsInProcess: filteredCommands
      };
    }

    default: {
      return state;
    }
  }
}

export default fileUploaderContext;
