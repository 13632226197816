/* eslint-disable react/no-children-prop */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Accept, Cancel } from '../Button/styled';
import {
  Wrapper, WrapperTitle, Title, Footer, Message
} from './styled';

const Modal = ({
  title, icon, children, onAccept, onClose, acceptLabel, closeLabel
}) => (
  <Wrapper>
    <WrapperTitle show={ icon || title }>
      {icon}
      <Title children={ title } />
    </WrapperTitle>
    <Message children={ children } />
    <Footer show={ onClose || onAccept }>
      { onClose && <Cancel onClick={ onClose } children={ closeLabel } /> }
      { onAccept && <Accept onClick={ onAccept } children={ acceptLabel } /> }
    </Footer>
  </Wrapper>
);

Modal.propTypes = {
  acceptLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  title: PropTypes.string
};

Modal.defaultProps = {
  acceptLabel: 'Accept',
  closeLabel: 'Close',
  title: ''
};

export default Modal;
