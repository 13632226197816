export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export function setError (message) {
  return {
    type: SHOW_ERROR,
    message
  };
}

export function hideError () {
  return {
    type: HIDE_ERROR
  };
}
