import EvidenceTab from 'Common/Components/EvidenceTab';
import React from 'react';
import { Wrapper } from '../General/styled';

const Evidence = () => (
  <Wrapper>
    <EvidenceTab isEditing />
  </Wrapper>
);

export default Evidence;
