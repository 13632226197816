/* eslint-disable import/prefer-default-export */
import React from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import {
  FaAngleDown as Expand,
  FaAngleUp as Unexpand
} from 'react-icons/fa';
import { Content, Capitalized, Text } from './styled';
import { Severity } from '..';

export const Expander = ({
  isFetching, isExpanded, original, group_by
}) => {
  const count = get(original, 'count', 0);
  const vulnsCountString = `(${count})`;
  const isGroupingBy = (field) => group_by === field;
  const ExpandIcon = () => (isExpanded ? <Unexpand /> : <Expand />);
  const FieldText = () => {
    if (isFetching) return null;
    if (isGroupingBy('severity')) return <Severity vuln={ original } />;
    if (isGroupingBy('status')) return <Capitalized children={ original[group_by] } />;
    if (isGroupingBy('cve_instances__name')) {
      return (
        <>
          <p children={ `${original[group_by]}` } />
          <p children={ `Target: ${original.target}` } />
        </>
      );
    }
    return <p>{original[group_by]}</p>;
  };

  return (
    <Content>
      <ExpandIcon />
      <FieldText />
      <Text show={ !isFetching } children={ vulnsCountString } />
    </Content>
  );
};

Expander.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  original: PropTypes.object.isRequired,
  group_by: PropTypes.string.isRequired
};
