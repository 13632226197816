import React from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import Text from './styled';

// eslint-disable-next-line import/prefer-default-export
export const GenericCell = ({
  rowInfo, attrib, defVal, capitalize, showSkeleton
}) => {
  const label = get(rowInfo, `original.${attrib}`, defVal);
  if (showSkeleton) return <Skeleton />;
  return (
    <Text capitalize={ capitalize }>
      { label }
    </Text>
  );
};

GenericCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  attrib: PropTypes.string.isRequired,
  defVal: PropTypes.string,
  capitalize: PropTypes.bool,
  showSkeleton: PropTypes.bool
};

GenericCell.defaultProps = {
  defVal: '',
  capitalize: false,
  showSkeleton: false
};
