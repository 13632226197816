import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { setPage } from 'store/Manage/filterActions';
import { downloadVulns } from 'store/Manage/actions';
import { selectPage, selectRowsPerPage, selectGroupByField } from 'store/Filters/selectors';
import {
  selectVulnsSelected, selectVulnsCount,
  selectShowDetail,
  selectIsExportingCSV
} from 'store/Manage/selectors';
import VulnFlagFilter from 'Common/Components/VulnFlagFilter';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/CustomActionBar/styled';
import Pagination from 'Common/Components/Pagination';
import CreateActions from 'Screens/Manage/components/CreateActions';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import OpenContextMenuButton from '../OpenContextMenu';
import GroupFieldSelector from '../GroupFieldSelector';
import FilterSideBarIcon from '../FilterSidebarIcon';
import DuplicateButton from '../DuplicateButton';
import TagButton from '../TagButton';
import RefreshButton from '../RefreshButton';
import AddRemoveColumnsButton from '../AddRemoveColumnsButton';
import { Download, DownloadButton } from '../../styled';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const showVulnDetail = useSelector(selectShowDetail);
  const page = useSelector((state) => selectPage('vulns', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulns', state));
  const isGrouping = useSelector((state) => selectGroupByField('vulns', state));
  const hasVulns = vulnsCount > 0;
  const showVulnsActionButtons = (!readonly && vulnsSelected.length > 0);
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));
  const isExportingCSV = useSelector(selectIsExportingCSV);

  return (
    <Wrapper>
      <DeleteConfirmationModal />
      <LeftGroup>
        {!readonly && <CreateActions /> }
        { showVulnsActionButtons &&
          <>
            <Separator />
            <Group>
              <EditButton />
              <TagButton setFF={ setFF } />
              <DeleteButton />
              <DuplicateButton setFF={ setFF } />
              <OpenContextMenuButton display={ showVulnsActionButtons && vulnsSelected.length > 0 } />
            </Group>
          </>
        }
      </LeftGroup>

      <RightGroup>
        { (hasVulns && !isGrouping) && <VulnFlagFilter /> }

        { ((hasVulns) && (vulnsSelected.length === 0 && !showVulnDetail)) && <GroupFieldSelector /> }

        { <Pagination offset={ (hasVulns && !isGrouping) ? page : null } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />}

        { hasVulns &&
          <Group>
            <DownloadButton disabled={ isExportingCSV } title={ isExportingCSV ? 'Exporting CSV' : 'Download Filtered Workspace' } onClick={ () => dispatch(downloadVulns()) }>
              <Download />
            </DownloadButton>
            <RefreshButton />
            <FilterSideBarIcon entity="vulns" />
          </Group>
        }

        <Group>
          <AddRemoveColumnsButton />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
