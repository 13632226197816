import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as AddIcon } from 'Images/icon-action-bar-plus.svg';

export const Title = styled.h2`
font-size: 21.5px;
font-weight: bold;
color: #1c2243;
margin-bottom:11px;
`;

export const SubTitle = styled.h3`
  font-size: 17px;
  font-weight: 600;
  color: #1c2243;
  margin-top:10px;
`;

export const TextItem = styled.p`
  font-size: 14px;
  color: #63758d;
  margin-bottom:12px !important;
`;

export const TextInfo = styled.p`
font-size: 13.5px;
  font-weight: 500;
  color: #0082ed;
  margin-bottom:13px;
  cursor: pointer;
`;

export const TextInput = styled.p`
font-size: 14.5px;
font-weight: 600;
color: #1c2243;
margin: 12px 0 !important;
&:after{
content: "*";
color: red;
margin-left: 5px;
}
`;

export const TextLink = styled.p`
font-size: 14.5px;
letter-spacing: 0.11px;
color: #a4247a;
`;

export const Wrapper = styled.div`
padding-left: 66px;
height: 100%;
`;

export const ContentWrapper = styled.div`
height: calc(100% - 102px);
overflow: auto;
`;

export const Input = styled.input`
  font-size: 14.5px;
  font-weight: normal;
  letter-spacing: 0.11px;
  box-shadow: inset 0 -1px 0 0 ${colors.grey16};
  border: 0;
  width: 421px;
  padding: 5px 13px;
  background: ${colors.iceBlue};
  height: 34px;
  &::-webkit-input-placeholder {
    color: ${colors.grey17};
    font-weight: normal;
    letter-spacing: 0.11px;
  }

  &::placeholder {
    font-size: 13px;
    font-weight: 300;
  }
`;
Input.displayName = 'Input';

export const InputName = styled(Input)`
  margin-bottom: 15px;
`;
InputName.displayName = 'Input';

export const WrapperField = styled.div`
  width: 421px;
  padding-bottom: 40px;
`;
WrapperField.displayName = 'WrapperField';

export const Label = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  line-height: 1.33;
  color: ${colors.grey19};
  padding-bottom: 21px;
  height: 34px;
`;
Label.displayName = 'Label';

export const Content = styled.div`
  display: flex;
  //justify-content: space-between;
  margin-bottom: 21px;
  flex-wrap: wrap;
  & > * {
    margin-top: 10px;
    margin-right: 15px;
    width: 252px;
  }
`;

export const Code = styled.code`
font-size: 11px;
line-height: 1.42;
color: #1c2243;
word-break: break-all;
padding: 13px 0;
padding-right: 21px;
`;

export const CodeWrapper = styled.div`
  background:#edf2f7;
  border: solid 1px #bec8d2;
  width: 528px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  margin-right: 20px;
`;

export const PurpleColor = styled.span`
  color: #a4247a;
`;

export const BlueColor = styled.span`
  color: #0082ed;
  font-weight: bold;
  margin-left: 5px;
`;

export const Add = styled(AddIcon)`
  width:10px;
  height: 10px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 21px;
`;

export const SelectTitle = styled.div`
font-size: 15px;
margin-right: 30px;
cursor: pointer;
color:${(props) => (props.selected ? '#0082ed' : '#264d6d')};
font-weight: ${(props) => (props.selected ? '600' : 'normal')};
border-bottom: 3px solid ${(props) => (props.selected ? '#0082ed' : 'transparent')};
`;
