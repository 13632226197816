import styled from 'styled-components';
import colors from 'Styles/colors';

export const Button = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 2px;
  border: solid 1px rgba(144, 169, 192, 0.3);
  cursor: pointer;
  display: flex;
  font-size: 11.5px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  padding: 0 10px;
  & > svg {
    margin-right: 10px;
     & > path {
      fill: ${colors.dark2};
    }
  }
  &:hover {
    background-color: #f1f4f7;
    border-radius: 3px;
    border: solid 1px #d8dee5;
  }
`;
Button.displayName = 'Button';

export const Text = styled.div`
  appearence: none;
  color: ${colors.black};
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
`;
Text.displayName = 'Text';
