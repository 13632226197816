import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import { MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import { Cancel } from 'Common/Components/Button/styled';
import {
  Wrapper, Header, Warning, Title, Message,
  Footer
} from './styled';

const ModalWarning = () => {
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(closeModal(MODAL_EVIDENCE_WARNING_UPLOAD));
  }, [dispatch]);

  const close = () => dispatch(closeModal(MODAL_EVIDENCE_WARNING_UPLOAD));

  return (
    <Wrapper>
      <Header>
        <Warning />
        <Title>Warning</Title>
      </Header>

      <Message>
        The maximum size allowed is 20MB.
      </Message>
      <Footer>
        <Cancel onClick={ close }>Close</Cancel>
      </Footer>
    </Wrapper>
  );
};

export default ModalWarning;
