import React from 'react';
import { DashboardTour } from 'Common/Components/Tour';
import DashboardLayout from '../DashboardLayout';

const Dashboard = () => (
  <>
    <DashboardLayout />
    <DashboardTour />
  </>
);

export default Dashboard;
