import React from 'react';
import { useSelector } from 'react-redux';
import { selectToolHasEnvVars, selectToolHasBeenConfigured, selectToolHasOptionalEnvVars } from 'store/Agents/selectors';
import { ReactComponent as WarningIcon } from 'Images/cog.svg';
import useModal from 'Hooks/useModal';
import Modal from 'Common/Components/Modal';
import Wrapper from './styled';
import ConfigureTool from '../ConfigureTool';

const ConfigureButton = ({ tool }) => {
  const props = useSelector((state) => ({
    toolHasEnvVars: selectToolHasEnvVars(state, tool.name),
    toolHasBeenConfigured: selectToolHasBeenConfigured(state, tool.name),
    toolHasOptionalEnvVar: selectToolHasOptionalEnvVars(state, tool.name)
  }));

  const [ModalConfigureTool, showModalConfigureTool, toggleModalConfigureTool] = useModal(Modal);

  return (props.toolHasEnvVars || props.toolHasOptionalEnvVar) && !props.toolHasBeenConfigured && (
  <Wrapper>
    <WarningIcon id={ `modal-agents-tools-edit-icon-${tool.name}` } onClick={ () => toggleModalConfigureTool(true) } />
    {showModalConfigureTool && <ModalConfigureTool><ConfigureTool tool={ tool } onClose={ () => toggleModalConfigureTool(false) } /></ModalConfigureTool>}
  </Wrapper>
  );
};

export default ConfigureButton;
