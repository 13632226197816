import * as types from './types';

const initialState = {
  attributes: [],
  caToEdit: null
};

export default function customAttributes (state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOM_ATTRIBUTES: {
      return {
        ...state,
        attributes: [...action.attributes]
      };
    }
    case types.SET_CUSTOM_ATTRIBUTE_TO_EDIT: {
      return {
        ...state,
        caToEdit: action.ca
      };
    }
    case types.ADD_CUSTOM_ATTRIBUTE: {
      return {
        ...state,
        attributes: [...state.attributes, action.ca]
      };
    }

    case types.DELETE_CUSTOM_ATTRIBUTE: {
      return {
        ...state,
        attributes: state.attributes.filter((field) => field.id !== action.idToDelete)
      };
    }

    case types.EDIT_CUSTOM_ATTRIBUTE: {
      return {
        ...state,
        attributes: [...action.newCaList],
        caToEdit: null
      };
    }

    case types.SET_CUSTOM_ATTRIBUTES: {
      return {
        ...state,
        attributes: action.attributes
      };
    }

    default: {
      return state;
    }
  }
}
