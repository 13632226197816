/* eslint-disable react/no-children-prop */
import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { PropTypes } from 'prop-types';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from './styled';

const ConfirmationModal = ({
  handleSubmit, handleClose
}) => (
  <ModalWrapper>
    <ModalContent>
      <ModalTitle>
        <Warning />
        <Title>Delete Custom Attribute</Title>
      </ModalTitle>
      <ModalBody>
        <BodyText>You’re deleting a custom attribute. This action cannot be undone. Are you sure you want to proceed?</BodyText>
      </ModalBody>
      <ButtonsContainer>
        <CancelButton onClick={ handleClose } children="Cancel" />
        <ConfirmButton onClick={ handleSubmit } children="Delete" />
      </ButtonsContainer>
    </ModalContent>
  </ModalWrapper>
);

ConfirmationModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ConfirmationModal;
