import get from 'lodash/get';
import initialState from './initialState';

export const selectHostEditCreateId = (state) => get(state, 'hostEditCreate.id', initialState.id);
export const selectHostEditCreateIp = (state) => get(state, 'hostEditCreate.ip', initialState.ip);
export const selectHostEditCreateOs = (state) => get(state, 'hostEditCreate.os', initialState.os);
export const selectHostEditCreateMac = (state) => get(state, 'hostEditCreate.mac', initialState.mac);
export const selectHostEditCreateDescription = (state) => get(state, 'hostEditCreate.description', initialState.description);
export const selectHostEditCreateOwned = (state) => get(state, 'hostEditCreate.owned', initialState.owned);
export const selectHostEditCreateHostnames = (state) => get(state, 'hostEditCreate.hostnames', initialState.hostnames);
export const selectHostEditCreateErrorMessage = (state) => get(state, 'hostEditCreate.errorMessage', initialState.errorMessage);
