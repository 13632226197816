import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  margin: 20px 0px;
  min-height: 114px;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
