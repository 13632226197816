import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const FieldTitle = styled.div`
    font-size: 14.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #1c2243;
    margin-top: 31px;
    margin-bottom: 19px;
`;
FieldTitle.displayName = 'FieldTitle';

export const ErrorMessage = styled.div`
    font-size: 14.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: ${colors.redPink};
    float: left;
    margin-top: 45px;

`;
ErrorMessage.displayName = 'ErrorMessage';
