import styled from 'styled-components';
import { ReactComponent as StarIcon } from 'Images/star.svg';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px ${colors.grey12};
  background-color: ${colors.iceBlue};
  padding: 4px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: min-content;
  & > svg:not(:last-child) {
    margin-right: 4.5px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Star = styled(StarIcon)`
  width: ${({ small }) => (small ? '7.5px' : 'auto')};
  height: ${({ small }) => (small ? '7px' : 'auto')};

  & #star_body {
    fill: ${({ active }) => active ? '#e4d55c' : colors.iceBlue};
    opacity: 1;
  }
  & #star_stroke {
    fill: ${({ active }) => active ? '#979797' : '#bec8d2'};
  }
`;
Star.displayName = 'Star';
