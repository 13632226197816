import { css } from 'styled-components';

export const pressed = css`
  &:active {
    transform: translateY(2px);
  }
`;

export const hover = css`
  &:hover {
    opacity: 1;
  }
`;
