import get from 'lodash/get';
import initialState from './initialState';
import { SHOW_ERROR, HIDE_ERROR } from './actions';

function errorReducer (state = initialState, action) {
  switch (action.type) {
    case HIDE_ERROR: {
      return {
        ...state,
        showError: false
      };
    }

    case SHOW_ERROR: {
      return {
        ...state,
        showError: true,
        message: get(action, 'message', 'There was an error, please try again.')
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}

export default errorReducer;
