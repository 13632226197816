import React from 'react';
import { Wrapper } from './styled';

const Skeleton = ({ opacity = '0.3' }) => (
  <Wrapper>
    <svg width="100%" height="19">
      <defs>
        <linearGradient id="linear-gradient" x1="-100%" y1="0" x2="200%" y2="0">
          <stop offset="0" stopColor="#CCC">
            <animate attributeName="offset" values="0;0.1" dur="1s" repeatCount="indefinite" />
          </stop>
          <stop offset="0.1" stopColor="#d9e4ef">
            <animate attributeName="offset" values="0.5;1;0" dur="1s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
      <g id="placeholder" fill="url(#linear-gradient)" opacity={ opacity }>
        <rect id="Rectangle" x="0" y="0" width="100%" height="19" rx="4" />
      </g>
    </svg>

  </Wrapper>
);

export default Skeleton;
