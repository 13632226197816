import styled from 'styled-components';
import { BackButton } from 'Common/Components/MainContainer/styled';

export const Wrapper = styled.div`
width: 450px;
height: fit-content;
border-radius: 2px;
box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
border: solid 1px #d6d6d6;
background-color: #ffffff;
border-top: 10px solid #419bf9;
padding: 34px;
margin-left: auto;
margin-right: auto;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.label`
  color: #0082ed;
  float: right;
  margin-top: 42px;
  font-size: 12.5px;
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.16;
  text-align: left;
  margin-bottom: 34px;
`;
Title.displayName = 'Title';

export const BackBtn = styled(BackButton)`
  float: right;
  margin-right: 9px
`;
BackBtn.displayName = 'BackBtn';

export const CLink = styled.div`
  margin-top: 5px;
  a{
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left;
    color: #419bf9;    
  }
`;
CLink.displayName = 'CLink';

export const AddMore = styled.div`
  margin-top: 10px;
  cursor:pointer;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #0082ed;

  svg {
    margin-right: 5px;
    font-size: 0.9em;
    position: relative;
    top: -2px;
  }
`;
AddMore.displayName = 'AddMore';
