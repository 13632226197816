import Importance from 'Common/Components/Importance';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateWS } from 'Screens/Workspaces/actions/Actions';
import { Wrapper } from './styled';

const ImportanceColumn = ({ ws }) => {
  const dispatch = useDispatch();
  const callback = (importance) => dispatch(updateWS(ws.name, { importance }, false));

  return (
    <Wrapper>
      <Importance importance={ ws.importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;
