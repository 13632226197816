import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideHostDetail } from 'store/HostDetail/actions';
import Tabs from 'Common/Components/Tabs';
import { selectHostsSelected } from 'store/Host/selectors';
import { selectHostDetail } from 'store/HostDetail/selectors';
import {
  Wrapper, Close, Hostnames, Hostname, VulnsGrouped
} from './styled';
import Vulnerabilities from './Vulnerabilities';
import Services from './Services';
import Ip from './Ip';
import General from './General';

function HostDetail () {
  const data = useSelector(selectHostDetail);
  const hostsSelected = useSelector(selectHostsSelected);
  const dispatch = useDispatch();

  return data
    ? (
    <Wrapper>
      <Close onClick={ () => dispatch(hideHostDetail()) } />
      <Ip />
      <Hostnames>
        { hostsSelected && data && data.host && data.host.hostnames && data.host.hostnames.slice(0, 2).map((host) => (
          <Hostname key={ `host_${host.id}` }>{host.name}</Hostname>
        ))}
      </Hostnames>
      <VulnsGrouped />
      <Tabs>
        <Vulnerabilities title="Vulnerabilities" />
        <Services title="Services" data={ data } />
        <General title="General" data={ data } />
      </Tabs>
    </Wrapper>
      )
    : null;
}

export default HostDetail;
