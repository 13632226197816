/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomAttributeToEdit, removeCustomAttribute } from 'store/CustomAttributes/actions';
import { selectCustomAttributes, selectHasCustomAttributes } from 'store/CustomAttributes/selectors';
import CustomAttribute from '../CustomAttribute';
import ConfirmationModal from './ConfirmationModal';
import Wrapper from './styled';

const AttributeList = () => {
  const dispatch = useDispatch();
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const customAttributes = useSelector(selectCustomAttributes);
  const hasCustomAttributes = useSelector(selectHasCustomAttributes);

  const onEdit = (field) => dispatch(setCustomAttributeToEdit(field));
  const onRemove = (field) => setFieldToDelete(field);
  const onCancel = () => setFieldToDelete(null);

  const handleRemove = () => {
    dispatch(removeCustomAttribute(fieldToDelete));
    setFieldToDelete(null);
  };

  if (!hasCustomAttributes) return null;
  return (
    <Wrapper>
      { fieldToDelete && <ConfirmationModal handleSubmit={ handleRemove } handleClose={ onCancel } />}
      <Droppable droppableId="custom_fields_droppable">
        {(provided) => (
          <div ref={ provided.innerRef } { ...provided.droppableProps }>
            {customAttributes.sort((a, b) => a.field_order - b.field_order).map((field, i) => <CustomAttribute field={ field } index={ i } key={ `Custom_Field_${field.id}` } onRemove={ onRemove } onEdit={ onEdit } />)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Wrapper>
  );
};

export default AttributeList;
