import {
  SAVE_SETTINGS_CHANGE_PASSWORD_START,
  SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS,
  SAVE_SETTINGS_CHANGE_PASSWORD_FAIL
} from 'store/Settings/changePasswordActions';

import {
  LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS,
  SAVE_SETTINGS_LOGIN_SETTINGS_FAIL,
  SAVE_SETTINGS_LOGIN_SETTINGS_START,
  SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR,
  SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS,
  SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD
} from './loginSettingsActions';
import {
  SET_SETTINGS_SHOW_MODAL
} from './settingsActions';

const initialState = {
  changePassword: {
    isFetching: false,
    error: false,
    message: ''
  },
  loginSettings: {
    logMeLastWorkspaceUsed: false
  }
};

function settings (state = initialState, action) {
  switch (action.type) {
    //
    // CHANGE PASSWORD section
    //
    case SAVE_SETTINGS_CHANGE_PASSWORD_START: {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isFetching: true
        }
      };
    }

    case SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePassword: {
          ...initialState.changePassword,
          error: false,
          message: 'Your password was saved successfully.',
          isFetching: false
        }
      };
    }

    case SAVE_SETTINGS_CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    //
    // LOGIN SETTINGS section
    //
    case SET_SETTINGS_CHANGE_LOGIN_SETTINGS_FIELD: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          [action.field]: action.value
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_START: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          isFetching: true
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_CLEAR: {
      return {
        ...state,
        changePassword: {
          ...initialState.changePassword
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_SUCCESS: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          logMeLastWorkspaceUsed: action.logMeLastWorkspaceUsed,
          isFetching: false,
          error: false,
          message: action.message
        }
      };
    }

    case SAVE_SETTINGS_LOGIN_SETTINGS_FAIL: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          isFetching: false,
          error: true,
          message: action.message
        }
      };
    }

    case LOAD_SETTINGS_LOGIN_SETTINGS_SUCCESS: {
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          logMeLastWorkspaceUsed: action.autoSelectWs,
          isFetching: false
        }
      };
    }

    // COMMON SETTINGS section
    case SET_SETTINGS_SHOW_MODAL: {
      return {
        ...state,
        showModal: action.showModal
      };
    }

    default: {
      return state;
    }
  }
}

export default settings;
