/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Wrapper, TextArea } from './styled';

class CustomTextArea extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);

    this.state = {
      value: ''
    };
  }

  componentDidMount () {
    if (this.props.defaultValue !== null) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  componentDidUpdate (prevProps) {
    const deflt = this.props.defaultValue ? this.props.defaultValue : '';
    const prevDeflt = prevProps.defaultValue ? prevProps.defaultValue : '';
    if (prevDeflt !== deflt) {
      this.setState({ value: deflt });
    }
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    if (!this.props.hardUpdate) {
      this.props.updateValue(v);
    }
  }

  onBlurHandler (v) {
    if (this.props.hardUpdate) {
      this.props.updateValue(v);
    }
  }

  render () {
    const {
      title,
      width,
      height,
      placeholder,
      id
    } = this.props;

    const { value } = this.state;

    return (
      <Wrapper width={ width } className={ title.toLowerCase().replace(' ', '-') }>
        <div className="input-list-title">{title}</div>
        <TextArea id={ id } value={ value } width={ width } height={ height } placeholder={ placeholder } onChange={ (e) => this.onChangeHandler(e.target.value) } onBlur={ (e) => this.onBlurHandler(e.target.value) } />
      </Wrapper>
    );
  }
}

CustomTextArea.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  updateValue: PropTypes.func.isRequired,
  hardUpdate: PropTypes.bool,
  defaultValue: PropTypes.string,
  id: PropTypes.string
};

CustomTextArea.defaultProps = {
  title: '',
  width: '392px',
  height: '137px',
  placeholder: 'Write Here',
  hardUpdate: false,
  defaultValue: '',
  id: ''
};

const mapDispatchToProps = () => ({

});

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomTextArea));
