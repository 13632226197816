import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const selectCustomAttributes = (state) => get(state, 'customAttributes.attributes', []);
export const selectHasCustomAttributes = (state) => !isEmpty(selectCustomAttributes(state));
export const selectCAToEdit = (state) => get(state, 'customAttributes.caToEdit', null);
export const selectParsedCustomAttributes = (state) => {
  const unParsedCustomFields = selectCustomAttributes(state);
  const customFields = {};

  unParsedCustomFields.map((field) => {
    customFields[field.field_display_name] = {
      visible: false,
      order: -1
    };

    return customFields;
  });
  return customFields;
};
export const selectCustomAttributesList = (state) => {
  const attributes = get(state, 'customAttributes.attributes', []);
  return attributes.map((attr) => attr.field_name);
};
