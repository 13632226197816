import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRow, unselectTotalVulns } from 'store/Manage/actions';
import Checkbox from 'Common/Components/Checkbox';
import { selectVulnsSelected } from 'store/Manage/selectors';

export const CheckColum = ({ vuln }) => { // eslint-disable-line import/prefer-default-export
  const vulnsSelected = useSelector((state) => selectVulnsSelected(state));
  const dispatch = useDispatch();
  const isSelected = vulnsSelected.find((vulnSelected) => vulnSelected._id === vuln._id);
  const handleClick = (e) => {
    dispatch(unselectTotalVulns());
    dispatch(selectRow(e, vuln));
  };
  return (
    <div title="Select">
      <Checkbox theme="grey" state={ !!isSelected } onChange={ handleClick } />
    </div>
  );
};
