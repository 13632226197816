import styled from 'styled-components';

export const Text = styled.p`
  font-size: 13.5px;
  color: #53686f;
  font-weight: ${({ confirmed }) => (confirmed ? '600' : 'normal')}; 
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
Text.displayName = 'Text';

export default Text;
