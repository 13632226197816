import React from 'react';
import CustomButton from 'Common/Components/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { toogleGridView } from 'Screens/Workspaces/actions/Actions';
import { selectShowGridView } from 'store/Workspace/selectors';
import { Wrapper, ListViewIcon } from './styled';

// eslint-disable-next-line import/prefer-default-export
export const ListViewButton = () => {
  const showGridView = useSelector((state) => selectShowGridView(state));
  const dispatch = useDispatch();

  return showGridView && (
    <Wrapper>
      <CustomButton icon={ <ListViewIcon /> } title="List View" text="List View" onClick={ () => dispatch(toogleGridView(!showGridView)) } />
    </Wrapper>
  );
};
