/**
 * Breakpoints configuration
 *
 * @return object
 */

export default {
  xsm: '(min-width: 700px)',
  sm: '(min-width: 900px)',
  md: '(min-width: 1280px)',
  lg: '(min-width: 1800px)',
  xlg: '(min-width: 2500px)'
};
