import styled from 'styled-components';
import { fadeIn } from 'Styles/effects';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;
Wrapper.displayName = 'Wrapper';

export const Transparency = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0px;
  width: 100%;
  height: 70px;
  background-image: linear-gradient(to bottom, #fafbfc, rgba(216, 216, 216, 0));
  ${fadeIn(0, 1)};
`;
Transparency.displayName = 'Transparency ';

export const CloseIcon = styled(CloseSVG)`
  position: absolute;
  left: 9px;
  top: 9px;
  margin: auto;
  cursor: pointer;
  &>:hover{
    left: 8px;
    top: 8px;
    & g {
      & circle{
        background-color: #edf2f7;
      }  
    }
  }  
  & g {
    & path{
      fill: #63758d;
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseIconWrapper = styled.div`
  flex: 0 0 37px;
  position: relative;
  padding-left: 3px;
  height: 37px;
  margin: auto 0 auto auto;
  cursor: pointer;
  &:hover{
    background-color: #edf2f7;
    border-radius: 50%;
  }  
`;
CloseIconWrapper.displayName = 'CloseIconWrapper';
