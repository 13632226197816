import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_SELECTOR } from 'Screens/Wizard/constants';
import {
  Title, SubTitle
} from 'Common/Components/MainContainer/styled';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { workspaceSelectedForWorking } from 'Screens/Faraday/actions/Actions';
import { selectAutoSelectWs } from 'store/Preferences/workspace/selectors';
import { redirect } from 'store/Router/actions';
import SearchWs from './components/SearchWs';
import { toggleAutoSelectWs } from '../../actions/Actions';
import {
  Wrapper,
  WsSelect,
  WsItem,
  CheckWrapper,
  CheckText,
  WsName
} from './styled';
import { getWorkspaceStats, setWsStatsError } from 'Screens/Workspaces/actions/Actions';
import api from 'services/api';
import get from 'lodash/get';

const StepWorkspaceSelection = ({ onStep }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.sesion.user);
  const autoSelectWs = useSelector((state) => selectAutoSelectWs(state));
  const workspaces = useSelector((state) => state.faraday.workspaces);

  const selectWorkSpace = async (ws) => {
    try {
      const response = await api.workspace.getWsStats(ws.name);
      dispatch(getWorkspaceStats(response));
      const totalVulns = get(response, 'stats.total_vulns', -1);
      if (totalVulns > 0) {
        dispatch(workspaceSelectedForWorking(ws.name));
        dispatch(redirect(`/feed/${ws.name}`));
      } else {
        dispatch(workspaceSelectedForWorking(ws.name));
        onStep(FILE_SELECTOR);
      }
    } catch (error) {
      dispatch(setWsStatsError(error.message));
    }
  };

  const wsList = workspaces.filter((ws) => ws.active);
  const isWsListLong = wsList.length > 3;

  return (
    <Wrapper>
      <SubTitle>{`Welcome ${user}`}</SubTitle>
      <Title className="margin-top-14 margin-bottom-24">Select a Workspace</Title>
      <SearchWs data={ wsList } onSelect={ selectWorkSpace } />
      {
        wsList.length > 0
          ? (
            <WsSelect isWsListLong={ isWsListLong }>
              {
                wsList.map((ws) => (
                  <WsItem key={ `ws_${ws.name}` } onClick={ () => { selectWorkSpace(ws); } }>
                    <WsName title={ ws.name }>{ws.name}</WsName>
                  </WsItem>
                ))
              }
            </WsSelect>
            )
          : null
      }

      <CheckWrapper>
        <CustomCheckbox isChecked={ autoSelectWs } handleCheckboxChange={ () => { dispatch(toggleAutoSelectWs(!autoSelectWs)); } } label="" id="test_id_auto_select_we" />
        <CheckText onClick={ () => { dispatch(toggleAutoSelectWs(!autoSelectWs)); } }>Always log me into my last used Workspace</CheckText>
      </CheckWrapper>
    </Wrapper>
  );
};

export default StepWorkspaceSelection;
