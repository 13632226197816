import styled from 'styled-components';
import colors from 'Styles/colors';

export const TabSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 0px;
  align-items: center;
`;
TabSelectorWrapper.displayName = 'TabSelectorWrapper';

export const TabButton = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 155px;
  height: 34px;
  text-align: center;
  user-select: none;
  background-color: #fafbfc;
  font-weight: ${({ active }) => (active ? '600' : '400')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px;
  color: ${({ active }) => (active ? colors.blueCerulean : colors.darkBlueGrey)};
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.blueCerulean}` : 'none')};
  opacity: 1;
  &:hover {
    opacity: 0.8;
  }
`;
