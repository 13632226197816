import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';

const NotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(redirect('/'));
  }, [dispatch]);

  return null;
};

export default NotFound;
