import styled from 'styled-components';
import { ReactComponent as _EvidenceDefault } from 'Images/evidence-default.svg';

export const ImageWrapper = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 3px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  padding: 7px;
  margin: 10px 22px 0px 22px;
  cursor: pointer;
  position: relative;

  &:hover{
    background-color: #eeeeee;
  }
`;

export const EvidenceDefault = styled(_EvidenceDefault)`
  margin: 15px;
`;

export const BlueText = styled.p`
  color: #0082ed;
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  margin: 5px auto auto auto;
  cursor: pointer;
`;
