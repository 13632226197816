import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch } from 'react-redux';
import { showContextMenu } from 'store/Manage/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_APPLY_TEMPLATE } from 'store/modals/modals';
import { TemplateIcon } from './styled';

const ApplyTemplate = () => {
  const dispatch = useDispatch();

  return (
    <MenuItem
      title="Apply Template"
      icon={ <TemplateIcon /> }
      onClick={ () => {
        dispatch(openModal(MODAL_APPLY_TEMPLATE));
        dispatch(showContextMenu(false));
      } }
    />
  );
};

export default ApplyTemplate;
