import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  height: 60vw;
  overflow: auto;
  padding-right: 7px;
  & > :last-child {
    width: 100%;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Empty = styled.div`
  color: ${colors.grey5};
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
  padding: 15px 2px;
  display: flex;
  justify-content: center;
`;
Empty.displayName = 'Empty';
