import get from 'lodash/get';

export const selectLocation = (state) => get(state, 'router.location', {});

export const selectPathname = (state) => get(state, 'router.location.pathname', '');

export const selectAction = (state) => get(state, 'router.action', '');

export const selectHash = (state) => get(state, 'router.location.hash', '');

export const selectQuery = (state) => get(state, 'router.location.query', {});
