import React from 'react';
import { Wrapper, Star } from './styled';

const Importance = ({ importance, callback, small }) => {
  const handleClick = (e, stars) => {
    callback(importance === stars ? stars - 1 : stars);
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <Star small={ small } onClick={ (e) => handleClick(e, 1) } active={ importance >= 1 } />
      <Star small={ small } onClick={ (e) => handleClick(e, 2) } active={ importance >= 2 } />
      <Star small={ small } onClick={ (e) => handleClick(e, 3) } active={ importance === 3 } />
    </Wrapper>
  );
};

export default Importance;
