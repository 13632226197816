/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'Screens/Host/components/Table';
import ActionBar from './components/ActionBar';
import HostModalEditCreate from 'Screens/HostEditCreate/components/HostModalEditCreate';
import { getData } from 'store/Host/actions';
import {
  hideHostDetail,
  showHostDetailById
} from 'store/HostDetail/actions';
import { connect } from 'react-redux';
import HostDetail from 'Screens/Host/components/HostDetail';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import get from 'lodash/get';
import FilterSideBar from 'Screens/Manage/components/FilterSideBar';
import { closeModal } from 'store/modals/actions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CREATE_SERVICE } from 'store/modals/modals';
import AddEditService from './components/HostDetail/Services/components/AddEditService';
import { Wrapper, TableContainer } from './styled';

class Host extends Component {
  constructor (props) {
    super(props);
    this.state = {
      addRemoveColumnsOpened: false
    };
    this.actionBar = React.createRef();
    this.getTableContainerHeight = this.getTableContainerHeight.bind(this);
  }

  handleParamId () {
    const id = get(this, 'props.params.id', 0);
    if (parseInt(id, 10) > 0) this.props.showHostDetailById(id);
    else this.props.hideHostDetail();
  }

  componentDidMount () {
    this.handleParamId();
  }

  componentWillUnmount () {
    this.handleParamId();
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleParamId();
      this.props.getData();
    }
  }

  getTableContainerHeight () {
    const actionBarHeight = get(this.actionBar, 'current.clientHeight', 34);
    const tableContainerTopMargin = 0;
    return `calc(100% - ${actionBarHeight + tableContainerTopMargin}px)`;
  }

  toggleAddColumns () {
    this.setState((prevState) => ({
      addRemoveColumnsOpened: !prevState.addRemoveColumnsOpened
    }));
  }

  render () {
    const {
      showLeftFilters
    } = this.props;

    return (
      <Wrapper>
        <div ref={ this.actionBar }>
          <ActionBar />
        </div>
        <TableContainer height={ this.getTableContainerHeight() }>
          <Table showLeftFilters={ showLeftFilters } />
          <FilterSideBar entity="assets" />
        </TableContainer>
        <HostDetail />
        <HostModalEditCreate />
        <ReduxModal id={ MODAL_CREATE_SERVICE }>
          <AddEditService onClose={ () => this.props.closeModal() } />
        </ReduxModal>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  showLeftFilters: selectShowHostLeftFilters(state)
});

const mapDispatchToProps = (dispatch) => ({
  showHostDetailById: (host) => {
    dispatch(showHostDetailById(host));
  },
  hideHostDetail: () => {
    dispatch(hideHostDetail());
  },
  getData: () => {
    dispatch(getData());
  },
  closeModal: () => {
    dispatch(closeModal(MODAL_CREATE_SERVICE));
  }
});

Host.propTypes = {
  showHostDetailById: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Host);
