import initialState from './initialState';
import { SET_LOCALE } from './types';

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return action.locale;
    default:
      return state;
  }
}
