import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Close } from 'Images/clear.svg';

export const CloseIcon = styled(Close)`
    margin-left: 6px;
    cursor: pointer;
  `;
CloseIcon.displayName = 'CloseIcon';

const opShadow = 'inset 0 0 12px 0 rgba(144, 169, 192, 0.16)';
const normalShadow = '0 0 5px 0 rgba(0, 0, 0, 0.06)';
const borderStyle = `solid 1px ${colors.grey12}`;

export const FilterTagContainer = styled.div`
  display:flex;
  flex-direction: row;
`;

export const TagContainer = styled.div`
  cursor: default;
  display:flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 6px 7px 5.5px 7px;
  margin-right:     ${({ type }) => (type === 'val' && '5px')};
  background-color: ${({ type }) => (type === 'op' ? `${colors.iceBlue}` : `${colors.paleGrey}`)};
  box-shadow:       ${({ type }) => (type === 'op' ? opShadow : normalShadow)};
  border-top:       ${({ type }) => (type === 'op' && borderStyle)};
  border-bottom:    ${({ type }) => (type === 'op' && borderStyle)};
  border-right:     ${({ valSelected }) => (!valSelected && borderStyle)};
  border:           ${({ type }) => (type !== 'op' && borderStyle)};
  border-radius:    ${({ type }) => {
    if (type === 'key') return '2px 0px 0px 2px';
    if (type === 'val') return '0px 2px 2px 0px';
    return '0px';
  }};
`;

export const TagLabel = styled.div`
  text-align: left;
  font-size: 11.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  white-space: nowrap;
`;
