import styled from 'styled-components';

export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #0b2128;
    margin-bottom:15px
`;
Title.displayName = 'Title';

export const AddAssets = styled.div`

`;
AddAssets.displayName = 'AddAssets';

export const AddedAssets = styled.div`
    border-left: solid 1px #e8e8e8;
    padding-left: 35px;
    height: 480px;
    overflow-y: auto;
`;
AddedAssets.displayName = 'AddedAssets';

export const AssetItem = styled.div`
    width: 370px;
    height: 38px;
    box-shadow: inset 0 -1px 0 0 #cbcdd6;
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.66;
    letter-spacing: 0.11px;
    color: #262d37;
    padding: 5px 10px;
`;
AssetItem.displayName = 'AssetItem';

export const AssetItemWrapper = styled.div`
    
`;
AssetItemWrapper.displayName = 'AssetItemWrapper';

export const RemoveAssetIcon = styled.div`
    padding: 1px 6px 4px;
    float: right;
    margin-left: 10px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 50%;
    position: relative;
    top: -26px;

    &:hover{
        background-color: #95acc2;          
        
        svg {           
            color: white!important;       
        }   
    }
`;
RemoveAssetIcon.displayName = 'RemoveAssetIcon';

export const CreateAsset = styled.div`
    font-weight: 500;
`;
CreateAsset.displayName = 'CreateAsset';

export const AssetTitle = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 225px;
    white-space: nowrap;
    display: block;
    font-weight: 300;
`;
AssetTitle.displayName = 'AssetTitle';

export const EmptyInfoText = styled.div`
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #63758d;
    width: 372px;
`;
EmptyInfoText.displayName = 'EmptyInfoText';

export const AddServiceWarning = styled.div`
    width: 370px;
    height: 34px;    
    padding: 9px 1px 8px 21px;
    background-color: rgba(245, 146, 32, 0.1);
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f59220;
`;
AddServiceWarning.displayName = 'AddServiceWarning';

export const ServiceLink = styled.span`
    color: #0082ed;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: underline;
`;
ServiceLink.displayName = 'ServiceLink';

export const Row = styled.div`
    display:flex;
`;
