import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import collection from 'resources/translations';
import { getLocaleSelector } from 'store/locale/selectors';

const messagesKeys = {};

collection.forEach((translation) => {
  messagesKeys[translation.locale] = translation.keys;
});

const mapStateToProps = (state) => {
  const locale = getLocaleSelector(state);
  return {
    locale,
    messages: messagesKeys[locale]
  };
};

const IntlProviderContainer = connect(
  mapStateToProps
)(IntlProvider);

IntlProviderContainer.displayName = 'IntlProvider';

export default IntlProviderContainer;
