import React from 'react';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectShowManageLeftFilters } from 'store/Manage/selectors';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import Filters from '../../../Filters';

const FilterSideBar = ({ entity }) => {
  const showFilters = useSelector((state) => {
    if (entity === 'vulns') return selectShowManageLeftFilters(state);
    if (entity === 'assets') return selectShowHostLeftFilters(state);
    return false;
  });

  return showFilters && <Filters entity={ entity } />;
};

FilterSideBar.propTypes = {
  entity: PropTypes.string.isRequired
};

export default FilterSideBar;
