import styled from 'styled-components';

export const contextMenuWidth = 200;

export const MenuWrapper = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  top: ${({ yPos }) => `${yPos}px`};
  left: ${({ xPos }) => `${xPos}px`};
  max-height: 515px;
  width: ${`${contextMenuWidth}px`};
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  text-align: left;
  box-shadow: 2px 2px 10px 0 #d5dee6;
  & > :first-child {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  & > :last-child {
    border-radius: 0 0 0.25rem 0.25rem;
  }
  &::-webkit-scrollbar {
    border-radius: 0 0.25rem 0.25rem 0;
    background-color: #ffffff;
    opacity: 1;
  }
`;
MenuWrapper.displayName = 'MenuWrapper';

export const Separator = styled.div`
    display: ${({ show }) => (show ? 'block' : 'none')};
    flex: 1 1 auto;
    height: 2px;
    border: 1px solid #d8e0e7;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 3px;
    margin-bottom: 3px;
`;
Separator.displayName = 'Separator';
