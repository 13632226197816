import styled from 'styled-components';
import colors from 'Styles/colors';

export const Dropdown = styled.div`
margin-top: ${(props) => (props.paddingTop ? `${(props.paddingTop) * 34}px` : `${0}px`)};
max-height: 314px;
min-width: 171px;
max-width: 300px;
margin-bottom: auto;
overflow: hidden;
z-index: 4;
border-radius: 3px;
box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
background-color: #ffffff;
`;

export const DropdownOptionContainer = styled.div`
display: flex;
flex-direction: column;
overflow-y: auto;
overflow-x: hidden;
margin-top: 8px;
margin-bottom: 9px;
max-height: 276px;
`;

export const DropdownOptionLabel = styled.div`
cursor: pointer;
padding-right: 13px;
padding-left: 21px;
font-size: 13.5px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2.52;
letter-spacing: normal;
color: #1c2243;
height: 34px;
text-align: left;
background-color: ${(props) => (`${props.isActive ? colors.iceBlue : ''}`)};
`;
