import styled from 'styled-components';
import colors from 'Styles/colors';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`;
Backdrop.displayName = 'PopupBackdrop';

export const Dropdown = styled.div`
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
    display: flex;
    flex-direction: column;
    height: 118px;
    max-width: 171px;
    overflow: hidden;
    position: absolute;
    top: 27px;
    z-index: 4;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export const DropdownOptionLabel = styled.div`
    cursor: pointer;
    height: 34px;
    padding-right: 13px;
    padding-left: 21px;
    font-size: 13.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.52;
    letter-spacing: normal;
    color: #1c2243;
    text-align: left;
    text-transform: none;

    &:hover{
        background-color: ${colors.iceBlue};
    }
`;
