import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: auto;
  margin-top: 34px;
`;

Wrapper.displayName = 'FieldListWrapper';

export default Wrapper;
