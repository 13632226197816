import React from 'react';
import Checkbox from 'Common/Components/Checkbox';
import { Label, CheckboxWrapper } from './styled';

const CheckboxField = ({ defaultValue, label, onChange }) => {
  return (
    <CheckboxWrapper key={ label } >
      <Checkbox state={ defaultValue } onChange={ (e) => onChange(!e.currentTarget.checked) } />
      <Label children={ label } />
    </CheckboxWrapper>
  );
};
export default CheckboxField;
