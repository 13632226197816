import styled from 'styled-components';

export const TableWrapper = styled.div`
    margin-top: 13px;
    margin-right: -8px;
    margin-left: -8px;
    width: 100%;
    height: ${(props) => props.height};
`;
TableWrapper.displayName = 'TableWrapper';

export const Wrapper = styled.div`
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';
