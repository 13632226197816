import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
  background-color: #edf2f7;
  max-height: 511px; 
  overflow: scroll;
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 20px;
  //height: 511px; /*A ver hacer dinamico*/ 
  ::-webkit-scrollbar {
    width: 5px;
  };
  ::-webkit-scrollbar-corner {
    background: inherit;
  }
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:first-child{
    margin-top: 25px;
  }
 
`;
Wrapper.displayName = 'Wrapper';

export const ToAddColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  font-size: 12.5px;
  color: #1c2243;
  margin-bottom:10px;
  padding: 0 5px;

`;
ToAddColumn.displayName = 'ToAddColumn';

export const Field = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 12px;
`;
Field.displayName = 'Field';
