import React from 'react';
import { useIntl } from 'react-intl';
import AddButton from 'Common/Components/AddButton';

const CreateActions = ({ setVisibilityCreateModal }) => {
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'host.create' });
  const handleShow = () => setVisibilityCreateModal(true);

  return (
    <AddButton
      onClick={ handleShow }
      title={ addText }
      text={ addText }
    />
  );
};

export default CreateActions;
