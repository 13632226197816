const colors = {
  grey1: '#122d46',
  grey2: '#31556d',
  grey3: '#4f7d94',
  grey4: '#668ea2',
  grey5: '#7d9fb0',
  grey6: '#94b0be',
  grey7: '#abc1cd',
  grey8: '#c2d2db',
  grey9: '#d9e3e9',
  grey10: '#f0f4f7',
  grey11: '#8DA6BE',
  grey12: '#6A91A5',
  blue1: '#00365b',
  blue2: '#004980',
  blue3: '#005ca4',
  blue4: '#006fc9',
  blue5: '#0082ed',
  blue6: '#2496f1',
  blue7: '#49aaf4',
  blue8: '#6dbdf8',
  blue9: '#92d1fb',
  blue10: '#b6e5ff',
  red1: '#a32851',
  red2: '#c62d55',
  red3: '#ea3158',
  purple: '#A4247A',
  orange: '#F59220',
  green: '#88CC3F',
  lightBlue: '#82B8E5',
  yellow: '#FFD76B',
  white: '#FFFFFF',
  black: '#000000'
};

export default colors;
