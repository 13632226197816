import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Edit } from 'Images/icon_edit.svg';
import { ReactComponent as DragHandle } from 'Images/icon_drag.svg';
import { Draggable } from 'react-beautiful-dnd';
import { COLOR_BY_CUSTOM_ATTRIBUTE_TYPE, TYPE_TO_NAME } from '../../constants';
import { getFieldMetadataAsList } from '../../functions';
import {
  Container, Field, Name, Type,
  Description, TrashIcon, Icons, Texts, DescriptionContainer
} from './styled';

const CustomAttribute = ({
  field, index, onRemove, onEdit
}) => {
  const { field_display_name, field_type } = field;

  return (
    <Container>
      <Draggable key={ `draggable_${field_display_name}_${index}` } draggableId={ `draggable_${field_display_name}_${index}` } index={ index }>
        {(provided) => (
          <Field
            ref={ provided.innerRef }
            /* eslint-disable react/jsx-props-no-spreading */
            { ...provided.draggableProps }
            /* eslint-disable react/jsx-props-no-spreading */
            { ...provided.dragHandleProps }
          >
            <Texts>
              <Name>{field_display_name}</Name>
              <Type color={ COLOR_BY_CUSTOM_ATTRIBUTE_TYPE[field_type] }>{ TYPE_TO_NAME[field_type] }</Type>
              <DescriptionContainer>
                {field.field_metadata && <Description>{ getFieldMetadataAsList(field.field_metadata) }</Description>}
              </DescriptionContainer>
            </Texts>
            <Icons>
              <Edit onClick={ () => onEdit(field) } />
              <TrashIcon onClick={ () => onRemove(field) } />
              <div style={ { display: 'inline' } }>
                <DragHandle />
              </div>
            </Icons>
          </Field>
        )}
      </Draggable>
    </Container>
  );
};

CustomAttribute.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string.isRequired,
    field_type: PropTypes.string.isRequired,
    field_metadata: PropTypes.string
  }).isRequired
};

export default CustomAttribute;
