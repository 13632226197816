import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(39, 39, 39, 0.2);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  ${slideIn}
`;
ModalWrapper.displayName = 'CustomAttributesModalWrapper';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 567px;
  box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
  border-top: 10px solid ${colors.softBlue};
  border-radius: 2px 2px 0 0;
  padding: 26px 36px 36px 36px;
  max-height: 85%;
`;
Wrapper.displayName = 'CustomFieldsWrapper';

export const Header = styled.div`
  display: flex;
`;

Header.displayName = 'Header';

export const Description = styled.span`
  color: ${colors.grey19};
  font-size: 12.5px;
  line-height: 1.36;
`;

Description.displayName = 'Description';
