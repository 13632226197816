import React from 'react';
import { PropTypes } from 'prop-types';
import SeverityIcon from 'Common/Components/SeverityIcon';

export const Severity = ({ vuln }) => { // eslint-disable-line import/prefer-default-export
  const parsedSeverity = () => {
    if (vuln.severity === 'info') return 'informational';
    if (vuln.severity === 'med') return 'medium';
    return vuln.severity;
  };

  const capitalizedSeverity = vuln.severity && parsedSeverity()[0].toUpperCase() + parsedSeverity().substring(1);
  const severityLetter = vuln.severity ? vuln.severity.charAt(0).toUpperCase() : '';
  return <SeverityIcon title={ `${capitalizedSeverity}` } type={ severityLetter }>{ severityLetter }</SeverityIcon>;
};

Severity.propTypes = {
  vuln: PropTypes.object.isRequired
};
