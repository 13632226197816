import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Subtitle = styled.div`
  margin-top: 8px;
  margin-bottom: 14px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c4566;
  user-select: none;
  text-align: left;
`;
Subtitle.displayName = 'Subtitle';
