// Reset entity
export const RESET_FILTERS = 'RESET_FILTERS';

// Group By filter
export const SET_GROUP_BY = 'SET_GROUP_BY';
export const CLEAR_GROUP_BY = 'CLEAR_GROUP_BY';

// Sort By filter
export const SET_ORDER_BY = 'SET_ORDER_BY';
export const CLEAR_ORDER_BY = 'CLEAR_ORDER_BY';

// Data fetch page
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';

// Filters
export const SET_FILTERS = 'SET_FILTERS';
export const REMOVE_FILTERS = 'REMOVE_FILTERS';
export const SET_ADVANCED_FILTER = 'SET_ADVANCED_FILTER';
export const SET_EXPANDED_FILTER = 'SET_EXPANDED_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_FILTER_ERROR = 'SET_FILTER_ERROR';
export const SET_KEYS = 'SET_KEYS';
export const CLEAR_KEYS = 'CLEAR_KEYS';

// History
export const ADD_FILTER_TO_HISTORY = 'ADD_FILTER_TO_HISTORY';
export const SET_FILTER_HISTORY = 'SET_FILTER_HISTORY';

// Custom Filters
export const SET_CUSTOM_FILTERS = 'SET_CUSTOM_FILTERS';

// Search bar functionality
export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';
export const SET_ADVANCED_MODE = 'SET_ADVANCED_MODE';
export const SET_SAVE_STATUS = 'SET_SAVE_STATUS';

export const SAVE_DASHBOARD_TEMPLATE = 'SAVE_DASHBOARD_TEMPLATE';
