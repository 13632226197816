import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CONFIRMED_FLAG_FILTERS } from 'store/Filters/constants';
import { addFilter, removeFilter } from 'store/Filters/actions';
import { newGetVulns } from 'store/Manage/filterActions';
import { isFilteringBy } from 'store/Filters/selectors';
import FlagIcon from '../FlagIcon';
import { Wrapper, IconWrapper } from './styled';

const VulnFlagFilter = () => {
  const dispatch = useDispatch();
  const { confirmed, notConfirmed } = CONFIRMED_FLAG_FILTERS;
  const themeList = ['allVulns', 'confirmed', 'notConfirmed'];
  const [theme, setTheme] = useState(0);
  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByNotConfirm = useSelector((state) => isFilteringBy(state, 'vulns', notConfirmed));
  const isFilteringByConfirmAndNotConfirmed = isFilteringByConfirm && isFilteringByNotConfirm;

  useEffect(() => {
    if (isFilteringByConfirmAndNotConfirmed) setTheme(0);
    else if (isFilteringByConfirm) setTheme(1);
    else if (isFilteringByNotConfirm) setTheme(2);
    else setTheme(0);
  }, [isFilteringByConfirm, isFilteringByNotConfirm, isFilteringByConfirmAndNotConfirmed, setTheme]);

  const handleClick = () => {
    if (theme === 0) {
      dispatch(removeFilter('vulns', notConfirmed));
      dispatch(addFilter('vulns', confirmed));
    } else if (theme === 1) {
      dispatch(removeFilter('vulns', confirmed));
      dispatch(addFilter('vulns', notConfirmed));
    } else {
      dispatch(removeFilter('vulns', confirmed));
      dispatch(removeFilter('vulns', notConfirmed));
    }
    dispatch(newGetVulns());
  };

  const getLabel = () => {
    if (theme === 0) return 'All vulnerabilities';
    if (theme === 1) return 'Confirmed vulnerabilities';
    if (theme === 2) return 'Not confirmed vulnerabilities';
    return '';
  };

  return (
    <Wrapper>
      <IconWrapper onClick={ handleClick } title={ getLabel() }>
        <FlagIcon theme={ themeList[theme] } />
      </IconWrapper>
    </Wrapper>
  );
};

export default VulnFlagFilter;
