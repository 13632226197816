import styled from 'styled-components';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

const Trash = styled(TrashIcon).attrs({
  id: 'qa-delete-ws'
})`
    height: 17px;
  `;

Trash.displayName = 'Trash';
export default Trash;
