import isEmpty from 'lodash/isEmpty';
import { NAME_TO_TYPE } from './constants';

/* For some reason, the BE sends the option list of the choice CF not as an array, but as a string with the format:
         "[ "option1", "option 2", "option 3"] " so we need to remove the '[]' and the ' "" ' that appear between each
         so with the following line we get an array of strings  */
export const parseOptions = (options = []) => {
  if (isEmpty(options)) return [];
  return options.replace(/\[|\]/g, '').replaceAll('"', '').split(',');
};

export const getFieldMetadataAsList = (fieldMetadata) =>
  (fieldMetadata ? parseOptions(fieldMetadata).join() : '');

// Receives a [option, option2, option3] list and returns "["option1", "option2", "option3"]"
export const formatOptions = (list) => {
  const listOfStrings = list.map((e) => `"${e}"`);
  return `[${listOfStrings}]`;
};

export const newField = (oldFields, name, fieldName, type, currentOptions) => ({
  field_display_name: name,
  field_metadata: isEmpty(currentOptions) ? null : formatOptions(currentOptions),
  field_name: fieldName,
  field_order: oldFields ? oldFields.length + 1 : 0,
  field_type: NAME_TO_TYPE[type.toLowerCase()],
  table_name: 'vulnerability'
});
