import styled from 'styled-components';
import colors from '../colors';
import { spinner } from 'Styles/effects';
import { ButtonText } from '../Typography/styled';

const buttonColors = {
  blue: {
    default: colors.blue5,
    hover: colors.blue4,
    active: colors.blue3
  },
  red: {
    default: colors.red3,
    hover: colors.red2,
    active: colors.red1
  },
  white: {
    default: colors.white,
    hover: colors.white,
    active: colors.white
  }
};

export const Text = styled(ButtonText)`
  color: ${colors.white};
`;

export const StyledButton = styled.button`
  background-color: ${({ color }) => buttonColors[color].default};
  border: none;
  border-radius: 3px;
  padding: ${({ narrow_sides }) => narrow_sides ? '10px' : '10px 25px'};
  border: solid 2px transparent;
  display: flex;
  align-items: center;
  width: max-content;
  height: 38px;

  &:hover {
    background-color: ${({ color }) => buttonColors[color].hover};
  }

  &:active {
    background-color: ${({ color }) => buttonColors[color].active};
  }

  &:focus {
    box-shadow: 0 0 4px 0 rgba(0, 111, 201, 0.4);
    border: solid 2px #006fc9;
  }

  &:disabled {
    border-radius: 3px;
    background-color: #d9e3e9;
    & ${Text} {
      color: ${colors.grey7};
    }
  }
  
  pointer-events: ${({ isLoading }) => isLoading && 'none'};
  ${({ isLoading }) => isLoading && spinner({ diameter: '15px' })};
  
  
  color: ${colors.white};

  ${({ color }) => {
    if (color === 'white') {
      return `
      border-color: ${buttonColors.blue.default};
      & ${Text} {color: ${buttonColors.blue.default};}

      &:hover {
        & ${Text} {color: ${buttonColors.blue.hover};}
        border-color: ${buttonColors.blue.hover};
      }
    
      &:active {
        & ${Text} {color: ${buttonColors.blue.active};}
        border-color: ${buttonColors.blue.active};;
      } 
      `;
  }
}}
`;

StyledButton.displayName = 'Button';

export const IconWrapper = styled.div`
//margin: 0 5px;
  margin: 0 5px 0 0;
  display: flex;

`;

// height: 34px;
// border-radius: 2px;
// background-color: ${(props) => (props.disabled ? colors.blue12 : colors.blueCerulean)};
// padding: 7px 22px;
// color: ${(props) => (props.isLoading ? 'transparent' : colors.white)};
// text-transform: none;
// border: none;
// font-size: 14px;
// font-weight: 500;
// font-stretch: normal;
// font-style: normal;
// line-height: normal;
// letter-spacing: normal;
// text-align: center;
// pointer-events: ${(props) => props.isLoading && 'none'};
// ${(props) => props.isLoading && spinner({ diameter: '15px' })};

// &:hover {
//     background-color: ${(props) => (props.disabled ? '' : colors.blue11)};
// }

// &:active {
//     background-color: ${(props) => (props.disabled ? '' : colors.softBlue)};
// }
