import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  selectCurrentExternalId
} from 'store/Manage/selectors';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';
import Wrapper from './styled';

const TAB = 'general';
const ID = 'externalID';

const ExternalId = () => {
  const intl = useIntl();
  const externalId = useSelector(selectCurrentExternalId);
  const isExpanded = useExpandable(TAB, ID, externalId);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.externalID' }) } tab={ TAB } id={ ID }>
      <Wrapper>
      { externalId || '' }
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default ExternalId;
