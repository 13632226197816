import React from 'react';
import { useIntl } from 'react-intl';
import { Wrapper, Button } from './styled';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE_VULN_DETAIL } from 'store/modals/modals';

const AddTagButton = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const editLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.edit' });
  const onClick = () => dispatch(openModal(MODAL_UPGRADE_LICENSE_VULN_DETAIL));
  return (
    <Wrapper>
      <Button onClick={ onClick } >{ editLabel }</Button>
    </Wrapper>
  );
};

export default AddTagButton;
