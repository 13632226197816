import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { newGetVulns } from 'store/Manage/filterActions';
import { showManageLeftFilters } from 'store/Manage/actions';
import { clearFilters } from 'store/Filters/actions';
import { getData, showHostLeftFilters } from 'store/Host/actions';
import { selectIsFiltering } from 'store/Filters/selectors';

import {
  FiltersHeader, HeaderTitle, ButtonsContainer, ClearAll, Close
} from '../styled';

const Header = ({ entity }) => {
  const dispatch = useDispatch();
  const isFiltering = useSelector((state) => selectIsFiltering(entity, state));

  const setHideFilter = () => {
    if (entity === 'vulns') dispatch(showManageLeftFilters(false, false));
    else if (entity === 'assets') dispatch(showHostLeftFilters(false));
  };

  const clearAll = () => {
    if (isFiltering) {
      dispatch(clearFilters(entity));
      if (entity === 'vulns') dispatch(newGetVulns());
      else if (entity === 'assets') dispatch(getData());
    }
  };
  return (
    <FiltersHeader>
      <HeaderTitle isFiltering={ isFiltering }>Filters</HeaderTitle>
      <ButtonsContainer>
        <ClearAll onClick={ clearAll }>Clear All</ClearAll>
        <Close onClick={ setHideFilter } />
      </ButtonsContainer>
    </FiltersHeader>
  );
};

Header.propTypes = {
  entity: PropTypes.string.isRequired
};

export default Header;
