import styled from 'styled-components';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { fadeIn } from 'Styles/effects';

export const Modal = styled(ModalWrapper)`
  background-color: rgba(0,0,0,0.85);
  ${fadeIn(0, 1)}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 70%;
  width: 70%;
`;
Modal.displayName = 'Modal';

export const Image = styled.img`
  object-fit: scale-down;
`;
Image.displayName = 'Image';

export const CloseIcon = styled(CloseSVG)`
  margin: auto;
  cursor: pointer;
  &>:hover{
    left: 8px;
    top: 8px;
    & g {
      & circle{
        background-color: #edf2f7;
      }  
    }
  }  
  & g {
    & path{
      fill: #63758d;
    }  
  }
`;
CloseIcon.displayName = 'CloseIcon';

export const CloseWrapper = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: #eee;
  border-radius: 50%;
  &:hover{
    background-color: #ddd;
    border-radius: 50%;
  }  
`;
CloseWrapper.displayName = 'CloseWrapper';

export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
CloseWrapper.displayName = 'CloseWrapper';
