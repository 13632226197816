import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled.div`
  display: inline-block;
  color: #fff;
  height: 23px;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: 900;
  line-height: 23px;
  width: ${(props) => `${(props.width)}%`};
`;
Item.displayName = 'Item';

export const Critical = styled(Item)`
  background-color: ${colors.warmPurple};
  box-shadow: inset 0 -2px 0 0 ${colors.warmPurple};
  background-color: ${colors.warmPurple};
`;
Critical.displayName = 'Critical';

export const High = styled(Item)`
  background-color: ${colors.red3};
  box-shadow: inset 0 -2px 0 0 ${colors.red3};
  background-color: ${colors.red3};
`;
High.displayName = 'High';

export const Medium = styled(Item)`
  background-color: ${colors.orange1};
  box-shadow: inset 0 -2px 0 0 ${colors.orange1};
  background-color: ${colors.orange1};
`;
Medium.displayName = 'Medium';
