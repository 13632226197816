/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withBreakpoints } from 'react-breakpoints';
import { connect } from 'react-redux';
import ActionBar from './ActionBar';
import { selectKnowledgeBaseProps } from 'store/KnowledgeBase/selectors';
import { resetFilters, setSelectedEntity } from 'store/Filters/actions';
import { getSelectedEntity } from 'store/Filters/selectors';
import get from 'lodash/get';
import { TableWrapper, Wrapper } from './styled';
import Table from './Table';

class KnowledgeBase extends Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.actionBar = React.createRef();
    this.getTableContainerHeight = this.getTableContainerHeight.bind(this);
  }

  componentDidMount () {
    const { selectedEntity, clearSelectedEntity } = this.props;
    if (selectedEntity !== 'knowledgeBase') clearSelectedEntity();
  }

  componentWillUnmount () {
    const { resetFilters } = this.props;
    resetFilters();
  }

  getTableContainerHeight () {
    const actionBarHeight = get(this.actionBar, 'current.clientHeight', 34);
    const tableContainerTopMargin = 30;
    return `calc(100% - ${actionBarHeight + tableContainerTopMargin}px)`;
  }

  render () {
    return (
      <Wrapper>
        <div ref={ this.actionBar }>
          <ActionBar />
        </div>
        <TableWrapper height={ this.getTableContainerHeight() }>
          <Table />
        </TableWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  ...selectKnowledgeBaseProps(state),
  selectedEntity: getSelectedEntity(state)
});

const mapDispatchToProps = (dispatch) => ({
  resetFilters: () => {
    dispatch(resetFilters('knowledgeBase'));
  },
  clearSelectedEntity: () => {
    dispatch(setSelectedEntity(''));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(KnowledgeBase));
