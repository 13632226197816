import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedEntity, selectFilterHistory, selectLabeledHistory } from 'store/Filters/selectors';
import { removeFilter } from 'store/Filters/actions';
import PropTypes from 'prop-types';
import {
  CloseIcon, TagContainer, TagLabel, FilterTagContainer
} from './styled';
import moment from 'moment';

export const Tag = ({
  showDelete, value, onDelete, type, valSelected, filterKey
}) => {
  const isDate = type === 'val' && filterKey.toLowerCase().includes('date');
  const valueParsed = isDate ? moment(value).format('L') : value;
  if (!value) return null;
  return (
    <TagContainer type={ type } valSelected={ valSelected }>
      <TagLabel>
        {valueParsed}
      </TagLabel>
      { showDelete && <CloseIcon onClick={ onDelete } />}
    </TagContainer>
  );
};

export const FilterTags = ({ onSearch }) => {
  const dispatch = useDispatch();
  const selectedEntity = useSelector(getSelectedEntity);
  const filterHistory = useSelector((state) => selectFilterHistory(selectedEntity, state));
  const labeledFilters = useSelector((state) => selectLabeledHistory(selectedEntity, state));
  const deleteTag = (filterIndex) => {
    dispatch(removeFilter(selectedEntity, filterHistory[filterIndex]));
    onSearch();
  };

  return (
    labeledFilters.map((filter, i) => (
      <FilterTagContainer key={ JSON.stringify(filter) }>
        <Tag type="key" filterKey={ filter.name } value={ filter.name } />
        <Tag type="op" filterKey={ filter.name } value={ filter.op } valSelected={ filter.val } />
        <Tag type="val" filterKey={ filter.name } value={ filter.val } showDelete onDelete={ () => deleteTag(i) } />
      </FilterTagContainer>
    )));
};

export const DraftTag = ({ draftTag }) => (
  <>
    <Tag type="key" value={ draftTag.name } />
    <Tag type="op" value={ draftTag.op } />
  </>
);

Tag.propTypes = {
  showDelete: PropTypes.bool,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  value: PropTypes.string,
  filterKey: PropTypes.string
};

Tag.defaultProps = {
  onDelete: null,
  showDelete: false,
  value: '',
  filterKey: ''
};

DraftTag.propTypes = {
  draftTag: PropTypes.object.isRequired
};
