import React from 'react';
import Button from 'Common/Components/OutlinedButton/styled';

const OutlinedButton = ({
  onClick, label, disabled, className
}) => (
  <Button className={ className } onClick={ onClick } disabled={ disabled }>
    { label }
  </Button>
);

export default OutlinedButton;
