import * as dashboard from './dashboard';
import * as manage from './manage';
import * as faraday from './faraday';
import * as workspace from './workspace';
import * as host from './host';
import * as preferences from './preferences';
import * as hostDetail from './hostDetail';
import * as knowledgeBase from './knowledgeBase';
import * as service from './services';
import * as fileUploaderContext from './fileUploaderContext';
import * as settings from './settings';
import * as customAttributes from './customAttributes';
import * as agents from './agents';
import * as attachments from './attachments';
import * as filters from './filters';
import * as auth from './auth';

export default {
  dashboard,
  manage,
  faraday,
  workspace,
  host,
  preferences,
  hostDetail,
  knowledgeBase,
  service,
  fileUploaderContext,
  settings,
  customAttributes,
  agents,
  attachments,
  filters,
  auth
};
