import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showProcessingQueue } from 'Common/Components/FileUploadContext/actions';
import { Queue, Wrapper, IconWrapper, Count } from './styled';
import { selectUnfinishedCommands } from 'store/FileUploaderContext/selector';

const ProcessingQueueButton = () => {
  const dispatch = useDispatch();
  const unfinishedCommands = useSelector(selectUnfinishedCommands);
  const count = unfinishedCommands.length;

  const handleClick = () => {
    dispatch(showProcessingQueue(true));
  };

  return (
    <Wrapper>
      <IconWrapper onClick={ handleClick } title="Processing Queue">
        <Queue />
        {count ? <Count>{count}</Count> : null }
      </IconWrapper>
    </Wrapper>
  );
};

export default ProcessingQueueButton;
