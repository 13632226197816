import styled from 'styled-components';
import OSX from 'Images/osx.png';
import Linux from 'Images/linux.png';
import Windows from 'Images/windows.png';
import Unknown from 'Images/laptop_icon.svg';
import Shodan from 'Images/shodan.png';
import colors from 'Styles/colors';
import Severity from 'Screens/Host/components/HostDetail/Severity';
import TimeAgo from 'react-timeago';

export const Wrapper = styled.div`
  height: 100%;
  flex: 1 1 0px;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Expander = styled.div.attrs((props) => ({
  className: props.isExpanded ? '-group-expanded' : '-group-unexpanded'
}))``;
Expander.displayName = 'Expander';

export const Icon = styled.img.attrs((props) => {
  if (props.type === 'unix') return { src: Linux };
  if (props.type === 'linux') return { src: Linux };
  if (props.type === 'osx') return { src: OSX };
  if (props.type === 'apple') return { src: OSX };
  if (props.type === 'mac') return { src: OSX };
  if (props.type === 'windows') return { src: Windows };
  if (props.type === 'shodan') return { src: Shodan };
  return { src: Unknown };
})`
  width: 12px;
  pointer-events: none;
  margin: 0 auto;
`;
Icon.displayName = 'Icon';

export const CenteredIcon = styled.div`
  display: flex;
  justify-content: center;
`;
CenteredIcon.displayName = 'CenteredIcon';

export const CenteredText = styled.div`
  text-align: center;
`;
CenteredText.displayName = 'CenteredText';

export const Ip = styled.div`
  font-weight: 500;
  color: ${colors.dark2};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
Ip.displayName = 'Ip';

export const NumericRow = styled.div`
  font-weight: 500;
  color: ${colors.grey11};
  pointer-events: none;
`;
NumericRow.displayName = 'NumericRow';

export const CenteredNumericRow = styled(NumericRow)`
  text-align: center;
  pointer-events: all;
  cursor: default;
`;
NumericRow.displayName = 'NumericRow';

export const Hostnames = styled.div`
  pointer-events: none;
`;
Hostnames.displayName = 'Hostnames';

export const SeverityColumn = styled(Severity)`
`;
SeverityColumn.displayName = 'SeverityColumn';

export const TimeAgoColumn = styled(TimeAgo)`
  pointer-events: none;
`;
TimeAgoColumn.displayName = 'TimeAgoColumn';
