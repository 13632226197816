import React, { Component } from 'react';
import fileImg from 'Images/generic_file.svg';
import PropTypes from 'prop-types';
import LoadingGif from 'Images/faraday_loadingbar.gif';

import {
  FileUploaderContainer,
  FileName,
  LongTimeMessage,
  CustomProgressBar,
  File, LoadingIcon
} from './styled';

class FileUploader extends Component {
  componentDidMount () {
    const { uploadFile } = this.props;
    uploadFile();
  }

  render () {
    const {
      currentUploadState,
      currentUploadError,
      onManage,
      filenames
    } = this.props;

    return (
      <FileUploaderContainer onManage={ onManage } style={ { display: 'inline-block', position: 'relative', textAlign: 'center' } }>
        <>
          <File src={ fileImg } alt="File" />
          <FileName currentUploadState={ currentUploadState }>{filenames}</FileName>
          { currentUploadState !== 'Error' &&
            (
              <CustomProgressBar currentUploadState={ currentUploadState }>
                <LoadingIcon src={ LoadingGif } />
              </CustomProgressBar>
            )
          }
          <LongTimeMessage>{ currentUploadState === 'Error' ? currentUploadError : 'This may take a while' }</LongTimeMessage>
        </>
      </FileUploaderContainer>
    );
  }
}

FileUploader.propTypes = {
  uploadFile: PropTypes.func.isRequired
};

FileUploader.defaultProps = {
};

export default FileUploader;
