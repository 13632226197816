import React from 'react';
import { useSelector } from 'react-redux';
import { getRequestUrl } from 'services/api/connector';
import { useIntl } from 'react-intl';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectVulnDetailId } from 'store/Manage/selectors';
import copyToClipboard from 'utils/clipboard';
import { CopyApiLinkWrapper, CopyApiLinkIcon } from './styled';

const CopyApiLink = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.detail.tab.copyApiLink' });
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const vulnDetailId = useSelector(selectVulnDetailId);

  const copyApiLink = () => {
    if (vulnDetailId && workspaceSelected) {
      const vulnPath = `ws/${workspaceSelected}/vulns/${vulnDetailId}`;
      const link = getRequestUrl(vulnPath);
      copyToClipboard(link);
    }
  };

  return (
    <CopyApiLinkWrapper title={ title } onClick={ copyApiLink }>
      <CopyApiLinkIcon />
    </CopyApiLinkWrapper>
  );
};

export default CopyApiLink;
