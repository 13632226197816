import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  & > *:not(:first-child) {
    margin-left: 30px;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
