import React, { useState } from 'react';
import MainContainer from 'Common/Components/MainContainer';
import { resetPassword } from 'Screens/Login/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import isValidPassword from 'Common/Components/Password/functions';
import BlueButton from 'Common/Components/BlueButton/index';
import { redirect } from 'store/Router/actions';
import {
  Title,
  SubTitle,
  PasswordChanged,
  Login,
  Rule,
  ErrorMessage,
  ResetPass,
  Container
} from './styled';

const ResetPassword = ({ params }) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const intl = useIntl();
  const props = useSelector((state) => (
    {
      isFetching: state.sesion.isFetching,
      error: state.sesion.error,
      errorMessage: state.sesion.errorMessage
    }));
  const dispatch = useDispatch();

  const summitPassword = () => {
    setSubmitted(true);
    dispatch(resetPassword(params.hash, password, repeatPassword));
  };

  const isPasswordMatch = () => password === repeatPassword;

  const passPasswordTest = () => isValidPassword(password) && isPasswordMatch();

  return (
    <MainContainer minHeight="440px">
      <Title>Reset your password?</Title>
      <SubTitle>Please enter your new password.</SubTitle>
      <ResetPass placeholder={ intl.formatMessage({ id: 'resetpassword.enter.password' }) } onChange={ (v) => { setPassword(v); } } valid={ isValidPassword(password) } />
      <ResetPass placeholder={ intl.formatMessage({ id: 'resetpassword.repeat.password' }) } onChange={ (v) => { setRepeatPassword(v); } } valid={ isValidPassword(repeatPassword) } />
      { !props.isFetching && submitted && isValidPassword(password) && props.error ? <ErrorMessage>{ props.errorMessage }</ErrorMessage> : null }
      { ((password && !isValidPassword(password)) || (repeatPassword && !isValidPassword(repeatPassword))) &&
        (
        <Rule>
          <FormattedMessage id="resetpassword.rule" />
        </Rule>
        )}
      { isValidPassword(password) && isValidPassword(repeatPassword) && !isPasswordMatch() &&
        (
        <Rule>
          <FormattedMessage id="resetpassword.doesntmatch" />
        </Rule>
        )}

      { submitted && !props.error ? <PasswordChanged><FormattedMessage id="resetpassword.success" /></PasswordChanged> : null }
      <Container>
        <Login onClick={ () => { dispatch(redirect('/login')); } }>{'<  Go Back'}</Login>
        { !submitted || props.error ? <BlueButton disabled={ !passPasswordTest() } onClick={ () => summitPassword() } label="Submit" isLoading={ props.isFetching } /> : null }
      </Container>

    </MainContainer>
  );
};

export default ResetPassword;
