import colors from 'Styles/colors';

const CustomAttribute = (name, desc, color) => ({
  name,
  desc,
  color
});

export const FIELD_TYPES = [
  CustomAttribute('String', 'String', colors.warmPurple),
  CustomAttribute('Integer', 'Integer', colors.softBlue),
  CustomAttribute('List', 'List', colors.redPink),
  CustomAttribute('Choice', 'Choice', colors.apple),
  CustomAttribute('Date', 'Date', colors.tangerine)
];

export const COLOR_BY_CUSTOM_ATTRIBUTE_TYPE = {
  str: colors.warmPurple,
  int: colors.softBlue,
  list: colors.redPink,
  choice: colors.apple,
  date: colors.tangerine
};

export const CUSTOM_ATTRIBUTE_TYPES = {
  STRING: 'str',
  INTEGER: 'int',
  LIST: 'list',
  CHOICE: 'choice',
  DATE: 'date'
};

export const NAME_TO_TYPE = {
  string: CUSTOM_ATTRIBUTE_TYPES.STRING,
  integer: CUSTOM_ATTRIBUTE_TYPES.INTEGER,
  list: CUSTOM_ATTRIBUTE_TYPES.LIST,
  choice: CUSTOM_ATTRIBUTE_TYPES.CHOICE,
  date: CUSTOM_ATTRIBUTE_TYPES.DATE
};

export const TYPE_TO_NAME = {
  [CUSTOM_ATTRIBUTE_TYPES.STRING]: 'String',
  [CUSTOM_ATTRIBUTE_TYPES.INTEGER]: 'Integer',
  [CUSTOM_ATTRIBUTE_TYPES.LIST]: 'List',
  [CUSTOM_ATTRIBUTE_TYPES.CHOICE]: 'Choice',
  [CUSTOM_ATTRIBUTE_TYPES.DATE]: 'Date'
};

export const TYPE_TO_PLACEHOLDER = {
  [CUSTOM_ATTRIBUTE_TYPES.STRING]: 'customAttributes.string.placeholder',
  [CUSTOM_ATTRIBUTE_TYPES.INTEGER]: 'customAttributes.integer.placeholder',
  [CUSTOM_ATTRIBUTE_TYPES.LIST]: 'customAttributes.list.placeholder',
  [CUSTOM_ATTRIBUTE_TYPES.CHOICE]: 'customAttributes.choice.placeholder',
  [CUSTOM_ATTRIBUTE_TYPES.DATE]: 'customAttributes.date.placeholder'
};

export const CUSTOM_ATTRIBUTES_MODAL_ID = 'create-custom-attribute-modal-id';
