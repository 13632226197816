import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';

import { selectOrderBy } from 'store/Filters/selectors';
import { selectWsEditCreateId } from 'store/WorkspaceEditCreate/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const selectRoutes = (state) => {
  const permissions = get(state, 'faraday.permissions', []);
  const routeList = [];

  if (permissions) {
    // TODO: Add validation and when custom attributes exists in permissions
    /* if (this.props.faraday.permissions.custom_fields && this.props.faraday.permissions.custom_fields.view.allowed) */
    if (permissions.workspaces && permissions.workspaces.view.allowed) routeList.push({ name: '/workspaces', desc: 'Workspaces' });
    if (permissions.vulnerability_template && permissions.vulnerability_template.view.allowed) routeList.push({ name: '/knowledge_base', desc: 'Knowledge Base' });
  }
  return routeList;
};
export default selectRoutes;

export const selectWorkspacesSelected = (state) => get(state, 'workspace.workspacesSelected', []);
export const selectCurrentlySelectedWorkspace = (state) => get(state, 'workspace.workspaceSelected', []);
export const selectWorkspacesCount = (state) => get(state, 'workspace.workspaceCount', 0);
export const selectLastWorkspaceSelected = (state) => get(state, 'workspace.lastWorkspaceSelected', {});
export const selectLastIndexForRange = (state) => get(state, 'workspace.lastIndexForRange', -1);
export const selectLastSelected = (state) => get(state, 'workspace.lastSelected', -1);
export const selectShowGridView = (state) => get(state, 'workspace.showGridView', false);
export const selectWorkspaceList = (state) => get(state, 'workspace.workspaceList', []);
export const selectRedirect = (state) => get(state, 'workspace.redirect', false);
export const selectShowModalCreate = (state) => get(state, 'workspace.showModalCreate', false);
export const selectMoreOptionsOpened = (state) => get(state, 'workspace.moreOptionsOpened', false);
export const selectIsFetching = (state) => get(state, 'workspace.isFetching', false);
export const selectShowInactive = (state) => get(state, 'workspace.showInactive', false);
export const selectActiveWorkspaces = (state) => {
  const workspaces = get(state, 'faraday.workspaces');
  return filter(workspaces, { active: true });
};

export const selectWorkspacesForListView = (state) => get(state, 'workspace.workspacesForListView', []);
export const selectWsDataSelected = (state) => get(state, 'workspace.currentWs', {});
export const selectTotalVulns = (state) => get(state, 'workspace.currentWs.stats.total_vulns', 0);

export const selectWorkspacesOrdered = (state) => {
  const showGridView = selectShowGridView(state);
  const workspacesForListView = selectWorkspacesForListView(state);
  const workspacesList = selectWorkspaceList(state);
  const workspaces = showGridView ? workspacesList : workspacesForListView;
  const orderBy = selectOrderBy('workspaces', state);
  const orderedWorkspaces = orderBy.length > 0 ? workspaces : workspaces.sort((a, b) => b.stats.critical_vulns - a.stats.critical_vulns);
  return orderedWorkspaces;
};

export const selectCurrentWorkspaceData = (state) => {
  const showGridView = selectShowGridView(state);
  const workspacesForListView = selectWorkspacesForListView(state);
  const workspacesList = selectWorkspaceList(state);
  const workspaces = showGridView ? workspacesList : workspacesForListView;
  const id = selectWsEditCreateId(state);
  const currentWs = workspaces.find((ws) => ws.id === id);
  return currentWs || null;
};

export const selectWorkspacesForSelector = (state) => get(state, 'workspace.workspacesForSelector', []);

export const selectVulnsCountForManage = (state) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const activeWorkspaces = selectActiveWorkspaces(state);
  const ws = find(activeWorkspaces, { name: currentWorkspace });
  return get(ws, 'stats.total_vulns', 0);
};

export const selectPermissions = (state) => {
  const permissions = get(state, 'faraday.permissions.workspaces', {});
  return permissions;
};

export const selectRowsPerPageListView = (state) => get(state, 'workspace.rowsPerPageListView', 50);

export const selectLoadingWsForDropdown = (state) => get(state, 'workspace.loadingWsForDropdown', false);
