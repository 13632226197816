import React from 'react';
import NotIncludedList from './components/NotIncludedList';
import DragList from './components/DragList';
import Header from './components/Header';
import Help from './components/Help';
import {
  CustomContextMenu, Wrapper, Columns, AddedColumnsWrapper
} from './styled';

// TODO: toggle menu state

const EditColumnContext = ({
  trigger, toggle, fields, setFieldVisibility, setNewOrderColumns, resetDefault
}) => (
  <CustomContextMenu id={ trigger } onHide={ toggle } onShow={ toggle }>
    <Wrapper>
      <Header resetDefault={ resetDefault } />
      <Columns>
        <AddedColumnsWrapper onClick={ (e) => e.stopPropagation() }>
          <DragList fields={ fields } setFieldVisibility={ setFieldVisibility } setNewOrderColumns={ setNewOrderColumns } />
          <Help />
        </AddedColumnsWrapper>
        <NotIncludedList fields={ fields } setFieldVisibility={ setFieldVisibility } />
      </Columns>
    </Wrapper>
  </CustomContextMenu>
);

export default EditColumnContext;
