import React, { useMemo } from 'react';
import ExpandableWrapper from '../ExpandableWrapper';
import { useIntl } from 'react-intl';
import {
  Risk, Text, Line, Link
} from './styled';
import { useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import useExpandable from 'Hooks/useExpandable';
import CVE from '../CVE';

const TAB = 'general';
const ID = 'risk';

const RiskAndCVE = () => {
  const intl = useIntl();
  const currentVuln = useSelector(selectVulnDetail);
  const isExpanded = useExpandable(TAB, ID, currentVuln.cve);

  const vulnCvss = () => {
    if (!currentVuln.cvss3) return null;
    if (currentVuln.cvss3.base_score) return { ...currentVuln.cvss3, isV3: true };
    else if (currentVuln.cvss2.base_score) return { ...currentVuln.cvss2, isV3: false };
    else return null;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cvss = useMemo(() => vulnCvss(), [currentVuln.cvss3, currentVuln.cvss2]);
  const cwe = useMemo(() => currentVuln.cwe[0], [currentVuln.cwe]);
  const owasp = useMemo(() => currentVuln.owasp[0], [currentVuln.owasp]);
  const risk = useMemo(() => currentVuln.risk, [currentVuln.risk]);

  const title = (!cvss && !cwe && !owasp && !risk?.score) ? intl.formatMessage({ id: 'manage.detail.tab.general.cve' }) : intl.formatMessage({ id: 'manage.detail.tab.general.risk' });
  const showRiskInfo = cvss || cwe || owasp || risk?.score;

  return (
    <ExpandableWrapper id={ ID } title={ title } defaultValue={ isExpanded } tab={ TAB }>
     { showRiskInfo &&
      <Risk>
        <table>
        {cvss && <Line>
            <Text bold>CVSS {cvss.isV3 ? 'v3.0' : 'v2.0'} Base Score</Text>
            <Text>{cvss.base_score}</Text>
                 </Line>}
        {cvss && <Line>
            <Text pb bold>CVSS {cvss.isV3 ? 'v3.0' : 'v2.0'} Vector</Text>
            <Text pb>{cvss.vector_string}</Text>
                 </Line>}
        {cwe && <Line >
            <Text pb bold>CWE Weakness Type</Text>
            <Text pb ><Link target="_blank" href={ `https://cwe.mitre.org/data/definitions/${cwe.split('-')[1]}.html` } rel="noopener noreferrer">{cwe}</Link></Text>
                </Line>}
        </table>

      </Risk>}
      <CVE showRiskInfo={ showRiskInfo } />
    </ExpandableWrapper>
  );
};

export default RiskAndCVE;
