import {
  SET_WORKSPACE_FOR_EDIT,
  RESET_STATE_WORKSPACE_CREATE_UPDATE,
  WORKSPACE_CREATE_UPDATE_START,
  WORKSPACE_CREATE_UPDATE_FAIL,
  WORKSPACE_CREATE_UPDATE_SUCCESS,
  SET_FIELD
} from 'Screens/Workspaces/actions/Actions';

import {
  SET_FIELD_NAME_HOST_CREATE_UPDATE
} from 'store/HostEditCreate/types';

const initialState = {
  id: null,
  name: '',
  description: '',
  start: '',
  end: '',
  scope: [],
  isFetching: false,
  errorMessage: '',
  redirect: false,
  users: [],
  currentName: '',
  workspaceSaved: false
};

function workspaceEditCreate (state = initialState, action) {
  switch (action.type) {
    case SET_FIELD: {
      return {
        ...state,
        [action.field]: action.value
      };
    }

    case RESET_STATE_WORKSPACE_CREATE_UPDATE: {
      return {
        ...initialState
      };
    }

    case SET_FIELD_NAME_HOST_CREATE_UPDATE: {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }

    case WORKSPACE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case WORKSPACE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error,
        workspaceSaved: false
      };
    }

    case WORKSPACE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceSaved: true
      };
    }

    case SET_WORKSPACE_FOR_EDIT: {
      return {
        ...state,
        ...action.workspace,
        currentName: action.workspace.name,
        start: action.workspace.duration.start_date ? new Date(action.workspace.duration.start_date) : '',
        end: action.workspace.duration.end_date ? new Date(action.workspace.duration.end_date) : ''
      };
    }

    default:
      return state;
  }
}

export default workspaceEditCreate;
