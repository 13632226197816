import fetchApi from 'services/api/connector';

export const fetchLatestVulns = (ws, confirmed) => fetchApi(`ws/${ws}/vulns?page=0&page_size=5&sort=date&sort_dir=desc${confirmed ? '&confirmed=1' : ''}`);

export const getVulnsCountByStatus = (ws) => fetchApi(`ws/${ws}/vulns/count?group_by=status`);

export const getVulnsCountBySeverity = (ws) => fetchApi(`ws/${ws}/vulns/count?group_by=severity`);

export const getTopHostsDashboard = (ws) => fetchApi(`ws/${ws}/hosts?page=1&page_size=10&sort=services&sort_dir=desc`);

export const getVulnsCountByMultiWs = (ws, param) => fetchApi(`ws/${ws}/vulns/count_multi_workspace?group_by=severity&workspaces=${ws}${param}`);

export const getTopServicesByName = (ws) => fetchApi(`ws/${ws}/services/count?group_by=name`);

export const getVulnsTimeline = (ws) => fetchApi(`ws/${ws}/vulns/timeline`);

export const getActivitiesList = (ws, page) => fetchApi(`ws/${ws}/activities?page_size=16&page=${page}`);

export const getCommandHistory = (ws, page) => fetchApi(`ws/${ws}/commands?page_size=16&page=${page}`);
