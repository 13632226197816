import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'Common/Components/CustomButton';
import { selectShowGridView } from 'store/Workspace/selectors';
import { toogleGridView } from 'Screens/Workspaces/actions/Actions';
import { Wrapper, GridViewIcon } from './styled';

// eslint-disable-next-line import/prefer-default-export
export const GridViewButton = () => {
  const showGridView = useSelector(selectShowGridView);
  const dispatch = useDispatch();

  return !showGridView && (
    <Wrapper>
      <CustomButton icon={ <GridViewIcon /> } title="Grid View" text="Grid View" onClick={ () => dispatch(toogleGridView(!showGridView)) } />
    </Wrapper>
  );
};
