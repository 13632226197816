import AddButton from 'Common/Components/AddButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 10px;
`;
Wrapper.displayName = 'Wrapper';

export const AddServiceButton = styled(AddButton)`
  margin-top: 21px;
  width: 110px;
`;

export default Wrapper;
