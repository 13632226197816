import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  height: ${(props) => !props.emptyAgents && '100%'};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
