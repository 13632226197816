import styled, { css } from 'styled-components';
import colors from 'Styles/colors';

export const Default = styled.div`
  user-select: none;
  border: solid 1px ${colors.dark6};
  text-align: center;
  min-width: 89px;
  width: auto;
  height: 34px;
  line-height: 24px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
  cursor: pointer;
  display: inline-block;
  user-select: none;
  ${(props) => props.disabled && css`
    opacity: 0.4;
    pointer-events: none;
  `}
`;
Default.displayName = 'Default';

export const Accept = styled(Default)`
  user-select: none;
  background-color: ${({ disabled }) => (disabled ? '#d6d6d6' : `${colors.blueCerulean}`)};
  color: ${colors.white};
  border-color: ${({ disabled }) => (disabled ? '#ebeff3' : `${colors.blueCerulean}`)};
`;
Accept.displayName = 'Accept';

export const Cancel = styled(Default)`
  user-select: none;
  background-color: ${colors.white};
  color: ${colors.blue6};
  border: solid 1px ${colors.dark6};
`;
Cancel.displayName = 'Cancel';
