import selectModal from './selectors';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const closeModal = (modalName) => (dispatch, getState) => {
  const opened = selectModal(getState(), modalName);
  if (opened) dispatch({ type: CLOSE_MODAL, payload: modalName });
};

export const openModal = (modalName) => (dispatch, getState) => {
  const opened = selectModal(getState(), modalName);
  if (!opened) dispatch({ type: OPEN_MODAL, payload: modalName });
};
