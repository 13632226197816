
import React from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import Text from './styled';

// eslint-disable-next-line import/prefer-default-export
export const Impact = ({ rowInfo, showSkeleton }) => {
  const impact = get(rowInfo, 'original.impact', {});
  const keys = Object.keys(impact);
  const filteredImpacts = keys.filter((key) => impact[key]).sort();
  const commaSeparatedImpacts = filteredImpacts.join(', ');

  if (showSkeleton) return <Skeleton />;
  return <Text children={ commaSeparatedImpacts } />;
};

Impact.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  showSkeleton: PropTypes.bool
};

Impact.defaultProps = {
  showSkeleton: false
};
