import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { selectVulnsSelected, selectShowDetail } from 'store/Manage/selectors';
import { useIntl } from 'react-intl';
import DuplicateIcon from './styled';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import FFDuplicates from 'Images/ff_duplicates.png';

const DuplicateButton = ({ setFF }) => {
  const dispatch = useDispatch();
  const selectedVulns = useSelector(selectVulnsSelected);
  const multipleVulnsSelected = selectedVulns.length > 1;
  const showVulnDetail = useSelector(selectShowDetail);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'vuln.detail.duplicates.markAsDuplicates' });

  const onClick = () => {
    setFF(FFDuplicates, 'upgradeLicense.sidebar.duplicates.description');
    dispatch(openModal(MODAL_UPGRADE_LICENSE_ACTION_BAR));
  };

  if (!multipleVulnsSelected || showVulnDetail) return null;
  return <IconButton icon={ <DuplicateIcon /> } title={ title } onClick={ onClick } />;
};

export default DuplicateButton;
