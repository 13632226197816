import {
  ADD_WORKSPACE_SUCCESS
} from 'Screens/Wizard/actions/Actions';

import get from 'lodash/get';
import { SET_MODE_HOST_CREATE_UPDATE } from 'store/HostEditCreate/types';
import { SET_URL_RETURN } from '../../Screens/Faraday/actions/Actions';
import { SET_MODE_MANAGE_CREATE_UPDATE } from '../../Screens/ManageEditCreate/actions/Actions';
import { SET_MODE_TEMPLATE_CREATE_UPDATE } from '../../Screens/KnowledgeBaseEditCreate/actions/Actions';
import { LOAD_PREFERENCES } from '../../Screens/Login/actions/Actions';
import {
  GET_DATA_WORKSPACES_SUCCESS,
  WORKSPACE_SELECTED_FOR_WORKING,
  GET_WORKSPACES_FOR_WORKING_STARTED,
  GET_WORKSPACES_FOR_WORKING_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_FAILURE,
  CURRENT_WORKSPACE_EDITED_UPDATE,
  SET_CURRENT_WORKSPACE_AS_INVALID,
  SET_SHOW_WORKSPACE_VALIDATOR
} from '../../Screens/Workspaces/actions/Actions';

const initialState = {
  isFetching: false,
  workspaces: [],
  workspaceSelected: undefined,
  readonly: false,
  workspaceList: [],
  showWorkspaceValidator: false,
  error: false,
  errorMessage: '',
  mode: '',
  permissions: {},
  version: '',
  hash: '',
  apiVersion: '',
  role: '',
  userName: '',
  urlReturn: ''
};

function faraday (state = initialState, action) {
  switch (action.type) {
    case WORKSPACE_SELECTED_FOR_WORKING: {
      const ws = action.workspace ? state.workspaces.filter((ws) => ws.name === action.workspace)[0] : null;
      const readonly = get(ws, 'readonly', false);
      return {
        ...state, workspaceSelected: action.workspace, readonly
      };
    }

    case CURRENT_WORKSPACE_EDITED_UPDATE: {
      const ws = action.oldWsName ? state.workspaces.filter((ws) => ws.name === action.oldWsName)[0] : null;
      const readonly = get(ws, 'readonly', false);
      return {
        ...state, workspaceSelected: action.newWsName, readonly
      };
    }

    case GET_WORKSPACES_FOR_WORKING_STARTED:
      return { ...state, isFetching: true };

    case GET_WORKSPACES_FOR_WORKING_SUCCESS:
      return { ...state, workspaces: action.data, isFetching: false };

    case GET_WORKSPACES_FOR_WORKING_FAILURE:
      return {
        ...state, error: true, errorMessage: action.data, isFetching: false
      };

    case GET_DATA_WORKSPACES_SUCCESS:
      return { ...state, workspaces: action.data, isFetching: false };

    case SET_MODE_MANAGE_CREATE_UPDATE:
    case SET_MODE_HOST_CREATE_UPDATE:
    case SET_MODE_TEMPLATE_CREATE_UPDATE: {
      return { ...state, mode: action.mode };
    }

    case LOAD_PREFERENCES: {
      return {
        ...state,
        permissions: action.permissions,
        hash: action.hash,
        apiVersion: action.apiVersion,
        role: action.role,
        userName: action.userName,
        email: action.email,
        wsPort: action.wsPort,
        licenseStatus: get(action, 'licenseStatus.license_status', 'INVALID')
      };
    }

    case ADD_WORKSPACE_SUCCESS: {
      const workspaces = state.workspaces;
      workspaces.unshift(action.data);

      return {
        ...state,
        workspaces,
        workspaceSelected: action.data.name
      };
    }

    case SET_CURRENT_WORKSPACE_AS_INVALID: {
      return {
        ...state,
        workspaceSelected: null,
        workspaces: state.workspaces.filter((ws) => ws.name !== state.workspaceSelected),
        showWorkspaceValidator: true
      };
    }

    case SET_SHOW_WORKSPACE_VALIDATOR: {
      return {
        ...state,
        showWorkspaceValidator: action.show
      };
    }

    case SET_URL_RETURN: {
      return {
        ...state,
        urlReturn: action.urlReturn
      };
    }

    default:
      return state;
  }
}

export default faraday;
