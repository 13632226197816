import React, { useState } from 'react';
import useKeypress from 'Hooks/useEscKey';
import Wrapper from './styled';

const useModal = (Element, id = '') => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);

  useKeypress('Escape', () => {
    setShowModal(false);
  });

  /* eslint-disable react/jsx-props-no-spreading */
  // eslint-disable-next-line react/destructuring-assignment
  const Modal = (props) => <Wrapper id={ id } addEditServices={ props.addEditServices }><Element { ...props } /></Wrapper>;

  return [Modal, showModal, toggle];
};

export default useModal;
