import Modal from 'Common/Components/Modal';
import useModal from 'Hooks/useModal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideError } from 'store/errors/actions';
import { selectErrorMessage, selectShowError } from 'store/errors/selectors';
import { ReactComponent as ErrorIcon } from 'Images/exclamation_error.svg';

const Error = () => {
  const dispatch = useDispatch();
  const [ModalError, showModalError, toggleModalError] = useModal(Modal);

  const props = useSelector((state) => ({
    showError: selectShowError(state),
    message: selectErrorMessage(state)
  }));

  useEffect(() => {
    if (showModalError !== props.showError) toggleModalError(props.showError);
  }, [props.showError, showModalError, toggleModalError]);

  const onClose = () => {
    dispatch(hideError());
  };

  return showModalError && props.message && (
  <ModalError icon={ <ErrorIcon /> } title="Error" onClose={ onClose }>
    { props.message }
  </ModalError>
  );
};

export default Error;
