import styled from 'styled-components';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const WsSelect = styled.div`
    width: 340px;
    max-height: ${(props) => (props.isWsListLong ? 'none' : '106px')};
    height: ${(props) => (props.isWsListLong ? '106px' : 'none')};
    border-radius: 3px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    margin-top: 21px;
    overflow-y: ${(props) => (props.isWsListLong ? 'auto' : 'unset')};;
`;
WsSelect.displayName = 'WsSelect';

export const WsItem = styled.div`
    width: 100%;
    height: 34px;
    font-size: 12.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: normal;
    text-align: left;
    color: #7a7e8f;
    padding-left: 13px;
    padding-right: 13px;
    display: flow-root;
    
    &:hover {
        background-color: #e6eff6;
        cursor: pointer;
    }
`;
WsItem.displayName = 'WsItem';

export const UpdateDate = styled.div`
    font-size: 11.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.13;
    letter-spacing: normal;
    text-align: left;
    color: #cccccc;
    float:right;
`;
UpdateDate.displayName = 'UpdateDate';

export const ViewAllWs = styled.div`
    font-size: 12.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left;
    color: #0091ff;
    margin: 21px 0;
    text-align: left;
    cursor: pointer;
`;
ViewAllWs.displayName = 'ViewAllWs';

export const CheckWrapper = styled.div`
    display: flex;
    margin-top: 21px;
`;
CheckWrapper.displayName = 'CheckWrapper';

export const CheckText = styled.span`
    font-size: 11.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #a7a7a7;
    cursor:pointer;
`;
CheckText.displayName = 'CheckText';

export const WsName = styled.div`
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    font-weight: 400;
`;
WsName.displayName = 'WsName';
