import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSelectedVulns, hideVulnModalDelete
} from 'store/Manage/actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectIsFetching, selectSelectAll, selectShowDeleteConfirmation, selectVulnsCount, selectVulnsSelected } from 'store/Manage/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const selectAll = useSelector(selectSelectAll);
  const count = selectAll ? vulnsCount : vulnsSelected.length;
  const isFetching = useSelector(selectIsFetching);

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteSelectedVulns()) }
      handleClose={ () => dispatch(hideVulnModalDelete()) }
      entity="vulnerability"
      count={ count }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
