/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Wrapper, ImpactItem, Row } from './styled';

class Impacts extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeAccountabilityHandler = this.onChangeAccountabilityHandler.bind(this);
    this.onChangeAvailabilityHandler = this.onChangeAvailabilityHandler.bind(this);
    this.onChangeConfidentialityHandler = this.onChangeConfidentialityHandler.bind(this);
    this.onChangeIntegrityHandler = this.onChangeIntegrityHandler.bind(this);

    this.state = { ...props.impacts };
  }

  componentDidUpdate (prevProps) {
    const { impacts } = this.props;
    if (prevProps.impacts !== impacts) {
      this.setState({ ...impacts });
    }
  }

  onChangeAccountabilityHandler () {
    this.setState((prevState) => ({ accountability: !prevState.accountability }));
    this.props.selectCheckbox('accountability');
  }

  onChangeAvailabilityHandler () {
    this.setState((prevState) => ({ availability: !prevState.availability }));
    this.props.selectCheckbox('availability');
  }

  onChangeConfidentialityHandler () {
    this.setState((prevState) => ({ confidentiality: !prevState.confidentiality }));
    this.props.selectCheckbox('confidentiality');
  }

  onChangeIntegrityHandler () {
    this.setState((prevState) => ({ integrity: !prevState.integrity }));
    this.props.selectCheckbox('integrity');
  }

  render () {
    const {
      accountability,
      availability,
      confidentiality,
      integrity
    } = this.state;

    const { hideTitle, title, width, margin } = this.props;

    return (
      <Wrapper width={ width } margin={ margin } className="impacts">
        {!hideTitle && title && <div className="input-list-title">{title}</div>}
        <Row>
          <ImpactItem active={ accountability } onClick={ () => this.onChangeAccountabilityHandler() } className="impact-item">Accountability</ImpactItem>
          <ImpactItem active={ availability } onClick={ () => this.onChangeAvailabilityHandler() } className="impact-item">Availability</ImpactItem>
          <ImpactItem active={ integrity } onClick={ () => this.onChangeIntegrityHandler() } className="impact-item">Integrity</ImpactItem>
          <ImpactItem active={ confidentiality } onClick={ () => this.onChangeConfidentialityHandler() } className="impact-item">Confidentiality</ImpactItem>
        </Row>
      </Wrapper>
    );
  }
}

Impacts.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.bool,
  selectCheckbox: PropTypes.func.isRequired,
  impacts: PropTypes.shape({
    accountability: PropTypes.bool.isRequired,
    availability: PropTypes.bool.isRequired,
    confidentiality: PropTypes.bool.isRequired,
    integrity: PropTypes.bool.isRequired
  })
};

Impacts.defaultProps = {
  title: 'Impact',
  width: '400px',
  margin: true,
  impacts: {
    accountability: false,
    availability: false,
    confidentiality: false,
    integrity: false
  }
};

export default withRouter(Impacts);
