import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const TabList = styled.div`
  text-align: left;
`;
TabList.displayName = 'TabList';

export const TabListContent = styled.div`
  display: block;
  overflow: auto;
`;
TabListContent.displayName = 'TabListContent';
