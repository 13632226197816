import React from 'react';
import ExpandableWrapper from '../ExpandableWrapper';
import MarkdownEditor from '../MarkdownEditor';

const ExternalId = ({
  value, title, id, onChange, selected, expanded
}) => (
  <ExpandableWrapper id={ id } title={ title } onChange={ onChange } selected={ selected } expanded={ expanded }>
    <MarkdownEditor value={ value || '' } />
  </ExpandableWrapper>
);

export default ExternalId;
