import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 170px;
  border-radius: 4.1px;
  box-shadow: 3px 3px 7px 0 rgba(97, 97, 97, 0.07);
  border: solid 1.4px #dbdbdb;
  background-color: ${colors.paleGrey};
  margin-top: 15px;
  user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const FeatureImage = styled.img`
  flex: 0 0 200px;
  height: auto;
`;
FeatureImage.displayName = 'FeatureImage';

export const TextWrapper = styled.div`
  flex: 0 0 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
TextWrapper.displayName = 'TextWrapper';

export const Description = styled.div`
  font-family: 'CentraNo2';
  font-weight: normal;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${colors.grey19};
  text-align: center;
`;
Description.displayName = 'Description';

export const Link = styled(Description)`
  margin-top: 30px;
  cursor: pointer;
  color: #0082ed;
`;
Link.displayName = 'Link';
