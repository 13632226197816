/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import {
  clearAdvancedFilter, getCustomFilters, resetFilters, setSelectedEntity
} from 'store/Filters/actions';
import {
  resetState,
  expandGroupByAssets,
  setOrderByAssets,
  showHostLeftFilters
} from 'store/Host/actions';
import {
  resetHostDetail
} from 'store/HostDetail/actions';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  selectMapGroupByIndex,
  selectHostsSelected,
  selectIsFetching,
  selectShowHostLeftFilters
} from 'store/Host/selectors';
import {
  getSelectedEntity,
  selectGroupByField
} from 'store/Filters/selectors';
import { selectFields, selectHostList } from 'store/Preferences/host/selectors';
import colors from 'Styles/colors';
import { selectShowManageLeftFilters } from 'store/Manage/selectors';
import getColumns from './Columns';
import { Wrapper } from './styled';

const getSorting = (sorted) => {
  const importantVulnsSortIndex = sorted.findIndex((s) => s.id === 'severity_counts');

  if (importantVulnsSortIndex >= 0) {
    const desc = sorted[importantVulnsSortIndex].desc;
    const sortCriticalCount = { id: 'vulnerability_critical_generic_count', desc };
    const sortHighCount = { id: 'vulnerability_high_generic_count', desc };
    const sortMediumCount = { id: 'vulnerability_medium_generic_count', desc };

    const newSorting = [...sorted];
    newSorting.splice(importantVulnsSortIndex, 1, sortCriticalCount, sortHighCount, sortMediumCount);
    return newSorting;
  }
  return sorted;
};

class Table extends Component {
  constructor (props) {
    super(props);
    this.table = React.createRef();
    this.state = {
      expanded: {}
    };

    this.getTrProps = this.getTrProps.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount () {
    const {
      clearSelectedEntity, selectedEntity
    } = this.props;
    this.props.getCustomFilters();
    if (this.props.showLeftMenuFilters || this.props.showSidebarFilters) this.props.showLeftFilters(true);
    else this.props.showLeftFilters(false);
    if (selectedEntity !== 'assets') clearSelectedEntity();
  }

  componentWillUnmount () {
    const {
      resetState, resetHostDetail, resetFilters, clearAdvancedFilter
    } = this.props;
    resetState();
    resetHostDetail();
    resetFilters();
    clearAdvancedFilter();
  }

  getTrProps (state, rowInfo) {
    if (rowInfo && rowInfo.row && !rowInfo.original.count) {
      const selected = this.props.hostsSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

      return {
        style: {
          background: selected ? `${colors.blue8}` : ''
        }
      };
    }

    return {};
  }

  fetchData (state) {
    const { isFetching } = this.props;
    const { sorted } = state;
    if (!isFetching) {
      const sorting = getSorting(sorted);
      this.props.setOrderBy(sorting);
    }
  }

  render () {
    const {
      data, fields, groupByField, isFetching
    } = this.props;

    const { expanded } = this.state;
    const mockData = [...Array(13).keys()];

    return (
      <Wrapper ref={ (element) => { this.table = element; } }>
        <CustomReactTable
          data={ isFetching ? mockData : data }
          columns={ getColumns(false, fields, groupByField, isFetching) }
          manual
          onFetchData={ this.fetchData }
          minRows={ 0 }
          showPagination={ false }
          getTrProps={ (state, rowInfo) => this.getTrProps(state, rowInfo) }
          noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
          expanded={ expanded }
          onExpandedChange={ (expanded, index) => {
            if (expanded[index]) {
              this.props.expandGroupBy(index, data[index]);
            }
            this.setState({ expanded });
          } }
          SubComponent={ (row) => (
            <CustomReactTable
              data={ this.props.data[row.index].groupData }
              columns={ getColumns(true, fields, groupByField, isFetching) }
              manual
              minRows={ 0 }
              showPagination={ false }
              TheadComponent={ () => null }
              getTrProps={ (state, rowInfo) => this.getTrProps(state, rowInfo) }
              style={ { overflow: 'hidden' } }
            />
          ) }
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  data: selectHostList(state),
  isFetching: selectIsFetching(state),
  fields: selectFields(state),
  groupByField: selectGroupByField('assets', state),
  hostsSelected: selectHostsSelected(state),
  lastHostSelected: state.host.lastHostSelected,
  lastIndexForRange: state.host.lastIndexForRange,
  lastSelected: state.host.lastSelected,
  groupDataByIndex: selectMapGroupByIndex(state),
  selectedEntity: getSelectedEntity(state),
  showLeftMenuFilters: selectShowHostLeftFilters(state),
  showSidebarFilters: selectShowManageLeftFilters(state)
});

const mapDispatchToProps = (dispatch) => ({
  showLeftFilters: (show) => {
    dispatch(showHostLeftFilters(show));
  },
  getCustomFilters: () => {
    dispatch(getCustomFilters());
  },
  setOrderBy: (order_by) => {
    dispatch(setOrderByAssets(order_by));
  },
  expandGroupBy: (index, value) => {
    dispatch(expandGroupByAssets(index, value));
  },
  resetState: () => {
    dispatch(resetState());
  },
  resetHostDetail: () => {
    dispatch(resetHostDetail());
  },
  resetFilters: () => {
    dispatch(resetFilters('assets'));
  },
  clearSelectedEntity: () => {
    dispatch(setSelectedEntity(''));
  },
  clearAdvancedFilter: () => {
    dispatch(clearAdvancedFilter('assets'));
  }
});

Table.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.instanceOf(Object).isRequired,
  groupByField: PropTypes.string.isRequired,
  expandGroupBy: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  clearSelectedEntity: PropTypes.func.isRequired,
  getCustomFilters: PropTypes.func.isRequired,
  resetHostDetail: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
