import React from 'react';
import moment from 'moment';

const DateFormatter = ({ date }) => {
  const today = moment();

  const isToday = (d) => moment(d).isSame(today, 'day');

  const shortDateFormat = (d) => moment(d).format('LT');
  const longDateFormat = (d) => moment(d).format('lll');

  const dateFormatted = isToday(date) ? `Today, ${shortDateFormat(date)}` : longDateFormat(date);

  return (
    <p>{dateFormatted}</p>
  );
};

export default DateFormatter;
