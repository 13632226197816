import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styled';

class Tab extends Component {
  constructor (props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick () {
    const { title, onClick } = this.props;
    onClick(title);
  }

  render () {
    const {
      onClick,
      props: { activeTab, title }
    } = this;

    return (
      <Wrapper onClick={ onClick } active={ activeTab === title }>
        {title}
      </Wrapper>
    );
  }
}
Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Tab;
