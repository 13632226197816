import React from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAdmin } from 'store/Faraday/selectors';
import { selectLocation } from 'store/Router/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_CUSTOM_ATTRIBUTES } from 'store/modals/modals';
import {
  Wrapper, Title, ButtonsWrapper,
  CustomAttributeButton, ResetButton
} from './styled';

const Header = ({ resetDefault }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const location = useSelector(selectLocation);
  const isManage = get(location, 'pathname', '').includes('/manage');
  const canShow = isAdmin && isManage;

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'workspaces.columnSelector.title' })}</Title>
      <ButtonsWrapper>
        { canShow && <CustomAttributeButton label="Custom Attributes" onClick={ () => dispatch(openModal(MODAL_CUSTOM_ATTRIBUTES)) } /> }
        <ResetButton label="Reset" onClick={ resetDefault } />
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default Header;
