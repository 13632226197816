import fetchApi from 'services/api/connector';

export const fetchUserAgents = () => fetchApi('agents', { method: 'GET' });
export const removeAgent = (id) => fetchApi(`agents/${id}`, { method: 'DELETE' });
export const fetchToken = (data) => fetchApi('agent_token', {
  method: 'GET',
  headers: { 'Content-Type': 'multipart/form-data' },
  data
});
export const fetchAgentTools = (id) => fetchApi(`agents/${id}`);

export const runUserAgentTool = (agentId, payload) => fetchApi(`agents/${agentId}/run`, {
  method: 'POST',
  headers: { 'Content-type': 'application/json' },
  data: { ...payload }
});

export const getActiveAgents = () => fetchApi('agents/active_agents', { method: 'GET' });
