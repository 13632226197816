import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  font-size: 13.5px;
  cursor: pointer;
  ${({ active }) => active && `
    border-bottom: 2px solid #419bf9;
    color: #419bf9;
    font-weight: 500;
  `}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
