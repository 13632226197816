const initialState = {
};

function executiveReport (state = initialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

export default executiveReport;
