import React from 'react';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import { selectToolError } from 'store/Agents/selectors';
import { FieldWrapper, Label, Field } from './styled';

const fieldType = {
  boolean: 'checkbox',
  integer: 'number',
  string: 'text'
};

const NonBooleanField = ({
  defaultValue, parameters, param, onChangeField
}) => {
  const base = get(parameters, `${param}.base`, 'string');
  const required = get(parameters, `${param}.mandatory`, false);
  const paramType = get(fieldType, `${base}`, 'text');
  const paramError = useSelector((state) => selectToolError(state, param));
  const extractName = (str) => capitalize(str.replace('_', ' '));

  return (
    <FieldWrapper key={ param } required={ required }>
      <Label>{extractName(param)}</Label>
      <Field defaultValue={ defaultValue } onChange={ (e) => onChangeField(param, e.target.value) } failed={ paramError } title={ paramError ? paramError.data[0] : param } type={ paramType } />
    </FieldWrapper>
  );
};

export default NonBooleanField;
