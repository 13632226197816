import React from 'react';
import { useIntl } from 'react-intl';
import { Wrapper, Title, FieldWrapper } from './styled';
import CheckboxField from './CheckboxField';
import { useDispatch, useSelector } from 'react-redux';
import { selectIgnoreInfo, selectResolveHost } from 'store/ManageEditCreate/selectors';
import { setIgnoreInfo, setResolveHost } from 'Screens/ManageEditCreate/actions/Actions';

const AdvancedOptions = () => {
  const ignoreInfo = useSelector(selectIgnoreInfo);
  const resolveHost = useSelector(selectResolveHost);
  const dispatch = useDispatch();
  const intl = useIntl();
  const ignoreInfoLabel = intl.formatMessage({ id: 'importFileCreationModal.advancedOptions.ignoreInfo' });
  const resolveHostLabel = intl.formatMessage({ id: 'importFileCreationModal.advancedOptions.resolveHost' });

  return (
    <Wrapper>
      <Title children={ intl.formatMessage({ id: 'importFileCreationModal.advancedOptions.title' }) } />
      <FieldWrapper>
        <CheckboxField defaultValue={ ignoreInfo } label={ ignoreInfoLabel } onChange={ (checked) => dispatch(setIgnoreInfo(checked)) } />
        <CheckboxField defaultValue={ resolveHost } label={ resolveHostLabel } onChange={ (checked) => dispatch(setResolveHost(checked)) } />
      </FieldWrapper>
    </Wrapper>
  );
};

export default AdvancedOptions;
