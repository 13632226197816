import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import Wrapper from './styled';
import BooleanField from './BooleanField';
import { useSelector, useDispatch } from 'react-redux';
import { selectTriggerIgnoreInfo, selectTriggerResolveHostname } from 'store/Agents/selectors';
import { setIgnoreInfo, setResolveHostname } from 'store/Agents/actions';
import { useIntl } from 'react-intl';

const ExecutionParams = ({ currentTool }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ignoreInfo = useSelector(selectTriggerIgnoreInfo);
  const resolveHostname = useSelector(selectTriggerResolveHostname);
  const ignoreInfoLabel = intl.formatMessage({ id: 'agents.triggerAgent.ignoreInfo' });
  const resolveHostnameLabel = intl.formatMessage({ id: 'agents.triggerAgent.resolveHostnames' });

  useEffect(() => {
    dispatch(setIgnoreInfo(false));
    dispatch(setResolveHostname(false));
  }, [dispatch]);

  if (isEmpty(currentTool)) return null;
  return (
    <Wrapper>
      <BooleanField defaultValue={ ignoreInfo } label={ ignoreInfoLabel } onChange={ (checked) => dispatch(setIgnoreInfo(checked)) } />
      <BooleanField defaultValue={ resolveHostname } label={ resolveHostnameLabel } onChange={ (checked) => dispatch(setResolveHostname(checked)) } />
    </Wrapper>
  );
};

export default ExecutionParams;
