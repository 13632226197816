import { newGetVulns } from 'store/Manage/filterActions';
import { getData as getAssetsData } from 'store/Host/actions';
import { getData as getKBData } from 'Screens/KnowledgeBase/actions/Actions';
import { VULNS_SEARCH_BY_FILTER_KEYS, ASSETS_SEARCH_BY_FILTER_KEYS, KB_SEARCH_BY_FILTER_KEYS } from './constants';

export const ENTITIES = {
  vulns: {
    name: {
      label: 'Vulnerabilities',
      value: 'vulns'
    },
    pathname: 'manage',
    values: VULNS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search vulns',
    advancedPlaceholder: 'e.g. {"name":"severity","op":"==","val":"critical"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-vulns-aka-status-report-fields',
    searchFunction: newGetVulns,
    wsDependant: true
  },
  assets: {
    name: {
      label: 'Assets',
      value: 'assets'
    },
    pathname: 'host',
    values: ASSETS_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search assets',
    advancedPlaceholder: 'e.g. {"name":"os","op":"ilike","val":"%Win%"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#manage-hosts-fields',
    searchFunction: getAssetsData,
    wsDependant: true
  },
  knowledgeBase: {
    name: {
      label: 'Vuln Templates',
      value: 'knowledgeBase'
    },
    pathname: 'knowledge_base',
    values: KB_SEARCH_BY_FILTER_KEYS,
    placeholder: 'Search vuln templates',
    advancedPlaceholder: 'e.g. {"name":"name","op":"ilike","val":"%SQL%"}',
    documentationUrl: 'https://docs.faradaysec.com/Filters/#search',
    searchFunction: getKBData,
    wsDependant: false
  }
};

export const SEARCH_BY_ENTITIES = [
  ENTITIES.vulns,
  ENTITIES.assets,
  ENTITIES.knowledgeBase
];
