import React from 'react';
import { Wrapper, Text, Link } from './styled';

const EmptyAgents = () => (
  <Wrapper>
    <Text>
      You don&apos;t have any Agents yet, create one to get started or
      <Link href="https://docs.agents.faradaysec.com/getting-started/" target="_blank"> check our documentation</Link>
      .
    </Text>
  </Wrapper>
);

export default EmptyAgents;
