import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ArrowCollapsed } from 'Images/wf_arrow_collapsed.svg';
import { ReactComponent as ArrowExpanded } from 'Images/wf_arrow_expand.svg';
import { ReactComponent as _Resizer } from 'Images/resize_bottom_right.svg';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    position: relative;
    border: solid 1px #dbdbdb;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: #63758d;
  user-select: none;
  text-align: left;
`;
Title.displayName = 'Title';

export const Header = styled.div`
  display: flex;
  flex: 0 0 44px;
  padding: 9px 20px;
  align-items: center;
  cursor: pointer;
  & > * {
    margin-right: 10px;
  }
`;
Header.displayName = 'Header';

export const Counter = styled.div`
  ${({ show }) => !show && 'display: none'};
  user-select: none;
  padding: 3px 12px 3px 12px;
  color: ${colors.darkBlueGrey};
  font-size: 10px;
  font-weight: 900;
  border-radius: 9px;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
  background-color: ${colors.iceBlue};
  margin-left: 14px;
`;
Counter.displayName = 'Counter';

const IconWrapper = css`
  position: absolute;
  top: 17px;
  right: 15px;
`;

export const ExpandedIcon = styled(ArrowExpanded)`
  ${IconWrapper};
  margin: auto 0px auto auto;
  width: 12px;
  color: #63758d;
  path {
    fill: ${colors.greyBlue};
  }
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowCollapsed)`
  ${IconWrapper};
  margin: auto 0px auto auto;
  height: 12px;
  path {
    fill: ${colors.greyBlue};
  }
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const ChildWrapper = styled.div.attrs(({ containerHeight }) => {
  let height = '';
  if (containerHeight) height = `${containerHeight}px`;
  return ({ style: { height } });
})`
  ${({ $isExpanded }) => (!$isExpanded && 'display: none')};
  overflow: visible;
`;
ChildWrapper.displayName = 'ChildWrapper';

export const Resizer = styled(_Resizer)`
  cursor: ns-resize;
  ${({ $isExpanded }) => (!$isExpanded && 'display: none')};
  margin: 18px 12px 15px auto;
  flex: 0 0 20px;
  width: 20px;
`;
Resizer.displayName = 'Resizer';
