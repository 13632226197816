import { push, replace } from 'connected-react-router';
import { openModal, closeModal } from 'store/modals/actions';
import { MODAL_REDIRECT_CONFIRMATION } from 'store/modals/modals';
import {
  selectRedirectPathname,
  selectRedirectSearch,
  selectRedirectSaveHistory,
  selectHasPendingChanges
} from 'store/Global/selectors';
import * as types from '../Global/types';

export function setHasPendingChanges (hasPendingChanges) {
  return { type: types.SET_HAS_PENDING_CHANGES, hasPendingChanges };
}

function redirectStart (payload) {
  return async (dispatch) => {
    dispatch({ type: types.REDIRECT_START, ...payload });
  };
}

function redirectFinished (ignorePendingChanges) {
  return async (dispatch) => {
    dispatch({ type: types.REDIRECT_CLEAR });
    if (!ignorePendingChanges) dispatch(setHasPendingChanges(false));
    dispatch(closeModal(MODAL_REDIRECT_CONFIRMATION));
  };
}

export function redirectClear () {
  return async (dispatch) => {
    dispatch({ type: types.REDIRECT_CLEAR });
    dispatch(closeModal(MODAL_REDIRECT_CONFIRMATION));
  };
}

export function performRedirect (ignorePendingChanges) {
  return async (dispatch, getState) => {
    const state = getState();
    const pathname = selectRedirectPathname(state);
    const saveHistory = selectRedirectSaveHistory(state);
    const search = selectRedirectSearch(state);
    const payload = { pathname, search };

    if (saveHistory) dispatch(push(payload));
    else dispatch(replace(payload));

    dispatch(redirectFinished(ignorePendingChanges));
  };
}

export function redirect (pathname, saveHistory = true, search = '', ignorePendingChanges = false) {
  return async (dispatch, getState) => {
    const hasPendingChanges = selectHasPendingChanges(getState());
    dispatch(redirectStart({ pathname, saveHistory, search }));
    if (hasPendingChanges && !ignorePendingChanges) dispatch(openModal(MODAL_REDIRECT_CONFIRMATION));
    else dispatch(performRedirect(ignorePendingChanges));
  };
}
