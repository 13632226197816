import React, { useState, useEffect } from 'react';
import BlueButton from 'Common/Components/BlueButton/index';
import OutlinedButton from 'Common/Components/OutlinedButton/index';
import { Wrapper, Buttons, Content, Input } from './styled';

const DateField = ({
  field, vuln, onCancel, onSave, initialValue
}) => {
  const [value, setValue] = useState(initialValue);
  const [editMode, setEditMode] = useState(!initialValue);

  const handleChange = (e) => setValue(e.target.value);

  const handleSave = () => {
    onSave(vuln, field.field_name, value);
    setEditMode(false);
    if (!value) onCancel();
  };

  const handleCancel = () => {
    setEditMode(false);
    if (initialValue) {
      setValue(initialValue);
    } else {
      onCancel();
    }
  };

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      {!editMode && initialValue &&
        <Content onClick={ () => setEditMode(true) }>{ initialValue }</Content>
      }
      {editMode &&
      (
        <>
          <Input type="date" value={ value } onChange={ handleChange } />
          <Buttons>
            <OutlinedButton label="Cancel" onClick={ handleCancel } />
            <BlueButton label="Save" onClick={ handleSave } />
          </Buttons>
        </>
      )}
    </Wrapper>
  );
};

export default DateField;
