import React from 'react';
import CommonConfirmationDialog from 'Common/Components/ConfirmationDialog';
import { useSelector, useDispatch } from 'react-redux';
import { bulkUpdateVulns } from 'store/Manage/actions';
import {
  selectIsFetching, selectModalBulkUpdateValue,
  selectSelectAll,
  selectVulnsCount,
  selectVulnsSelected
} from 'store/Manage/selectors';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';

const BulkUpdateConfirmationDialog = () => {
  const showModal = useSelector((state) => selectModal(state, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const selectAll = useSelector(selectSelectAll);
  const count = selectAll ? vulnsCount : vulnsSelected.length;

  const isFetching = useSelector(selectIsFetching);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);

  if (!showModal) return null;
  return (
    <CommonConfirmationDialog
      visible={ showModal }
      isFetching={ isFetching }
      title="Bulk Edit"
      handleSubmit={ () => dispatch(bulkUpdateVulns(bulkUpdateValue)) }
      handleClose={ () => dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION)) }
      count={ count }
      upperCaseTitle="Vulnerabilities"
      isLoading={ isFetching }
    />
  );
};
export default BulkUpdateConfirmationDialog;
