import initialState from './initialState';
import * as types from './types';

function breakpoints (state = initialState, action) {
  switch (action.type) {
    case types.ADD:
      return [
        ...state,
        action.payload
      ];
    case types.REMOVE:
      return state.filter((bp) => bp !== action.payload);
    default:
      return state;
  }
}

export default breakpoints;
