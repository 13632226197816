import styled from 'styled-components';
import colors from 'Styles/colors';
import { FiTrash2 as Trash } from 'react-icons/fi';

export const Container = styled.div`
    display: flex;
    align-items: stretch;

    > :nth-child(1){
        margin-right: 13px;
    }
`;

Container.displayName = 'Container';

export const Field = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    height: 55px;
    margin: 0px 38px 25.3px 6px;
    padding: 19px 21px;
    opacity: 0.6;
    border-radius: 3px;
    box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
    border: solid 1px #dbdbdb;
    background-color: ${colors.white};
    overflow: hidden;
`;

Field.displayName = 'Field';

export const Name = styled.span`
    display: inline-block;
    width: 35%;
    text-align: left;
    vertical-align: middle;
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.grey19};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

Name.displayName = 'Name';

export const Type = styled.span`
    display: inline-block;
    width: 10%;
    font-size: 12.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => (props.color ? props.color : colors.warmPurple)};   
`;

Type.displayName = 'Type';

export const DescriptionContainer = styled.div`
    width: 55%;
    display: inline-block;
`;
export const Description = styled.span`
    display: inline-block;
    text-align: left;
    padding-left: 31px;
    width: 100%;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.blueGrey};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
`;

Description.displayName = 'Description';

export const Icons = styled.div`

    width: 19%;
    > :not(:last-child){
        margin-right: 13px;
    }
    
     > :nth-child(1) {
        cursor: pointer;
        width: 16px;
        height: 17px;

        path {
            fill: ${colors.grey12};
        }
      }
`;
Icons.displayName = 'IconsContainer';

export const Texts = styled.div`
      width: 81%;
`;
Texts.displayName = 'Texts';

export const TrashIcon = styled(Trash)` 
    width: 16px;
    height: 17px;
    align-self: flex-end;
    color: ${colors.grey12};
`;

TrashIcon.displayName = 'TrashIcon';
