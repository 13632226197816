import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { dragCustomAttribute } from 'store/CustomAttributes/actions';
import AttributeList from './components/AttributeList';
import { Wrapper, ModalWrapper } from './styled';
import AddCustomAttribute from './components/AddCustomAttribute';
import Header from './components/Header/index';
// TODO:

// Error handling (no dejar crear CF si el back tira un error // disable buttom si no cumple con condiciones minimas)

const CustomAttributesModal = () => {
  const dispatch = useDispatch();
  const handleDragEnd = (result) => dispatch(dragCustomAttribute(result));
  return (
    <ModalWrapper>
      <Wrapper>
        <Header />
        <AddCustomAttribute />
        <DragDropContext onDragEnd={ handleDragEnd }>
          <AttributeList />
        </DragDropContext>
      </Wrapper>
    </ModalWrapper>
  );
};
export default CustomAttributesModal;
