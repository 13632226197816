/* eslint-disable react/no-children-prop */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import get from 'lodash/get';
import Modal from 'Common/Components/Modal';
import useModal from 'Hooks/useModal';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

const DeletionModal = ({ agentToDelete, onAccept, onClose }) => {
  const intl = useIntl();
  const agentName = get(agentToDelete, 'name', '');
  const [ModalWarning] = useModal(Modal);
  const deleteTitle = `${intl.formatMessage({ id: 'agents.delete.title' })} "${agentName}"`;
  const acceptLabel = intl.formatMessage({ id: 'agents.delete.confirmation' });
  const closeLabel = intl.formatMessage({ id: 'agents.delete.cancel' });
  const deleteMessage = intl.formatMessage({ id: 'agents.delete.message' });

  if (!agentToDelete) return null;
  return (
    <ModalWarning
      onAccept={ onAccept }
      onClose={ onClose }
      title={ deleteTitle }
      acceptLabel={ acceptLabel }
      closeLabel={ closeLabel }
      children={ deleteMessage }
    />
  );
};

DeletionModal.propTypes = {
  agentToDelete: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DeletionModal;
