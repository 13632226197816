import styled from 'styled-components';
import colors from 'Styles/colors';
import Password from 'Common/Components/Password/styled';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.dark2}
`;
Title.displayName = 'Title';

export const SubTitle = styled.div`
  margin-top: 21px;
  margin-bottom: 30px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.blueGrey}
`;
SubTitle.displayName = 'SubTitle';

export const PasswordChanged = styled.div`
  background-color: ${colors.green1};
  cursor: default;
  margin: 22px 0px 0 0px;
  height: 34px;
  border-radius: 2px;
  font-size: 12.5px;
  font-weight: 500;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  border: 0;
`;
PasswordChanged.displayName = 'PasswordChanged';

export const Login = styled.div`
    font-size: 12.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    color: ${colors.blueCerulean};
    width: 68px;
`;
Login.displayName = 'Login';

export const Rule = styled.div`
  font-size: 10.5px;
  color: ${colors.red1};
  margin-top: 12px;
  text-align: justify;
`;
Rule.displayName = 'Rule';

export const ErrorMessage = styled.div`
  color: ${colors.red1};
  letter-spacing: 0.08px;
  color: ${colors.red3};
  font-size: 10.5px;
  text-align: left;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const ResetPass = styled(Password)`
  max-width: 500px;
  width: 100%;
`;

export const Container = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
