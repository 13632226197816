import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { FormattedMessage } from 'react-intl';
import { setOrderByVulnsAssets, setPageNumberVulnAssets } from 'store/HostDetail/actions';
import { selectVulns, selectIsFetching, selectFields, selectHostDetail, selectVulnsCount } from 'store/HostDetail/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import getColumns from './Columns';
import { Wrapper, PaginationWrapper } from './styled';
import Pagination from 'Common/Components/Pagination';

const Vulnerabilities = () => {
  const isFetching = useSelector(selectIsFetching);
  const vulns = useSelector(selectVulns);
  const fields = useSelector(selectFields);
  const dispatch = useDispatch();
  const data = useSelector(selectHostDetail);
  const vulnsCount = useSelector(selectVulnsCount);
  const page = useSelector((state) => selectPage('vulnsAssets', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulnsAssets', state));
  const onPrev = () => dispatch(setPageNumberVulnAssets(page - 1, data.ip));
  const onNext = () => dispatch(setPageNumberVulnAssets(page + 1, data.ip));
  const customStyle = { height: 'calc(100vh - 306px)' };

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching) {
      dispatch(setOrderByVulnsAssets(sorted, data.ip));
    }
  };

  const mockData = [...Array(15).keys()];

  return (
    <Wrapper>
       { vulns &&
        <PaginationWrapper>
          <Pagination offset={ page } limit={ rowsPerPage } total={ vulnsCount } onPrev={ onPrev } onNext={ onNext } />
        </PaginationWrapper>
      }
      <CustomReactTable
        data={ isFetching ? mockData : vulns }
        columns={ getColumns(fields, isFetching) }
        minRows={ 0 }
        manual
        onFetchData={ fetchData }
        showPagination={ false }
        noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
        style={ customStyle }
      />
    </Wrapper>
  );
};

export default Vulnerabilities;

// {"offset":0,"limit":50,"order_by":[{"field":"confirmed","direction":"desc"},{"field":"severity","direction":"desc"}]}

// {"offset":0,"limit":50,"order_by":[{"field":"severity","direction":"desc"}]}
