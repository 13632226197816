import get from 'lodash/get';

export const selectIsFetching = (state) => get(state, 'sesion.isFetching', false);
export const selectRememberMe = (state) => get(state, 'sesion.rememberMe', false);
export const selectIsLoggedIn = (state) => get(state, 'sesion.loggedIn', false);
export const selectErrorMessage = (state) => get(state, 'sesion.errorMessage', '');
export const selectUser = (state) => get(state, 'sesion.user', '');
export const selectUserRoles = (state) => get(state, 'sesion.roles', []);
export const selectIsValidatingSession = (state) => get(state, 'sesion.validatingSession', false);
export const selectCsrfToken = (state) => get(state, 'sesion.csrfToken', '');
