import React from 'react';
import { StyledButton, IconWrapper, Text } from './styled';

const Button = ({
  onClick, disabled, isLoading, id, children, Icon = null, color = 'blue'
}) => (
  <StyledButton
    id={ id }
    onClick={ onClick }
    disabled={ disabled }
    type="button"
    isLoading={ isLoading }
    color={ color }
    aria-label={ children }
    narrow_sides={ !!Icon }
  >
    {Icon && <IconWrapper><Icon /></IconWrapper>}
    <Text>{ children }</Text>
  </StyledButton>
);

export default Button;
