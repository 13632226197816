/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { showContextMenu, showVulnModalDelete } from 'store/Manage/actions';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { useIntl } from 'react-intl';
import { DeleteIcon } from './styled';

export const Delete = () => {
  const dispatch = useDispatch();
  const vulnsCount = useSelector(selectVulnsSelected).length;
  const singleDelete = useIntl().formatMessage({ id: 'manage.cm.delete' });
  const bulkDelete = useIntl().formatMessage({ id: 'manage.cm.bulkDelete' });
  const title = vulnsCount === 1 ? singleDelete : bulkDelete;

  const handleClick = () => {
    dispatch(showVulnModalDelete());
    dispatch(showContextMenu(false));
  };

  return (
    <MenuItem
      title={ title }
      color="#ea3158"
      icon={ <DeleteIcon /> }
      onClick={ handleClick }
    />
  );
};
