import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    margin-bottom: 3px;
    text-align: initial;    
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #0b2128;
    font-weight: 600;
    margin-bottom: 12px;
    opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`;
Title.displayName = 'Title';

export const CustomInput = styled.input`
    width: ${(props) => (props.width ? `${props.width}` : '224px')};
    height: ${(props) => (props.height ? `${props.height}` : '38px')};
    border: 1px solid transparent;
    box-shadow: 0 1px 0 0 #afafaf;
    border-bottom: ${(props) => (props.error ? '1px solid red' : 'none')};
    background-color: ${colors.iceBlue};
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.48;
    letter-spacing: normal;
    color: #1c2243;
    margin-top:13

    &.full{
      background-color: white; 
    }

    &::placeholder {
      color: #afb1bc;
      font-size: 13.5px;
      font-weight: 300;
    }

    &:hover{
      border-style: solid;
      border-color: #afb1bc;
      border-width: 1px 1px 0px 1px;
    }

    &:focus{
        background-color: white;        

        &::placeholder {
            color: transparent;
        }
    }
`;
CustomInput.displayName = 'CustomInput';
