import api from 'services/api';
import { getData } from 'Screens/KnowledgeBase/actions/Actions';

export const SET_MODE_TEMPLATE_CREATE_UPDATE = 'SET_MODE_TEMPLATE_CREATE_UPDATE';
export const RESET_STATE_TEMPLATE_CREATE_UPDATE = 'RESET_STATE_TEMPLATE_CREATE_UPDATE';
export const ADD_REPORT_TEMPLATE_CREATE_UPDATE = 'ADD_REPORT_TEMPLATE_CREATE_UPDATE';
export const REMOVE_REPORT_TEMPLATE_CREATE_UPDATE = 'REMOVE_REPORT_TEMPLATE_CREATE_UPDATE';
export const SET_REDIRECT_TEMPLATE_CREATE_UPDATE = 'SET_REDIRECT_TEMPLATE_CREATE_UPDATE';
export const SET_FIELD_NAME_TEMPLATE_CREATE_UPDATE = 'SET_FIELD_NAME_TEMPLATE_CREATE_UPDATE';
export const SELECT_CHECK_BOX_TEMPLATE_CREATE_UPDATE = 'SELECT_CHECK_BOX_TEMPLATE_CREATE_UPDATE';
export const REMOVE_REFERENCE_CREATE_UPDATE = 'REMOVE_REFERENCE_CREATE_UPDATE';
export const REMOVE_POLICY_CREATE_UPDATE = 'REMOVE_POLICY_CREATE_UPDATE';
export const ADD_REFERENCE_CREATE_UPDATE = 'ADD_REFERENCE_CREATE_UPDATE';
export const ADD_POLICY_CREATE_UPDATE = 'ADD_POLICY_CREATE_UPDATE';
export const SET_TEMPLATE_NAME_TEMPLATE_CREATE_UPDATE = 'SET_TEMPLATE_NAME_TEMPLATE_CREATE_UPDATE';
export const TEMPLATE_CREATE_UPDATE_START = 'TEMPLATE_CREATE_UPDATE_START';
export const TEMPLATE_CREATE_UPDATE_FAIL = 'TEMPLATE_CREATE_UPDATE_FAIL';
export const TEMPLATE_CREATE_UPDATE_SUCCESS = 'TEMPLATE_CREATE_UPDATE_SUCCESS';
export const SET_TEMPLATE_CREATE_UPDATE = 'SET_TEMPLATE_CREATE_UPDATE';
export const SET_CUSTOM_ATTRIBUTE_KB_CREATE_UPDATE = 'SET_CUSTOM_ATTRIBUTE_KB_CREATE_UPDATE';
export const ADD_CVE_KB_CREATE_UPDATE = 'ADD_CVE_KB_CREATE_UPDATE';
export const REMOVE_CVE_KB_CREATE_UPDATE = 'REMOVE_CVE_KB_CREATE_UPDATE';

export function resetState () {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_TEMPLATE_CREATE_UPDATE
    });
  };
}

export function setField (fieldName, value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_NAME_TEMPLATE_CREATE_UPDATE, fieldName, value
    });
  };
}

export function selectCheckbox (checkName) {
  return (dispatch) => {
    dispatch({
      type: SELECT_CHECK_BOX_TEMPLATE_CREATE_UPDATE, checkName
    });
  };
}

export function removeReference (index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_REFERENCE_CREATE_UPDATE, index
    });
  };
}

export function removePolicy (index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_POLICY_CREATE_UPDATE, index
    });
  };
}

export function addReference () {
  return (dispatch) => {
    dispatch({
      type: ADD_REFERENCE_CREATE_UPDATE
    });
  };
}

export function addPolicy () {
  return (dispatch) => {
    dispatch({
      type: ADD_POLICY_CREATE_UPDATE
    });
  };
}

export function setTemplateName (index, value) {
  return (dispatch) => {
    dispatch({
      type: SET_TEMPLATE_NAME_TEMPLATE_CREATE_UPDATE, index, value
    });
  };
}

export function createTemplate () {
  return async (dispatch, getState) => {
    dispatch({ type: TEMPLATE_CREATE_UPDATE_START });

    const { knowledgeBaseEditCreate } = getState();
    const parsedCVEList = knowledgeBaseEditCreate.cve.map((item) => item.toUpperCase());

    const template = {
      id: knowledgeBaseEditCreate.id,
      name: knowledgeBaseEditCreate.name,
      exploitation: knowledgeBaseEditCreate.exploitation,
      impact: {
        accountability: knowledgeBaseEditCreate.accountability,
        availability: knowledgeBaseEditCreate.availability,
        integrity: knowledgeBaseEditCreate.integrity,
        confidentiality: knowledgeBaseEditCreate.confidentiality
      },
      easeofresolution: knowledgeBaseEditCreate.easeofresolution ? knowledgeBaseEditCreate.easeofresolution : null,
      desc: knowledgeBaseEditCreate.description,
      description: knowledgeBaseEditCreate.description,
      resolution: knowledgeBaseEditCreate.resolution,
      external_id: knowledgeBaseEditCreate.external_id,
      data: knowledgeBaseEditCreate.data,
      create_at: '',
      creator: '',
      customfields: knowledgeBaseEditCreate.customfields,
      cwe: '',
      cve: parsedCVEList,
      policyviolations: knowledgeBaseEditCreate.policyviolations,
      references: knowledgeBaseEditCreate.references,
      refs: knowledgeBaseEditCreate.references,
      type: 'vulnerability_template'
    };

    try {
      if (knowledgeBaseEditCreate.id) await api.knowledgeBase.updateTemplate(template.id, template);
      else await api.knowledgeBase.createTemplate(template);

      // TODO, replace when api supports filters
      dispatch({ type: TEMPLATE_CREATE_UPDATE_SUCCESS, template });
      return dispatch(getData());
    } catch (error) {
      return dispatch({ type: TEMPLATE_CREATE_UPDATE_FAIL, error: error.message });
    }
  };
}

export function setCustomAttribute (key, value) {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOM_ATTRIBUTE_KB_CREATE_UPDATE, key, value
    });
  };
}

export function addCVE (cve) {
  return (dispatch) => {
    dispatch({ type: ADD_CVE_KB_CREATE_UPDATE, cve });
  };
}

export function removeCVE (cve) {
  return (dispatch) => {
    dispatch({ type: REMOVE_CVE_KB_CREATE_UPDATE, cve });
  };
}
