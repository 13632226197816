import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ClipboardIcon } from 'Images/copy.svg';

export const ClipboardWrapper = styled.div`
  display: none;
  background: #0082ed;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  position: absolute;
  right: 0;
`;

ClipboardWrapper.displayName = 'ClipboardWrapper';

export const Wrapper = styled.div`
  cursor: pointer;
  background: ${colors.paleGrey};
  height: 26px;
  padding: 0px 9px;
  font-size: 12px;
  border: 1px solid #e4e9ee;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: content-box;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  
  &:hover {
    & ${ClipboardWrapper} {
      display: flex;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 94%;
`;
Text.displayName = 'Text';

export const Icon = styled(ClipboardIcon)`
  display: flex;
  width: 12px;
  height: 14px;
    & > path {
    fill: ${colors.white};
  }
`;

Icon.displayName = 'Icon';
