import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  med: colors.tangerine,
  low: colors.apple,
  info: colors.grey2,
  unclassified: colors.grey4,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
  margin: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const SeverityName = styled.div`
  color: #ffffff;
  background: ${({ type }) => sevColor[type]};
  font-size: 13.5px;
  line-height: 2.52;
  padding: 0 20px;
  width: 127px;
  height: 34px;
  font-size: 13.5px;
  font-weight: 500;
  text-transform: capitalize;
`;
SeverityName.displayName = 'SeverityName';
