/* eslint-disable react/jsx-key */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Wrapper } from '../styled';
import { CustomCheckMark, StyledLabel, Delete } from './styled';
import { useIntl } from 'react-intl';
import { removeCommand } from 'Common/Components/FileUploadContext/actions';

const FinishedOk = ({ commandId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <CustomCheckMark />
      <StyledLabel children={ intl.formatMessage({ id: 'uploadContext.status.finishedOk' }) } />
      <Delete onClick={ () => dispatch(removeCommand(commandId)) } />
    </Wrapper>
  );
};

export default FinishedOk;
