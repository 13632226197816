/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import {
  CommandIcon, Command, User, Params, CreateDate
} from './styled';

export default function getColumns (fields, isFetching) {
  const alias = 'host.detail.general.tools.table.columns';
  const columns = [{
    Header: () => (
      <FormattedMessage id={ `${alias}.command` } />
    ),
    accessor: 'command',
    resizable: true,
    show: fields.command,
    width: 200,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <Command>
            <CommandIcon />
            { rowInfo.original.command }
          </Command>
          )
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.user` } />
    ),
    accessor: 'user',
    id: 'user',
    show: fields.user,
    maxWidth: 130,
    Cell: (rowInfo) => (
      isFetching ? <Skeleton /> : <User>{ rowInfo.original.user }</User>
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.params` } />
    ),
    accessor: 'params',
    id: 'params',
    show: fields.params,
    maxWidth: 150,
    Cell: (rowInfo) => (
      isFetching ? <Skeleton /> : <Params>{ rowInfo.original.params }</Params>
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.create_date` } />
    ),
    id: 'create_date',
    show: fields.create_date,
    Cell: (rowInfo) => {
      if (isFetching) return <Skeleton />;
      if (rowInfo.original.create_date) {
        return (
          <CreateDate>
            <DateFormatter date={ rowInfo.original.create_date } />
          </CreateDate>
        );
      }
      return '';
    },
    maxWidth: 130
  }];

  return columns;
}
