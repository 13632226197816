import React from 'react';
import {
  Accept,
  Cancel
} from 'Common/Components/Button/styled';
import { Wrapper } from './styled';

const Header = ({ onClose, onAccept }) => (
  <Wrapper>
    <Cancel onClick={ onClose }>Cancel</Cancel>
    <Accept onClick={ onAccept }>Save</Accept>
  </Wrapper>
);
export default Header;
