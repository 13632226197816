import styled from 'styled-components';

const themes = {
  allVulns: {
    border: '#90a9c0',
    body: '#90a9c0'
  },
  notConfirmed: {
    border: '#90a9c0',
    body: '#fafbfc'
  },
  confirmed: {
    border: '#f59220',
    body: '#ffd76b'
  }
};

const Icon = styled.svg`
  width: 24px;
  height: 24px;

  & #flag-border {
    fill: ${(props) => themes[props.theme].border};
  }

  & #flag-body {
    fill: ${(props) => themes[props.theme].body};
  }
`;

export default Icon;
