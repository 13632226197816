import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 400;
  font-size: 12.5px;
  line-height: 2.08;
  user-select: none;
  text-transform: capitalize;
  color: #AFB1BC;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionsCount = styled.div`
  background-color: #edf2f7;
  border-radius: 2em;
  color: #1c2243;
  font-size: 9.5px;
  font-weight: 900;
  display: inline-block;
  line-height: 1;
  min-width: 1;
  padding: 3px 8px;
  text-align: center;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
`;

export const Label = styled.div`
`;

const backgroundColorFunc = (isFocused, required) => {
  if (isFocused) return '#edf2f7';
  else if (required) return 'rgba(255,86,48,0.1)';
  else return 'white';
};

export const dropdownStyle = {
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    minHeight: '35px',
    borderRadius: '1px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: isDisabled ? '#edf2f7' : '#ffffff',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    opacity: isDisabled ? 0.5 : 0.7,
    fontWeight: 300
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: backgroundColorFunc(isFocused, data.required),
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  },
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state?.data?.required ? '#bec8d2' : '#edf2f7',
    color: '#63758d',
    fontSize: '11.5px',
    fontWeight: 500

  }),
  multiValueLabel: (provided, state) => (state.data.required
    ? {
        ...provided, fontWeight: 'bold', color: 'white', paddingRight: 6
      }
    : provided),
  multiValueRemove: (provided, state) => (state.data.required ? { ...provided, display: 'none' } : provided)
};

export const inputDropdownStyle = {
  control: (provided, { isDisabled }) => ({
    display: 'flex',
    minHeight: '35px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf',
    backgroundColor: isDisabled ? '#edf2f7' : '#edf2f7',
    border: 'solid 1px #fafbfc',
    borderBottomWidth: '0px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    borderLeft: '12px solid #53a9ff'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided, { isDisabled }) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    opacity: isDisabled ? 0.5 : 0.7,
    fontWeight: 300
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '13px',
    color: '#1c2243',
    fontWeight: 300
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#505965',
    width: '30px'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: backgroundColorFunc(isFocused, data.required),
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  valueContainer: () => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: 'auto'
  }),
  menu: () => ({
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '100%',
    border: 'none',
    borderRadius: '3px',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  menuList: () => ({
    maxHeight: '250px',
    overflowX: 'auto',
    borderRadius: '3px',
    paddingTop: '17px',
    paddingBottom: '13px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return {
      ...provided, opacity, transition, color: '#1c2243', width: 'fit-content', height: '19px', fontSize: '14px', fontWeight: 400, top: '8px', position: 'relative'
    };
  },
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state?.data?.required ? '#bec8d2' : '#edf2f7',
    color: '#63758d',
    fontSize: '11.5px',
    fontWeight: 500

  }),
  multiValueLabel: (provided, state) => (state.data.required
    ? {
        ...provided, fontWeight: 'bold', color: 'white', paddingRight: 6
      }
    : provided),
  multiValueRemove: (provided, state) => (state.data.required ? { ...provided, display: 'none' } : provided)
};

export const dropdownBlueStyle = {
  ...dropdownStyle,
  control: (provided, { isDisabled }) => ({
    ...provided,
    display: 'flex',
    minHeight: '35px',
    borderRadius: '1px',
    borderColor: '#afafaf',
    backgroundColor: isDisabled ? '#edf2f7' : '#ffffff',
    borderLeft: 'solid 12px #53a9ff', // Add a left border
    borderWidth: '0px',
    borderBottomWidth: '1px',
    color: 'white',
    paddingLeft: '10px',
    fontWeight: '700',
    textOverflow: 'ellipsis',
    width: '220px', // Set the width of the dropdown
    flexWrap: 'nowrap'
  }),
  option: (provided, { isFocused, data }) => ({
    ...provided,
    backgroundColor: isFocused ? '#edf2f7' : provided.backgroundColor, // Change background color on focus to #afafaf
    fontSize: '14px',
    textAlign: 'left',
    color: '#1c2243',
    cursor: isFocused ? 'pointer' : 'default',
    paddingLeft: '22px',
    paddingRight: '22px',
    height: '36px',
    textTransform: 'lowercase',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '220px' // Set the width of the option to match the control's width
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#fafbfc',
    position: 'absolute',
    marginTop: '6px',
    zIndex: 5000,
    width: '220px', // Set the width of the dropdown menu to match the control's width
    border: 'none',
    borderRadius: '3px',
    // boxShadow: '2px 2px 30px 0 #d5dee6',
    minWidth: '190px'
  }),
  valueContainer: () => ({
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    marginRight: 'auto',
    width: '80%'
  })

};

export const Input = styled.input`
height: 35px;
border-radius: 1px;
box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -1px 0 0 #afafaf;
background-color: #ffffff;
border: solid 1px #fafbfc;
padding-left: 10px;
font-weight: 300;
font-size: 14px;
`;
