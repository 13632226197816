import uniq from 'lodash/uniq';
import { GROUP_BY } from 'store/Filters/constants';

const processCtrl = (rowInfo, selected, currentSelectedRows) => {
  const { index } = rowInfo;
  let newRowsSelected = currentSelectedRows;
  if (selected) newRowsSelected.push(rowInfo.original);
  else newRowsSelected = newRowsSelected.filter((v) => v._id !== rowInfo.original._id);

  return { newRowsSelected, lastSelected: index, rowSelected: rowInfo.original };
};

const isGrouped = (group_by) => group_by.length > 0 && group_by !== GROUP_BY.none.key.value;

const validateShiftForProcessing = (rowList, lastRowSelected, group_by, groupContainingCurrentSelection) => {
  const lastRowSelectedGroup = rowList.filter((g) => g.groupData && g.groupData.filter((element) => element._id === lastRowSelected._id).length > 0);
  const rowsAreGrouped = isGrouped(group_by);
  const selectingRowInSameGroup = rowsAreGrouped && lastRowSelectedGroup[0].groupData[0]._id === groupContainingCurrentSelection[0]._id;
  return !rowsAreGrouped || selectingRowInSameGroup;
};

const processShift = (rowInfo, rowList, currentSelectedRows, groupContainingCurrentSelection, lastIndexForRange, lastSelected) => {
  const { index } = rowInfo;
  const arrayToWork = groupContainingCurrentSelection.length > 0 ? groupContainingCurrentSelection : rowList;
  let newRowsSelected = currentSelectedRows;
  let indexForRange = -1;

  if (lastIndexForRange > 0) {
    if (lastSelected < lastIndexForRange) {
      newRowsSelected = newRowsSelected.filter((x) => !arrayToWork.slice(lastSelected + 1, lastIndexForRange + 1).map((y) => y._id).includes(x._id));
    } else {
      newRowsSelected = newRowsSelected.filter((x) => !arrayToWork.slice(lastIndexForRange, lastSelected).map((y) => y._id).includes(x._id));
    }
  }

  if (lastSelected < index) {
    newRowsSelected = newRowsSelected.concat(arrayToWork.slice(lastSelected + 1, index + 1));
    indexForRange = index;
  } else {
    newRowsSelected = newRowsSelected.concat(arrayToWork.slice(index, lastSelected));
    indexForRange = index;
  }
  // newRowsSelected: tiene valores repetidos, hack usando uniq.
  return {
    newRowsSelected: uniq(newRowsSelected, 'id'), indexForRange, lastSelected, rowSelected: rowInfo.original
  };
};

const processSingle = (selected, rowInfo) => {
  const newRowsSelected = [];
  if (selected) newRowsSelected.push(rowInfo.original);
  return { newRowsSelected, rowSelected: rowInfo.original, lastSelected: rowInfo.index };
};

const handleLeftClick = (event, rowInfo, group_by, selected, rowList, currentSelectedRows, lastRowSelected, lastIndexForRange, lastSelected) => {
  let groupContainingCurrentSelection = [];
  if (isGrouped(group_by)) {
    groupContainingCurrentSelection = rowList.filter((g) => g.groupData && g.groupData.filter((element) => element._id === rowInfo.original._id).length > 0);
    groupContainingCurrentSelection = groupContainingCurrentSelection[0].groupData;
  }

  if (event.ctrlKey || event.metaKey) {
    return processCtrl(rowInfo, !selected, currentSelectedRows, groupContainingCurrentSelection);
  }

  if (event.shiftKey) {
    // Avoid shift click between diferent groups
    if (validateShiftForProcessing(rowList, lastRowSelected, group_by, groupContainingCurrentSelection)) return processShift(rowInfo, rowList, currentSelectedRows, groupContainingCurrentSelection, lastIndexForRange, lastSelected);
    return null;
  }

  return processSingle(!selected, rowInfo);
};

export default handleLeftClick;
