import styled from 'styled-components';

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 6px;
`;
Toolbar.displayName = 'Toolbar';

export default Toolbar;
