import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  img {
    border: solid 1px #dbdbdb;
    margin: 19px;
    width: 99px;
    height: 99px;
    border-radius: 3px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 17.5px;
  font-weight: 600;
  color: ${colors.black};
  padding-bottom: 13px;
  padding-right: 13px;
  margin-right: auto;
`;
Title.displayName = 'Title';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 225px;
  height: 100%;
  overflow: auto;
  margin-top: 21px;
`;
Content.displayName = 'Content';

export const WrapperField = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 21px;
`;
WrapperField.displayName = 'WrapperField';

export const Label = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  letter-spacing: 0.09px;
  color: ${colors.grey17};
  padding-bottom: 9px;
`;
Label.displayName = 'Label';

export const Field = styled.textarea`
  padding: 8px 10px 8px 15px;
  background-color: ${colors.iceBlue};
  box-shadow: inset 0 -1px 0 0 #afafaf;
  border: 0;
  resize: none;
  font-size: 14.5px;
  font-weight: normal;
  line-height: 1.24;
  letter-spacing: 0.11px;
  color: ${colors.grey17};
`;
Field.displayName = 'Field';

export const WrapperTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;
WrapperTitle.displayName = 'WrapperTitle';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 185px;
`;
ButtonsContainer.displayName = 'ButtonsContainer';
