import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentReferences, selectVulnDetail } from 'store/Manage/selectors';
import { updateVuln } from 'store/Manage/actions';
import CustomList from 'Common/Components/CustomList';
import ExpandableWrapper from '../ExpandableWrapper';
import Wrapper from './styled';
import useExpandable from 'Hooks/useExpandable';

const TAB = 'general';
const ID = 'references';

const References = () => {
  const dispatch = useDispatch();
  const list = useSelector(selectCurrentReferences);
  const currentVuln = useSelector(selectVulnDetail);
  const intl = useIntl();
  const isExpanded = useExpandable(TAB, ID, list);

  const refsList = list.map((ref) => ref && ref.name);
  const onAdd = (valueAdded) => {
    const newRefs = [...refsList, valueAdded];
    dispatch(updateVuln(currentVuln, 'refs', newRefs));
  };
  const onRemove = (indexToRemove) => {
    const newRefs = refsList.filter((ref, index) => index !== indexToRemove);
    dispatch(updateVuln(currentVuln, 'refs', newRefs));
  };

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="references" title={ intl.formatMessage({ id: 'manage.detail.tab.general.references' }) } tab={ TAB }>
      <Wrapper>
        <CustomList
          key="DETAIL_CREATION_MODAL"
          placeholder="Add a Reference"
          addItem={ onAdd }
          removeItem={ onRemove }
          listData={ refsList }
          responsive
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default References;
