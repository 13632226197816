import styled from 'styled-components';
import { ReactComponent as ColumnIcon } from 'Images/icon-action-bar-column.svg';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const EditColumns = styled(ColumnIcon).attrs({
  id: 'qa-edit-columns-vuln'
})`
    height: 17px;
`;

EditColumns.displayName = 'EditColumns';

export const TableContainer = styled.div`
  height: ${(props) => (props.height ? props.height : '100%')};
  display: flex;
  flex-direction: row;
  flex: 0 0 1;
`;
TableContainer.displayName = 'TableContainer';
