import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { manageTourConfig, dashboardTourConfig } from './tourConfig';
import { openModal } from 'store/modals/actions';
import { MODAL_TOUR_FINISHED } from 'store/modals/modals';
import EnjoyPopup from './EnjoyPopup';
import CommonTour from './CommonTour';
import { endTour } from 'store/Manage/actions';

export const DashboardTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  const redirectToManage = () => {
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <CommonTour
      steps={ dashboardTourConfig(redirectToManage) }
      isTourOpen={ isTourOpen }
      setTour={ setTour }
    />
  );
};

export const ManageTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();

  const showEnjoyPopup = () => {
    dispatch(openModal(MODAL_TOUR_FINISHED));
    setTour(false);
    dispatch(endTour());
  };

  return (
    <>
      <CommonTour steps={ manageTourConfig(showEnjoyPopup) } isTourOpen={ isTourOpen } setTour={ setTour } />
      <EnjoyPopup />
    </>
  );
};
