import styled from 'styled-components';
import colors from 'Styles/colors';
import { bpd } from 'utils/breakpoints';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const WrapperTitle = styled.div`
  display: flex;
`;
WrapperTitle.displayName = 'WrapperTitle';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  color: ${colors.black};
  padding-bottom: 13px;
  margin-right: auto;
`;
Title.displayName = 'Title';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 185px;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const Description = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  line-height: 1.28;
  color: ${colors.grey19};
  padding-bottom: 21px;
`;
Description.displayName = 'Description';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  max-height: 370px;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-right: 17.5px;
  right: -24px;
  margin-left: -25px;
`;
Content.displayName = 'Content';

export const ToolContainer = styled.div`
  font-size: 17.5px;
  font-weight: 500;
`;
ToolContainer.displayName = 'ToolContainer';

export const ToolCategory = styled.div`
  font-size: 17.5px;
  font-weight: 500;
  padding-bottom: 21px;
  color: ${colors.black};
`;
ToolCategory.displayName = 'ToolCategory';

export const Tools = styled.div`
  padding-bottom: 21px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-direction: column;
  ${bpd('xsm')`
    flex-direction: row;
  `}
  & > * {
    margin-top: 10px;
    ${bpd('xsm')`
      width: 49%;
    `}
  }
`;
Tools.displayName = 'Tools';

export const WrapperButton = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.blueCerulean};
  margin-right: auto;
`;
WrapperButton.displayName = 'WrapperButton';

export const WrapperAdded = styled.div`
  display: flex;
  align-items: center;
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.warmPurple};
  margin-right: auto;
`;
WrapperAdded.displayName = 'WrapperAdded';
