import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  flex: 1 1 0px;
  margin: auto;
  align-items: center;
  margin-top: 50px;
  user-select: none;
`;

Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  margin-top: 35px;
  text-align: center;
  align-items: center;
`;

Content.displayName = 'Content';

export const TitleWrapper = styled.div`
  font-family: 'CentraNo2';
  font-weight: normal;
  font-size: 21px;
  letter-spacing: 0.16px;
  color: ${colors.dark2};
  text-align: center;
  width: 340px;
  margin: auto;
`;

TitleWrapper.displayName = 'TitleWrapper';

export const Description = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${colors.grey19};
  text-align: center;
  margin: 23px 0px;
  width: 400px;
`;

Description.displayName = 'Description';

export const BoldDescription = styled.div`
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: ${colors.grey19};
  font-weight: 600;
`;
BoldDescription.displayName = 'BoldDescription';

export const FeatureImage = styled.img`
  width: 305px;
  height: auto;
`;

FeatureImage.displayName = 'FeatureImage';

export const TitleBold = styled(TitleWrapper)`
  display: inline;
  font-weight: 500;
`;

TitleBold.displayName = 'TitleBold';
