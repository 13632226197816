/* eslint-disable no-useless-constructor */
import React from 'react';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import Dropdown from 'Common/Components/Dropdown';
import { standardDropdownStyle } from 'Common/styles/style';
import { Wrapper } from './styled';

const changeStyle = (newWidth) => {
  const newStyle = { ...standardDropdownStyle };
  const newControl = { ...newStyle.control(), width: newWidth };
  newStyle.control = () => newControl;
  return newStyle;
};

const Item = ({ title, normal }) => (
  <div className="d-inline-flex item-option">
    <span>{normal ? title : capitalize(title)}</span>
  </div>
);

Item.propTypes = {
  title: PropTypes.string.isRequired
};

export default class StandardDropdown extends React.Component {
  constructor (props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange (newValue) {
    const {
      field, updateValue, selectObject, options
    } = this.props;
    if (selectObject) {
      const selected = options.find((o) => o.name === newValue);
      updateValue(field, selected);
    } else {
      updateValue(field, newValue);
    }
  }

  render () {
    const {
      options, width, defaultValue, placeholder, disabled, menuPortalTarget, normal
    } = this.props;
    const style = width ? changeStyle(width) : standardDropdownStyle;
    const dropdownOptions = options.map((element) => ({ label: <Item title={ element.desc } normal={ normal } />, value: element.name }));
    return (
      <Wrapper>
        <Dropdown
          customStyle={ style }
          options={ dropdownOptions }
          updateValue={ this.onChange }
          placeholder={ placeholder }
          defaultValue={ defaultValue }
          disabled={ disabled }
          menuPortalTarget={ menuPortalTarget }
        />
      </Wrapper>
    );
  }
}

StandardDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    desc: PropTypes.string
  })).isRequired,
  width: PropTypes.string,
  field: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  selectObject: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  menuPortalTarget: PropTypes.node,
  normal: PropTypes.bool
};

StandardDropdown.defaultProps = {
  width: '',
  selectObject: false,
  placeholder: 'Select',
  disabled: false,
  menuPortalTarget: undefined,
  normal: false
};
