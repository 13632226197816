import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { toggleInactive } from 'Screens/Workspaces/actions/Actions';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import { Wrapper, Text } from './styled';

// eslint-disable-next-line import/prefer-default-export
export const ShowInactive = () => {
  const dispatch = useDispatch();
  const showInactive = useSelector((state) => (state.workspace.showInactive));
  const workspacesSelected = useSelector(selectWorkspacesSelected);

  return (
    <Wrapper ws={ workspacesSelected.length > 0 }>
      <CustomCheckbox label="" handleCheckboxChange={ () => dispatch(toggleInactive()) } key="showInactive" isChecked={ showInactive } id="test_id_show_inactive" />
      <Text>Show Inactive</Text>
    </Wrapper>
  );
};
