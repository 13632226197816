/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { useKeyPress } from 'Common/Components/SearchBar/components/BasicFilter/functions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Dropdown, DropdownOptionContainer, DropdownOptionLabel } from './styled';

const DropdownOptions = ({
  options, onSelect, onSearch, defaultSelect, isLastDropdown
}) => {
  const [cursor, setCursor] = useState(defaultSelect ? 0 : -1);
  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [hovered, setHovered] = useState(undefined);
  const [elRefs, setElRefs] = React.useState([]);

  useEffect(() => {
    setElRefs((elRefs) => (
      Array(options.length).fill().map((_, i) => elRefs[i] || createRef())
    ));
  }, [options.length]);

  const executeScroll = (cursor) => {
    const ref = get(elRefs, `[${cursor}].current`, null);
    if (ref) ref.scrollIntoView(false);
  };

  useEffect(() => {
    const maxIndex = options.length - 1;
    if (cursor > maxIndex) {
      setCursor(maxIndex);
      executeScroll(maxIndex);
    }
    if (options.length === 1) {
      if (defaultSelect) setCursor(0);
      else setCursor(-1);
    }
  }, [options]);

  useEffect(() => {
    if (downPress) {
      const maxIndex = options.length - 1;
      if (cursor < maxIndex) {
        const newCursor = cursor + 1;
        setCursor(newCursor);
        executeScroll(newCursor);
      } else if (cursor === maxIndex) {
        setCursor(0);
        executeScroll(0);
      }
    }
  }, [downPress]);

  useEffect(() => {
    if (upPress) {
      if (cursor > 0) {
        const newCursor = cursor - 1;
        setCursor(newCursor);
        executeScroll(newCursor);
      } else if (cursor === 0) {
        if (defaultSelect) {
          const newCursor = options.length - 1;
          setCursor(newCursor);
          executeScroll(newCursor);
        } else {
          setCursor(-1);
          executeScroll(0);
        }
      }
    }
  }, [upPress]);

  useEffect(() => {
    if (enterPress) {
      if (cursor === -1) onSearch();
      else onSelect(options[cursor]);
    }
  }, [enterPress]);

  useEffect(() => {
    if (hovered && options.length) {
      setCursor(options.indexOf(hovered));
    }
  }, [hovered]);

  if (isEmpty(options)) return null;
  return (
    <Dropdown>
      <DropdownOptionContainer>
        {options.map((option, index) => (
          <DropdownOptionLabel
            ref={ elRefs[index] }
            isActive={ index === cursor }
            key={ option.name.value }
            onMouseEnter={ () => setHovered(option) }
            onMouseLeave={ () => setHovered(undefined) }
            onClick={ () => {
              onSelect(option);
              if (isLastDropdown && (option.name.value !== '')) onSearch();
            } }
            children={ `${option.name.label}` }
          />
        ))}
      </DropdownOptionContainer>
    </Dropdown>
  );
};

DropdownOptions.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isLastDropdown: PropTypes.bool
};

DropdownOptions.defaultProps = {
  isLastDropdown: false
};

export default DropdownOptions;
