import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFiltering, selectRowsPerPage } from 'store/Filters/selectors';
import { selectTotalVulns, unSelectAll, unselectTotalVulns } from 'store/Manage/actions';
import { allVulnsAreSelected, selectSelectAll, selectVulnsCount, selectVulnsSelected } from 'store/Manage/selectors';
import { Wrapper, Text, Blue, Bold } from './styled';

const SelectAll = () => {
  const dispatch = useDispatch();
  const allVisualVulnsSelected = useSelector(allVulnsAreSelected);
  const vulnPageLimit = useSelector((state) => selectRowsPerPage('vulns', state));
  const selectAll = useSelector(selectSelectAll);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnCount = useSelector(selectVulnsCount);
  const isFiltering = useSelector(state => selectIsFiltering('vulns', state));
  // All 50 Vulnerabilities on this page are selected. Select all 302 Vulnerabilities in Workspace
  // All 302 Vulnerabilities on this Workspace are selected. Clear Selection

  const handleSelectAll = () => {
    dispatch(selectTotalVulns());
  };
  const handleClear = () => {
    dispatch(unselectTotalVulns());
    dispatch(unSelectAll());
  };

  const content = () => {
    if (selectAll) {
      return (
            <Text>
        All <Bold>{vulnCount}</Bold> Vulnerabilities on this {isFiltering ? 'search' : 'Workspace'} are selected. <Blue onClick={ handleClear }>Clear Selection</Blue>
            </Text>
      );
    } else if (isFiltering) {
      return (
<Text>
        All <Bold>{vulnsSelected.length}</Bold> Vulnerabilities on this page are selected. <Blue onClick={ handleSelectAll }>Select all Vulnerabilities that match this search.</Blue>
</Text>
      );
    } else {
      return (
<Text>
        All <Bold>{vulnsSelected.length}</Bold> Vulnerabilities on this page are selected. <Blue onClick={ handleSelectAll }>Select all {vulnCount} Vulnerabilities in Workspace</Blue>
</Text>
      );
    }
  };
  if (!allVisualVulnsSelected) return null;
  if (vulnsSelected.length < vulnPageLimit || vulnCount <= vulnPageLimit) return null;
  return <Wrapper>
        {content()}
         </Wrapper>;
};

export default SelectAll;
