/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-useless-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dropdown from 'Common/Components/Dropdown';
import { eorDropdownStyle } from 'Common/styles/style';
import { easeOfResolution } from 'Screens/Constants';
import { Wrapper } from './styled';

const Item = (props) => (
  <div className="d-inline-flex item-option">
    <span>{props.title.charAt(0).toUpperCase() + props.title.slice(1).toLowerCase()}</span>
  </div>
);

Item.propTypes = {
  title: PropTypes.string.isRequired
};

class EORDropdown extends React.Component {
  constructor (props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange (value) {
    this.props.updateValue('easeOfResolution', value);
  }

  render () {
    const { defaultValue } = this.props;
    const options = easeOfResolution.map((element) => ({ label: <Item title={ element.desc } />, value: element.name }));

    return (
      <Wrapper>
        <Dropdown customStyle={ eorDropdownStyle } options={ options } className="ease-of-resolution" updateValue={ this.onChange } placeholder="Ease of Resolution" defaultValue={ defaultValue } />
      </Wrapper>
    );
  }
}

EORDropdown.propTypes = {
  defaultValue: PropTypes.string,
  updateValue: PropTypes.func.isRequired
};

EORDropdown.defaultProps = {
  defaultValue: null
};

export default withRouter(EORDropdown);
