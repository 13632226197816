import styled from 'styled-components';
import colors from 'Styles/colors';
import W3af from 'Images/tool_logo_w3af.png';
import Burp from 'Images/tool_logo_burp.png';
import Arachni from 'Images/tool_logo_arachni.png';
import Nikto from 'Images/tool_logo_nikto.png';
import Wpscan from 'Images/tool_logo_wpscan.png';
import Nessus from 'Images/tool_logo_nessus.png';
import Nmap from 'Images/tool_logo_nmap.png';
import Nuclei from 'Images/tool_logo_nuclei.png';
import Openvas from 'Images/tool_logo_openvas.png';
import Cli from 'Images/tool_logo_cli.png';
import Insightvm from 'Images/tool_logo_insightVM.png';
import Qualys from 'Images/tool_logo_qualys.png';
import Shodan from 'Images/tool_logo_shodan.png';
import Sonarqube from 'Images/tool_logo_sonarQube.jpeg';
import Sublist3r from 'Images/tool_logo_sublist34.png';
import Tenableio from 'Images/tool_logo_tenable.png';
import Zap from 'Images/tool_logo_zap.png';
import Appscan from 'Images/tool_logo_appscan.png';

export const Wrapper = styled.div`
  display: flex;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.07);
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 17px;
  padding-bottom: 0;
  width: 100%;
  border-left: #dbdbdb solid 1px;
`;
Content.displayName = 'Content';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: ${colors.black};
  padding-bottom: 5px;
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.28;
  color: ${colors.grey19};
  padding-bottom: 3px;
`;
Description.displayName = 'Description';

export const Logo = styled.img.attrs(({ type }) => {
  if (type === 'appscan') return { src: Appscan };
  if (type === 'arachni') return { src: Arachni };
  if (type === 'burp') return { src: Burp };
  if (type === 'gvm_openvas') return { src: Openvas };
  if (type === 'insightvm') return { src: Insightvm };
  if (type === 'nessus') return { src: Nessus };
  if (type === 'nikto2') return { src: Nikto };
  if (type === 'nmap') return { src: Nmap };
  if (type === 'nuclei') return { src: Nuclei };
  if (type === 'openvas_legacy') return { src: Openvas };
  if (type === 'qualys') return { src: Qualys };
  if (type === 'shodan2') return { src: Shodan };
  if (type === 'sonarqube') return { src: Sonarqube };
  if (type === 'sublist3r') return { src: Sublist3r };
  if (type === 'tenableio') return { src: Tenableio };
  if (type === 'w3af') return { src: W3af };
  if (type === 'wpscan') return { src: Wpscan };
  if (type === 'wpscan_legacy') return { src: Wpscan };
  if (type === 'zap') return { src: Zap };

  return { src: Cli };
})`
  width: 99px;
  height: 99px;
  object-fit: contain;
  ${({ type }) => type === 'shodan2' && `background-color: ${colors.black}`};
`;
Logo.displayName = 'Logo';
