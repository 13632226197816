import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { Trash } from '../../styled';
import { showVulnModalDelete } from 'store/Manage/actions';

const DeleteButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showVulnModalDelete());

  return (
    <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } />
  );
};

export default DeleteButton;
