import {
  SAVE_PREFERENCES_SUCCESS
} from 'store/Preferences/actions';

const initialState = { };

function preferences (state = initialState, action) {
  switch (action.type) {
    case SAVE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        ...action.preferences
      };
    }

    default: {
      return state;
    }
  }
}

export default preferences;
