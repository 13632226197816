import get from 'lodash/get';

const setOrderServices = (sorted, data) => {
  const desc = get(sorted, 'length', 0) > 0 && sorted[0].desc;
  const field = get(sorted, 'length', 0) > 0 && sorted[0].id;

  let servicesSorted = {};
  if (desc) {
    servicesSorted = data.sort((a, b) => b[field] - a[field]);
  } else {
    servicesSorted = data.sort((a, b) => a[field] - b[field]);
  }
  return servicesSorted;
};

export default setOrderServices;
