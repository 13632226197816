/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Wrapper,
  Title,
  CustomInput,
  Error
} from './styled';

class EmailField extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.validateEnter = this.validateEnter.bind(this);
    this.validateEmail = this.validateEmail.bind(this);

    this.state = {
      value: ''
    };
  }

  componentDidMount () {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue });
    }
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    this.props.onChange(v);
  }

  validateEnter (e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (this.props.onBlur) {
        this.props.onBlur();
      } else if (this.props.submit) {
        this.props.submit();
      }
    }
  }

  validateEmail () {
    if (this.state.value === '') return '';
    // eslint-disable-next-line prefer-regex-literals
    const re = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    return re.test(this.state.value) ? '' : 'Invalid Email';
  }

  render () {
    const { value } = this.state;
    const {
      placeholder,
      title,
      width,
      height,
      disabled,
      error,
      id,
      readOnly
    } = this.props;

    return (
      <Wrapper>
        <Title disabled={ disabled }>{ title }</Title>
        <CustomInput readOnly={ readOnly || false } id={ id } value={ value } placeholder={ placeholder } type="email" width={ width } height={ height } onChange={ (e) => this.onChangeHandler(e.target.value) } disabled={ disabled } onBlur={ () => (this.props.onBlur ? this.props.onBlur() : () => {}) } onKeyPress={ this.validateEnter } error={ this.validateEmail() !== '' || error } />
        <Error>{ this.validateEmail() }</Error>
      </Wrapper>
    );
  }
}

EmailField.propTypes = {
  placeholder: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  onBlur: PropTypes.func,
  submit: PropTypes.func
};

EmailField.defaultProps = {
  placeholder: '',
  title: '',
  width: '100%',
  height: '36px',
  disabled: false,
  error: false,
  id: '',
  readOnly: false,
  defaultValue: '',
  onBlur: undefined,
  submit: undefined
};

const mapDispatchToProps = () => ({

});

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailField));
