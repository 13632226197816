import styled from 'styled-components';
import { slideInRight } from 'Styles/effects';

export const ItemWrapper = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 23px;
  cursor: pointer;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #d9e4ef;
  }


    
    ${({ selected }) => selected && `
      background-color: #d9e4ef;
  `}
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Text = styled.div`
  margin-left: 13px;
  font-size: 15px;
  color: #264d6d;
  width: auto;
  ${slideInRight}
  
  ${({ selected }) => selected &&
  `
    font-weight: 600;
  `}
`;

export const Icon = styled.div`
  width: 22px;
  display: flex;
  justify-content: center;
  &:not(.reports) svg {
    width: 22px;
  }
  .reports {
    margin-left: 3px;
  }
`;
