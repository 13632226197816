import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Help } from 'Images/icon_help.svg';

export const AdvancedModeWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 48px;
  right: 0px;
`;

export const HelpIcon = styled(Help)`
  margin-right: 9px;
  background-color:  ${colors.blueCerulean};
  cursor: pointer;
`;
HelpIcon.displayName = 'HelpIcon';

export const AdvancedModeLabel = styled.div`
  user-select: none;
  margin-left: auto;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color:  ${colors.blueCerulean};
  cursor: pointer;
`;
