import { SET_MODE_HOST_CREATE_UPDATE } from 'store/HostEditCreate/types';

import {
  WORKSPACE_SELECTED_FOR_WORKING,
  GET_WORKSPACES_FOR_WORKING_STARTED,
  GET_WORKSPACES_FOR_WORKING_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_FAILURE,
  STOP_REDIRECT,
  WORKSPACE_SELECTED,
  DELETE_WORKSPACES,
  GET_DATA_WORKSPACE_START,
  GET_DATA_WORKSPACE_FAIL,
  GET_DATA_WORKSPACE_SUCCESS,
  WORKSPACE_UPDATE_SUCCESS,
  SET_VISIBILITY_CREATE_MODAL_WORKSPACE,
  WORKSPACE_MASSIVE_UPDATE_FAIL,
  WORKSPACE_MASSIVE_UPDATE_SUCCESS,
  MORE_OPTIONS_STATUS_CHANGE_WORKSPACE,
  TOGGLE_INACTIVE_FLAG,
  GET_DATA_WORKSPACES_SUCCESS,
  WORKSPACE_SHOW_GRID_VIEW,
  WORKSPACE_ENABLE_DISABLE,
  GET_WORKSPACE_STATS_SUCCESS,
  GET_WORKSPACE_STATS_FAIL,
  GET_WORKSPACES_FOR_SELECTOR_START,
  GET_WORKSPACES_FOR_SELECTOR_SUCCESS,
  GET_WORKSPACES_FOR_SELECTOR_FAIL,
  GET_WORKSPACE_STATS_START,
  GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS
} from '../../Screens/Workspaces/actions/Actions';

import { SET_MODE_MANAGE_CREATE_UPDATE } from '../../Screens/ManageEditCreate/actions/Actions';

const initialState = {
  isFetching: false,
  workspaces: [],
  workspaceSelected: [],
  workspaceList: [],
  error: false,
  errorMessage: '',
  redirect: false,
  mode: '',
  workspacesSelected: [],
  lastSelected: -1,
  lastWorkspaceSelected: {},
  lastIndexForRange: -1,
  showModalCreate: false,
  moreOptionsOpened: false,
  showInactive: false,
  workspaceCount: 0,
  showGridView: false,
  rowsPerPageListView: 50,
  workspacesForListView: []
};

function faraday (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_WORKSPACE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_DATA_WORKSPACE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_DATA_WORKSPACE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaces: action.data
      };
    }

    case WORKSPACE_SELECTED_FOR_WORKING:
      return {
        ...state,
        workspaceSelected: action.workspace /* redirect: action.redirect */
      };

    case STOP_REDIRECT:
      return { ...state, redirect: false };

    case GET_WORKSPACES_FOR_WORKING_STARTED:
      return { ...state, isFetching: true, loadingWsForDropdown: true };

    case GET_WORKSPACES_FOR_WORKING_SUCCESS:
      return {
        ...state,
        workspaces: action.data,
        workspaceCount: action.count,
        isFetching: false,
        loadingWsForDropdown: false
      };

    case GET_WORKSPACES_FOR_WORKING_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.data,
        isFetching: false,
        loadingWsForDropdown: false
      };

    case SET_MODE_MANAGE_CREATE_UPDATE:
    case SET_MODE_HOST_CREATE_UPDATE: {
      return { ...state, mode: action.mode };
    }

    case WORKSPACE_SELECTED: {
      const { newRowsSelected, lastSelected, indexForRange, rowSelected } =
        action.vulnsSelectedInfo;

      return {
        ...state,
        lastWorkspaceSelected: rowSelected,
        workspacesSelected: newRowsSelected,
        lastSelected: lastSelected || -1,
        lastIndexForRange: indexForRange || -1
      };
    }

    case DELETE_WORKSPACES: {
      return {
        ...state,
        isFetching: true
      };
    }

    case WORKSPACE_UPDATE_SUCCESS: {
      const { workspaceList, workspacesSelected, workspacesForListView, showGridView } = state;
      const workspaces = showGridView ? workspaceList : workspacesForListView;
      const indexWorkspaces = workspaceList.findIndex(
        (wspace) => wspace.id === action.ws.id
      );
      workspaces[indexWorkspaces] = { ...action.ws };

      const indexWorkspacesSelected = workspacesSelected.findIndex(
        (wspace) => wspace.id === action.ws.id
      );
      workspacesSelected[indexWorkspacesSelected] = { ...action.ws };

      return {
        ...state,
        workspaceList: showGridView ? workspaces : workspaceList,
        workspacesForListView: showGridView ? workspacesForListView : workspaces,
        workspacesSelected,
        isFetching: false
      };
    }

    case SET_VISIBILITY_CREATE_MODAL_WORKSPACE: {
      return {
        ...state,
        showModalCreate: action.value
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_SUCCESS: {
      return {
        ...state,
        workspaces: [...action.workspaces]
      };
    }

    case MORE_OPTIONS_STATUS_CHANGE_WORKSPACE: {
      return {
        ...state,
        moreOptionsOpened: action.value
      };
    }

    case TOGGLE_INACTIVE_FLAG: {
      return {
        ...state,
        showInactive: !state.showInactive
      };
    }

    case GET_DATA_WORKSPACES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceList: action.data,
        workspaceCount: action.count
      };
    }

    case WORKSPACE_SHOW_GRID_VIEW: {
      return {
        ...state,
        showGridView: action.showGridView
      };
    }

    case WORKSPACE_ENABLE_DISABLE: {
      let readonly = state.readonly;

      if (action.ws === state.workspaceSelected) readonly = !readonly;

      return {
        ...state,
        isFetching: true,
        readonly
      };
    }

    case GET_WORKSPACE_STATS_START: {
      return {
        ...state,
        isFetching: true,
        currentWs: action.data
      };
    }

    case GET_WORKSPACE_STATS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        currentWs: action.data
      };
    }

    case GET_WORKSPACE_STATS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_WORKSPACES_FOR_SELECTOR_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_WORKSPACES_FOR_SELECTOR_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspacesForSelector: action.data
      };
    }

    case GET_WORKSPACES_FOR_SELECTOR_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_WORKSPACES_FOR_LIST_VIEW_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspacesForListView: action.data,
        workspaceCount: action.count
      };
    }
    default:
      return state;
  }
}

export default faraday;
