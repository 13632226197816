import { Accept, Cancel } from 'Common/Components/Button/styled';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pickBy from 'lodash/pickBy';
import { selectToolsByCategory, selectToolsSelected, selectAgentTypes } from 'store/Agents/selectors';
import { addTool } from 'store/Agents/actions';
import { useIntl } from 'react-intl';
import Card from '../Card';
import {
  Wrapper, Title, Description, Content, ButtonsContainer, ToolContainer, ToolCategory, Tools, WrapperTitle, WrapperButton, WrapperAdded
} from './styled';
import WebSiteButton from '../WebsiteButton';
import Toolbar from '../Toolbar/styled';

const Add = ({ added, onAdded, toolName }) => {
  const intl = useIntl();
  return !added && (
  <WrapperButton id={ `modal-agents-tools-${toolName}-add` } onClick={ onAdded }>
    {intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.addToAgent' })}
  </WrapperButton>
  );
};

const Added = ({ added }) => {
  const intl = useIntl();
  return added && (
  <WrapperAdded>
    {intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.added' })}
  </WrapperAdded>
  );
};

const ToolCollection = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const toolsByCategory = useSelector(selectToolsByCategory);
  const toolsSelected = useSelector(selectToolsSelected);
  const allTools = useSelector(selectAgentTypes);
  const [tools, setTools] = useState(toolsSelected);

  const isAdded = (tool) => {
    const toolsSelected = Object.entries(tools).map(el => el[0]);
    return toolsSelected.indexOf(tool) > -1;
  };

  const onAdded = (e, tool) => {
    const newTool = pickBy(allTools, { name: tool });
    const newTools = { ...tools, ...newTool };
    setTools(newTools);
    e.preventDefault();
  };

  const onSave = (e) => {
    Object.values(tools).forEach(tool => {
      dispatch(addTool(tool.name));
    });
    e.preventDefault();
    onClose();
  };

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.title' })}</Title>
        <ButtonsContainer>
          <Cancel id="modal-agents-tools-back" onClick={ onClose }>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.back' })}</Cancel>
          <Accept id="modal-agents-tools-done" onClick={ onSave }>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.done' })}</Accept>
        </ButtonsContainer>
      </WrapperTitle>
      <Description>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.description' })}</Description>
      <Content>
        {
        toolsByCategory.map((tool) => (
          <ToolContainer key={ tool.categoryName }>
            <ToolCategory>{ tool.categoryName }</ToolCategory>
            <Tools>
              {
                tool.items.map((item) => {
                  const toolbar = (
                    <Toolbar>
                      <Added added={ isAdded(item.name) } />
                      <Add added={ isAdded(item.name) } onAdded={ (e) => onAdded(e, item.name) } toolName={ item.name } />
                      <WebSiteButton url={ item.website } toolName={ item.name } />
                    </Toolbar>
                  );
                  return <Card key={ item.title } title={ item.title } description={ item.description } image={ item.name } toolbar={ toolbar } />;
                })
              }
            </Tools>
          </ToolContainer>
        ))
      }
      </Content>
    </Wrapper>
  );
};
export default ToolCollection;
