import React from 'react';
import { ReactComponent as ClipboardIcon } from 'Images/icon-clipboard.svg';
import copyToClipboard from 'utils/clipboard';
import {
  TextItem, PurpleColor
} from '../styled';
import {
  CodeWrapper, Code, Link, Flex
} from './styled';
import { QuestionMark } from '../../styled';

export const DockerTab = () => {
  const script = 'docker pull faradaysec/faraday_agent_dispatcher';
  const copyTextToClipboard = () => copyToClipboard(script);
  return (
    <>
      <TextItem>
        1-Download and install
        <Link href="https://hub.docker.com/u/faradaysec" target="_blank"> Docker</Link>
      </TextItem>
      <TextItem>
        2- Pull the Docker image file from our
        <Link href="https://hub.docker.com/r/faradaysec/faraday" target="_blank"> dockehub page </Link>
        and load it in Docker:
      </TextItem>
      <Flex>
        <CodeWrapper>
          <Code>
            $
            <PurpleColor> docker pull </PurpleColor>
            faradaysec/faraday_agent_dispatcher
          </Code>
        </CodeWrapper>
        <ClipboardIcon onClick={ copyTextToClipboard } />
      </Flex>
      <Flex>
        <QuestionMark />
        <Link href="https://docs.agents.faradaysec.com/misc/docker/" target="_blank">Docker configuration documentation</Link>
      </Flex>
    </>
  );
};

export const PythonTab = () => {
  const script = 'pip install faraday_agent_dispatcher';
  const copyTextToClipboard = () => copyToClipboard(script);
  return (
    <>
      <TextItem>
        1-Download and install
        <Link href="https://www.python.org/downloads/" target="_blank"> Python</Link>
      </TextItem>
      <TextItem>
        2- Install Faraday Agent Dispatcher:
      </TextItem>
      <Flex>
        <CodeWrapper>
          <Code>
            $
            <PurpleColor> pip install </PurpleColor>
            faraday_agent_dispatcher
          </Code>
        </CodeWrapper>
        <ClipboardIcon onClick={ copyTextToClipboard } />
      </Flex>
      <Flex>
        <QuestionMark />
        <Link href="https://docs.agents.faradaysec.com/getting-started/" target="_blank">Python configuration documentation</Link>
      </Flex>
    </>
  );
};
