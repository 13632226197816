import styled from 'styled-components';

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 26px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const FieldWrapper = styled.div`
  padding: 9px 20px;
`;
FieldWrapper.displayName = 'FieldWrapper';
