import * as types from './types';

export const add = (name) => ({
  type: types.ADD,
  payload: name
});

export const remove = (name) => ({
  type: types.REMOVE,
  payload: name
});
