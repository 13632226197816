import React from 'react';
import Generator from 'Common/Components/Generator';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_AGENT_TOKEN } from 'store/modals/modals';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { useDispatch } from 'react-redux';
import {
  Wrapper, Message, Help, Documentation, Title, Footer
} from './styled';

const Token = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const close = () => dispatch(closeModal(MODAL_AGENT_TOKEN));

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'agents.token.title' }) }</Title>
      <Message>
        Copy this token into Faraday Agent Dispatcher when prompted. This will allow to connect the agent with your Faraday instance.
      </Message>
      <Help>If you need further help you can check</Help>
      <Documentation href="https://docs.agents.faradaysec.com/getting-started/" target="_blank"> our documentation.</Documentation>
      <Generator />
      <Footer>
        <Cancel onClick={ close }>Close</Cancel>
        <Accept onClick={ close }>Accept</Accept>
      </Footer>
    </Wrapper>
  );
};
export default Token;
