import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: block;
  max-width: 442px;
  width: 100%;
  text-align: left;
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.44;
  padding: 36px 40px;
  box-shadow: rgb(167 167 167 / 50%) 18px 43px 50px 0px;
  border-top: 10px solid ${colors.softBlue};
  border-radius: 2px 2px 0px 0px;
  overflow: auto;
  max-height: 85%;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Documentation = styled.a`
  display: inline-block;
  color: ${colors.blueCerulean};
  margin: 0 5px;
`;
Documentation.displayName = 'Documentation';

export const Message = styled.div`
  display: block;
  color: ${colors.greyBlue};
`;
Message.displayName = 'Message';

export const Help = styled.div`
  display: inline-block;
  color: ${colors.greyBlue};
`;
Help.displayName = 'Help';

export const Title = styled.div`
  display: inline-block;
  padding-bottom: 12px;
  font-size: 21.5px;
  font-weight: 600;
  line-height: 1.49;
  color: ${colors.greyBlue};
`;
Title.displayName = 'Title';

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
Footer.displayName = 'Footer';
