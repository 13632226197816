import React from 'react';
import { useSelector } from 'react-redux';
import logo from 'Images/faraday_logo.svg';
import { selectApiVersion } from 'store/Faraday/selectors';
import {
  ContainerBox, Box, LoginLogoContainer, Logo
} from './styled';

const Container = ({ children, width, height }) => {
  const apiVersion = useSelector(selectApiVersion);
  const frontVersion = process.env.REACT_APP_VERSION;

  return (
    <ContainerBox>
      <Box width={ width } height={ height }>
        <LoginLogoContainer>
          <Logo src={ logo } alt="Faraday" title={ `Server: ${apiVersion}\nClient: ${frontVersion}` } />
        </LoginLogoContainer>
        {children}
      </Box>
    </ContainerBox>
  );
};

export default Container;
