import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  opacity: 0.5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Spinner = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid #939596;
  border-right-color: #939596;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
`;

/* eslint-disable react/jsx-props-no-spreading */
const Loading = (props) => (
  <Wrapper { ...props }>
    <Spinner />
  </Wrapper>
);

export default Loading;
