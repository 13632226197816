import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { selectAllVulns, selectConfirmedVulns, selectNotConfirmedVulns } from 'store/Manage/actions';
import { Dropdown, DropdownOptionLabel, Backdrop } from './styled';

const ConfirmedDropdown = ({ show, hideDropdown }) => {
  const dispatch = useDispatch();
  const select = (type) => {
    if (type === 'all') dispatch(selectAllVulns());
    if (type === 'confirmed') dispatch(selectConfirmedVulns());
    if (type === 'notConfirmed') dispatch(selectNotConfirmedVulns());
    hideDropdown();
  };

  return show && (
    <>
      <Backdrop onClick={ hideDropdown } />
      <Dropdown>
        <DropdownOptionLabel
          onClick={ () => select('all') }
          children="All"
        />
        <DropdownOptionLabel
          onClick={ () => select('confirmed') }
          children="Confirmed"
        />
        <DropdownOptionLabel
          onClick={ () => select('notConfirmed') }
          children="Not Confirmed"
        />
      </Dropdown>
    </>
  );
};

ConfirmedDropdown.propTypes = {
  show: PropTypes.bool.isRequired,
  hideDropdown: PropTypes.func.isRequired
};

export default ConfirmedDropdown;
