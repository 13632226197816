import styled from 'styled-components';
import { ReactComponent as CloudIcon } from 'Images/icon_upload.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
  align-content: flex-start;
`;

export const ActiveDragBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border: 1px solid #0082ed;
  background-color: aliceblue;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
ActiveDragBox.displayName = 'ActiveDragBox';

export const Text = styled.p`
  color: #0082ed;
  font-size: 14px;
  user-select: none;
  margin-top: 10px;
`;
Text.displayName = 'Text';

export const Cloud = styled(CloudIcon)`
  display: block;
  cursor: default;
  width: 41px;
  height: 34px;
  & path{
    fill: ${({ color }) => (color || '#7a7e8f')};

  }
`;
Cloud.displayName = 'Cloud';
