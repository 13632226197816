export function copy (mainObj) {
  const objCopy = {};

  Object.keys(mainObj).forEach((key) => {
    objCopy[key] = mainObj[key];
  });
  return objCopy;
}

export function copyArray (mainArray) {
  const arrayCopy = [];

  mainArray.forEach((mainObj) => {
    const objCopy = {};

    Object.keys(mainObj).forEach((key) => {
      objCopy[key] = mainObj[key];
    });

    arrayCopy.push(objCopy);
  });

  return arrayCopy;
}

export function copyStringArray (mainArray) {
  return mainArray.slice();
}
