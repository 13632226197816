import styled from 'styled-components';
import { ReactComponent as FiltersOff } from 'Images/icon_filter_off.svg';
import { ReactComponent as FiltersOn } from 'Images/icon_filter_on.svg';

export const DisabledFilterIcon = styled(FiltersOff)`
  height: 16px;
`;
DisabledFilterIcon.displayName = 'DisabledFilterIcon';

export const EnabledFilterIcon = styled(FiltersOn)`
  height: 16px;
`;
EnabledFilterIcon.displayName = 'EnabledFilterIcon';
