import styled from 'styled-components';
import colors from 'Styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const ButtonLink = styled.button`
  color: ${colors.blueCerulean};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 5px;
`;
ButtonLink.displayName = 'ButtonLink';
