import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';

const Wrapper = styled.div.attrs((props) => ({
  id: props.id ? props.id : ''
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props) => (props.addEditServices ? '100%' : '100vw')};
  height: ${(props) => (props.addEditServices ? '100%' : '100vh')};
  background: rgb(39, 39, 39, 0.2);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-top: 10px solid ${colors.softBlue};
    border-radius: 2px 2px 0 0;
    padding: 36px 40px;
    overflow: auto;
    max-height: 85%;
  }
  ${slideIn}
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
