import Skeleton from 'Common/Components/Skeleton';
import React from 'react';
import TimeAgo from 'react-timeago';
import Target from './components/Target';
import { Wrapper, Content, Data, DateTime, Duration, Label, RunBy } from './styled';

const Card = ({ isSkeleton, target, user, duration, date }) => {
  const getDuration = () => {
    if (duration) return `${duration} secs`;
    return '-';
  };

  return (
    <Wrapper>
      <Target title={ target }>{isSkeleton ? <Skeleton /> : target}</Target>
      <Content>
        <RunBy>
          <Label>Run by:</Label>
          <Data>{isSkeleton ? <Skeleton /> : user}</Data>
        </RunBy>
        <Duration>
          <Label>Duration</Label>
          <Data>{isSkeleton ? <Skeleton /> : getDuration()}</Data>
        </Duration>
      </Content>
      <DateTime>{isSkeleton ? <Skeleton /> : (date && <TimeAgo date={ date } minPeriod={ 60 } />)}</DateTime>
    </Wrapper>
  );
};
export default Card;
