import React from 'react';
import { Col, Row } from 'Common/Components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import get from 'lodash/get';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { redirect } from 'store/Router/actions';
import {
  StyledRow, Name, Title, Value, Empty, ClickableRow
} from './styled';
import { setFilter } from 'store/Filters/actions';
import formatNumber from 'Common/Functions/FormatNumber';
/* eslint-disable consistent-return */
const Summary = () => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const data = useSelector((state) => get(state, 'dashboard.tools[3].data.stats', {}));

  const goToAssets = () => {
    dispatch(redirect(`/host/${workspaceSelected}`));
  };

  const goToManage = () => {
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  const goToManageWebVulns = () => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilitiesByType('vulnerability_web')));

    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <ClickableRow >
      <Col>
        <Row onClick={ goToManage }>
          <Col><Title>Workspace Summary</Title></Col>
        </Row>
        { data
          ? (
          <StyledRow>
            <Col onClick={ goToAssets }>
              <StyledRow><Value title={ data.hosts }>{formatNumber(data.hosts)}</Value></StyledRow>
              <StyledRow><Name>Assets</Name></StyledRow>
            </Col>

            <Col onClick={ goToAssets }>
              <StyledRow><Value title={ data.services }>{formatNumber(data.services)}</Value></StyledRow>
              <StyledRow><Name>Services</Name></StyledRow>
            </Col>

            <Col onClick={ goToManage } title="std + web">
              <StyledRow>
                <Value title={ data.total_vulns }>{formatNumber(data.total_vulns) || 0}</Value>
              </StyledRow>
              <StyledRow><Name>Total Vulnerabilities</Name></StyledRow>
            </Col>

            <Col onClick={ goToManageWebVulns } >
              <StyledRow>
                <Value title={ data.web_vulns }>{formatNumber(data.web_vulns) || 0}</Value>
              </StyledRow>
              <StyledRow><Name>Web Vulns</Name></StyledRow>
            </Col>

          </StyledRow>
            )
          : <Empty>There is no Vulnerabilities to show.</Empty> }
      </Col>
    </ClickableRow>
  );
};

export default Summary;
