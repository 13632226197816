import { useEffect, useState } from 'react';
import { selectTabToggleValue } from 'store/Global/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { toggleExpandCollapse } from 'store/Global/actions';
import { isEmpty } from 'lodash';
import { selectVulnDetailId } from 'store/Manage/selectors';

const useExpandable = (tab, fieldName, fieldValue) => {
  const dispatch = useDispatch();
  const isExpandedBase = useSelector((state) => selectTabToggleValue(state, tab, fieldName));
  const vulnId = useSelector(selectVulnDetailId);
  const [isExpanded, setIsExpanded] = useState(isExpandedBase);
  const fieldHasValue = !isEmpty(fieldValue) || !!fieldValue;

  useEffect(() => {
    setIsExpanded(isExpandedBase);
  }, [isExpandedBase]);

  useEffect(() => {
    setIsExpanded(fieldHasValue);
    if (fieldHasValue !== isExpandedBase) {
      dispatch(toggleExpandCollapse(tab, {
        [fieldName]: {
          expanded: fieldHasValue
        }
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vulnId]);

  return isExpanded;
};

export default useExpandable;
