import { io } from 'socket.io-client';
import dispatchWSAction from './dispatchWSActions';

// TODO: Make it scalable to multiple sockets instances.
const socketMiddleware = () => {
  let socket = null;

  // Middleware
  return (store) => (next) => (action) => { // eslint-disable-line consistent-return
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== null) {
          socket.close();
        }
        // Connect WS
        // socket = io(action.host, { path: '/_api/wsocket/v1' });
        socket = io(action.host);
        // WS dipatch incoming event
        socket.onAny((event, ...args) => dispatchWSAction(store, event, ...args));
        break;
      case 'WS_DISCONNECT':
        if (socket !== null) {
          socket.close();
        }
        socket = null;
        break;
      case 'NEW_MESSAGE':
        socket.emit(action.event, action.data);
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
