import styled from 'styled-components';

const Wrapper = styled.div`
  flex-direction: column;
  flex: 1 1 0px;
  width: 100%;
  margin-top: 20px;
  overflow: auto;
  padding: 0px 30px;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
