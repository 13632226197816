import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-left: 10px;
  width: 120px;
  display: flex;
  align-items: baseline;
`;

export const Label = styled.label`
  font-size: 13.5px;
  font-weight: 400;
  letter-spacing: normal;
  color: #63758d;
  margin: 0 0 0 5px;
`;
