import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, TrashIcon } from './styled';

const ActionBar = ({ markToDelete }) => (
  <Wrapper onClick={ markToDelete }>
    <TrashIcon />
  </Wrapper>
);

ActionBar.propTypes = {
  markToDelete: PropTypes.func.isRequired
};

export default ActionBar;
