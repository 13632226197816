import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import SeverityIcon from 'Common/Components/SeverityIcon';
import { ReactComponent as ArrowCollapsed } from 'Images/arrow-right.svg';
import { ReactComponent as ArrowExpanded } from 'Images/arrow-down.svg';

export const FiltersHeader = styled.div`
  display:flex;
  margin-bottom: 14px;
  padding-right: 14px;
`;
FiltersHeader.displayName = 'FiltersHeader';

export const Flex = styled.div`
  display:flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')}; 
  flex-wrap: wrap;
  margin-bottom: 13px;
  position: relative;
`;
Flex.displayName = 'Flex';

export const Content = styled(Flex)`
  ${({ isExpanded }) => (!isExpanded && 'display: none')};
`;
Content.displayName = 'Content';

export const Resizer = styled.div`
  height: 1px;
  margin: 2px 10px 13px 0;
  opacity: 0.57;
  border-top: solid 1px #bec8d2;
  cursor: ns-resize;
`;
Resizer.displayName = 'Resizer';

export const HeaderTitle = styled.h2`
  font-size: 15px;
  font-weight: 600;
  color: #63758d;
  margin-right: auto;
  padding-right: ${({ isFiltering }) => (isFiltering ? '0px' : '4px')};
`;
HeaderTitle.displayName = 'HeaderTitle';

export const Title = styled.h3`
  font-size: 14px;
  color: #1c2243;
  margin-right: auto;
  text-align: left;
`;
Title.displayName = 'Title';

export const Text = styled.p`
font-size: 13px;
color: #1c2243;
padding-left: 13px;
cursor: pointer;
`;
Text.displayName = 'Text';

export const Subtitle = styled.h4`
  font-size: 13px;
  font-weight: 500;
  color: #63758d;
  text-align: left;
  margin-bottom: 14px;
`;
Subtitle.displayName = 'Subtitle';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ClearAll = styled.p`
   font-size: 12px;
  font-weight: 500;
  color: #0082ed;
  cursor:pointer;
`;
ClearAll.displayName = 'ClearAll';

export const Clear = styled(ClearAll)`
  margin-right: 22px;
`;
Clear.displayName = 'Clear';

export const CheckboxOption = styled.div`
  display:flex;
  margin: 0 13px;
  margin-bottom: 4px;
  max-width: max-content;
`;
CheckboxOption.displayName = 'CheckboxOption';

export const FilterWrapper = styled.div`
  padding-top: ${({ isFiltering }) => (isFiltering ? '10px' : '8px')};
  padding-right: ${({ isFiltering }) => (isFiltering ? '0px' : '4px')};
`;
FilterWrapper.displayName = 'FilterWrapper';

export const Severity = styled(SeverityIcon)`
  width: 27px;
  height: 19px;
  margin-right: 7px;
`;
Severity.displayName = 'Severity';

const IconWrapper = css`
  position: absolute;
  top: 5px;
  right: 0px;
`;

export const ExpandedIcon = styled(ArrowExpanded)`
  ${IconWrapper};
  margin: 2px 5px auto auto;
  width: 10px;
  color: #63758d;
  transform: rotate(180deg);
  path {
    fill: ${colors.greyBlue};
  }
`;
ExpandedIcon.displayName = 'ExpandedIcon';

export const CollapsedIcon = styled(ArrowExpanded)`
  ${IconWrapper};
  margin: auto 5px auto auto;
  height: 10px;
  path {
    fill: ${colors.greyBlue};
  }
`;
CollapsedIcon.displayName = 'CollapsedIcon';

export const Close = styled(ArrowCollapsed)`
  transform: rotate(180deg);
  margin-left: 10px;
`;
Close.displayName = 'Close';
