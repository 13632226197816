import styled from 'styled-components';
import colors from 'Common/Kit/colors';

export const H1 = styled.p`
  font-family: 'Sequel Sans';
  font-size: 26px;
  font-weight: bold;
  color: ${colors.grey1};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
`;
H1.displayName = 'H1';

export const H2 = styled(H1)`
  font-size: 24px;
  line-height: 1.42;
`;
H2.displayName = 'H2';

export const H3 = styled(H1)`
  font-size: 22px;
  line-height: 1.41;
`;
H3.displayName = 'H3';

export const H4 = styled(H1)`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
`;
H4.displayName = 'H4';

export const H5 = styled(H1)`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.39;
`;
H5.displayName = 'H5';

export const H6 = styled(H1)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
`;
H6.displayName = 'H6';

export const DefaultParagraph = styled(H1)`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: ${colors.black};
`;
DefaultParagraph.displayName = 'DefaultParagraph';

export const StrongParagraph = styled(DefaultParagraph)`
  font-weight: 600;
  line-height: 1.43;
  color: ${colors.grey1};
`;
StrongParagraph.displayName = 'StrongParagraph';

export const SmallParagraph = styled(DefaultParagraph)`
  font-size: 10px;
  line-height: 1.4;
  color: ${colors.grey7};
`;
SmallParagraph.displayName = 'SmallParagraph';

export const TableText = styled(DefaultParagraph)`
`;
TableText.displayName = 'TableText';

export const TableTextStrong = styled(DefaultParagraph)`
  font-weight: 600;
  line-height: 1.43;
  color: ${colors.grey1};
`;
TableTextStrong.displayName = 'TableTextStrong';

export const TableLabel = styled.p`
  font-family: 'CentraNo2';
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${colors.grey11};
`;
TableLabel.displayName = 'TableLabel';

export const ButtonText = styled(TableLabel)`
  font-size: 14px;
  line-height: normal;
  color: ${colors.black};
`;
ButtonText.displayName = 'ButtonText';

export const TagsText = styled(TableLabel)`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.14;
  color: ${colors.grey1};
`;
TagsText.displayName = 'TagsText';

export const MenuText = styled(TableLabel)`
  font-size: 14px;
  line-height: normal;
  color: ${colors.black};
`;
MenuText.displayName = 'MenuText';

export const MenuTextBold = styled(MenuText)`
  font-weight: bold;
`;
MenuTextBold.displayName = 'MenuTextBold';

export const Link = styled.a`
  font-family: 'Sequel Sans';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: ${colors.blue5};
`;
Link.displayName = 'Link';
