import styled from 'styled-components';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const BlueText = styled.span`
    color: #419bf9;
    font-weight: 300;
`;
BlueText.displayName = 'BlueText';

export const SummaryContainer = styled.div`
    margin-top: 35px;
`;
SummaryContainer.displayName = 'SummaryContainer';

export const SummaryItem = styled.div`
    font-size: 14.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 14px;
`;
SummaryItem.displayName = 'SummaryItem';

export const ButtonWithoutBackgroundRight = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
    border: 1px solid #0082ed;
    color: #0082ed;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    flex: 0 0 149px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    height: 34px;
    letter-spacing: normal;
    line-height: 2.21;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    text-align: center;
`;
ButtonWithoutBackgroundRight.displayName = 'ButtonWithoutBackgroundRight';

export const Button = styled.button`
    background-color: #0082ed;
    border-radius: 2px;
    border: none;
    color: white;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    flex: 0 0 89px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    height: 34px;
    letter-spacing: normal;
    line-height: 2.21;
    margin-left: auto;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;
Button.displayName = 'Button';

export const ButtonsContainerCustom = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 22px;
`;
ButtonsContainerCustom.displayName = 'ButtonsContainerCustom';
