import styled from 'styled-components';

export const Wrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}` : '400px')};    
    margin: ${(props) => (props.margin ? 'auto' : '0')};
    user-select: none;
`;
Wrapper.displayName = 'Wrapper';

export const ImpactItem = styled.div`
    width: 195px;
    height: 24px;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 0 #acbdd2;    
    font-size: 12.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    text-align: center;    
    margin-right: 2px;
    margin-bottom: 3px;
    background-color: ${(props) => (props.active ? '#686A76' : '#edf2f7')};
    color: ${(props) => (props.active ? 'white' : '#0b2128')};

    &:hover {
        background-color: ${(props) => (props.active ? '#787a87' : '#f1f4f7')};
        cursor: pointer;
        color: ${(props) => (props.active ? 'white' : '#0b2128')};
    }
`;
ImpactItem.displayName = 'ImpactItem';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
