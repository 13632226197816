import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import CreateActions from 'Screens/Host/components/CreateActions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/CustomActionBar/styled';
import DeleteHostButton from './components/DeleteHostButton';
import EditHostButton from './components/EditHostButton';
import Pagination from 'Common/Components/Pagination';
import {
  toggleModalEditCreate,
  setPageNumberAssets
} from 'store/Host/actions';
import { selectShowHostDetail } from 'store/HostDetail/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import {
  selectHostCount,
  selectHostsSelected
} from 'store/Host/selectors';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import GroupBy from './components/GroupBy';
import FilterSideBarIcon from 'Screens/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import RefreshButton from 'Screens/Host/components/RefreshButton';
import AddRemoveColumnsButton from './components/AddRemoveColumnsButton';

const ActionBar = () => {
  const dispatch = useDispatch();
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const hostsSelected = useSelector(selectHostsSelected);
  const hostsCount = useSelector(selectHostCount);
  const isHostDetailVisible = useSelector(selectShowHostDetail);
  const page = useSelector((state) => selectPage('assets', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('assets', state));
  const showHostsActionButtons = (!readonly && hostsSelected.length > 0 && !isHostDetailVisible);
  const onPrev = () => dispatch(setPageNumberAssets(page - 1));
  const onNext = () => dispatch(setPageNumberAssets(page + 1));
  const hasHosts = hostsCount > 0;
  const showGroupBy = hasHosts && (hostsSelected.length === 0) && !isHostDetailVisible;

  return (
    <Wrapper>
      <DeleteConfirmationModal />
      <LeftGroup>
        {!readonly && <CreateActions setVisibilityCreateModal={ (value) => dispatch(toggleModalEditCreate(value)) } /> }
        { showHostsActionButtons &&
          <>
            <Separator />
            <Group>
              <EditHostButton />
              <DeleteHostButton />
            </Group>
            <Separator />
          </>
        }
      </LeftGroup>

      <RightGroup>
        { showGroupBy && <GroupBy /> }
        { (hasHosts) && <Pagination offset={ page } limit={ rowsPerPage } total={ hostsCount } onPrev={ onPrev } onNext={ onNext } />}
        { hasHosts &&
          <Group>
            <RefreshButton />
            <FilterSideBarIcon entity="assets" />
          </Group>
        }
        <Group>
          <AddRemoveColumnsButton />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default ActionBar;
