import React from 'react';
import { useDispatch } from 'react-redux';
import { Cancel } from 'Common/Components/Button/styled';
import { clearAgent } from 'store/Agents/actions';
import { Wrapper, SecondContainer, Next } from './styled';

const Header = ({
  back, cancel, next, finish, nextDisable
}) => {
  const dispatch = useDispatch();
  const onCancel = () => {
    dispatch(clearAgent());
    cancel();
  };
  return (
    <Wrapper>
      {back && <Cancel id="modal-agents-back" onClick={ back }> Back </Cancel>}
      <SecondContainer>
        {cancel && <Cancel id="modal-agents-cancel" onClick={ onCancel }>Cancel</Cancel>}
        {next && <Next id="modal-agents-next" label="Next" onClick={ next } disabled={ nextDisable } />}
        {finish && <Next id="modal-agents-finish" onClick={ finish } label="Finish" />}
      </SecondContainer>
    </Wrapper>
  );
};

export default Header;
