import styled from 'styled-components';
import StringField from 'Common/Components/StringField';
import { Title } from 'Common/Components/StringField/styled';

const Password = styled(StringField).attrs((props) => ({
  width: '100%',
  type: 'password',
  autocomplete: props.autocomplete
}))`
  margin-bottom: 16px;
  max-width: 48%;
  width: 100%;
  ${Title} {
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
Password.displayName = 'Password';

export default Password;
