import { useEffect, useRef } from 'react';

const useMouseHover = (onMouseEnter, onMouseLeave, delay = 500) => {
  const mouseEnterTimer = useRef(null);
  const mouseLeaveTimer = useRef(null);

  const mouseLeaveDebounced = () => {
    clearTimeout(mouseEnterTimer.current);
    mouseLeaveTimer.current = (setTimeout(() => {
      onMouseLeave();
    }, delay));
  };

  const mouseEnterDebounced = () => {
    clearTimeout(mouseLeaveTimer.current);
    mouseEnterTimer.current = (setTimeout(() => {
      onMouseEnter();
    }, delay));
  };

  const cancelEvent = () => {
    if (mouseEnterTimer.current) clearTimeout(mouseEnterTimer.current);
    if (mouseLeaveTimer.current) clearTimeout(mouseLeaveTimer.current);
  };

  useEffect(() => () => cancelEvent(), []);

  return [mouseEnterDebounced, mouseLeaveDebounced];
};

export default useMouseHover;
