import { isArray, isEmpty, isNumber, toString } from 'lodash';
import React from 'react';
import ExpandableWrapper from '../ExpandableWrapper';
import MarkdownEditor from '../MarkdownEditor';
import { Wrapper, ListItem } from './styled';

const CustomAttributes = ({
  fields, onChange, expanded
}) => {
  if (isEmpty(fields)) return null;

  const getContent = (name, value) => {
    if (!value) return null;
    else if (isNumber(value)) return <MarkdownEditor value={ toString(value) } />;
    else if (isArray(value)) {
      return (
        <Wrapper>
          { value.map((v) => <ListItem key={ `${name}-${v}` } >{v}</ListItem>) }
        </Wrapper>
      );
    }
    return <MarkdownEditor value={ value } />;
  };
  return <>
    {
      Object.keys(fields).map((name) => {
        const value = fields[name];
        if (!value) return null;
        return (
          <ExpandableWrapper key={ `field_${name}` } onChange={ () => onChange(name) } selected={ value } id={ name } title={ name } expanded={ expanded }>
            { getContent(name, value) }
          </ExpandableWrapper>
        );
      })
    }
         </>;
};

export default CustomAttributes;
