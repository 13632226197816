import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ enabled }) => (!enabled && 'pointer-events: none')};
  margin-top: auto;
  flex: 0 0 25px;
  display: flex;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px 0 rgba(99, 117, 141, 0.48);
  & > svg {
    cursor: pointer;
    min-width: 17px;
  }
  &:hover {
    & > svg {
      g {
        path {
          stroke: #77bb2e;
          fill: #77bb2e;
        }
      }
    }
  }
  &:active {
    & > svg {
      g {
        path {
          stroke: #66aa1d;
          fill: #66aa1d;
        }
      }
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
