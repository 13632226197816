import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const ViewAll = styled.a`
  color: #1188ff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
ViewAll.displayName = 'ViewAll';
