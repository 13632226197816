import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import {
  selectTemplatesCreated, selectFailedTemplates, selectStatusTemplateMessage, modalData,
  selecListTemplates
} from 'store/Manage/selectors';
import { closeTemplateModalMessage } from 'store/Manage/actions';
import WarningModal from 'Common/Components/WarningModal';
import Wrapper from './styled';

const SaveTemplateModal = () => {
  const templatesCreated = useSelector(selectTemplatesCreated);
  const failedTemplates = useSelector(selectFailedTemplates);
  const dispatch = useDispatch();
  const intl = useIntl();
  const getIntl = (id) => intl.formatMessage({ id: `manage.saveTemplate.${id}` });

  const status = useSelector(selectStatusTemplateMessage);
  const title = getIntl(`title.${modalData[status].title}`);
  const subtitle = modalData[status].subtitle ? getIntl(`subtitle.${modalData[status].subtitle}`) : '';

  const list = useSelector((state) => selecListTemplates(state, status));

  const partialSuccess = (status === 'singlePartialSuccess') || (status === 'multiplePartialSuccess');
  const numTemplatesCreated = partialSuccess ? `${templatesCreated.length} vulnerabilities ` : '';
  const firstSubtitleBold = { start: getIntl('boldSub.number.start'), middle: numTemplatesCreated, end: getIntl('boldSub.number.end') };

  const onlyOneFailed = failedTemplates.length === 1;
  const nameFailedTemplate = onlyOneFailed ? failedTemplates.map((t) => t.name).toString() : '';
  const secondSubtitleBold = { start: getIntl('boldSub.singleFail.start'), middle: `"${nameFailedTemplate}"`, end: getIntl('boldSub.singleFail.end') };

  return (
    <Wrapper>
      <WarningModal
        title={ title }
        subtitle={ subtitle }
        firstSubtitleBold={ partialSuccess ? firstSubtitleBold : null }
        secondSubtitleBold={ onlyOneFailed ? secondSubtitleBold : null }
        list={ list }
        showIcon={ status !== 'success' }
        onDone={ () => dispatch(closeTemplateModalMessage()) }
        listWithDoubleQuotes
      />
    </Wrapper>
  );
};

const TemplateModal = () => {
  const dispatch = useDispatch();
  const templatesCreated = useSelector(selectTemplatesCreated);
  const failedTemplates = useSelector(selectFailedTemplates);
  useEffect(() => () => {
    dispatch(closeTemplateModalMessage());
  }, [dispatch]);

  return (
    <>
      { (!isEmpty(templatesCreated) || !isEmpty(failedTemplates)) && <SaveTemplateModal /> }
    </>
  );
};

export default TemplateModal;
