/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetState } from 'Screens/Wizard/actions/Actions';
import {
  selectIsFetching, selectRememberMe, selectErrorMessage, selectIsLoggedIn
} from 'store/Sesion/selectors';
import { login, loginStart, setRememberMe } from 'Screens/Login/actions/Actions';
import StringField from 'Common/Components/StringField';
import 'Screens/Login/styles/loginStyles.scss';
import MainContainer from 'Common/Components/MainContainer';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { redirect } from 'store/Router/actions';
import {
  OptionsContainer, RememberMe, RememberMeLabel,
  InputTitle, ErrorMessage, WrapperField, AcceptButton
} from './styled';

class Login extends Component {
  constructor (props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      error: false,
      fetched: false
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount () {
    this.props.resetState();
    this.setState({ fetched: false });
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.errorMessage && nextProps.errorMessage.length > 0 && this.state.fetched) this.setState({ error: true });
    if (nextProps.loggedIn) {
      this.setState({
        user: '', password: '', error: false, fetched: false
      });
    }
  }

  // Chrome only sets the autofill value once the user interacts with the app by cliking somewhere.
  // Because of that, we need to delay the submit of the login until state is updated with autofill values
  // This can be fixed by using a login with form and login url

  submit () {
    this.props.loginStart();

    setTimeout(() => {
      this.props.login(this.state.user, this.state.password);
      this.setState({
        error: false, fetched: true
      });
    }, 500);
  }

  render () {
    const {
      isFetching,
      rememberMe
    } = this.props;

    return (
      <MainContainer>
        <div className="login-input-container-title margin-bottom-33">
          <p className="width-340">Sign in</p>
        </div>

        <>
          <WrapperField>
            <InputTitle error={ this.state.error }>Username</InputTitle>
          </WrapperField>
          <WrapperField>
            <StringField id="username" width="340px" height="34px" placeholder="johndoe" onChange={ (v) => { this.setState({ user: v, error: false, fetched: false }); } } submit={ () => { this.submit(); } } error={ this.state.error } autocomplete="username" />
          </WrapperField>
        </>

        <div className="margin-top-22">
          <WrapperField>
            <InputTitle error={ this.state.error }>Password</InputTitle>
          </WrapperField>
          <WrapperField>
            <StringField id="password" width="340px" height="34px" placeholder="********" type="password" onChange={ (v) => { this.setState({ password: v, error: false, fetched: false }); } } submit={ () => { this.submit(); } } error={ this.state.error } autocomplete="password" />
          </WrapperField>
        </div>

        {this.state.error ? <ErrorMessage>Invalid username or password</ErrorMessage> : null}

        <OptionsContainer>
          <RememberMe>
            <CustomCheckbox label="" handleCheckboxChange={ () => { this.props.setRememberMe(!rememberMe); } } key="rememberMe" isChecked={ rememberMe } id="test_id_remember_me" />
            <RememberMeLabel>Remember Me</RememberMeLabel>
          </RememberMe>
        </OptionsContainer>

        <WrapperField>
          <AcceptButton onClick={ () => this.submit() } label="Login" isLoading={ isFetching } />
        </WrapperField>
      </MainContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetching: selectIsFetching(state),
  rememberMe: selectRememberMe(state),
  errorMessage: selectErrorMessage(state),
  isLoggedIn: selectIsLoggedIn(state)
});

const mapDispatchToProps = (dispatch) => ({
  loginStart: () => {
    dispatch(loginStart());
  },
  login: (user, password) => {
    dispatch(login(user, password));
  },
  resetState: () => {
    dispatch(resetState());
  },
  setRememberMe: (value) => {
    dispatch(setRememberMe(value));
  },
  redirect: (pathname) => {
    dispatch(redirect(pathname));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
