import styled from 'styled-components';
import colors from 'Styles/colors';

export const Help = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  color: ${colors.blueGrey};
  margin-bottom: 15px;
`;
Help.displayName = 'Help';

export const Here = styled.a`
  font-size: 13.5px;
  font-weight: normal;
  color: ${colors.blueCerulean};
  margin-left: 5px;
`;
Here.displayName = 'Here';
