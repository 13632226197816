import React from 'react';
import PropTypes from 'prop-types';
import GetBackgroundColor from 'Common/Functions/GetBackgroundColor';

import Icon from './styled';

const getLetter = (word) => {
  if (word) {
    if (word.length === 1) return word;

    return word[0];
  }
  return 'f';
};

const ColorImage = ({ role, title }) => {
  const background = GetBackgroundColor(role);
  const letter = getLetter(title);

  return (
    <Icon background={ background } title={ title }>
      {letter}
    </Icon>
  );
};

ColorImage.propTypes = {
  role: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ColorImage;
