const initialState = {
  id: undefined,
  _id: undefined,
  ip: '',
  owned: false,
  os: '',
  description: '',
  hostnames: [],
  mac: '',
  isFetching: false,
  errorMessage: undefined,
  redirect: false,
  mode: '',
  report: [],
  importSuccess: false,
  successMessage: '',
  ownder: '',
  default_gateway: 'None',
  hostnameToAdd: ''
};

export default initialState;
