import React from 'react';
import { useIntl } from 'react-intl';
import ExpandableWrapper from '../ExpandableWrapper';
import MarkdownEditor from '../MarkdownEditor';

const Resolution = ({
  value, onChange, selected, expanded
}) => {
  const intl = useIntl();

  return (
    <ExpandableWrapper selected={ selected } onChange={ onChange } id="resolution" title={ intl.formatMessage({ id: 'manage.detail.tab.general.resolution' }) } expanded={ expanded }>
      <MarkdownEditor value={ value } />
    </ExpandableWrapper>
  );
};

export default Resolution;
