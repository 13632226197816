/* eslint-disable import/prefer-default-export */
import React from 'react';
import { getRequestUrl } from 'services/api/connector';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { showContextMenu } from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import head from 'lodash/head';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import copyToClipboard from 'utils/clipboard';
import { CopyApiLinkIcon } from './styled';

export const CopyApiLink = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const isBulk = vulnsSelected.length > 1;

  const onClick = () => {
    const selectedVulnID = get(head(vulnsSelected), '_id', 0);
    if (selectedVulnID && workspaceSelected) {
      const vulnPath = `ws/${workspaceSelected}/vulns/${selectedVulnID}`;
      const link = getRequestUrl(vulnPath);
      copyToClipboard(link);
    }
    dispatch(showContextMenu(false));
  };

  if (isBulk) return null;
  return (
    <MenuItem
      title={ intl.formatMessage({ id: 'manage.cm.copyApiLink' }) }
      icon={ <CopyApiLinkIcon /> }
      onClick={ onClick }
    />
  );
};
