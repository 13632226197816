import React from 'react';
import Shell from 'Images/mini-terminal.svg';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Wrapper, PrimaryTitle, SecondaryTitle, TertiaryTitle, Text, Row, Button, Logo, Container
} from '../../styled';

const Extras = ({ onClose }) => {
  const intl = useIntl();

  return (
    <Wrapper maxWidth="585px">
      <Logo src={ Shell } alt="shell" />
      <PrimaryTitle>
        {intl.formatMessage({ id: 'user.extras.title' })}
      </PrimaryTitle>
      <SecondaryTitle>
        {intl.formatMessage({ id: 'user.extras.secondary-title' })}
      </SecondaryTitle>
      <Container>
        <Row>
          <Text>
            {intl.formatMessage({ id: 'user.extras.text' })}
          </Text>
        </Row>

        <Row>
          <TertiaryTitle href="https://github.com/infobyte/faraday-cli" target="_blank">
            {intl.formatMessage({ id: 'user.extras.tertiary-title.Faraday-CLI' })}
          </TertiaryTitle>
          <Text>
            {intl.formatMessage({ id: 'user.extras.text.Faraday-CLI' })}
          </Text>
        </Row>

        <Row>
          <TertiaryTitle href="https://github.com/infobyte/faraday_agent_dispatcher" target="_blank">
            {intl.formatMessage({ id: 'user.extras.tertiary-title.Agent-Dispatcher' })}
          </TertiaryTitle>
          <Text>
            {intl.formatMessage({ id: 'user.extras.text.Agent-Dispatcher' })}
          </Text>
        </Row>

        <Row>
          <TertiaryTitle href="https://github.com/infobyte/faraday_templates/tree/master/methodologies" target="_blank">
            {intl.formatMessage({ id: 'user.extras.tertiary-title.Methodology' })}
          </TertiaryTitle>
          <Text>
            {intl.formatMessage({ id: 'user.extras.text.Methodology' })}
          </Text>
        </Row>

        <Row>
          <TertiaryTitle href="https://github.com/infobyte/faraday_templates/tree/master/vulnerability_templates" target="_blank">
            {intl.formatMessage({ id: 'user.extras.tertiary-title.Vulnerability-KB' })}
          </TertiaryTitle>
          <Text>
            {intl.formatMessage({ id: 'user.extras.text.Vulnerability-KB' })}
          </Text>
        </Row>

        <Row>
          <TertiaryTitle href="https://portswigger.net/bappstore/82f3cbaea46c4f158fd85bbccc90c31c" target="_blank">
            {intl.formatMessage({ id: 'user.extras.tertiary-title.Burp-Extender' })}
          </TertiaryTitle>
          <Text>
            {intl.formatMessage({ id: 'user.extras.text.Burp-Extender' })}
          </Text>
        </Row>

      </Container>
      <Button onClick={ onClose }>{intl.formatMessage({ id: 'user.buttton' })}</Button>

    </Wrapper>
  );
};

Extras.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Extras;
