import React from 'react';
import { useIntl } from 'react-intl';
import HelpIcon from 'Images/noun-help.svg';

import { Wrapper, Text, IconHelp } from './styled';

const Help = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <IconHelp src={ HelpIcon } alt="Help" />
      <Text>{intl.formatMessage({ id: 'workspaces.columnSelector.text' })}</Text>
    </Wrapper>
  );
};

export default Help;
