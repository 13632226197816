import React from 'react';
import Header from '../Header';
import Wrapper from './styled';
import UpgradeLicense from '../UpgradeLicense';
import FFSaml from 'Images/ff_saml.png';

const Saml = ({ onClose }) => {
  return (
    <Wrapper>
      <Header onClose={ onClose } onAccept={ onClose } />
      <UpgradeLicense image={ FFSaml } descriptionIntlId={ 'upgradeLicense.sidebar.settings.saml.description' } />
    </Wrapper>
  );
};

export default Saml;
