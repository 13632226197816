import { setCurrentWorkspaceAsInvalid } from 'Screens/Faraday/actions/Actions';
import { READ_ONLY_ERROR } from 'store/Manage/types';
import get from 'lodash/get';

const DEFAULT_ERROR = 'Something went wrong. Please try again later.';

// eslint-disable-next-line react/display-name
const validateWorkspace = (error) => (dispatch) => {
  const errorMessage = get(error, 'message', DEFAULT_ERROR);
  // If the error is caused due to the workspace no longer existing
  if (errorMessage.toLowerCase().startsWith('no such workspace')) { // TODO: Refactor with actual error code from BE once we have one
    return dispatch(setCurrentWorkspaceAsInvalid());
  } if (errorMessage.startsWith('Altering a readonly workspace is not allowed')) {
    return dispatch({ type: READ_ONLY_ERROR, error: errorMessage });
  }
  return null;
};

export default validateWorkspace;
