import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { Edit } from 'Screens/Manage/components/CustomActionBar/styled';
import { showVulnTemplateModalEdit } from 'Screens/KnowledgeBase/actions/Actions';

const EditButton = () => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showVulnTemplateModalEdit());

  return (
    <IconButton icon={ <Edit /> } title="Edit" onClick={ handleClick } />
  );
};

export default EditButton;
