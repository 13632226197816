import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpen } from 'store/SideBar/selector';
import { selectLocation } from 'store/Router/selectors';
import useMouseHover from 'Hooks/useMouseHover';
import { redirect } from 'store/Router/actions';
import SideBarSubMenu from '../SideBarSubMenu';
import SubPage from '../SubPage';
import {
  Wrapper, Text, Icon, ItemWrapper
} from './styled';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const SideBarItem = ({ icon, name, subMenu, showFF }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const location = useSelector(selectLocation);
  const [showMenu, setShowMenu] = useState(false);
  const onHide = () => setShowMenu(false);
  const onShow = () => setShowMenu(true);
  const [mouseEnterDebounced, mouseLeaveDebounced] = useMouseHover(onShow, onHide);

  const hasSelectedItem = subMenu.some(({ url }) => url === location.pathname);
  const hasOneChild = subMenu.length === 1;
  const canShowMenu = showMenu && (((isOpen && !hasOneChild && !hasSelectedItem) || !isOpen));
  const canShowSubPages = (isOpen && hasSelectedItem && !hasOneChild);

  const enabledItems = subMenu.filter((item) => get(item, 'enabledByRole', false));

  const selectFirst = () => {
    const enabledByFeature = get(enabledItems, '[0].enabledByFeature', false);
    if (enabledByFeature) {
      const url = get(enabledItems, '[0].url', '');
      dispatch(redirect(url));
    } else {
      const FF = get(enabledItems, '[0].FF', {});
      showFF(FF);
    }
  };

  if (isEmpty(enabledItems)) return null;
  return (
    <Wrapper onMouseEnter={ mouseEnterDebounced } onMouseLeave={ mouseLeaveDebounced } >
      <ItemWrapper selected={ hasSelectedItem || canShowMenu } onClick={ selectFirst } data-tour={ name }>
        <Icon children={ icon } />
        { isOpen && <Text selected={ hasSelectedItem } children={ name } /> }
      </ItemWrapper>
      { canShowMenu && <SideBarSubMenu items={ enabledItems } name={ name } showFF={ showFF } /> }
      { canShowSubPages && enabledItems.map(({ name, url, enabledByFeature, FF }) => <SubPage name={ name } url={ url } key={ name } enabledByFeature={ enabledByFeature } FF={ FF } showFF={ showFF } />) }
    </Wrapper>
  );
};

export default SideBarItem;
