import colors from 'Styles/colors';

const severityMapColors = {
  critical: colors.warmPurple,
  high: colors.redPink,
  info: colors.grey2,
  low: colors.apple,
  med: colors.tangerine,
  unclasified: colors.grey4
};

export default severityMapColors;
