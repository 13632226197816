import { updatePreferences } from 'store/Preferences/actions';

function setTourFinished (status) {
  return async (dispatch) => {
    const preferences = { tourFinished: status };
    dispatch(updatePreferences(preferences));
  };
}

export default setTourFinished;
