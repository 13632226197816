/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getTools
} from 'store/HostDetail/actions';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { FormattedMessage } from 'react-intl';
import getColumns from './Columns';
import {
  Wrapper, Title, Detail, DetailTitle, Tools, DetailContent
} from './styled';

const WrapperTable = () => {
  const props = useSelector((state) => ({
    isFetching: state.hostDetail.tools.isFetching,
    data: state.hostDetail.tools.data,
    fields: state.hostDetail.tools.fields,
    host: state.hostDetail.host
  }));

  const {
    isFetching, data, fields, host
  } = props;
  const customStyle = { height: 'calc(100vh - 260px)' };

  const mockData = [...Array(15).keys()];

  return (
    <Wrapper>
      <Tools>
        <Title><FormattedMessage id="host.detail.general.title" values={ { ip: host.ip } } /></Title>
        <CustomReactTable
          data={ isFetching ? mockData : data }
          columns={ getColumns(fields, isFetching) }
          minRows={ 0 }
          showPagination={ false }
          noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
          style={ customStyle }
        />
      </Tools>
      <Detail>
        <DetailTitle>
          Operating System
          <DetailContent>{ host.os }</DetailContent>
        </DetailTitle>
        <DetailTitle>
          Hostnames
          <DetailContent>{ host.hostnames.map((value) => <p>{ value }</p>) }</DetailContent>
        </DetailTitle>
        <DetailTitle>
          MAC Address
          <DetailContent>{ host.mac }</DetailContent>
        </DetailTitle>
        <DetailTitle>
          Description
          <DetailContent>{ host.description }</DetailContent>
        </DetailTitle>
      </Detail>
    </Wrapper>
  );
};
WrapperTable.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  fields: PropTypes.instanceOf(Object).isRequired,
  host: PropTypes.instanceOf(Object).isRequired
};

const General = ({ data }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTools(data.id));
  }, [data.id, dispatch]);

  return <WrapperTable />;
};

export default General;
