import { ReactComponent as _DeleteEvidenceIcon } from 'Images/icon-trash-red.svg';
import { ReactComponent as ClipboardIcon } from 'Images/copy.svg';
import { ReactComponent as _ImagePlaceholder } from 'Images/unknown_filetype.svg';
import { ReactComponent as _DownloadIcon } from 'Images/download-icon.svg';

import styled from 'styled-components';

export const EvidenceImg = styled.img`
  object-fit: scale-down;
`;

export const ImagePlaceholder = styled(_ImagePlaceholder)`
  margin: 16px 28px;
`;

export const Options = styled.div`
  background-color: #ffffff;
  position: absolute;
  right: 7px;
  bottom: 7px;
  padding: 3px;
  border-radius: 4px;
  border: solid 1px #d9e4ef;
  display: none;

  & svg {
    margin: 0 5px;
  }
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 165px;
  width: 140px;
  margin: 10px 22px 0px 22px;
`;

export const ImageWrapper = styled.div`
  height: 140px;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  padding: 7px;
  cursor: pointer;
  position: relative;

  &:hover{
    background-color: #eeeeee;

    & ${Options}{
      display:block;
    }
  }
`;

export const FileNameWrapper = styled.div`
  flex: 0 0 20px;
  user-select: none;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
`;

export const FileName = styled.div`
  flex: 0 1 auto;
  font-size: 13px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FileExtension = styled(FileName)`
  flex: 0 0 auto;
`;

export const DeleteEvidenceIcon = styled(_DeleteEvidenceIcon)`
& path{
  fill: #000000;
  stroke: #90a9c0;
}
`;

export const Clipboard = styled(ClipboardIcon)``;

export const DownloadIcon = styled(_DownloadIcon)`
  fill: #90A9C0;
`;
