import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { setFilter } from 'store/Filters/actions';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { redirect } from 'store/Router/actions';
import { RowName } from '../Vulnerabilities/styled';

const Name = ({ vuln }) => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  const onClick = () => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilityById(vuln._id)));
    dispatch(redirect(`/manage/${workspaceSelected}/${vuln._id}`));
  };

  return <RowName onClick={ onClick } >{vuln.name}</RowName>;
};

export default Name;
