/* eslint-disable no-useless-constructor */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dropdown from 'Common/Components/Dropdown';
import { severityDropdownStyle } from 'Common/styles/style';
import { severities } from 'Screens/Constants';
import SeverityIcon from '../SeverityIcon';
import { Wrapper, RedAster, SeverityName } from './styled';

const SeverityItem = ({ title }) => (
  <div className="d-inline-flex item-option">
    <SeverityIcon type={ title.charAt(0).toUpperCase() }>{title.charAt(0).toUpperCase()}</SeverityIcon>
    <SeverityName>{title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()}</SeverityName>
  </div>
);

SeverityItem.propTypes = {
  title: PropTypes.string.isRequired
};

class SeverityDropdown extends React.Component {
  constructor (props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange (value) {
    const { updateValue } = this.props;
    updateValue('severity', value);
  }

  render () {
    const { defaultValue } = this.props;
    const options = severities.map((element) => ({ label: <SeverityItem title={ element.desc } />, value: element.name }));
    return (
      <Wrapper>
        <Dropdown customStyle={ severityDropdownStyle } options={ options } updateValue={ this.onChange } placeholder="Severity" className="severity" defaultValue={ defaultValue } />
        <RedAster>*</RedAster>
      </Wrapper>
    );
  }
}

SeverityDropdown.propTypes = {
  updateValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string

};

SeverityDropdown.defaultProps = {
  defaultValue: undefined
};

export default withRouter(SeverityDropdown);
