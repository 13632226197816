import React from 'react';
import { Wrapper, SeverityName } from './styled';
import ExpandableWrapper from '../ExpandableWrapper';

const Severity = ({
  value, title, id, onChange, selected, expanded
}) => (
  <ExpandableWrapper id={ id } title={ title } onChange={ onChange } selected={ selected } expanded={ expanded }>
    <Wrapper>
      <SeverityName type={ value }>{value}</SeverityName>
    </Wrapper>
  </ExpandableWrapper>
);

export default Severity;
