import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateHost } from 'store/Host/actions';
import { Wrapper, HostIp, Edit } from './styled';
import Importance from 'Common/Components/Importance';
import { selectHostDetail } from 'store/HostDetail/selectors';
import { showEditModal } from 'store/HostEditCreate/actions';

const Ip = () => {
  const dispatch = useDispatch();
  const host = useSelector(selectHostDetail);

  const callback = (importance) => dispatch(updateHost(host._id, { importance }));

  return (
    <Wrapper>
      <Importance importance={ host.importance } callback={ callback } />
      <HostIp>{host.ip}</HostIp>
      <Edit onClick={ () => dispatch(showEditModal()) } />
    </Wrapper>
  );
};

export default Ip;
