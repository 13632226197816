import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotFound from './NotFound';

const mapStateToProps = (state) => ({
  loggedIn: state.sesion.loggedIn
});

const mapDispatchToProps = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotFound));
