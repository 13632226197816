import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperField = styled.div`
  padding-bottom: 10px;
`;
WrapperField.displayName = 'WrapperField';

export const Title = styled.div`
  user-select: none;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
  margin-bottom: 14px;
  &:after{
    content: "*";
    color: red;
    margin-left: 5px;
  }
`;
Title.displayName = 'Title';

export const Label = styled.div`
  user-select: none;
  margin-bottom: 20px;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
`;
Label.displayName = 'Label';
