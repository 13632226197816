import axios from 'axios';
import get from 'lodash/get';
import head from 'lodash/head';
import validateWorkspace from 'utils/validateWorkspace';
import { failureSessionValidationCallback } from 'Screens/Login/actions/Actions';
import { redirect } from 'store/Router/actions';

export const CURRENT_API_VERSION = 'v3';
export const path = (version, url) => `/_api/${version}${url}`;

export const getRequestUrl = (url) => {
  const baseUrl = get(window, 'location.origin', '');
  const version = CURRENT_API_VERSION === 'v1' ? '' : `${CURRENT_API_VERSION}/`;
  return `${baseUrl}/_api/${version}${url}`;
};

function processResponse (response) {
  if (response.status > 299) throw (response);
  return response.data;
}

const parseErrorMessage = (htmlResponse) => head(htmlResponse.match(/<p>(.*?)<\/p>/g).map((val) => val.replace(/<\/?p>/g, ''))) || '';

const fetchApi = () => async (url, config = {}, cancelToken, redirectTo = true, base = false) => {
  const dispatch = get(window, 'store.dispatch', null);
  const {
    headers,
    method = 'GET',
    data,
    version,
    ...rest
  } = config;

  const options = {
    withCredentials: true,
    method,
    headers,
    data,
    ...rest
  };

  if (cancelToken) {
    options.cancelToken = cancelToken;
  }

  try {
    if (base) {
      return processResponse(await axios(url, { ...options, withCredentials: false }));
    }
    let v = `${CURRENT_API_VERSION}/`;
    if (version) v = version === 'v1' ? '' : `${version}/`;
    return processResponse(await axios(path(v, url), options));
  } catch (e) {
    if (axios.isCancel(e)) return { status: 'canceled' };
    else {
      const status = get(e, 'response.status');
      // Logout functionality disabled disabled by default (redirectTo). Use actions to show the ErrorDialog from App.jsx  instead
      if ((status === 401) && !window.location.pathname.includes('/login') && redirectTo) {
        if (dispatch) dispatch(failureSessionValidationCallback());
        dispatch(redirect('/login'));
      }
      const data = get(e, 'response.data', null);
      if (data && typeof data === 'string') {
        const error = { message: parseErrorMessage(data), status };
        if (dispatch) dispatch(validateWorkspace(error));
        throw (error) || e;
      } else {
        throw ({ ...data, status }) || e;
      }
    }
  }
};

export default fetchApi();
