import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.06);
  border: solid 1px ${colors.grey15};
  background-color: ${colors.white};
  max-width: 100%;
  width: 50%;
  margin: 14px 25%;
`;
Wrapper.displayName = 'Wrapper';

export const Token = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  color: ${colors.warmPurple};
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
Token.displayName = 'Token';

export const WrapperCountDown = styled.div`
  padding: 10px;
  svg {
    display: block;
  }

  #border {
    fill: ${colors.warmPurple};
  }
`;
WrapperCountDown.displayName = 'WrapperCountDown';
