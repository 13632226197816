import breakpoints from 'utils/breakpoints/rules';
import { add, remove } from 'store/Reducers/breakpoints/actions';

let mediaQueries = null;

const check = (dispatch, name, query) => {
  if (query.matches) {
    dispatch(add(name));
  } else {
    dispatch(remove(name));
  }
};

export const supported = () => !!window.matchMedia;

export const subscribe = ({ dispatch }) => {
  if (mediaQueries) {
    console.error('mediaListeners already subscribed to the store.'); // eslint-disable-line no-console
    return;
  }

  mediaQueries = {};

  Object.keys(breakpoints).forEach((name) => {
    const query = window.matchMedia(breakpoints[name]);
    const hook = (q) => check(dispatch, name, q);
    mediaQueries[name] = { query, hook };
    query.addListener(hook);
    hook(query); // Initial check
  });
};

export const unsubscribe = () => {
  if (!mediaQueries) return;

  Object.keys(mediaQueries).forEach((name) => {
    const { query, hook } = mediaQueries[name];
    query.removeListener(hook);
  });
};
