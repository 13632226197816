import api from 'services/api';

// **
// settings section
// **
export const SET_SETTINGS_SHOW_MODAL = 'SET_SETTINGS_SHOW_MODAL';

export const GET_SETTINGS_TEMPLATES_START = 'GET_SETTINGS_TEMPLATES_START';
export const GET_SETTINGS_TEMPLATES_SUCCESS = 'GET_SETTINGS_TEMPLATES_SUCCESS';
export const GET_SETTINGS_TEMPLATES_FAIL = 'GET_SETTINGS_TEMPLATES_FAIL';

// **
// settings section
// **

export function closeModal () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SHOW_MODAL, showModal: false });
  };
}

export function openModal () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SHOW_MODAL, showModal: true });
  };
}

export function getTemplates (tool) {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TEMPLATES_START });
    try {
      const response = await api.settings.fetchTemplates(tool);
      return dispatch({ type: GET_SETTINGS_TEMPLATES_SUCCESS, templates: response.templates });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TEMPLATES_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}
