import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FiTrash2 as Trash } from 'react-icons/fi';

// TODO: Refactor code
const DeleteButton = ({
  permissions, readonly, entity, selectedEntities, display, openConfirmationModal
}) => {
  const deleteDisabledReason = (!permissions || !permissions.delete.allowed) ? permissions.delete.reason : '';

  if (display) {
    return (
      <div>
        <Tooltip title={ readonly && entity !== 'workspaces' ? 'Workspace is read only.' : deleteDisabledReason }>
          <div className={ `trash-container-hover ${selectedEntities.length === 0 ? 'disabled' : ''}` }>
            <Trash
              color="#1c4566"
              style={ { fontSize: '20px' } }
              onClick={ () => { if ((!readonly || entity !== 'workspaces') && permissions && permissions.delete.allowed) openConfirmationModal(); } }
              className={ `icon-trash ${(readonly && entity !== 'workspaces') || (!permissions || !permissions.delete.allowed) || selectedEntities.length === 0 ? 'disabled' : ''}` }
              id="action-button-delete"
            />
          </div>
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default DeleteButton;
