import styled from 'styled-components';
import { Col } from 'Common/Components/Grid';

export const Wrapper = styled.div.attrs({
  id: 'feed_section_container'
})`
  padding-bottom: 22px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
Wrapper.displayName = 'Wrapper';

export const StyledCol = styled(Col)`
  & > *:not(:last-child) {
    margin-bottom: 14px;
  }
`;
StyledCol.displayName = 'StyledCol';
