import React from 'react';
import ReactDragListView from 'react-drag-listview/lib';
import checkbox from 'Images/Checkbox_ok.svg';
import drag from 'Images/drag.svg';
import get from 'lodash/get';
import matchField from '../../helper';
import { Icon } from '../../styled';
import {
  AddedColumn, ColumnsAddedList, Wrapper, FieldTitleLocked, FieldTitle
} from './styled';

const DragList = ({
  fields, setFieldVisibility, setNewOrderColumns
}) => {
  // TODO: cambiar props de context menu a draglist

  const handleHover = () => {
    const elements = document.querySelectorAll('[draggable=true]');

    if (elements.length > 0) {
      elements.forEach((e) => {
        e.removeAttribute('draggable');
      });
    }
  };

  const columnsNotIncluded = Object.keys(fields).filter((x) => fields[x].visible === true && get(fields, `${x}.locked`, false) && x !== 'selected');
  const columnsIncluded = Object.keys(fields).filter((x) => fields[x].visible === true && !get(fields, `${x}.locked`, false))
    .sort((a, b) => (fields[a].order < fields[b].order ? -1 : 1))
    .map((field) => (
      field
    ));

  const setNewOrderCol = (fromIndex, toIndex) => {
    const data = columnsIncluded;

    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    setNewOrderColumns(data);
  };

  return (
    <Wrapper>
      <ColumnsAddedList>
        {columnsNotIncluded.map((item) => (
          <AddedColumn key={ item }>
            <FieldTitleLocked>
              {matchField(item)}
            </FieldTitleLocked>
          </AddedColumn>
        ))}
      </ColumnsAddedList>
      <ReactDragListView nodeSelector="li" handleSelector="p" onDragEnd={ (fromIndex, toIndex) => { setNewOrderCol(fromIndex, toIndex); } } lineClassName="border-draggable">
        <ColumnsAddedList>
          {columnsIncluded.map((item) => (
            <AddedColumn key={ item } onMouseLeave={ handleHover }>
              <FieldTitle>
                <Icon src={ drag } onClick={ (e) => { e.stopPropagation(); setFieldVisibility(item, false); } } />
                {matchField(item)}
              </FieldTitle>
              <Icon src={ checkbox } onClick={ (e) => { e.stopPropagation(); setFieldVisibility(item, false); } } />
            </AddedColumn>
          ))}
        </ColumnsAddedList>
      </ReactDragListView>
    </Wrapper>
  );
};

export default DragList;
