import styled from 'styled-components';
import { slideIn } from 'Styles/effects';
import colors from 'Styles/colors';
import { Cancel } from 'Common/Components/Button/styled';
import BlueButton from 'Common/Components/BlueButton';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px;
  padding-top: 16px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border-top: 10px solid ${colors.softBlue};
  background: #fff;
  width: 505px;
  user-select: none;
  max-height: 90%;
  ${slideIn}
`;
ModalContent.displayName = 'ModalContent';

export const Title = styled.div`
  color: #1c2243;
  font-weight: 600;
  font-size: 21.5px;
  line-height: 1.49;
  margin-top: 18px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.h4`
  font-size: 13px;
  color: #63758d;
  text-align: left;
  margin: 14px 0;
`;
Subtitle.displayName = 'Subtitle';

export const ButtonsContainer = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 34px;
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export const ConfirmButton = styled(BlueButton)`
`;
ConfirmButton.displayName = 'ConfirmButton';

export const CancelButton = styled(Cancel)`
  margin-right: 10px;
`;
CancelButton.displayName = 'CancelButton';

export const Fields = styled.div`
  margin-top:20px;
  max-height: 539px;
  overflow: auto;
  & > * {
    margin-bottom: 21px;
  }
`;
Fields.displayName = 'Fields';
