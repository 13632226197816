import styled from 'styled-components';

export const SavedFiltersWrapper = styled.div.attrs(({ containerHeight }) => {
  let height = '';
  if (containerHeight) height = `${containerHeight}px`;
  return ({ style: { height } });
})`
  overflow: auto;
  margin-right: 3px;
`;
SavedFiltersWrapper.displayName = 'SavedFiltersWrapper';

export const CFListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 276px;
  margin-bottom: 14px;
  > :nth-child(2){
    margin-top: 14px;
  }
`;

export const NoFilterLabel = styled.div`
  cursor: default;
  font-size: 13px;
  color: #63758d;
  text-align: center;
`;

export const Heading = styled.h5`
  font-size: 12px;
  font-weight: 500;
  color: #90a9c0;
  text-align: left;
  margin-bottom: 7px;
  margin-left: 15px;
`;
