import React from 'react';
import { Button, Text } from './styled';

const CustomButton = ({ onClick, icon, text }) => (
  <Button onClick={ onClick }>
    { icon }
    { text && <Text>{ text }</Text> }
  </Button>
);
export default CustomButton;
