import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Md from 'Common/Components/Md';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Manage/components/CustomAttributesModal/constants';

import Wrapper from './styled';

const MdCustomField = ({
  field, vuln, onCancel, onSave, initialValue
}) => {
  const [value, setValue] = useState(initialValue);

  const handleSave = (finalValue) => onSave(vuln, field.field_name, finalValue);

  const handleCancel = () => {
    if (initialValue) {
      setValue(initialValue);
    } else {
      onCancel();
    }
  };

  return (
    <Wrapper>
      <Md hidePlaceholder saveAction={ handleSave } value={ value } width="100%" title="" onCancel={ handleCancel } />
    </Wrapper>
  );
};

MdCustomField.defaultProps = {
  initialValue: ''
};

MdCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.MARKDOWN])
  }).isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.string
};

export default MdCustomField;
