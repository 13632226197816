import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlueButton from 'Common/Components/BlueButton';
import { getCustomFilters, createCustomFilter } from 'store/Filters/actions';
import {
  getSelectedEntity, selectIsFiltering, selectAdvancedFilter, selectSaveEnabled
} from 'store/Filters/selectors';
import {
  ButtonContainer, ButtonLabel, ModalContainer, RedAster,
  FilterLabelContainer, FilterLabel, NameInput, Wrapper, Backdrop
} from './styled';

const SaveFilter = () => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const [invertedDisplayPos, setInvertDisplayPost] = useState(false);

  const selectedEntity = useSelector(getSelectedEntity);
  const isFiltering = useSelector((state) => selectIsFiltering(selectedEntity, state));
  const isFilteringByAdvanced = useSelector((state) => selectAdvancedFilter(state, selectedEntity));
  const dispatch = useDispatch();
  const enabled = useSelector(selectSaveEnabled);
  const disableSave = !enabled || (!isFiltering && !isFilteringByAdvanced);

  const onBackdropClickHandler = () => {
    setShowModal(false);
    setInputFocus(false);
    setInputValue('');
  };

  const saveFilter = () => {
    dispatch(createCustomFilter(selectedEntity, inputValue));
    dispatch(getCustomFilters());
    onBackdropClickHandler();
  };

  const onKeyUp = ({ key }) => {
    if (key === 'Enter') {
      saveFilter();
    }
  };

  const measuredRef = (ref) => {
    if (ref !== null) {
      const { width, x } = ref.getBoundingClientRect();
      const viewWidth = document.body.clientWidth;
      const spaceLeftToPlace = invertedDisplayPos ? (viewWidth - x - width) : (viewWidth - x);
      if (spaceLeftToPlace < width) setInvertDisplayPost(true);
      else setInvertDisplayPost(false);
    }
  };

  return (
    <Wrapper>
      <ButtonContainer disabled={ disableSave } onClick={ disableSave ? null : () => setShowModal(!showModal) }>
        <ButtonLabel disabled={ disableSave }>
          Save Filter
        </ButtonLabel>
      </ButtonContainer>
      { showModal && (
        <>
          <Backdrop onClick={ onBackdropClickHandler } />
          <ModalContainer ref={ measuredRef } flipPos={ invertedDisplayPos }>
            <FilterLabelContainer>
              <FilterLabel>
                Filter Name
                <RedAster> *</RedAster>
              </FilterLabel>
            </FilterLabelContainer>
            <NameInput
              isSelected={ inputFocus }
              onBlur={ () => setInputFocus(false) }
              onFocus={ () => setInputFocus(true) }
              placeholder="My New Filter"
              value={ inputValue }
              onChange={ (e) => setInputValue(e.target.value) }
              onKeyUp={ onKeyUp }
            />
            <BlueButton label="Save" disabled={ !inputValue } onClick={ saveFilter } />
          </ModalContainer>
        </>
      )}
    </Wrapper>
  );
};

export default SaveFilter;
