import styled from 'styled-components';

const ButtonsContainer = styled.div`
  flex: 0 0 34px;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  & :not(:last-child) {
    margin-right: 10px;
  }
`;
ButtonsContainer.displayName = 'ButtonsContainer';

export default ButtonsContainer;
