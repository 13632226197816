/* eslint-disable no-negated-condition */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import head from 'lodash/head';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import {
  selectToolsSelected, selectAgentTypes, selectDraftNewAgentTools
} from 'store/Agents/selectors';
import Modal from 'Common/Components/Modal';
import useModal from 'Hooks/useModal';
import Header from '../Header';
import ToolCollection from './components/ToolCollection';
import {
  Wrapper, Title, TextItem, Content, Add, ContentWrapper
} from '../styled';

import AddTools from './styled';
import Toolbar from './components/Toolbar/styled';
import EditButton from './components/EditButton';
import ConfigureButton from './components/ConfigureButton';
import DeleteButton from './components/DeleteButton';
import Card from './components/Card';
import { getManifest } from 'store/Agents/actions';

const Tools = ({ nextStep, prevStep, onClose }) => {
  const [ModalBrowseTools, showModalBrowseTools, toggleModalBrowseTools] = useModal(Modal);
  const tools = useSelector(selectToolsSelected);
  const dispatch = useDispatch();
  const allTools = useSelector(selectAgentTypes);
  const draftNewAgentTools = useSelector(selectDraftNewAgentTools);

  useEffect(() => {
    dispatch(getManifest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toolHasBeenConfigured = (tool) => {
    const currentTool = pickBy(allTools, { name: tool });
    const enviromentVariables = get(currentTool, `${tool}.environment_variables`, []);
    const toolSelected = head(filter(draftNewAgentTools, { name: tool }));
    const isConfigured = [];
    if (toolSelected && !isEmpty(toolSelected.variables)) {
      enviromentVariables.forEach((item) => {
        const envVarConfigured = Object.hasOwn(toolSelected.variables, item) || !isEmpty(toolSelected.variables[item]);
        isConfigured.push(envVarConfigured);
      });
    }
    return !isEmpty(isConfigured) ? !isConfigured.includes(false) : false;
  };

  const checkForConfiguredTools = () => {
    const result = [];
    if (Object.keys(tools).length > 0) {
      Object.entries(tools).map((tool) => {
        if (tool[1].environment_variables.length) {
          result.push(toolHasBeenConfigured(tool[1].name));
        } else {
          result.push(true);
        }

        return result;
      });
    }
    return !isEmpty(result) ? !result.includes(false) : false;
  };

  const toolsConfigured = checkForConfiguredTools();

  return (
    <Wrapper>
      <Header back={ prevStep } cancel={ onClose } next={ nextStep } nextDisable={ !toolsConfigured || (Object.keys(tools).length === 0) } />
      <ContentWrapper>
        <Title>Selected tools</Title>
        <TextItem>The following tools will process the output data</TextItem>
        <AddTools id="modal-agents-add-tools" onClick={ () => toggleModalBrowseTools(true) }>
          <Add />
          Add tools from our collection
        </AddTools>
        { Object.keys(tools).length > 0 && (
        <Content>
          {
        Object.entries(tools).map((tool) => {
          const toolbar = (
            <Toolbar>
              <EditButton tool={ tool[1] } />
              <ConfigureButton tool={ tool[1] } />
              <DeleteButton tool={ tool[1].name } />
            </Toolbar>
          );
          return <Card key={ tool[1].title } title={ tool[1].title } description={ tool[1].description } image={ tool[1].name } toolbar={ toolbar } />;
        })
      }
        </Content>
        )}
        {showModalBrowseTools && <ModalBrowseTools><ToolCollection onClose={ () => toggleModalBrowseTools(false) } /></ModalBrowseTools>}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Tools;
