import React, { useState } from 'react';
import EmptyVulns from 'Common/Components/EmptyVulns';
import empty from 'Images/empty-vulns.png';
import ImportFileCreationModal from 'Screens/ManageEditCreate/components/ImportFileCreationModal';
import ManualCreationModal from 'Screens/ManageEditCreate/components/ManualCreationModal';
import EmptyWrapper from './styled';

const EmptyState = () => {
  const [showModalUploadVulns, setShowModalUploadVulns] = useState(false);
  const [showModalCreateVuln, setShowModalCreateVuln] = useState(false);

  return (
    <EmptyWrapper>
      <EmptyVulns image={ empty } toggleModalUploadVulns={ () => setShowModalUploadVulns(true) } toggleModalCreateVuln={ () => setShowModalCreateVuln(true) } />
      <ImportFileCreationModal show={ showModalUploadVulns } handleClose={ () => setShowModalUploadVulns(false) } />
      <ManualCreationModal show={ showModalCreateVuln } handleClose={ () => setShowModalCreateVuln(false) } />
    </EmptyWrapper>
  );
};

export default EmptyState;
