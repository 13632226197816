import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { disableTextSelection } from 'Styles/effects/text';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding: 15px 20px 25px 20px;
  background-color: ${colors.white};
  text-align: left;
  ${disableTextSelection}
  border-radius: 3px;
  box-shadow: 0 2px 15px 0 #e3e3e3;
  height: 115px;
  &:hover{
    & > :last-child{
      display: block;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const TextWrapper = styled.div`
  flex: 1 1 0px;
  overflow: hidden;
  margin-left: 20px;
`;
TextWrapper.displayName = 'TextWrapper';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: ${colors.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
Title.displayName = 'Title';

export const Status = styled.div`
  flex: 0 0 69px;
  margin-left: 25px;
  margin-right: 20px;
  border-radius: 10px;
  font-size: 12.5px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  height: 25px;
  color: ${colors.white};
  ${(props) => (props.value === 'offline'
    ? css`background: ${colors.blueGrey};`
    : css`background: ${colors.blueCerulean};`)
}
`;
Status.displayName = 'Status';
