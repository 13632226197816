import Importance from 'Common/Components/Importance';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateHost } from 'store/Host/actions';
import { Wrapper } from './styled';

const ImportanceColumn = ({ host }) => {
  const dispatch = useDispatch();
  const callback = (importance) => dispatch(updateHost(host._id, { importance }));

  return (
    <Wrapper>
      <Importance importance={ host.importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;
