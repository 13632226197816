import styled, { createGlobalStyle } from 'styled-components';
import colors from 'Styles/colors';
import MarkdownStyles from 'react-markdown-editor-lite/lib/index.css';
import BlueButton from 'Common/Components/BlueButton/index';
import OutlinedButton from 'Common/Components/OutlinedButton/index';
import ReactMarkdown from 'react-markdown';

export const StyledReactMarkdown = styled(ReactMarkdown)`
    padding-left: 5px;
    h1 {
        font-size: 21px;
        font-weight: bold;
    }
    
    h2 {
        font-size: 17px;
        font-weight: bold;
    }
    
    h3 {
        font-size: 15px;
        font-weight: bold;
    }
    
    h4 {
        font-size: 14px;
        font-weight: bold;
    }
    
    h5 {
        font-size: 13px;
        font-weight: bold;
    } 
`;
StyledReactMarkdown.displayName = 'StyledReactMarkdown';

export const GlobalStyle = createGlobalStyle`
  ${MarkdownStyles}
`;
GlobalStyle.displayName = 'MarkdownStyles';

export const Wrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}` : '405px')};
    height: ${(props) => (props.height ? `${props.height}` : '232px')};
    margin-bottom: 26px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.08;
    letter-spacing: normal;
    color: #0b2128;
    margin-bottom: 10px;
    margin-left: 15px;
    font-weight: 600;
`;
Title.displayName = 'Title';

export const PreviewWrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}` : '405px')};
    height: ${(props) => (props.height ? `${props.height}` : '232px')};
    border-radius: 3px;
    box-shadow: 0 1px 0 0 #afafaf;
    padding: 10px 15px 15px 15px;
    overflow-y: auto;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-style: solid;
    border-color: transparent;
    border-width: 1px 1px 0px 1px;

    &:hover {
      border-color: #afb1bc;
    }

    p {
      margin: 0px;
    }
`;

PreviewWrapper.displayName = 'PreviewWrapper';

export const DataContainer = styled.div`
    
`;

DataContainer.displayName = 'DataContainer';

export const EditorContainer = styled.div`
   
`;

EditorContainer.displayName = 'EditorContainer';

export const ButtonsWrapper = styled.div`
    display: inline-flex;
    margin-top: 3px;
    position: relative;
    float: right;
    bottom: 33px;
    margin-right: 10px;
    z-index: 2;
    .cancel-button {
        width: 60px;
        height: 22px;
        font-size: 0.6em !important;
    }

    .save-button {
        width: 40px;
        height: 22px;
        font-size: 0.6em !important;
    }

`;

ButtonsWrapper.displayName = 'ButtonsWrapper';

export const EmptyWrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}` : '405px')};
    height: ${(props) => (props.height ? `${props.height}` : '232px')};
    border-radius: 3px;
    box-shadow: 0 1px 0 0 #afafaf;
    background-color: ${colors.paleGrey};
    padding-left: 17px;
    font-size: 13.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: 0.1px;
    color: #afb1bc;
    padding-top: 8px;
    cursor: pointer;
    border: 1px solid transparent;

    p{
     cursor: pointer;
    }

    &:hover {
      border-style: solid;
      border-color: #afb1bc;
      border-width: 1px 1px 0px 1px;
    }
`;

EmptyWrapper.displayName = 'EmptyWrapper';

export const RedAster = styled.span`
    color: #ea3158;    
`;
RedAster.displayName = 'RedAster';

export const SaveButton = styled(BlueButton)`
  width: 50px;
  min-width: 50px;
  height: 22px;
  font-size: 10px;
  padding: 0;
`;
SaveButton.displayName = 'Save';

export const CancelButton = styled(OutlinedButton)`
  width: 63px;
  min-width: 63px;
  margin-right: 8px;
  height: 22px;
  font-size: 10px;
  padding: 0;
`;
CancelButton.displayName = 'CancelButton';
