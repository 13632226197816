/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImportFileModal from 'Common/Components/ImportFileModal';
import {
  uploadFile, setFileIndex, setUploadState, setFilesCount, resetFileUploadState
} from 'Screens/KnowledgeBase/actions/Actions';

class ImportFileModalContainer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const {
      title, subtitle, show, handleClose, errorFilesList, onUpload, onSetFileIndex,
      onSetUploadState, onSetFilesCount, files, uploadState, fileIndex,
      currentUploadState, onResetFileUploadState, currentUploadPercent, currentUploadError
    } = this.props;

    return (
      <ImportFileModal
        show={ show }
        transactional
        handleClose={ handleClose }
        title={ title }
        errorFilesList={ errorFilesList }
        uploadFile={ onUpload }
        subtitle={ subtitle }
        setFileIndex={ onSetFileIndex }
        setUploadState={ onSetUploadState }
        setFilesCount={ onSetFilesCount }
        resetFileUploadState={ onResetFileUploadState }
        files={ files }
        uploadState={ uploadState }
        fileIndex={ fileIndex }
        currentUploadState={ currentUploadState }
        currentUploadPercent={ currentUploadPercent }
        currentUploadError={ currentUploadError }
      />
    );
  }
}

ImportFileModalContainer.defaultProps = {
  subtitle: '',
  onSetFileIndex: () => {},
  onSetUploadState: () => {},
  onSetFilesCount: () => {},
  onResetFileUploadState: () => {},
  uploadState: '',
  currentUploadState: '',
  currentUploadPercent: 0,
  currentUploadError: '',
  onUpload: () => {}
};

ImportFileModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  onSetFileIndex: PropTypes.func,
  onSetUploadState: PropTypes.func,
  onSetFilesCount: PropTypes.func,
  onResetFileUploadState: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  uploadState: PropTypes.string,
  fileIndex: PropTypes.number.isRequired,
  currentUploadState: PropTypes.string,
  currentUploadPercent: PropTypes.number,
  currentUploadError: PropTypes.string,
  onUpload: PropTypes.func,
  errorFilesList: PropTypes.array.isRequired,
  title: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  onUpload: (files) => {
    dispatch(uploadFile(files));
  },
  onSetFileIndex: (index) => {
    dispatch(setFileIndex(index));
  },
  onSetUploadState: (state) => {
    dispatch(setUploadState(state));
  },
  onSetFilesCount: (files) => {
    dispatch(setFilesCount(files));
  },
  onResetFileUploadState: () => {
    dispatch(resetFileUploadState());
  }
});

const mapStateToProps = (state) => ({
  files: state.knowledgeBase.filesToUpload,
  uploadState: state.knowledgeBase.uploadState,
  fileIndex: state.knowledgeBase.fileIndex,
  errorFilesList: state.knowledgeBase.errorFilesList,
  currentUploadState: state.knowledgeBase.currentUploadState,
  currentUploadPercent: state.knowledgeBase.currentUploadPercent,
  currentUploadError: state.knowledgeBase.currentUploadError
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportFileModalContainer));
