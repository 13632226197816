import styled from 'styled-components';
import colors from 'Styles/colors';

const Icon = styled.div`
  user-select: none;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.background};
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  color: ${colors.white};
  text-transform: uppercase;
  flex-shrink: 0;
`;
Icon.displayName = 'Icon';

export default Icon;
