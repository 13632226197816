import React from 'react';
import FilesList from 'Common/Components/FilesList';
import { useIntl } from 'react-intl';
import { FaPlus as Plus } from 'react-icons/fa';
import { Wrapper, AddMore, Separator } from './styled';
import { useDispatch } from 'react-redux';
import { setFileIndex } from 'Screens/ManageEditCreate/actions/Actions';
import AdvancedOptions from '../AdvancedOptions';

const ListStep = ({
  setStep,
  handleDrop,
  files, setFiles
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleNewFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const removeFileFromList = (file) => {
    const fileList = files.filter((f) => f.name !== file.name || f.size !== file.size);
    setFiles(fileList);
    dispatch(setFileIndex(fileList.length - 1));
    if (fileList.length === 0) setStep('DRAG');
  };

  return (
    <Wrapper>
      <FilesList files={ files } removeFile={ removeFileFromList } handleDrop={ handleDrop } />
      <AddMore>
        <Plus />
        <label htmlFor="upload">{intl.formatMessage({ id: 'importFileCreationModal.label' })}</label>
        <input multiple style={ { opacity: 0, position: 'absolute', zIndex: -1 } } id="upload" type="file" onChange={ (e) => handleNewFile(e.target.files) } />
      </AddMore>
      <Separator />
      <AdvancedOptions />
    </Wrapper>
  );
};

export default ListStep;
