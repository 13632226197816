import React from 'react';
import Icon from './styled';

const FlagIcon = ({ theme, onClick, title }) => (
  <div title={ title }>
    <Icon theme={ theme } onClick={ onClick }>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          id="flag-border"
          d="M14 6l-.72-1.45c-.17-.34-.52-.55-.9-.55H6c-.55 0-1 .45-1 1v15c0 .55.45 1 1 1s1-.45 1-1v-6h5l.72 1.45a1 1 0 00.89.55H19c.55 0 1-.45 1-1V7c0-.55-.45-1-1-1h-5zm4 8h-4l-1-2H7V6h5l1 2h5v6z"
          fillRule="nonzero"
        />
        <path id="flag-body" d="M18 14h-4l-1-2H7V6h5l1 2h5z" />
      </g>
    </Icon>
  </div>
);

export default FlagIcon;
