import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.div`
  flex: 0 1 auto;
  font-size: 13.5px;
  color: #53686f;
  font-weight: normal;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
Text.displayName = 'Text';
