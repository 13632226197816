import React, { useRef, useEffect } from 'react';
import Input from './styled';

const DateInput = ({ onValueSelect }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) ref.current.showPicker();
  }, []);

  return <Input ref={ ref } type='date' onChange={ (e) => onValueSelect(e.target.value) } />;
};

export default DateInput;
