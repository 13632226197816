import colors from 'Styles/colors';
import styled from 'styled-components';
import { Col, Row } from 'Common/Components/Grid';
import { ReactComponent as Agents } from 'Images/agent_small.svg';
import { ReactComponent as Reports } from 'Images/report_small.svg';
import { ReactComponent as Shell } from 'Images/ico-web-shell.svg';

export const Wrapper = styled.div`
  height: ${(props) => (props.height ? `${props.height}` : 'auto')};
  padding: 0 0 22px 0;
  text-align: left;
  width: ${(props) => (props.width ? `${props.width}` : 'auto')};
`;
Wrapper.displayName = 'Wrapper';

export const Vulnerabilities = styled.div`
  color: ${colors.dark3};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.93;
  letter-spacing: normal;
`;
Vulnerabilities.displayName = 'Vulnerabilities';

export const HostsAndServices = styled(Vulnerabilities)`
  font-weight: 400;
  margin-left: 3px;
`;
HostsAndServices.displayName = 'HostsAndServices';

// time
export const Time = styled.div`
  color: ${colors.blueGrey};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.92;
`;
Time.displayName = 'Time';

// author
export const Author = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & >:nth-child(2) {
    margin: 0 4px;
  }
`;
Author.displayName = 'Author';

export const Dark = styled.div`
  color: ${colors.dark4};
`;
Dark.displayName = 'Dark';

export const Light = styled.div`
  color: ${colors.grey5};
`;
Light.displayName = 'Light';

export const Tag = styled.div`
  background-color: ${colors.grey8};
  border-radius: 3px;
  color: ${colors.white};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.92;
  margin-left: auto;
  padding: 0 8px;
  text-align: center;
  display: flex;
  align-items: center;
  height: 24px;
`;
Tag.displayName = 'Tag';

export const Tool = styled.span`
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
Tool.displayName = 'Tool';

export const Arrow = styled.div`
  border-color: ${colors.grey5};
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
`;
Arrow.displayName = 'Arrow';

export const ArrowDown = styled(Arrow)`
  -webkit-transform: rotate(45deg);
  margin: 0px 0px 0 48px;
  transform: rotate(45deg);
`;
ArrowDown.displayName = 'ArrowDown';

export const StyledCol = styled(Col)`
  height: 60vw;
  overflow: auto;
  line-height: 2.2;
  padding-right: 7px;
  overflow-y: scroll;
`;
StyledCol.displayName = 'StyledCol';

export const In = styled.span`
  font-weight: normal;
`;
In.displayName = 'In';

export const Empty = styled.div`
  color: ${colors.grey5};
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
  padding: 15px 2px;
  display: flex;
  justify-content: center;
`;
Empty.displayName = 'Empty';

export const WrapperActivity = styled(Row)`
  cursor:pointer;
  margin: 9px 0px 17px;
  flex-direction: column;
  background-color: ${colors.white};
  border: 1px solid #e4e9ee;
  border-left: 2px solid ${colors.blueCerulean};
  border-radius: 2px;
  padding: 18px 23px 16px 21px;
  &:hover {
    opacity: 0.8;
  }
`;
WrapperActivity.displayName = 'WrapperActivity';

export const ActivityDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
ActivityDescription.displayName = 'ActivityDescription';

export const ActivityFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;
ActivityFooter.displayName = 'ActivityFooter';

export const AgentIcon = styled(Agents)`
  margin-right: 4px;
`;
AgentIcon.displayName = 'AgentIcon';

export const ReportIcon = styled(Reports)`
  margin-right: 4px;
`;
ReportIcon.displayName = 'ReportIcon';

export const ShellIcon = styled(Shell)`
  margin-right: 4px;
  width: 17px;
`;
ShellIcon.displayName = 'ShellIcon';

export const Center = styled.div`
  width: 100%;
  text-align: center;
`;
Center.displayName = 'Center';
