import React from 'react';
import Background from './styled';

const ModalWrapper = ({ children, className }) => (
  <Background className={ className }>
    {children}
  </Background>
);

export default ModalWrapper;
