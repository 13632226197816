import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const InfoText = styled.div`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: left;
    color: #7a7e8f;
    margin: 34px 0;
`;
InfoText.displayName = 'InfoText';

export const OptionsWrapper = styled.div`
    text-align: left;
`;
OptionsWrapper.displayName = 'OptionsWrapper';

export const Option = styled.div`
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    text-align: left;
    color: #1c2243;
    display: inline-flex;
    width: 340px;
    height: 34px;
    border-radius: 3px;
    padding-left: 11px;
    cursor: pointer;
    padding-top: 10px;
    color: #1c2243;
    margin-bottom: 5px;

    .arrow {
        display: none;
    }

    svg{
        color: #7a7e8f;
    }

    &:hover{
        color: ${colors.grey19} !important;
        background-color: #e6eff6;
        svg {
            color: ${colors.grey19} !important;   
        }

        .arrow {
            display: block
        }
    }
`;
Option.displayName = 'Option';

export const OptionText = styled.div`
    font-size: 14.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    text-align: left;
`;
OptionText.displayName = 'OptionText';

export const OptionIcon = styled.span`
    margin-right: 8px;
    svg{
        font-size: 1.5em;
        bottom: 4px;
        position: relative;
        height: 20px;
        width: 20px;
    }
`;
OptionIcon.displayName = 'OptionIcon';

export const HoverIcon = styled.span`
    margin-left: auto;
    margin-right: 11px; 
`;
HoverIcon.displayName = 'HoverIcon';

export const SubTitleWs = styled.div`
    max-width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #7a7e8f;
    margin-top: 14px;
`;
SubTitleWs.displayName = 'SubTitleWs';

export const OptionTextWs = styled.div`
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    text-align: left;
`;
OptionTextWs.displayName = 'OptionTextWs';
