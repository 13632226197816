import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAgent } from 'store/Agents/actions';
import { selectDraftNewAgentTools, selectDraftNewAgentName } from 'store/Agents/selectors';
import Header from '../Header';
import {
  Wrapper, Title, TextItem, TextInput, Input, ContentWrapper
} from '../styled';

const Configuration = ({ nextStep, onClose }) => {
  const dispatch = useDispatch();
  const draftNewAgentTools = useSelector(selectDraftNewAgentTools);
  const draftNewAgentName = useSelector(selectDraftNewAgentName);
  const [agentName, setAgentName] = useState(draftNewAgentName);

  const onChangeAgentName = (e) => {
    setAgentName(e.target.value);
  };

  const onNext = () => {
    nextStep();
    const data = {
      name: agentName,
      server: window.location.origin,
      tools: draftNewAgentTools
    };
    dispatch(setAgent(data));
  };

  const nextEnable = agentName.length > 0;

  return (
    <Wrapper>
      <Header cancel={ onClose } next={ onNext } nextDisable={ !nextEnable } />
      <ContentWrapper>
        <Title>Configuration</Title>
        <TextItem>Generate a configuration file to be imported from the command line</TextItem>
        <TextInput>Name</TextInput>
        <Input placeholder="MyAgent" onChange={ onChangeAgentName } value={ agentName } id="modal-agents-name" />
      </ContentWrapper>
    </Wrapper>
  );
};

export default Configuration;
