import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  HIDE_ERROR_MESSAGE_LOGIN,
  LOGOUT_SUCCESS,
  LOGOUT_SUCCESS_REDIRECT,
  NOT_LOGGED,
  SET_FIRST_LOGIN,
  SET_REMEMBER_ME,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOGIN_FETCH_FINISHED,
  VALIDATING_SESSION_START,
  VALIDATING_SESSION_END
} from '../../Screens/Login/actions/Actions';

const initialState = {
  loggedIn: false,
  isFetching: false,
  error: false,
  errorMessage: '',
  user: '',
  firstLogin: false,
  rememberMe: false,
  emailSent: false,
  currentEmail: '',
  submitted: false,
  roles: []
};

function sesion (state = initialState, action) {
  switch (action.type) {
    case VALIDATING_SESSION_START:
      return { ...state, validatingSession: true };

    case VALIDATING_SESSION_END:
      return { ...state, validatingSession: false };

    case LOGIN_START:
      return { ...state, isFetching: true };

    case LOGIN_SUCCESS:
      return {
        ...state, loggedIn: true, user: action.user, roles: action.roles, csrfToken: action.csrfToken
      };

    case LOGIN_FETCH_FINISHED:
      return {
        ...state, isFetching: false
      };

    case LOGIN_FAIL:
      return {
        ...state,
        isFetching: false,
        loggedIn: false,
        error: true,
        errorMessage: action.data.message
      };

    case HIDE_ERROR_MESSAGE_LOGIN:
      return { ...state, error: false, errorMessage: '' };

    case LOGOUT_SUCCESS:
      return { ...state, loggedIn: false, rememberMe: false };

    case LOGOUT_SUCCESS_REDIRECT:
      return { ...state, loggedIn: false, rememberMe: false };

    case NOT_LOGGED:
      return { ...state, isFetching: false, loggedIn: false };

    case SET_FIRST_LOGIN: {
      return {
        ...state,
        firstLogin: action.firstLogin
      };
    }

    case SET_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: action.value
      };
    }

    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        emailSent: false,
        currentEmail: ''
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        emailSent: true,
        currentEmail: action.email
      };

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data.message,
        emailSent: false,
        currentEmail: ''
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
}

export default sesion;
