import colors from 'Styles/colors';
import styled from 'styled-components';
import { ReactComponent as FiltersOn } from 'Images/icon_filter_on.svg';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  informational: colors.grey2
};

export const Wrapper = styled.div`
  margin-bottom: 24px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  user-select: none;
  color: ${colors.white};
  font-size: 14px;
  font-weight: 600;
  padding: 11px 0 27px 0;
`;
Title.displayName = 'Title';

export const WrapperSeverity = styled.div`
  display: flex;
  width: 100%;
  & > *:not(:last-child) {
    margin-right: 1px;
  }
`;
WrapperSeverity.displayName = 'WrapperSeverity';

export const Severity = styled.div`
  background-color: ${colors.blue1};
  border-radius: 1px;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.2;
  padding: 10px;
  text-align: right;
  min-width: 40px;
  cursor: pointer;
  width: ${(props) => props.width * 100}%;

  user-select: none;
  border-bottom: solid 4px ${(props) => sevColor[props.type]};
  color: ${(props) => sevColor[props.type]};
  transition: width .5s;
`;
Severity.displayName = 'Severity';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';

export const Header = styled.div`
  display:flex;
  justify-content: space-between;
`;
Header.displayName = 'Header';

export const FilterIcon = styled(FiltersOn)`
  margin: 8px;
  width: 25px;
  & path {
    fill: #edf2f7;
  }
  & circle {
    fill: ${({ $active }) => $active ? 'red' : 'none'};
  }
`;
FilterIcon.displayName = 'FilterIcon';

export const FilterWrapper = styled.div`
  width: 215px;
  height: 34px;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 1.04;
  letter-spacing: 0.1px;
  color: #edf2f7;
  display: flex;
  background-color: #63758d;
  position:relative;
`;
FilterWrapper.displayName = 'FilterWrapper';

export const Dropdown = styled.div`
  border-left: solid 2px #264d6d;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
`;
Dropdown.displayName = 'Dropdown';

export const Selector = styled.div`
  width: 215px;
  height: 34px;
  display: flex;
  position: absolute;
  top: 34px;
  background-color: #63758d;
  border-top: solid 2px #264d6d;
  align-items: center;
  justify-content: space-around;
`;
Selector.displayName = 'Selector';

export const Option = styled.div`
  cursor: pointer;
  & svg{
    margin-right: 8px;
  }
`;
Option.displayName = 'Option';

export const ArrowDown = styled(ChevronDown)`
  width: 10px;
  color: ${colors.grey5};
  //margin-top: 4px;
`;
ChevronDown.displayName = 'ChevronDown';
