import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
