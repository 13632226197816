import React, { useState } from 'react';
import MainContainer from 'Common/Components/MainContainer';
import EmailField from 'Common/Components/EmailField';
import { forgotPassword } from 'Screens/Login/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'Common/Components/Loading/styled';
import { redirect } from 'store/Router/actions';
import {
  Title,
  SubTitle,
  ButtonsContainer,
  BtnResetLink,
  BtnEmailSent,
  BtnGoBack,
  ErrorMessage
} from './styled';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const props = useSelector((state) => ({
    error: state.sesion.error,
    errorMessage: state.sesion.errorMessage,
    isFetching: state.sesion.isFetching
  }));
  const dispatch = useDispatch();

  const isValidEmail = () => {
    // eslint-disable-next-line prefer-regex-literals
    const re = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    return re.test(email);
  };

  const onHandleAction = () => {
    if (isValidEmail()) setSubmitted(true);
    dispatch(forgotPassword(email));
  };

  return (
    <MainContainer height="440px">
      <Title>Forgot your password?</Title>
      <SubTitle>Enter your email address below and we&apos;ll send you a link to reset your password.</SubTitle>
      <EmailField disabled={ submitted && !props.error } placeholder="Type your email" onChange={ (v) => { setEmail(v); } } submit={ () => { onHandleAction(); } } defaultValue={ email } />
      <ButtonsContainer>
        { !props.isFetching && submitted && props.error && isValidEmail() ? <ErrorMessage>{ props.errorMessage }</ErrorMessage> : null }
        { submitted && !props.error ? <BtnEmailSent>Email Sent!</BtnEmailSent> : null }
        { !submitted || props.error ? <BtnResetLink disabled={ !isValidEmail() } onClick={ () => { onHandleAction(); } }>Send Reset Link</BtnResetLink> : null }
      </ButtonsContainer>
      { props.isFetching ? <Loading style={ { top: '50%' } } /> : null }
      <BtnGoBack onClick={ () => { dispatch(redirect('/login')); } }>{'<  Go Back'}</BtnGoBack>
    </MainContainer>
  );
};

export default ForgotPassword;
