import React from 'react';
import { Wrapper, FeatureImage, TextWrapper, Description, Link } from './styled';
import FF2FA from 'Images/ff_2fa.png';
import { FormattedMessage } from 'react-intl';
import { URL_PRICING } from 'Common/Components/UpgradeLicense/index';

const TwoFactor = () => {
  return (
    <Wrapper>
      <FeatureImage src={ FF2FA } />
      <TextWrapper>
        <Description children={ <FormattedMessage id={ 'upgradeLicense.sidebar.settings.2fa.description' } /> } />
        <Link onClick={ () => window.open(URL_PRICING) } children={ <FormattedMessage id={ 'upgradeLicense.sidebar.settings.2fa.link' } /> } />
      </TextWrapper>
    </Wrapper>
  );
};

export default TwoFactor;
