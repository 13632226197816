import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text } from './styled';

const IconButton = ({
  onClick, title, icon, text
}) => (
  <Wrapper title={ title } onClick={ onClick }>
    {icon}
    { text && <Text>{ text }</Text> }
  </Wrapper>
);

IconButton.defaultProps = {
  onClick: null
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired
};

export default IconButton;
