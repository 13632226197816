/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, TabList, TabListContent } from './styled';
import Tab from './Tab';

class Tabs extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.title
    };

    this.onClickTabItem = this.onClickTabItem.bind(this);
  }

  onClickTabItem (tab) {
    this.setState({ activeTab: tab });
  }

  render () {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab }
    } = this;

    return (
      <Wrapper>
        <TabList>
          {children.map((child) => {
            const { title } = child.props;
            return (
              <Tab
                activeTab={ activeTab }
                key={ title }
                title={ title }
                onClick={ onClickTabItem }
              />
            );
          })}
        </TabList>
        <TabListContent>
          {children.map((child) => {
            if (child.props.title !== activeTab) return undefined;
            return child;
          })}
        </TabListContent>
      </Wrapper>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};
export default Tabs;
