import styled, { css } from 'styled-components';
import colors from 'Styles/colors';
import { slideInRight } from 'Styles/effects';

export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const FiltersBody = styled.div`
  ${slideInRight}
`;
FiltersBody.displayName = 'FiltersBody';

export const CustomFiltersOptions = styled.div`
  float: right;
  background-color: ${(props) => (props.selected ? '#f1f4f7' : 'transparent')};
  margin-left: auto;
`;
CustomFiltersOptions.displayName = 'CustomFiltersOptions';

export const FilterNameContainer = styled.div`
  display: flex;
  background-color: ${(props) => (props.selected ? '#f1f4f7' : 'transparent')};
  height: 35px;
  cursor: pointer;

  p{
    margin-left: 10px;
  }

  ${CustomFiltersOptions} {
    display: none;

    svg{
      width: unset!important;
      height: unset!important;
      visibility: hidden;
    }
  }

  &:hover{
    background-color: #f1f4f7;

    ${CustomFiltersOptions} {
      display: inline;
      svg{
        width: unset!important;
        height: unset!important;
        visibility: visible;
      }
    }
  }

  .selected-filter {
    border-radius: 3px;
    background-color: #f1f4f7;
    font-weight: 600;
  }

  div {
    -webkit-appearance: unset;
  }
`;
FilterNameContainer.displayName = 'FilterNameContainer';

export const CustomFiltersSeparator = styled.div`
  margin-bottom: 12px;
  margin-top: 12px;
  margin-right: 20px;
  border-bottom: solid 1px ${colors.grey12};
  margin-left: 30px;
`;
CustomFiltersSeparator.displayName = 'CustomFiltersSeparator';

export const PlusIcon = styled.div`

  display: inline-block;
  float: right;
  font-weight: bold;

  svg {
    color: ${colors.grey12};
    width: 12px;
    height: 12px;
  }
`;
PlusIcon.displayName = 'PlusIcon';

export const CustomFiltersText = styled.div`
  font-size: 11.5px;
  font-weight: 500;
  color: ${colors.grey12};
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
  padding-right: 27px;
  padding-left: 30px;

  &:hover{
    color: ${colors.grey7};

    ${PlusIcon} svg{
      color: ${colors.grey7};
    }
  }
`;
CustomFiltersText.displayName = 'CustomFiltersText';

export const FilterName = styled.span`
  color: ${colors.dark2};
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  width: 69%;
  text-align: left;
  padding-left: 30px;
`;
FilterName.displayName = 'FilterName';

export const CloseFiltersIcon = styled.span`
  width: 27px;
  height: 27px;
  border: solid 1px ${colors.grey12};
  background-color: white;
  border-radius: 50%;

  svg {
    color: ${colors.grey5};
    width: 10px;
    height: 10px;
    margin-bottom: 2px;
    margin-right: 1px;
  }
`;
CloseFiltersIcon.displayName = 'CloseFiltersIcon';

export const FiltersHeader = styled.div`
  height: 36px;
  display: flex;
  width: 100%;
  padding-right: 20px;
  padding-left: 30px;
  align-items: flex-end;
  & > :first-child {
    margin-bottom: 2px;
  }
`;
FiltersHeader.displayName = 'FiltersHeader';

export const DeleteIcon = styled.img`
  position: relative;
  top: 5px;
  width: 16px;
  height: auto;
  margin-right: 25px;
`;
DeleteIcon.displayName = 'DeleteIcon';

export const EditIcon = styled.img`
  position: relative;
  top: 5px;
  width: 16px;
  height: auto;
  margin-right: 12px;
`;
EditIcon.displayName = 'EditIcon';

const settingIconStyles = css`
  width: 23px;
  height: 20px;
  cursor: pointer;
  margin-top: 9px;
`;
export const SettingIcon = styled.img`
  ${settingIconStyles};
`;
SettingIcon.displayName = SettingIcon;

// New Styles
export const FiltersContainer = styled.div`
  padding-right: 5px;
  padding-top: 14px;
  background-color: $background;
  box-shadow: none;
  width: 238px;
  overflow-x: hidden;
  flex: 0 0 250px;
  height: 100%;
  margin-left: 15px;
`;
FiltersContainer.displayName = 'FiltersContainer';

export const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 40px);
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #aaa;
  }
  
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
`;
Content.displayName = 'Content';
