import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideIn } from 'Styles/effects';
import StandardTextField from 'Common/Components/StandardTextField';
import { Texts } from 'Common/Components/StandardTextField/styled';

export const Modal = styled.div`
  border-radius: 8px 8px 2px 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  border-top: 10px solid #419bf9;
  padding: 35px;
  text-align: left;
  width: 493px;
  margin-right: 5px;
  ${slideIn}

  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    padding: 34px 30px;
    height: 348px;
  }
`;
Modal.displayName = 'Modal';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  display: inline-block;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #1c2243;
`;
Title.displayName = 'Title';

const Button = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  float: right;
  font-size: 14px;
  font-weight: 500;
`;

export const CancelButton = styled(Button)`
  margin-left: auto;
  border: solid 1px ${colors.dark6};
  color: ${colors.dark2};
  background-color: white;
  margin-right: 8px;
  float: none;
`;
CancelButton.displayName = 'CancelButton';

export const CreateButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? colors.grey20 : colors.softBlue)};
  color: white;
  border: 0;
`;
CreateButton.displayName = 'CreateButton';

export const Header = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 18px;
  }
`;
Header.displayName = 'Header';

export const Body = styled.div`
  margin-top: 34px;
  padding-right: 3px;
`;
Body.displayName = 'Body';

export const TopRow = styled.div`
  display: flex;
  width: 100%;
`;
TopRow.displayName = 'TopRow';

export const FieldTitle = styled.div`
  font-size: 12.5px;
  color: ${colors.grey17};
  line-height: 1;
  letter-spacing: 0.09px;
  color: #afb1bc;
  margin-bottom: 5px;
`;
FieldTitle.displayName = 'FieldTitle';

export const FieldTitleDescription = styled(FieldTitle)`
  margin-bottom: 5px;
`;
FieldTitleDescription.displayName = 'FieldTitleDescription';

export const Field = styled.div`
  display: inline-block;
  width: 50%;
  margin-left: ${(props) => (props.index > 0 ? '21px' : '0')};
`;
Field.displayName = 'Field';

export const FieldDescription = styled(Field)`
  width: fit-content;
  margin-top: 21px;
  margin-bottom: 5px;

  textarea {
    overflow: hidden;
    resize: none;
  }
`;
FieldDescription.displayName = 'FieldDescription';

export const FieldScope = styled(Field)`
  width: 100%;
  margin-top: 16px;
`;
FieldScope.displayName = 'FieldScope';

export const CheckboxContainer = styled.div`
`;
CheckboxContainer.displayName = 'CheckboxContainer';

export const Checkbox = styled.input`
  display: inline-block;
  background-color: ${colors.grey18};
`;
Checkbox.displayName = 'Checkbox';

export const CheckboxText = styled.div`
  display: inline-block;
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-left: 8px;
`;
CheckboxText.displayName = 'CheckboxText';

export const SecondTitle = styled.div`
  font-size: 14.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 34px;
`;
CheckboxText.displayName = 'CheckboxText';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 13px;
  margin-bottom: 21px;
  color: ${colors.grey19};
`;
SubTitle.displayName = 'SubTitle';

export const UserSelectorWraper = styled.div`
  input {
    width: 439px;
  }

  svg { 
    position: absolute;
    right: 52px;
    top: 405px;
  }
`;
UserSelectorWraper.displayName = 'UserSelectorWraper';

export const UserSelect = styled.div`
  height: 102px;
  border-radius: 3px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-top: 21px;
`;
UserSelect.displayName = 'UserSelect';

export const TextAreaContainer = styled.div`
  div {
    margin-bottom: 0px;
  }
`;
TextAreaContainer.displayName = 'TextAreaContainer';

export const ErrorMessage = styled.div`
  height: 12px;
  margin-top: 15px;
  color: ${colors.redPink};
  font-size: 10.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.08px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const TextField = styled(StandardTextField)`
  width: ${(props) => (props.fullwidth ? '100%' : '50%')};
  height: min-content;
  
  span {
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0px;
  }

  input {
    border-bottom: 1px solid transparent;
    box-shadow: ${(props) => (props.error ? '0 1px 0 0 #ea3158' : '0 1px 0 0 #afafaf')};
    margin-top: 5px;
    color: ${colors.darkBlueGrey};
    &:focus {
      background-color: ${colors.white};
    }
  }

  span:not(:first-child) {
    margin-top: 4px;
  }
  svg {
    top: 39px;
  }

  ${Texts} {
    height: min-content;
  }
`;
TextField.displayName = 'TextField';

export const TextSpan = styled.span`
font-size: 12.5px;
color: #1c4566;
margin-left: 10px;
line-height: 1;
height: fit-content;
font-weight: 300;
`;
TextSpan.displayName = 'TextSpan';

export const Flex = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`;

Flex.displayName = 'Flex';
