import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';

const Edit = styled(EditIcon).attrs({
  id: 'qa-edit-ws'
})`
    height: 17px;
  `;

Edit.displayName = 'Edit';

export default Edit;
