import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`      
    padding: 21px;
    background-color: #fafbfc;
    border: none;
    border-left: 8px solid #53a9ff;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #53a9ff;
`;
Title.displayName = 'Title';

export const TitleField = styled.div`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #afb1bc;
    margin-bottom: 8px;
`;
TitleField.displayName = 'TitleField';

export const FieldsWrapper = styled.div`
    display: inline-flex;
    margin-top: 21px;
`;
FieldsWrapper.displayName = 'FieldsWrapper';

export const FieldItem = styled.div`
    
`;
FieldItem.displayName = 'FieldItem';

export const ButtonsWrapper = styled.div`
    margin-top: 34px;
    text-align: right;
`;
ButtonsWrapper.displayName = 'ButtonsWrapper';

export const BtnCancel = styled.button`
    width: 89px;
    height: 34px;
    margin: 0 8px 0 0;
    padding: 9px 21px 8px 22px;
    border-radius: 2px;
    border: solid 1px #707070;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1c2243c;
    background-color: #fff;
`;
BtnCancel.displayName = 'BtnCancel';

export const BtnCreate = styled.button`
    width: 89px;
    height: 34px;
    margin: 0 0 0 8px;
    padding: 9px 30px 8px 32px;
    border-radius: 2px;
    background-color: ${(props) => (props.disabled ? '#c5c5c5' : '#53a9ff')};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
BtnCreate.displayName = 'BtnCreate';

export const CustomInput = styled.input`
    width: ${(props) => (props.width ? `${props.width}` : '224px')};
    height: ${(props) => (props.height ? `${props.height}` : '34px')};    
    border-bottom: ${(props) => (props.error ? '1px solid red' : 'none')};
    border-left: none;
    border-top: none;
    border-right: none;
    box-shadow: inset 0 -1px 0 0 #afafaf;
    background-color: ${colors.iceBlue};
    padding-left: 15px;
    padding-right: 5px;
    font-size: 14.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.48;
    letter-spacing: normal;
    color: #1c2243;

    &.full{
        background-color: white; 
    }

    &::placeholder {
        color: #afb1bc;
        font-size: 13.5px;
        font-weight: 300;
    }

    &:hover{
        border: 1px solid #afb1bc;        
    }

    &:focus{
        background-color: white;        

        &::placeholder {
            color: transparent;
        }
    }
`;
CustomInput.displayName = 'CustomInput';
