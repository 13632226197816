import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedEntity, selectFilterError } from 'store/Filters/selectors';
import { clearFilterError, setSaveEnabled } from 'store/Filters/actions';
import { FilterErrorContainer, FilterErrorLabel, CloseErrorIcon } from './styled';

const FilterError = () => {
  const selectedEntity = useSelector(getSelectedEntity);
  const error = useSelector((state) => selectFilterError(selectedEntity, state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) dispatch(setSaveEnabled(false));
  }, [error, dispatch]);

  return error && (
    <FilterErrorContainer>
      <FilterErrorLabel>
        { error }
      </FilterErrorLabel>
      <CloseErrorIcon onClick={ () => dispatch(clearFilterError(selectedEntity)) } />
    </FilterErrorContainer>
  );
};

export default FilterError;
