import { copy } from 'Common/Functions/Copy';
import * as types from './types';
import initialState from './initialState';

function host (state = initialState, action) {
  switch (action.type) {
    case types.RESET_STATE_HOST_DETAIL: {
      return {
        ...copy(initialState)
      };
    }
    case types.SHOW_HOST_DETAIL: {
      return {
        ...state,
        host: action.host
      };
    }
    case types.HIDE_HOST_DETAIL: {
      return {
        ...state,
        host: null
      };
    }
    case types.GET_DATA_VULNS_START: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_VULNS_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: false,
          data: action.data,
          count: action.count
        }
      };
    }
    case types.GET_DATA_VULNS_FAIL: {
      return {
        ...state,
        isFetching: false,
        vulns: [],
        error: true,
        errorMessage: action.data
      };
    }
    case types.GET_DATA_SERVICES_START: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_SERVICES_SUCCESS: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: action.data
        }
      };
    }
    case types.GET_DATA_SERVICES_FAIL: {
      return {
        ...state,
        services: {
          ...state.services,
          isFetching: false,
          data: []
        },
        error: true,
        errorMessage: action.data
      };
    }
    case types.GET_DATA_TOOLS_START: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: true,
          data: []
        }
      };
    }
    case types.GET_DATA_TOOLS_SUCCESS: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: false,
          data: action.data
        }
      };
    }
    case types.GET_DATA_TOOLS_FAIL: {
      return {
        ...state,
        tools: {
          ...state.tools,
          isFetching: false,
          data: []
        }
      };
    }
    case types.HOST_ADD_EDIT_SERVICE_FAIL: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          error: true,
          errorMessage: action.message,
          selectedService: initialState.createOrEditService.selectedService
        }
      };
    }
    case types.HOST_ADD_EDIT_SERVICE_SUCCESS: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          saved: true,
          error: initialState.createOrEditService.error,
          errorMessage: initialState.createOrEditService.errorMessage,
          selectedService: initialState.createOrEditService.selectedService
        }
      };
    }
    case types.HOST_ADD_EDIT_SERVICE_RESET: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          saved: initialState.createOrEditService.saved,
          error: initialState.createOrEditService.error,
          errorMessage: initialState.createOrEditService.errorMessage,
          selectedService: initialState.createOrEditService.selectedService
        }
      };
    }
    case types.SELECT_SERVICE: {
      return {
        ...state,
        createOrEditService: {
          ...state.createOrEditService,
          selectedService: action.selectedRow
        }
      };
    }
    case types.UPDATE_VULN_ASSET_PREVIEW_START: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          isFetching: true
        }
      };
    }
    case types.UPDATE_VULN_ASSET_PREVIEW_SUCCESS: {
      return {
        ...state,
        vulns: {
          ...state.vulns,
          data: action.data,
          isFetching: false,
          count: action.vulnsCount
        }
      };
    }
    case types.UPDATE_VULN_ASSET_PREVIEW_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    default: {
      return state;
    }
  }
}

export default host;
