import fetchApi from 'services/api/connector';

export const fetchApiConfig = () => fetchApi('config', { version: 'v1' });

export const getSession = () => fetchApi('session', { version: 'v1' });

export const forgotPassword = (email) => fetchApi('auth/forgot_password', { version: 'v1', method: 'POST', data: { email } }, null, false);

export const resetPassword = (token, password, repeatPassword) => fetchApi(`auth/reset_password/${token}`, { version: 'v1', method: 'POST', data: { password, password_confirm: repeatPassword } }, null, false);

export const getLicense = ({ ver }) => {
  const host = 'portal.faradaysec.com';
  const clientKey = 'white';
  fetchApi(`https://${host}/api/v1/license_check?version=${ver}&key=${clientKey}`, {}, null, true, true);
};
