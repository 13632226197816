import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logOut } from 'Screens/Login/actions/Actions';

class LogOutForced extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    const { logOut } = this.props;
    logOut();
  }

  render () {
    return (
      <></>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => {
    dispatch(logOut());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LogOutForced);
