import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  informational: colors.grey2,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Risk = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Risk.displayName = 'Risk';

export const RiskScoreInfo = styled.td`
  display: flex;
  padding-bottom: 5px;
`;
RiskScoreInfo.displayName = 'RiskScoreInfo';

export const Text = styled.td`
  font-weight: ${({ bold }) => bold ? '600' : 'normal'};
  font-size: ${({ big }) => big ? '16px' : '13.5px'};
  padding-bottom: ${({ pb }) => pb ? '5px' : '0px'};
  color: ${({ color }) => color ? sevColor[color] : '#1c4566'};
`;
Text.displayName = 'Text';

export const Title = styled.td`
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 10px;
  color: #1c4566;
`;

export const Line = styled.tr`
  font-size: 13px;
  & >:first-child {
    width: 180px;
  }
`;
Line.displayName = 'Line';

export const Link = styled.a`
  font-weight: normal;
  font-size: 13.5px;
  color: #1c4566;
`;
