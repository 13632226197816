import React from 'react';
import { redirect } from 'store/Router/actions';
import { useSelector, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectLocation } from 'store/Router/selectors';
import { Wrapper, SideBarSubMenuItem, SubMenuHeader } from './styled';

const SideBarSubMenu = ({ items, name, showFF }) => {
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);

  const hasMoreThanOneItem = items.length > 1;
  const isSelected = (loc) => location.pathname === loc;

  const onClick = (enabledByFeature, url, FF) => {
    if (enabledByFeature) dispatch(redirect(url));
    else showFF(FF);
  };

  return (
    <Wrapper>
      { hasMoreThanOneItem && <SubMenuHeader children={ name } /> }
      { items.map(({ name, url, enabledByFeature, FF }) => <SideBarSubMenuItem onClick={ () => onClick(enabledByFeature, url, FF) } selected={ isSelected(url) } key={ name } children={ name } />)}
    </Wrapper>
  );
};

SideBarSubMenu.propTypes = {
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
};

export default SideBarSubMenu;
