import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsFetching, selectUserAgents } from 'store/Agents/selectors';
import isEmpty from 'lodash/isEmpty';
import { getUserAgents } from 'store/Agents/actions';
import Wrapper from './styled';
import EmptyAgents from './components/EmptyAgents';
import UserAgents from './components/UserAgents';

const Grid = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const userAgents = useSelector(selectUserAgents);
  const noAgents = isEmpty(userAgents) && !isFetching;

  useEffect(() => {
    dispatch(getUserAgents());
  }, [dispatch]);

  if (noAgents) {
    return (
      <Wrapper>
        <EmptyAgents />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <UserAgents />
    </Wrapper>
  );
};

export default Grid;
