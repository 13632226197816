import get from 'lodash/get';

const getCustomFieldValueFromVuln = (data, customField, entity) => {
  const fieldName = get(customField, 'field_name', '');
  let value = '';
  if (entity === 'vuln') {
    value = get(data, `custom_fields.${fieldName}`, '');
  } else {
    value = get(data, `customfields.${fieldName}`, '');
  }

  return value || '';
};

export default getCustomFieldValueFromVuln;
