import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import { templatesSelected, selectField } from 'store/KnowledgeBase/selectors';
import CustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomField/index';
import { Wrapper, Content, NoFieldsText } from './styled';
import ToggleButtons from 'Screens/Manage/components/ToggleButtons';
import ExpandableWrapper from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import { setCustomAttribute } from 'Screens/KnowledgeBaseEditCreate/actions/Actions';
import useExpandable from 'Hooks/useExpandable';
import getCustomFieldValueFromVuln from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/functions';
const ID = 'customAttributes';

const CustomFieldWrapper = ({ field }) => {
  const templateSelected = useSelector(templatesSelected);
  const editValue = useSelector((state) => selectField(state, field));
  const value = Object.keys(templateSelected).length === 0 ? editValue : getCustomFieldValueFromVuln(templateSelected, field, 'template');
  const initialValue = value ? value.toString() : '';
  const dispatch = useDispatch();
  const isExpanded = useExpandable(ID, field.field_name, initialValue);
  const saveData = (fieldName, fieldValue) => {
    dispatch(setCustomAttribute(fieldName, fieldValue));
  };
  return (
    <ExpandableWrapper defaultValue={ isExpanded } key={ `expandible_custom_${field.id}` } id={ field.field_name } title={ field.field_display_name } tab="customAttributes">
      <CustomField field={ field } key={ `Custom_Field_${field.id}` } initialValue={ value } dataSelected={ templateSelected } onSave={ saveData } entity="template" />
    </ExpandableWrapper>
  );
};

const CustomAttributes = () => {
  const intl = useIntl();
  const customFields = useSelector(selectCustomAttributes);
  const hasCustomFields = customFields.length > 0;
  return (
    <Wrapper>
      <Content>
        <ToggleButtons id={ ID } />

        {customFields.map((field) => (
          <CustomFieldWrapper key={ `custom_field_wrapper_${field.id}` } field={ field } />
        ))}
        {!hasCustomFields && <NoFieldsText>{intl.formatMessage({ id: 'customAttributes.empty' })}</NoFieldsText>}
      </Content>
    </Wrapper>
  );
};

export default withRouter(CustomAttributes);
