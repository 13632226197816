import styled from 'styled-components';

import { Icon } from '../../styled';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 38px;
  align-items: flex-start;
  margin-top: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.p`
  font-size: 12.5px;
  color: #90a9c0;
  flex: 1;
`;
Text.displayName = 'Text';

export const IconHelp = styled(Icon)`
  margin: 2px 8px 0 0;
`;
Icon.displayName = 'Icon';
