import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &> :not(:last-child) {
    margin-right: 7px;
    &::after{
      content: ",";
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export const Link = styled.a`
    font-size: 13.5px;
    color: ${colors.blueCerulean};
    cursor: pointer;
    text-decoration: none;
`;
Link.displayName = 'Link';
