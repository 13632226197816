import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  padding: 11px 21.6px 11px 21px;
  background: rgb(39, 39, 39, 0.2);
  margin: 10px 0;
`;

Wrapper.displayName = 'Wrapper';

export const Message = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  color: ${colors.green1};
`;
Message.displayName = 'Message';
