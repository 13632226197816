import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DropdownMenu = styled.ul`
    position: absolute;
    width: 200px;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,.15);
    background-color: #fff;
    padding: 17px 0;
    z-index:9999;
    box-shadow: rgba(28, 34, 67, 0.1) 0 0 50px 0;
    right: 0px;
    top: 48px;
`;
DropdownMenu.displayName = 'DropdownMenu';

export const UserDropdownItem = styled.li`
    list-style: none;
    text-align: left;
    color: #1c2243;
    font-size: 14px;
    padding:10px 20px;
    font-weight: 300;
    cursor: pointer;
    &:hover{
        background-color: #f1f4f7;
    }

    & a{
        color: #1c2243;
    }
`;
UserDropdownItem.displayName = 'DropdownItem';

export const UserAvatar = styled.div`
    position: relative;
    cursor: pointer;
`;
UserDropdownItem.displayName = 'UserAvatar';

export const CustomLink = styled(Link)`
    text-decoration: none !important;
    display:flex;
`;
