import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import {
  EditorContainer,
  StyledMdEditor
} from './styled';

const mdConfig = {
  view: {
    menu: true,
    md: true,
    html: false,
    fullScreen: false,
    hideMenu: false
  },
  canView: {
    menu: true,
    md: true,
    html: true,
    fullScreen: true,
    hideMenu: false
  }
};

const MDEditor = ({
  onBlur, markdown, setMarkdown, isEditing, setEditing, plainText, uploadImage, onImageUpload
}) => {
  const editorRef = useRef(null);
  const editorWrapperRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (editorWrapperRef.current && !editorWrapperRef.current.contains(event.target)) {
      setEditing(false);
      onBlur(markdown);
    }
  }, [markdown, onBlur, setEditing]);

  useEffect(() => {
    if (isEditing && editorRef.current) {
      editorRef.current.nodeMdText.current.setSelectionRange(markdown.length, markdown.length);
      editorRef.current.nodeMdText.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside, isEditing, markdown]);

  const handleEditorChange = (it) => setMarkdown(it.text);

  const handleImageUpload = (file) => onImageUpload(file);

  const mdParser = new MarkdownIt();
  const plugins = [
    'header',
    'font-bold',
    'font-italic',
    'block-code-inline',
    'block-code-block',
    'block-quote',
    'table',
    'link'
  ];

  const getPlugins = () => {
    if (plainText) return [];
    else if (uploadImage) return [...plugins, 'image'];
    else return plugins;
  };

  return (
    <EditorContainer ref={ editorWrapperRef }>
      <StyledMdEditor
        ref={ editorRef }
        value={ markdown }
        renderHTML={ (text) => mdParser.render(text) }
        plugins={ getPlugins() }
        onChange={ handleEditorChange }
        config={ mdConfig }
        onImageUpload={ uploadImage ? handleImageUpload : () => {} }
      />
    </EditorContainer>
  );
};

MDEditor.propTypes = {
  onBlur: PropTypes.func.isRequired,
  markdown: PropTypes.string,
  setMarkdown: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  setEditing: PropTypes.func.isRequired,
  uploadImage: PropTypes.bool
};

MDEditor.defaultProps = {
  markdown: '',
  isEditing: false,
  uploadImage: false
};

export default MDEditor;
