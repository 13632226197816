const defaultTools = [{
  name: 'Vulnerabilities',
  render: true, // Set initial in false for dashboard custom
  data: [],
  error: ''
}, {
  name: 'Top Services',
  render: true, // Set initial in false for dashboard custom
  data: [],
  error: ''
}, {
  name: 'Top Hosts',
  render: true, // Set initial in false for dashboard custom
  data: [],
  error: ''
}, {
  name: 'Summary',
  render: true, // Set initial in false for dashboard custom
  data: [],
  error: ''
}, {
  name: 'Activity Feed',
  render: true, // Set initial in false for dashboard custom
  data: [],
  error: ''
}, {
  name: 'Latest Vulns',
  render: true, // Set initial in false for dashboard custom
  data: [],
  error: ''
}];

export default defaultTools;
