import clone from 'lodash/clone';
import {
  ADD_REFERENCE_MANAGE_CREATE_UPDATE,
  REMOVE_REFERENCE_MANAGE_CREATE_UPDATE,
  ADD_POLICY_MANAGE_CREATE_UPDATE,
  REMOVE_POLICY_MANAGE_CREATE_UPDATE,
  SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE,
  SET_FIELD_NAME_MANAGE_CREATE_UPDATE,
  REMOVE_FILE_MANAGE_CREATE_UPDATE,
  SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE,
  SET_ATTACHMENT_MANAGE_CREATE_UPDATE,
  DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE,
  MANAGE_CREATE_UPDATE_START,
  MANAGE_CREATE_UPDATE_SUCCESS,
  MANAGE_CREATE_UPDATE_FAIL,
  RESET_STATE_MANAGE_CREATE_UPDATE,
  SET_REDIRECT_MANAGE_CREATE_UPDATE,
  GET_TEMPLATES_MANAGE_EDIT_CREATE,
  GET_TEMPLATES_MANAGE_EDIT_SUCCESS,
  GET_TEMPLATES_MANAGE_EDIT_FAIL,
  SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE,
  GET_HOSTS_REQUEST,
  GET_HOSTS_SUCCESS,
  GET_HOSTS_FAIL,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  HOST_CREATE_REQUEST,
  HOST_CREATE_SUCCESS,
  HOST_CREATE_FAIL,
  ADD_TARGET,
  REMOVE_ASSET,
  SET_FILES_TO_UPLOAD_MANAGE,
  SET_UPLOAD_STATE_MANAGE,
  UPDATE_FILES_TO_UPLOAD,
  UPLOAD_REPORT_MANAGE_UPDATE_PERCENT,
  RESET_FILE_STATE_MANAGE,
  CANCEL_REQUEST_MANAGE,
  SET_FILE_INDEX_MANAGE,
  UPLOAD_REPORT_ERROR_MANAGE,
  SET_NAME_INTO_FILE_ERROR_LIST,
  CREATE_SERVICES_REQUEST,
  CREATE_SERVICES_SUCCESS,
  CREATE_SERVICES_FAIL,
  ADD_CVE_MANAGE_CREATE_UPDATE,
  REMOVE_CVE_MANAGE_CREATE_UPDATE,
  RESET_ERROR_VALUE,
  SET_IGNORE_INFO,
  SET_RESOLVE_HOST
} from '../../Screens/ManageEditCreate/actions/Actions';
import { copyArray, copy } from '../../Common/Functions/Copy';

const initialState = {
  _attachments: {},
  accountability: false,
  availability: false,
  confidentiality: false,
  confirmed: true,
  currentUploadError: '',
  currentUploadPercent: 0,
  currentUploadState: '',
  customFields: {},
  data: '',
  description: '',
  easeOfResolution: undefined,
  errorFilesList: [],
  errorMessage: undefined,
  externalId: '',
  fileIndex: -1,
  files: [],
  filesToUpload: [],
  host: '',
  hosts: [],
  id: undefined,
  importSuccess: false,
  integrity: false,
  isFetching: false,
  method: '',
  mode: '',
  paramName: '',
  params: '',
  path: '',
  policies: [],
  port: '',
  protocol: '',
  query: '',
  redirect: false,
  references: [],
  report: [],
  request: '',
  resolution: '',
  response: '',
  service: '',
  services: [],
  severity: '',
  status_code: '',
  targets: [],
  templates: [],
  uploadState: '',
  vuln: undefined,
  website: '',
  CVE: []
};

function manageEditCreate (state = initialState, action) {
  switch (action.type) {
    case ADD_TARGET: {
      const targets = copyArray(state.targets);
      targets.push(action.data);
      return {
        ...state,
        targets
      };
    }
    case REMOVE_ASSET: {
      const targets = copyArray(state.targets);
      const index = targets.findIndex((item) => item.type === action.data.type && item.id === action.data.id);
      if (index > -1) {
        targets.splice(index, 1);
      }
      return {
        ...state,
        targets
      };
    }

    case ADD_CVE_MANAGE_CREATE_UPDATE: {
      const CVE = [...state.CVE];
      CVE.push(action.CVE);

      return {
        ...state,
        CVE
      };
    }

    case REMOVE_CVE_MANAGE_CREATE_UPDATE: {
      const CVE = [...state.CVE];
      const filterList = CVE.filter((key) => key !== action.CVE);

      return {
        ...state,
        CVE: filterList
      };
    }

    case ADD_REFERENCE_MANAGE_CREATE_UPDATE: {
      const references = [...state.references];
      references.push(action.reference);

      return {
        ...state,
        references
      };
    }

    case REMOVE_REFERENCE_MANAGE_CREATE_UPDATE: {
      const references = [...state.references];
      references.splice(action.index, 1);

      return {
        ...state,
        references
      };
    }

    case ADD_POLICY_MANAGE_CREATE_UPDATE: {
      const policies = clone(state.policies);
      policies.push(action.policy);

      return {
        ...state,
        policies
      };
    }

    case REMOVE_POLICY_MANAGE_CREATE_UPDATE: {
      const policies = clone(state.policies);
      policies.splice(action.index, 1);

      return {
        ...state,
        policies
      };
    }

    case SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        [action.checkName]: !state[action.checkName]
      };
    }

    case SET_FIELD_NAME_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }

    case REMOVE_FILE_MANAGE_CREATE_UPDATE: {
      const files = copyArray(state.files);
      files.splice(action.index, 1);

      return {
        ...state,
        files
      };
    }

    case SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE: {
      const customFields = copy(state.customFields);
      customFields[action.key] = action.value;

      return {
        ...state,
        customFields
      };
    }

    case SET_ATTACHMENT_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        _attachments: {
          ...state._attachments, // eslint-disable-line no-underscore-dangle
          [action.name]: {
            content_type: action.content_type,
            data: action.data
          }
        },
        files: [action.file]
      };
    }

    case DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE: {
      const { [action.key]: deleted, ...attachments } = state._attachments; // eslint-disable-line no-underscore-dangle
      return {
        ...state,
        _attachments: {
          ...attachments
        }
      };
    }

    case MANAGE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case MANAGE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        redirect: true
      };
    }

    case MANAGE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
        error: true
      };
    }

    case RESET_STATE_MANAGE_CREATE_UPDATE: {
      return {
        ...initialState,
        references: []
      };
    }

    case SET_REDIRECT_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        redirect: true,
        importSuccess: false
      };
    }

    case GET_TEMPLATES_MANAGE_EDIT_CREATE: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_TEMPLATES_MANAGE_EDIT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        templates: action.data
      };
    }

    case GET_TEMPLATES_MANAGE_EDIT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: 'Failed to get templates'
      };
    }

    case SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE: {
      return {
        ...state,
        [action.field]: action.value
      };
    }

    case GET_HOSTS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_HOSTS_SUCCESS: {
      return {
        ...state,
        hosts: action.data.rows
      };
    }

    case GET_HOSTS_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case GET_SERVICES_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        services: action.data.services
      };
    }

    case GET_SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case HOST_CREATE_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case HOST_CREATE_SUCCESS: {
      return {
        ...state,
        hosts: [...state.hosts, { id: action.data.id, key: action.data.id, value: action.data }]
      };
    }

    case HOST_CREATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case CREATE_SERVICES_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case CREATE_SERVICES_SUCCESS: {
      const index = state.targets.findIndex((t) => (t.id === action.service.parent));
      const tempTargets = state.targets;
      let host = state.hosts.find((item) => item.id === action.service.host_id);
      if (host) {
        host = host.value;
        tempTargets[index] = { ...action.service, hostname: host.name, hostnameData: host.hostnames.length > 0 ? host.hostnames : '' };
      }
      return {
        ...state,
        services: [...state.services, { id: action.service.id, key: action.service.id, value: action.service }],
        targets: tempTargets
      };
    }

    case CREATE_SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case SET_FILES_TO_UPLOAD_MANAGE: {
      return {
        ...state,
        filesToUpload: action.files
      };
    }

    case SET_UPLOAD_STATE_MANAGE: {
      return {
        ...state,
        uploadState: action.state
      };
    }

    case SET_FILE_INDEX_MANAGE: {
      return {
        ...state,
        fileIndex: action.index
      };
    }

    case UPLOAD_REPORT_ERROR_MANAGE: {
      return {
        ...state,
        currentUploadState: 'Error',
        currentUploadPercent: 0,
        currentUploadError: action.error
      };
    }

    case UPDATE_FILES_TO_UPLOAD: {
      const { filesToUpload } = state;
      const tempFiles = filesToUpload;
      tempFiles.shift();
      return {
        ...state,
        filesToUpload: tempFiles
      };
    }

    case SET_NAME_INTO_FILE_ERROR_LIST: {
      return {
        ...state,
        errorFilesList: [...state.errorFilesList, action.name]
      };
    }

    case UPLOAD_REPORT_MANAGE_UPDATE_PERCENT: {
      return {
        ...state,
        currentUploadState: 'Uploading',
        currentUploadError: ''
      };
    }

    case RESET_FILE_STATE_MANAGE: {
      return {
        ...state,
        currentUploadState: '',
        currentUploadError: '',
        errorFilesList: []
      };
    }

    case CANCEL_REQUEST_MANAGE: {
      return {
        ...state,
        currentUploadState: 'Canceled',
        currentUploadPercent: 0,
        currentUploadError: ''
      };
    }

    case RESET_ERROR_VALUE: {
      return {
        ...state,
        errorMessage: '',
        error: false
      };
    }

    case SET_IGNORE_INFO: {
      return {
        ...state,
        ignoreInfo: action.value
      };
    }

    case SET_RESOLVE_HOST: {
      return {
        ...state,
        resolveHost: action.value
      };
    }

    default:
      return state;
  }
}

export default manageEditCreate;
