import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 1;
`;
Wrapper.displayName = 'Wrapper';

export const SkeletonIcon = styled.svg`

`;
SkeletonIcon.displayName = 'SkeletonIcon';
