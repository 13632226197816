import React from 'react';
import { PropTypes } from 'prop-types';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { Button, Text } from './styled';

const AddButton = ({
  title, onClick, text, className, disabled
}) => (
  <Button disabled={ disabled } title={ title } onClick={ onClick } className={ className }>
    <PlusIcon />
    <Text disabled={ disabled }>{ text }</Text>
  </Button>
);
export default AddButton;

AddButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

AddButton.defaultProps = {
  title: '',
  onClick: () => {},
  text: '',
  className: '',
  disabled: false
};
