import React from 'react';
import Wrapper from './styled';
import Header from '../Header';
import UpgradeLicense from '../UpgradeLicense';
import FFLdap from 'Images/ff_ldap.png';

const Ldap = ({ onClose }) => {
  return (
    <Wrapper>
      <Header onClose={ onClose } onAccept={ onClose } />
      <UpgradeLicense image={ FFLdap } descriptionIntlId={ 'upgradeLicense.sidebar.settings.ldap.description' } />
    </Wrapper>
  );
};

export default Ldap;
