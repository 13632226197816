/* eslint-disable react/jsx-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFinishedCommands, selectUnfinishedCommands } from 'store/FileUploaderContext/selector';
import {
  Wrapper, FileWrapper, FileName, Text,
  Commands
} from './styled';
import Status from './Status';
import isEmpty from 'lodash/isEmpty';

const Body = () => {
  const unfinishedCommands = useSelector((state) => selectUnfinishedCommands(state));
  const finishedCommands = useSelector((state) => selectFinishedCommands(state));

  if (isEmpty(unfinishedCommands) && isEmpty(finishedCommands)) {
    return (
    <Wrapper>
      <Text>The queue is empty</Text>
    </Wrapper>
    );
  }
  return (
    <Wrapper>
    { unfinishedCommands && (
      <Commands>
        {unfinishedCommands.map((file, i) => (
          <FileWrapper key={ `${file.name}_${i}` }>
            <FileName title={ file.name } children={ file.name } />
            <Status file={ file } />
          </FileWrapper>
        ))}
      </Commands>
    )}
    { finishedCommands && (
      <Commands>
        {finishedCommands.map((file, i) => (
          <FileWrapper key={ `${file.name}_${i}` }>
            <FileName title={ file.name } children={ file.name } />
            <Status file={ file } />
          </FileWrapper>
        ))}
      </Commands>
    )}
    </Wrapper>
  );
};

export default Body;
