/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Wrapper } from './styled';

const capitalize = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : string);

class Dropdown extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.state = {
      value: undefined
    };
  }

  componentDidMount () {
    if (this.props.defaultValue !== null && this.props.defaultValue !== '') {
      const option = { label: capitalize(this.props.defaultValue), value: this.props.defaultValue };
      this.setState({ value: option });
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      if (this.props.defaultValue !== null && this.props.defaultValue !== '') {
        const option = { label: capitalize(this.props.defaultValue), value: this.props.defaultValue };
        this.setState({ value: option });
      } else {
        this.setState({ value: this.props.defaultValue });
      }
    }
  }

  onChangeHandler (strValue) {
    const option = { label: strValue, value: strValue };
    this.setState({ value: option });
    this.props.updateValue(strValue);
  }

  render () {
    const { value } = this.state;
    const {
      customStyle,
      options,
      disabled,
      placeholder,
      className,
      menuPortalTarget
    } = this.props;

    return (
      <Wrapper isDisabled={ disabled }>
        <Select
          menuPlacement="auto"
          styles={ customStyle }
          isSearchable={ false }
          value={ value }
          isDisabled={ disabled }
          onChange={ (e) => { this.onChangeHandler(e.value); } }
          options={ options }
          placeholder={ placeholder }
          className={ className }
          menuPortalTarget={ menuPortalTarget }
        />
      </Wrapper>
    );
  }
}

Dropdown.propTypes = {
  customStyle: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.string
  })).isRequired,
  disabled: PropTypes.bool,
  updateValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  menuPortalTarget: PropTypes.node
};

Dropdown.defaultProps = {
  disabled: false,
  defaultValue: null,
  placeholder: '',
  className: '',
  menuPortalTarget: undefined
};

export default withRouter(Dropdown);
