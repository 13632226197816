import React from 'react';
import PropTypes from 'prop-types';
import ColorImage from 'Common/Components/ColorImage';
import UserDropdown from './Dropdown';
import { UserContainer } from './styled';

const User = ({ modals, ...props }) => {
  const {
    Extras, Help, About, Preferences
  } = modals;
  return (
    <>
      <UserContainer onClick={ props.onClick }>
        <ColorImage title={ props.userName } role={ props.userRole } />
        {props.dropdown && <UserDropdown modals={ modals } />}
      </UserContainer>
      {Preferences.showModal && <Preferences.Modal onClose={ Preferences.toggleModal } />}
      {Extras.showModal && <Extras.Modal onClose={ Extras.toggleModal } />}
      {About.showModal && <About.Modal onClose={ About.toggleModal } />}
      {Help.showModal && <Help.Modal onClose={ Help.toggleModal } />}
    </>

  );
};

User.propTypes = {
  modals: PropTypes.shape({
    Extras: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
      toggleModal: PropTypes.func.isRequired
    }),
    Help: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
      toggleModal: PropTypes.func.isRequired
    }),
    About: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
      toggleModal: PropTypes.func.isRequired
    }),
    Preferences: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
      toggleModal: PropTypes.func.isRequired
    })
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  dropdown: PropTypes.bool.isRequired
};

export default User;
