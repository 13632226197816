import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
