import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect } from 'store/Router/actions';
import { selectIsOpen, selectPagesData } from 'store/SideBar/selector';
import { toggleSideBar, setSideBarOpen } from 'store/SideBar/actions';
import { selectApiVersion } from 'store/Faraday/selectors';
import SideBarItem from './components/SideBarItem';
import {
  Wrapper,
  CompressedLogo,
  Logo,
  FullLogo,
  Arrow
} from './styled';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE_SIDEBAR } from 'store/modals/modals';
import selectTourFinished from 'store/Preferences/Tour/selectors';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';

const SideBar = () => {
  const [FFImage, setFFImage] = useState(null);
  const [FFDescription, setFFDescription] = useState('');
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const pages = useSelector(selectPagesData);
  const apiVersion = useSelector(selectApiVersion);
  const frontVersion = process.env.REACT_APP_VERSION;
  const toggleOpen = () => dispatch(toggleSideBar());
  const tourFinished = useSelector((state) => selectTourFinished(state));

  useEffect(() => {
    if (!tourFinished) dispatch(setSideBarOpen(!tourFinished));
  }, [dispatch, tourFinished]);

  const showFF = (FF) => {
    setFFImage(FF.image);
    setFFDescription(FF.description);
    dispatch(openModal(MODAL_UPGRADE_LICENSE_SIDEBAR));
  };

  return (
    <Wrapper open={ isOpen }>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_SIDEBAR }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_SIDEBAR } image={ FFImage } descriptionIntlId={ FFDescription } />
      </ReduxModal>
      <Logo onClick={ () => dispatch(redirect('/workspaces')) } open={ isOpen }>
        {isOpen
          ? <FullLogo title={ `Server: ${apiVersion}\nClient: ${frontVersion}` } />
          : <CompressedLogo title={ `Server: ${apiVersion}\nClient: ${frontVersion}` } />}
      </Logo>
      {pages.map(({
        icon, name, subMenu
      }) => <SideBarItem icon={ icon } name={ name } subMenu={ subMenu } key={ name } showFF={ showFF } />)}
      <Arrow $isOpen={ isOpen } onClick={ toggleOpen } />
    </Wrapper>
  );
};

export default SideBar;
