import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectWorkspacesOrdered, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import some from 'lodash/some';
import WorkspaceItem from './components/WorkspaceItem';
import { Wrapper } from './styled';
import { getData } from 'Screens/Workspaces/actions/Actions';

const GridView = ({ setShowConfirmation, setShowWarning }) => {
  const workspaceList = useSelector(selectWorkspacesOrdered);
  const workspacesSelected = useSelector(selectWorkspacesSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  return (
    <Wrapper>
      { workspaceList.map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />) }
    </Wrapper>
  );
};

export default GridView;
