import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectModal from 'store/modals/selectors';
import { MODAL_REDIRECT_CONFIRMATION } from 'store/modals/modals';
import { performRedirect, redirectClear, setHasPendingChanges } from 'store/Router/actions';
import { useIntl } from 'react-intl';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';
import { selectHasPendingChanges } from 'store/Global/selectors';
import { selectAction } from 'store/Router/selectors';

const RedirectModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const hasPendingChanges = useSelector(selectHasPendingChanges);
  const action = useSelector(selectAction);
  const show = useSelector((state) => selectModal(state, MODAL_REDIRECT_CONFIRMATION));
  const onCancel = () => dispatch(redirectClear());
  const onDone = () => dispatch(performRedirect());
  const title = intl.formatMessage({ id: 'app.redirectWarning.title' });
  const message = intl.formatMessage({ id: 'app.redirectWarning.message' });
  const done = intl.formatMessage({ id: 'app.redirectWarning.leave' });
  const cancel = intl.formatMessage({ id: 'app.redirectWarning.stay' });

  useEffect(() => {
    if (action === 'POP') dispatch(setHasPendingChanges(false));
  }, [dispatch, action]);

  useEffect(() => {
    if (hasPendingChanges) window.onbeforeunload = () => true;
    else window.onbeforeunload = null;
  }, [hasPendingChanges]);

  if (!show || !hasPendingChanges) return null;
  return (
    <ConfirmNavigationModal
      title={ title }
      message={ message }
      onCancel={ onCancel }
      onDone={ onDone }
      cancel={ cancel }
      done={ done }
    />
  );
};

export default RedirectModal;
