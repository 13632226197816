/* eslint-disable react/no-children-prop */
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import TimeAgo from 'react-timeago';
import { Wrapper, Label, Content } from './styled';

const LastExecution = ({ lastExecution }) => {
  const intl = useIntl();
  const label = `${intl.formatMessage({ id: 'agents.item.lastExecution' })}:`;
  const noExecution = intl.formatMessage({ id: 'agents.item.lastExecution.never' });

  return (
    <Wrapper>
      <Label children={ label } />
      <Content>
        { lastExecution ? <TimeAgo date={ lastExecution } /> : <div children={ noExecution } /> }
      </Content>
    </Wrapper>
  );
};

LastExecution.propTypes = {
  lastExecution: PropTypes.string.isRequired
};

export default LastExecution;
