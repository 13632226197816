import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImagePreview } from 'store/Manage/actions';
import { selectImagePreview } from 'store/Manage/selectors';
import { Wrapper, Modal, Image, ImageWrapper, CloseWrapper, CloseIcon } from './styled';

const ImagePreviewModal = () => {
  const dispatch = useDispatch();
  const previewSrc = useSelector(selectImagePreview);

  if (!previewSrc) return null;
  return (
    <Modal>
      <Wrapper>
        <CloseWrapper onClick={ () => dispatch(setImagePreview('')) }>
          <CloseIcon />
        </CloseWrapper>
        <ImageWrapper children={ <Image src={ previewSrc } /> } />
      </Wrapper>
    </Modal>
  );
};

export default ImagePreviewModal;
