import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Content from './components/Content';
import Website from './components/Website';
import Wrapper from './styled';

const ToolParams = ({ defaultParams, currentTool, onChangeField }) => {
  if (isEmpty(currentTool)) return null;
  return (
    <Wrapper>
      <Website currentTool={ currentTool } />
      <Content defaultParams={ defaultParams } currentTool={ currentTool } onChangeField={ onChangeField } />
    </Wrapper>
  );
};

export default ToolParams;
