import React from 'react';
import { useIntl } from 'react-intl';
import StandardDropdown from 'Common/Components/StandarDropdown/index';
import BlueButton from 'Common/Components/BlueButton/index';
import OutlinedButton from 'Common/Components/OutlinedButton/index';
import CustomList from 'Common/Components/CustomList';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { selectCAToEdit, selectCustomAttributes } from 'store/CustomAttributes/selectors';
import StandardTextField from 'Common/Components/StandardTextField';
import { FIELD_TYPES, CUSTOM_ATTRIBUTES_MODAL_ID } from '../../constants';
import {
  Wrapper, Title, TextFieldContainer, Fields, Buttons, Container
} from './styled';

const DisplayAddCustomAttribute = ({
  onSubmit, onCancel, handleChangeType, handleChangeName, name, type, options, onAddItem, onRemoveItem
}) => {
  const intl = useIntl();
  const customAttributes = useSelector(selectCustomAttributes);
  const caToEdit = useSelector(selectCAToEdit);
  const isEditing = !!caToEdit;

  const intlId = isEditing ? 'customAttributes.modal.edit' : 'customAttributes.modal.add';
  const title = intl.formatMessage({ id: intlId });
  const placeholder = intl.formatMessage({ id: 'customAttributes.modal.placeholder' });

  const nameIsDuplicated = (newName) => {
    const formattedNewName = newName.toLowerCase().trim().replaceAll(' ', '_');
    return customAttributes.some((field) => field.field_name === formattedNewName);
  };

  const canCreate = !isEmpty(name) && !isEmpty(type) && (isEditing || !nameIsDuplicated(name));
  const isChoice = type === 'Choice';
  const buttonLabel = isEditing ? 'Save' : 'Create';
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Container>
        <Fields>
          <TextFieldContainer>
            <StandardTextField
              name="custom_field_name"
              value={ name }
              onChange={ handleChangeName }
              mandatory
              placeholder={ placeholder }
              title="Name"
              error={ !isEditing && nameIsDuplicated(name) }
              errorMessage={ !isEditing && nameIsDuplicated(name) ? 'This name is already taken' : '' }
            />
          </TextFieldContainer>
          <StandardDropdown
            field="custom_field_Type"
            options={ FIELD_TYPES }
            updateValue={ handleChangeType }
            width="131px"
            selectObject
            disabled={ isEditing }
            defaultValue={ type }
            menuPortalTarget={ document.getElementById(CUSTOM_ATTRIBUTES_MODAL_ID) }
          />
        </Fields>
        {isChoice &&
          (
            <CustomList
              key="CF_OPTIONS"
              placeholder="Add option"
              addItem={ onAddItem }
              removeItem={ onRemoveItem }
              listData={ options }
              responsive
            />
          )}
      </Container>
      <Buttons>
        {isEditing && <OutlinedButton label="Cancel" onClick={ onCancel } />}
        <BlueButton label={ buttonLabel } onClick={ onSubmit } disabled={ !canCreate } />
      </Buttons>
    </Wrapper>
  );
};

export default DisplayAddCustomAttribute;
