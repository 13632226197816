import React from 'react';
import { FiEdit3 as Edit } from 'react-icons/fi';

// TODO: refactor
const EditButton = ({
  editFunction, selectedEntities, breakpoint, display
}) => {
  if (display) {
    return (
      <div
        className="margin-right-12 icon-container-hover"
        onClick={ () => { } }
        onKeyDown={ () => {} }
        role="button"
        aria-label="Edit"
        tabIndex="0"
        type="button"
      >
        <Edit className="icon-tag" style={ { fontSize: '20px' } } onClick={ () => editFunction(selectedEntities[0], breakpoint) } />
      </div>
    );
  }
  return null;
};

export default EditButton;
