const initialState = {
};

function tags (state = initialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}

export default tags;
