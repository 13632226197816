import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 13px;
  justify-content: flex-end;
  display: flex;
  padding: 0px;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
`;
Title.displayName = 'Title';
