import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Wrapper, Group, LeftGroup, RightGroup, Separator
} from './styled';
import { selectRowsPerPageListView, selectShowGridView, selectWorkspacesCount, selectWorkspacesSelected } from 'store/Workspace/selectors';
import isEmpty from 'lodash/isEmpty';
import { WsAddButton, WsEditButton, WsDeleteButton, MoreOptionsButton } from './components/LeftGroup';
import { ShowInactive, GridViewButton, ListViewButton } from './components/RightGroup';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { setPage } from 'Screens/Workspaces/actions/Actions';
import Pagination from 'Common/Components/Pagination';

const WSActionBar = () => {
  const dispatch = useDispatch();
  const selectedWs = useSelector(selectWorkspacesSelected);
  const hasSelectedWs = !isEmpty(selectedWs);
  const workspacesCount = useSelector(selectWorkspacesCount);
  const page = useSelector((state) => selectPage('workspaces', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('workspaces', state));
  const rowsPerPageListView = useSelector(selectRowsPerPageListView);
  const showGridView = useSelector(selectShowGridView);
  const onPrev = () => dispatch(setPage(page - 1));
  const onNext = () => dispatch(setPage(page + 1));

  return (
    <Wrapper>
      <LeftGroup>
        <WsAddButton />
        { hasSelectedWs && (
          <>
            <Separator />
            <Group>
              <WsEditButton />
              <WsDeleteButton />
              <MoreOptionsButton />
            </Group>
          </>
        )}
      </LeftGroup>
      <RightGroup>
        <ShowInactive />
        <Pagination offset={ page } limit={ showGridView ? rowsPerPage : rowsPerPageListView } total={ workspacesCount } onPrev={ onPrev } onNext={ onNext } />
        <GridViewButton />
        <ListViewButton />
      </RightGroup>
    </Wrapper>
  );
};

export default WSActionBar;
