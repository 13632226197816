import React from 'react';
import Button from 'Common/Components/BlueButton/styled';
import {
  Wrapper, Label, Image, ButtonLink
} from './styled';

const EmptyVulns = ({ toggleModalUploadVulns, toggleModalCreateVuln, image }) => (
  <Wrapper>
    <Image src={ image } />
    <Label>You don’t have any vulnerabilities yet.</Label>
    <Button onClick={ toggleModalUploadVulns }>Upload Vulnerabilities</Button>
    <Label>
      Or
      <ButtonLink onClick={ toggleModalCreateVuln }>Create vulnerabilities manually</ButtonLink>
    </Label>
  </Wrapper>
);

export default EmptyVulns;
