import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowDetail } from 'store/Manage/selectors';
import { redirectToManage } from 'store/Manage/actions';
import { PropTypes } from 'prop-types';
import TabSelector from '../Tabs/components/TabSelector';
import {
  Wrapper,
  CloseIconWrapper,
  CloseIcon,
  Transparency
} from './styled';

const Header = ({ showTransparency }) => {
  const dispatch = useDispatch();
  const show = useSelector(selectShowDetail);

  if (!show) return null;
  return (
    <Wrapper>
      <TabSelector />
      <CloseIconWrapper onClick={ () => dispatch(redirectToManage()) }>
        <CloseIcon />
      </CloseIconWrapper>
      {showTransparency && <Transparency />}
    </Wrapper>
  );
};

Header.propTypes = {
  showTransparency: PropTypes.bool.isRequired
};

export default Header;
