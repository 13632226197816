import styled from 'styled-components';
import { slideInLeft } from 'Styles/effects';

export const Wrapper = styled.div`
  background-color: #fafbfc;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 75%;
  height: calc(100% - 43px);
  padding: 30px;
  z-index: 1;
  ${slideInLeft};
  `;
Wrapper.displayName = 'Wrapper';

export const Close = styled.div`
  height: 37px;
  width: 37px;
  background-color: #edf2f7;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  float: right;
  &:after, &:before {
    content: '';
    margin: 10px;
    height: 4px;
    display: block;
    border-radius: 2px;
    background-color: #63758d;
  }
  &:after {
    transform: translateY(-7px) rotate(-135deg);
  }
  &:before {
    transform: translateY(7px) rotate(135deg);
  }
`;
Close.displayName = 'Close';

export const Hostnames = styled.div`
  text-align: left;
  margin: 10px 0px;
`;
Hostnames.displayName = 'Hostnames';

export const Hostname = styled.div`
  border-radius: 2px;
  background-color: #e3e7ec;
  font-family: SequelSans-RomanHead;
  font-size: 13.5px;
  line-height: 24px;
  letter-spacing: normal;
  color: #1c2243;
  display: inline-block;
  margin-right: 14px;
  padding: 0 10px;
  cursor: pointer;
`;
Hostname.displayName = 'Hostname';

export const VulnsGrouped = styled.div`
  border-radius: 2px;
`;
VulnsGrouped.displayName = 'VulnsGrouped';

export const PaginationWrapper = styled.div`
  position: absolute;
  top: 0;
`;
PaginationWrapper.displayName = 'PaginationWrapper';
