import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useResizeY } from 'Hooks/useResize';
import {
  MDButtonsWrapper,
  InputWrapper,
  Wrapper,
  Resizer,
  Title
} from './styled';
import MarkdownInput from './MarkdownInput';

const MarkdownEditor = ({
  title, value, placeholder, onBlur, disabled, plainText, defHeight = 270, minHeight = 60, uploadImage, onImageUpload
}) => {
  const [markdown, setMarkdown] = useState(value);
  const [isEditing, setEditing] = useState(false);

  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);

  const height = useResizeY(wrapperRef, resizerRef, defHeight, minHeight);

  return (
    <Wrapper>
      <Title children={ title } />
      <InputWrapper containerHeight={ height } ref={ wrapperRef }>
        <MarkdownInput
          value={ value }
          onBlur={ onBlur }
          markdown={ markdown }
          setMarkdown={ setMarkdown }
          isEditing={ isEditing }
          setEditing={ setEditing }
          placeholder={ placeholder }
          disabled={ disabled }
          plainText={ plainText }
          uploadImage={ uploadImage }
          onImageUpload={ onImageUpload }
        />
      </InputWrapper>
      <Resizer ref={ resizerRef } />
      <MDButtonsWrapper />
    </Wrapper>
  );
};

MarkdownEditor.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  plainText: PropTypes.bool,
  uploadImage: PropTypes.bool
};

MarkdownEditor.defaultProps = {
  value: '',
  placeholder: 'This field is empty. Click to edit.',
  disabled: false,
  title: '',
  plainText: false,
  uploadImage: false
};

export default MarkdownEditor;
