import api from 'services/api';

// **
// change password section
// **
export const SAVE_SETTINGS_CHANGE_PASSWORD_START = 'SAVE_SETTINGS_CHANGE_PASSWORD_START';
export const SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS = 'SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS';
export const SAVE_SETTINGS_CHANGE_PASSWORD_FAIL = 'SAVE_SETTINGS_CHANGE_PASSWORD_FAIL';

// **
// change password section
// **

export function changePassword (currentPassword, newPassword, repeatNewPassword) {
  return async (dispatch) => {
    dispatch({ type: SAVE_SETTINGS_CHANGE_PASSWORD_START });
    try {
      const response = await api.settings.changePassword(currentPassword, newPassword, repeatNewPassword);
      return dispatch({ type: SAVE_SETTINGS_CHANGE_PASSWORD_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_CHANGE_PASSWORD_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}
