import colors from 'Styles/colors';
import styled from 'styled-components';
import { Row } from 'Common/Components/Grid';

export const Wrapper = styled.div`
  border-radius: 3px;
  margin-bottom: 14px;
  margin-right: 14px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  user-select: none;
  color:  ${colors.white};
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 22px 0;
`;
Title.displayName = 'Title';

export const Value = styled.div`
  user-select: none;
  color: ${colors.white};
  font-size: 27px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
`;
Value.displayName = 'Value';

export const Name = styled.div`
  user-select: none;
  color: ${colors.grey7};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
`;
Name.displayName = 'Name';

export const StyledRow = styled(Row)`
  & > *:not(:last-child) {
    & > *:not(:last-child) {
      border-right: 1px dashed ${colors.blue2};
    }
  }
  align-items: center;
  justify-content: center;
`;
StyledRow.displayName = 'StyledRow';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';

export const ClickableRow = styled(Row)`
  cursor: pointer;
`;
ClickableRow.displayName = 'ClickableRow';
