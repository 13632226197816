import { TOGGLE_SIDEBAR, SET_SIDEBAR } from './actions';

const initialState = {
  isOpen: false
};

function sideBar (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        isOpen: !state.isOpen
      };
    }
    case SET_SIDEBAR: {
      return {
        ...state,
        isOpen: action.isOpen
      };
    }

    default: {
      return state;
    }
  }
}

export default sideBar;
