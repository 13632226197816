import React from 'react';
import copyToClipboard from 'utils/clipboard';
import { ClipboardWrapper, Icon } from '../Target/styled';

const Clipboard = ({ text }) => {
  const onClick = () => copyToClipboard(text);

  return (
    <ClipboardWrapper title="Copy">
      <Icon onClick={ onClick } />
    </ClipboardWrapper>
  );
};

export default Clipboard;
