import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Sort } from 'Images/sort.svg';

const Icon = styled.svg`
  width: 34px;
  height: 24px;
  padding-left: 13.5px;
  padding-right: 13.5px;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  `;

// eslint-disable-next-line import/prefer-default-export
export const SeverityHeader = () => (
  <Icon>
    <Sort />
  </Icon>
);
