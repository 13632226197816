import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Manage/components/CustomAttributesModal/constants';
import StringCustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/StringCustomField/index';
import MdCustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/MdCustomField/index';
import IntegerCustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/IntegerCustomField/index';
import ListCustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/ListCustomField/index';
import ChoiceCustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/ChoiceCustomField/index';
import DateField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/DateField';

const TYPE_TO_CONTENT = {
  [CUSTOM_ATTRIBUTE_TYPES.STRING]: StringCustomField,
  [CUSTOM_ATTRIBUTE_TYPES.INTEGER]: IntegerCustomField,
  [CUSTOM_ATTRIBUTE_TYPES.LIST]: ListCustomField,
  [CUSTOM_ATTRIBUTE_TYPES.MARKDOWN]: MdCustomField,
  [CUSTOM_ATTRIBUTE_TYPES.CHOICE]: ChoiceCustomField,
  [CUSTOM_ATTRIBUTE_TYPES.DATE]: DateField
};

export default TYPE_TO_CONTENT;
