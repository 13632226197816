export const GET_DATA_VULNS_SUCCESS = 'GET_DATA_VULNS_SUCCESS';
export const GET_DATA_VULNS_FAIL = 'GET_DATA_VULNS_FAIL';
export const GET_DATA_VULNS_START = 'GET_DATA_VULNS_START';
export const GET_DATA_SERVICES_SUCCESS = 'GET_DATA_SERVICES_SUCCESS';
export const GET_DATA_SERVICES_FAIL = 'GET_DATA_SERVICES_FAIL';
export const GET_DATA_SERVICES_START = 'GET_DATA_SERVICES_START';
export const GET_DATA_TOOLS_SUCCESS = 'GET_DATA_TOOLS_SUCCESS';
export const GET_DATA_TOOLS_FAIL = 'GET_DATA_TOOLS_FAIL';
export const GET_DATA_TOOLS_START = 'GET_DATA_TOOLS_START';
export const SHOW_HOST_DETAIL = 'SHOW_HOST_DETAIL';
export const HIDE_HOST_DETAIL = 'HIDE_HOST_DETAIL';
export const RESET_STATE_HOST_DETAIL = 'RESET_STATE_HOST_DETAIL';
export const HOST_ADD_EDIT_SERVICE_FAIL = 'HOST_ADD_EDIT_SERVICE_FAIL';
export const HOST_ADD_EDIT_SERVICE_RESET = 'HOST_ADD_EDIT_SERVICE_RESET';
export const HOST_ADD_EDIT_SERVICE_SUCCESS = 'HOST_ADD_EDIT_SERVICE_SUCCESS';
export const SELECT_SERVICE = 'SELECT_SERVICE';
export const UPDATE_VULN_ASSET_PREVIEW_SUCCESS = 'UPDATE_VULN_ASSET_PREVIEW_SUCCESS';
export const UPDATE_VULN_ASSET_PREVIEW_FAIL = 'UPDATE_VULN_ASSET_PREVIEW_FAIL';
export const UPDATE_VULN_ASSET_PREVIEW_START = 'UPDATE_VULN_ASSET_PREVIEW_START';
