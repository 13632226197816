import React, { useEffect } from 'react';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { CheckboxLabel, WrapperCheckbox } from 'Screens/Settings/common-styled';
import { selectLogMeLastWorkspaceUsed } from 'store/Settings/loginSettingsSelectors';
import { setLoginSettingsField, loadLoginSettings } from 'store/Settings/loginSettingsActions';
import { Wrapper, Title } from './styled';

const LoginSettings = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadLoginSettings());
  }, [dispatch]);

  const props = useSelector((state) => ({
    logMeLastWorkspaceUsed: selectLogMeLastWorkspaceUsed(state)
  }));

  const change = (field, value) => {
    dispatch(setLoginSettingsField(field, value));
  };

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'preferences.account.loginSettings.title' }) }</Title>
      <WrapperCheckbox>
        <CustomCheckbox id="logMeLastWorkspaceUsed" label="" handleCheckboxChange={ () => change('logMeLastWorkspaceUsed', !props.logMeLastWorkspaceUsed) } isChecked={ props.logMeLastWorkspaceUsed } />
        <CheckboxLabel>{intl.formatMessage({ id: 'preferences.account.loginSettings.allwaysLogMe.title' }) }</CheckboxLabel>
      </WrapperCheckbox>
    </Wrapper>
  );
};
export default LoginSettings;
