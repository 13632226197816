/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCurrentlySelectedWorkspace } from 'store/Workspace/selectors';
import {
  PreviewWrapper,
  StyledReactMarkdown
} from './styled';

const MarkdownEditor = ({
  value
}) => {
  const [markdown, setMarkdown] = useState(value);

  useEffect(() => {
    setMarkdown(value);
  }, [value]);

  const selectedWorkspace = useSelector(selectCurrentlySelectedWorkspace);

  const parseMD = (textBlock) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /\(evidence\:(\w+)\:(\d+)\:([a-zA-Z0-9\s_\\.\-\(:]+\.\w+)\)/g;

    return textBlock.replace(regex, (match) => {
      const filename = match.split(':')[3].slice(0, -1);
      const content = match.replace(regex, `![$1 With Id $2 Evidence ](/_api/v3/ws/${selectedWorkspace}/vulns/$2/attachment/${filename})`);
      return content;
    });
  };

  return (
    <PreviewWrapper>
      <StyledReactMarkdown>
        { parseMD(markdown) }
      </StyledReactMarkdown>
    </PreviewWrapper>
  );
};

MarkdownEditor.propTypes = {
  value: PropTypes.string
};

MarkdownEditor.defaultProps = {
  value: ''
};

export default MarkdownEditor;
