import colors from 'Styles/colors';

const ColorRole = {
  admin: '#A4247A',
  pentester: '#EA3158',
  client: '#0082ED',
  asset_owner: colors.grey2
};

const getBackgroundColor = (role, colors = ColorRole) => {
  if (!role) return '#67AE3F';

  return colors[role];
};

export default getBackgroundColor;
