import styled from 'styled-components';
import colors from 'Styles/colors';

const OwnerWrapper = styled.div`
  border-radius: 3px;
  background-color: ${colors.white};
  padding: 14px 20px 26px 20px;
  user-select: none;
  font-size: 13px;
  font-weight: 300;
  color: #5E6667;
  padding: 0px 20px 20px 20px;
`;
OwnerWrapper.displayName = 'OwnerWrapper';

export default OwnerWrapper;
