import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  C: colors.warmPurple,
  H: colors.redPink,
  M: colors.tangerine,
  L: colors.apple,
  I: colors.grey2,
  U: colors.grey4,
  disable: '#d6d6d6'
};

const SeverityIcon = styled.div`
  width: 24px;
  height: 18px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color:${({ type }) => sevColor[type]};
  border: solid 1px ${({ type }) => sevColor[type]};
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);

  font-size: 9px;
  font-weight: 900;
  text-align: center;
  color: ${colors.white};
`;

export default SeverityIcon;
