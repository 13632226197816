import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from 'Images/download-icon.svg';
import { TextItem, SubTitle } from '../styled';

export const Download = styled(DownloadIcon)`
  fill: #0082ed;
  width: 17.2px;
  height: 16.2px;
`;

export const EnableText = styled(TextItem)`
margin-bottom: 30px !important;
`;

export const DownloadTitle = styled(SubTitle)`
  margin-bottom: 15px;
`;
export const LoadTitle = styled(SubTitle)`
  margin-bottom: 20px;
`;

export const Text = styled.div`
font-size: 13.5px;
font-weight: 500;
color: #0082ed;
cursor: pointer;
margin: 10px 0 26px 20px;
`;
