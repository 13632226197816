import { copy, copyArray } from '../../Common/Functions/Copy';

import initialState from './initialState';
import * as types from './types';

function hostEditCreate (state = initialState, action) {
  switch (action.type) {
    case types.RESET_STATE_HOST_CREATE_UPDATE: {
      return {
        ...initialState
      };
    }

    case types.ADD_REPORT_HOST_CREATE_UPDATE: {
      const report = copyArray(state.report);
      report.push(action.file);

      return {
        ...state,
        report
      };
    }

    case types.REMOVE_REPORT_HOST_CREATE_UPDATE: {
      const report = copyArray(state.report);
      report.splice(action.index, 1);

      return {
        ...state,
        report
      };
    }

    case types.IMPORT_REPORT_HOST_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case types.IMPORT_REPORT_HOST_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        importSuccess: true,
        successMessage: action.message
      };
    }

    case types.IMPORT_REPORT_HOST_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case types.SET_FIELD_NAME_HOST_CREATE_UPDATE: {
      return {
        ...state,
        [action.fieldName]: action.value,
        errorMessage: ''
      };
    }

    case types.REMOVE_HOST_NAME_HOST_CREATE_UPDATE: {
      const { hostnames } = state;
      hostnames.splice(action.index, 1);

      return {
        ...state,
        hostnames,
        errorMessage: ''
      };
    }

    case types.ADD_HOST_NAME_HOST_CREATE_UPDATE: {
      const hostnames = state.hostnames || [];
      const newHostnames = [...hostnames, action.value];

      return {
        ...state,
        hostnames: newHostnames,
        hostnameToAdd: '',
        errorMessage: ''
      };
    }

    case types.SET_HOST_NAME_HOST_CREATE_UPDATE: {
      return {
        ...state,
        hostnameToAdd: action.value,
        errorMessage: ''
      };
    }

    case types.HOST_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true,
        errorMessage: ''
      };
    }

    case types.HOST_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case types.SET_HOST_CREATE_UPDATE: {
      return {
        ...state,
        isFetching: false,
        id: action.host.id,
        ip: action.host.ip,
        owned: action.host.owned,
        os: action.host.os,
        mac: action.host.mac,
        description: action.host.description,
        hostnames: action.host.hostnames,
        owner: action.host.owner,
        default_gateway: action.host.default_gateway,
        errorMessage: ''
      };
    }

    case types.SET_HOST_FOR_EDIT: {
      return {
        ...copy(action.host ? action.host : initialState)
      };
    }

    default:
      return state;
  }
}

export default hostEditCreate;
