import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Account from 'Screens/Settings/components/Account';
import { ReactComponent as AccountIcon } from 'Images/preferences_icons_Account.svg';
import { ReactComponent as AuthIcon } from 'Images/preferences_icons_Authentication.svg';
import { ReactComponent as CustomAtributesIcon } from 'Images/customAttributes.svg';
import Saml from 'Screens/Settings/components/Saml';
import Ldap from 'Screens/Settings/components/Ldap';
import {
  Wrapper,
  Tabs,
  TabGroup,
  Title,
  Subtitle,
  Item,
  TabContent
} from './styled';
import 'Screens/Settings/styles.scss';
import CustomAttributes from '../CustomAttributes';

const Menu = ({ onClose }) => {
  const intl = useIntl();
  const [visibleComponent, setVisibleComponent] = useState(<Account onClose={ onClose } />);
  const [active, setActive] = useState('account');

  const click = (key, component) => { setActive(key); setVisibleComponent(component); };

  const menuItems = [
    {
      title: intl.formatMessage({ id: 'preferences.general.title' }),
      items: [
        {
          key: 'account', title: intl.formatMessage({ id: 'preferences.account.title' }), click: () => { click('account', <Account onClose={ onClose } />); }, icon: <AccountIcon />
        },
        {
          key: 'customAttributes', title: intl.formatMessage({ id: 'preferences.customAttributes.title' }), click: () => { click('customAttributes', <CustomAttributes onClose={ onClose } />); }, icon: <CustomAtributesIcon />
        }
      ]
    },
    {
      title: intl.formatMessage({ id: 'preferences.authentication.title' }),
      canView: true,
      items: [
        {
          key: 'ldap', title: intl.formatMessage({ id: 'preferences.ldap.title' }), click: () => { click('ldap', <Ldap onClose={ onClose } />); }, icon: <AuthIcon />, canView: true
        },
        {
          key: 'saml', title: intl.formatMessage({ id: 'preferences.saml.title' }), click: () => { click('saml', <Saml onClose={ onClose } />); }, icon: <AuthIcon onClose={ onClose } />, canView: true
        }
      ]
    }
  ];

  return (
    <Wrapper>
      <Tabs>
        <Title>{intl.formatMessage({ id: 'preferences.title' })}</Title>
        { menuItems.map((group) => (
          <TabGroup key={ group.title }>
            <Subtitle>{group.title}</Subtitle>
            { group.items.map((item) => (
              <Item key={ item.key } active={ active === item.key } onClick={ item.click }>
                {item.icon}
                {' '}
                {item.title}
              </Item>
            ))}
          </TabGroup>
        ))}
      </Tabs>
      <TabContent>
        {visibleComponent}
      </TabContent>
    </Wrapper>
  );
};

Menu.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Menu;
