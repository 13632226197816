import React from 'react';
import { withRouter } from 'react-router-dom';
import { UserDropdownItem, CustomLink } from './styled';

const DropdownItem = ({
  Modal, action, link, children
}) => {
  const generateDropdownLink = (url, description) => (
    <UserDropdownItem>
      <CustomLink to={ url }>
        {description}
      </CustomLink>
    </UserDropdownItem>
  );

  const generateDropdownModal = (toggleModal, description) => <UserDropdownItem onClick={ toggleModal }>{description}</UserDropdownItem>;

  const generateDropdownAction = (action, description) => (
    <UserDropdownItem onClick={ () => action() }>{description}</UserDropdownItem>
  );

  let Component;
  if (Modal) {
    Component = generateDropdownModal(Modal.toggleModal, children);
  } if (action) {
    Component = generateDropdownAction(action, children);
  } if (link) {
    Component = generateDropdownLink(link, children);
  }

  return Component;
};

export default withRouter(DropdownItem);
