import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as ClipboardIcon } from 'Images/icon-clipboard.svg';
import IconButton from 'Common/Components/IconButton';
import { getToken, getManifest } from 'store/Agents/actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { Wrapper, Token, WrapperCountDown } from './styled';

const CountDown = ({ value, total }) => {
  const [anim, setAnim] = useState('');
  const [alpha, setAlpha] = useState(-1);

  useEffect(() => {
    const v = (1 - (value / total)) * 360;
    setAlpha(v);
  }, [value, total, setAlpha]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const v = (alpha + (360 / total)) % 360;
      if (alpha !== -1) setAlpha(v);
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [alpha, setAlpha, total]);

  useEffect(() => {
    const π = Math.PI;
    const α = alpha;
    const r = ((α * π) / 180);
    const x = Math.sin(r) * 125;
    const y = Math.cos(r) * (-125);
    const mid = (α > 180) ? 1 : 0;
    const newAnim = `M 0 0 v -125 A 125 125 1 ${
      mid} 1 ${
      x} ${
      y} z`;
    setAnim(newAnim);
  }, [alpha, setAnim]);

  if (alpha === -1) { return null; }

  return (
    <WrapperCountDown>
      <svg width="25" height="25" viewBox="0 0 250 250">
        <path id="border" transform="translate(125, 125)" d={ anim } />
      </svg>
    </WrapperCountDown>
  );
};

const Generator = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({
    workspaceSelected: selectCurrentWorkspace(state),
    token: state.agents.token,
    expiresIn: get(state, 'agents.tokenExpiresIn', 0),
    tokenDuration: get(state, 'agents.tokenDuration', 60)
  }));

  useEffect(() => {
    dispatch(getToken());
    dispatch(getManifest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeOut = 0;
    if (props.expiresIn > 0) {
      timeOut = setTimeout(() => dispatch(getToken()), props.expiresIn * 1000);
    }
    return () => timeOut && clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expiresIn]);

  return (
    <Wrapper>
      <CountDown value={ props.expiresIn } total={ props.tokenDuration } />
      <Token title={ props.token }>{ props.token }</Token>
      <CopyToClipboard text={ props.token }>
        <IconButton title="Copy" icon={ <ClipboardIcon /> } />
      </CopyToClipboard>
    </Wrapper>
  );
};
export default Generator;
