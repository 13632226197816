import React from 'react';
import PropTypes from 'prop-types';
import DropdownItem from './UserDropdownItem';
import { DropdownMenu } from './styled';

const UserDropdown = ({ modals }) => {
  const {
    Help, Extras, About, Preferences
  } = modals;

  return (
    <DropdownMenu>
      <DropdownItem Modal={ Preferences }>Preferences</DropdownItem>
      <hr className="h-menu-separator m-0" />
      <DropdownItem link="/api-definitions" >Faraday Api</DropdownItem>
      <DropdownItem Modal={ Help }>Help</DropdownItem>
      <DropdownItem Modal={ Extras }>Extras</DropdownItem>
      <DropdownItem Modal={ About }>About</DropdownItem>
      <DropdownItem link="/logout">Logout</DropdownItem>
    </DropdownMenu>
  );
};

UserDropdown.propTypes = {
  modals: PropTypes.shape({
    Extras: PropTypes.object.isRequired,
    Help: PropTypes.object.isRequired,
    About: PropTypes.object.isRequired,
    Preferences: PropTypes.object.isRequired
  }).isRequired
};

export default UserDropdown;
