import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from './styled';

const ModalConfirmation = ({
  show, handleSubmit, handleClose, entity, count, loading
}) => {
  const upperCaseTitle = entity.charAt(0).toUpperCase() + entity.slice(1);

  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{`Delete ${upperCaseTitle}(s)`}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{`You’re deleting ${count} ${upperCaseTitle}(s). This action cannot be undone. Are you sure you want to proceed?`}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ handleClose }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit } disabled={ loading }>
            Delete
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalConfirmation;
