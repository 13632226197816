import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as ErrorMark } from 'Images/error-mark.svg';
import { Label } from '../styled';

export const StyledLabel = styled(Label)`
  color: ${colors.redPink};
  margin-left: 10px;
`;
StyledLabel.displayName = 'StyledLabel';

export const CustomErrorMark = styled(ErrorMark)`
  cursor: default;
  flex: 0 0 25px;
  height: 25px;
  `;
CustomErrorMark.displayName = 'CustomErrorMark';
