/* eslint-disable react/jsx-key */
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { expandProcessingQueue, showProcessingQueue } from '../../actions';
import {
  Wrapper, HeaderMessage, Close, ArrowExpanded, ArrowCollapsed, Queue
} from './styled';

const Header = ({ showBody }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const toggleBodyVisibility = () => dispatch(expandProcessingQueue(!showBody));

  return (
    <Wrapper isShowingBody={ showBody }>
      <Queue />
      <HeaderMessage children={ intl.formatMessage({ id: 'uploadContext.title' }) } />
      {showBody ? <ArrowExpanded onClick={ toggleBodyVisibility } /> : <ArrowCollapsed onClick={ toggleBodyVisibility } />}
      <Close onClick={ () => dispatch(showProcessingQueue(false)) } />
    </Wrapper>
  );
};

export default Header;
