import BlueButton from 'Common/Components/BlueButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const CustomBlueButton = styled(BlueButton)`
  float: right;
  width: 89px;
  height: 34px;
`;
CustomBlueButton.displayName = 'CustomBlueButton';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
Title.displayName = 'Title';
