import React from 'react';
import Wrapper from './styled';
import ActionBar from './components/ActionBar';
import Grid from './components/Grid';

const Agents = () => (
  <Wrapper>
    <ActionBar />
    <Grid />
  </Wrapper>
);

export default Agents;
