import styled from 'styled-components';
import { ReactComponent as CheckMark } from 'Images/icon-toolbar-confirmed-on.svg';
import { ReactComponent as CloseSVG } from 'Images/icon-close-without-background.svg';
import { Label } from '../styled';
import colors from 'Styles/colors';

export const StyledLabel = styled(Label)`
  color: #0082ec;
  margin-left: 10px;
`;
StyledLabel.displayName = 'StyledLabel';

export const CustomCheckMark = styled(CheckMark)`
  cursor: default;
  flex: 0 0 25px;
  height: 25px;
  `;
CustomCheckMark.displayName = 'CustomCheckMark';

export const Delete = styled(CloseSVG)`
  position: absolute;
  top: 14px;
  right: 7px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  
  path {
    fill: ${colors.blueGrey};
  }
`;
Delete.displayName = 'Delete';
