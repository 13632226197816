import React from 'react';
import { useSelector } from 'react-redux';

import startCase from 'lodash/startCase';
import {
  selectAgentTypes, selectDraftNewAgentName, selectDraftNewAgentServer, selectDraftNewAgentTools, selectToolsSelected
} from 'store/Agents/selectors';
import { selectWsPort } from 'store/Faraday/selectors';
import Url from 'url-parse';
import { Download, Text } from './styled';

const Dispatcher = () => {
  const draftNewAgentName = useSelector(selectDraftNewAgentName);
  const draftNewAgentServer = useSelector(selectDraftNewAgentServer);
  const agentTypes = useSelector(selectAgentTypes);
  const toolsSelected = useSelector(selectToolsSelected);
  const stepChooseTools = useSelector(selectDraftNewAgentTools);
  const wsPort = useSelector(selectWsPort);

  const getParams = (items) => {
    let result = '';
    Object.keys(items).forEach((paramName) => {
      const values = items[paramName];
      result += `         ${paramName}:\n`;
      Object.keys(values).forEach((key) => {
        result += `           ${key.toLowerCase()}: ${values[key]}\n`;
      });
    });
    return result;
  };

  const getEnvVars = (name) => {
    const { variables } = stepChooseTools.find((i) => i.name === name);
    const variableList = Object.keys(variables);
    if (variableList.length === 0) return ' {}';
    return `\n${variableList.map((key) => {
      if (key.includes('PULL_INTERVAL')) return `         ${key.toUpperCase()}:  '${variables[key]}'`;
      else return `         ${key.toUpperCase()}:  ${variables[key]}`;
      }).join('\n')}`;
  };

  const getToolConf = (tool) => {
    if (!tool) return;
    return (
      `     ${`${tool.name}:\n` +
      '       max_size: 65536\n' +
      '       params:\n'}${
        getParams(tool.arguments)
      }` +
      `       repo_name: ${tool.name}\n` +
      `       repo_executor: ${tool.name}.py\n` +
      `       varenvs:${
        getEnvVars(tool.name)
      }\n`);
  };

  const getApiPort = (ssl, port) => {
    if (port) return port;
    if (ssl) return 443;
    return 5985;
  };

  const getWebSocketPort = (ssl, port) => {
    if (port) return wsPort;
    if (ssl) return 443;
    return 80;
  };

  const getFileAsString = (agentName, agentServer) => {
    const newUrl = agentServer.indexOf('http') === -1 ? `http://${agentServer}` : agentServer;
    const url = new Url(newUrl);

    const host = url.hostname;
    const ssl = (url.protocol.indexOf('https') > -1);
    const port = url.port;
    const apiPort = getApiPort(ssl, port);
    const webSocketPort = getWebSocketPort(ssl, port);
    const tools = Object.entries(toolsSelected).map((tool) => getToolConf(agentTypes[tool[1].name])).join('');

    const fullText = '' +
      ' agent:\n' +
      `   agent_name: ${agentName}\n` +
      '   executors:\n' +
      `${tools}\n` +
      ' server:\n' +
      `   api_port: '${apiPort}'\n` +
      `   host: ${host}\n` +
      `   ssl: '${startCase(ssl)}'\n` +
      '   ssl_cert: \'\'\n' +
      '   ssl_ignore: \'False\'\n' +
      `   websocket_port: '${webSocketPort}'\n`;

    return fullText;
  };

  const saveAgent = (agentName, agentServer) => {
    const element = document.createElement('a');
    const fileTxt = getFileAsString(agentName, agentServer);
    const file = new Blob([fileTxt], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'dispatcher.yaml';
    document.body.appendChild(element);
    element.click();
  };
  return (
    <Text id="modal-agents-download-dispatcher" onClick={ () => saveAgent(draftNewAgentName, draftNewAgentServer) }>
      <Download />
      dispatcher.yaml
    </Text>
  );
};

export default Dispatcher;
