import React, { useState } from 'react';
import Header from '../Header';
import {
  Wrapper, SelectTitle, ContentWrapper
} from '../styled';
import {
  Title, Flex
} from './styled';
import { DockerTab, PythonTab } from './InfoTabs';

const Requirements = ({ backStep, onClose }) => {
  const [isDockerSelected, setIsDockerSelected] = useState(true);
  return (
    <Wrapper>
      <Header back={ backStep } cancel={ onClose } next={ backStep } />
      <ContentWrapper>
        <Title>System requirements</Title>
        <Flex>
          <SelectTitle selected={ isDockerSelected } onClick={ () => setIsDockerSelected(true) }>Docker</SelectTitle>
          <SelectTitle selected={ !isDockerSelected } onClick={ () => setIsDockerSelected(false) }>Python</SelectTitle>
        </Flex>
        {isDockerSelected ? <DockerTab /> : <PythonTab />}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Requirements;
