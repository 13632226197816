export const impact = [
  { name: 'accountability', value: false },
  { name: 'availability', value: false },
  { name: 'confidentiality', value: false },
  { name: 'integrity', value: false }
];

export const easeOfResolution = [
  { name: 'trivial', desc: 'Trivial' },
  { name: 'simple', desc: 'Simple' },
  { name: 'moderate', desc: 'Moderate' },
  { name: 'difficult', desc: 'Difficult' },
  { name: 'infeasible', desc: 'Infeasible' }
];

export const severities = [
  { name: 'critical', desc: 'CRITICAL' },
  { name: 'high', desc: 'HIGH' },
  { name: 'medium', desc: 'MEDIUM' },
  { name: 'low', desc: 'LOW' },
  { name: 'informational', desc: 'INFORMATIONAL' },
  { name: 'unclassified', desc: 'UNCLASSIFIED' }
];

export const vulnerabilityTypes = [
  { name: 'Vulnerability', desc: ' Vulnerability' },
  { name: 'VulnerabilityWeb', desc: ' Web Vulnerability' }
];

export const products = [
  { name: 'Faraday', desc: 'Faraday' },
  { name: 'Metasploit', desc: 'Metasploit' },
  { name: 'Nessus', desc: 'Nessus' },
  { name: 'Acunetix', desc: 'Acunetix' },
  { name: 'Burp', desc: 'Burp' },
  { name: 'Canvas', desc: 'Canvas' },
  { name: 'Maltego', desc: 'Maltego' },
  { name: 'Core', desc: 'label' },
  { name: 'Nexpose', desc: 'Nexpose' },
  { name: 'Netsparker', desc: 'Netsparker' },
  { name: 'Retina', desc: 'Retina' },
  { name: 'Onapsis', desc: ' Platform' },
  { name: 'Qualys', desc: 'Qualys' },
  { name: 'Fortify', desc: 'Fortify' },
  { name: 'Checkmarx', desc: 'Checkmarx' },
  { name: 'Other', desc: 'Other' }
];

export const status = [
  { name: 'open', desc: 'Open' },
  { name: 'closed', desc: 'Closed' },
  { name: 're-opened', desc: 'Re-Opened' },
  { name: 'risk-accepted', desc: 'Risk-Accepted' }
];

export const tableMaxPages = 3;

export const methodOptions = [
  { name: 'GET', desc: 'GET' },
  { name: 'POST', desc: 'POST' },
  { name: 'PUT', desc: 'PUT' },
  { name: 'DELETE', desc: 'DELETE' },
  { name: 'PATCH', desc: 'PATCH' },
  { name: 'HEAD', desc: 'HEAD' },
  { name: 'CONNECT', desc: 'CONNECT' },
  { name: 'OPTIONS', desc: 'OPTIONS' }
];
