import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as TrashIcon } from 'Images/tool_trash.svg';
import { removeTool } from 'store/Agents/actions';
import Wrapper from './styled';

const DeleteButton = ({ tool }) => {
  const dispatch = useDispatch();
  return (
    <Wrapper id={ `modal-agents-tools-delete-icon-${tool}` } onClick={ () => dispatch(removeTool(tool)) }>
      <TrashIcon />
    </Wrapper>
  );
};

export default DeleteButton;
