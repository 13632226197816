import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getManifest, getUserAgents } from 'store/Agents/actions';
import { selectToken, selectTokenExpiration } from 'store/Agents/selectors';
import {
  Wrapper, Title, TextItem, Flex, SelectTitle, ContentWrapper
} from '../styled';
import { EnableText, DownloadTitle, LoadTitle } from './styled';
import { DockerTab, PythonTab } from './InfoTabs';
import Header from '../Header';
import Dispatcher from './dispatcher';

const SaveFinish = ({ prevStep, onClose }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const expiresIn = useSelector(selectTokenExpiration);
  useEffect(() => {
    dispatch(getToken());
    dispatch(getManifest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeOut = 0;
    if (expiresIn > 0) {
      timeOut = setTimeout(() => dispatch(getToken()), expiresIn * 1000);
    }
    return () => timeOut && clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresIn]);
  const [isDockerSelected, setIsDockerSelected] = useState(true);
  const onFinish = () => {
    onClose();
    dispatch(getUserAgents());
  };

  return (
    <Wrapper>
      <Header back={ prevStep } finish={ onFinish } />
      <ContentWrapper>
        <Title>Save and finish</Title>
        <EnableText>Enable your new Agent with the generated configuration</EnableText>
        <DownloadTitle>1 - Download the configuration file </DownloadTitle>
        <Dispatcher />
        <LoadTitle>2 - Load config file and enable Agent from command line</LoadTitle>
        <Flex>
          <SelectTitle selected={ isDockerSelected } onClick={ () => setIsDockerSelected(true) }>Docker</SelectTitle>
          <SelectTitle selected={ !isDockerSelected } onClick={ () => setIsDockerSelected(false) }>Python</SelectTitle>
        </Flex>
        {isDockerSelected ? <DockerTab token={ token } /> : <PythonTab token={ token } />}
        <TextItem>Reload the Agents tab in Faraday’s web ui to view the updated list.</TextItem>
      </ContentWrapper>
    </Wrapper>
  );
};

export default SaveFinish;
