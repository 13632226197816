import React from 'react';
import { VictoryStack, VictoryBar, VictoryTooltip } from 'victory';
import { Wrapper } from './styled';
import Flyout from './components/Flyout';

const Histogram = ({ data }) => (
  <Wrapper>
    {data &&
        (
          <VictoryStack colorScale={ ['#f59220', '#ea3158', '#a4247a'] } height={ 50 } padding={ 10 }>
            <VictoryBar
              data={ data }
              x="date"
              y="medium"
              labels={ () => '' }
              labelComponent={ (
                <VictoryTooltip
                  flyoutComponent={ <Flyout data={ data } /> }
                />
            ) }
              barWidth={ 15 }
            />
            <VictoryBar
              data={ data }
              x="date"
              y="high"
              labels={ () => '' }
              labelComponent={ (
                <VictoryTooltip
                  flyoutComponent={ <Flyout data={ data } /> }
                />
            ) }
              barWidth={ 15 }
            />
            <VictoryBar
              data={ data }
              x="date"
              y="critical"
              labels={ () => '' }
              labelComponent={ (
                <VictoryTooltip
                  flyoutComponent={ <Flyout data={ data } /> }
                />
            ) }
              barWidth={ 15 }
            />
          </VictoryStack>
        )}
  </Wrapper>
);

export default Histogram;
