import styled from 'styled-components';

export const Capitalized = styled.p`
  text-transform: capitalize;
`;

export const Text = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-size: 14px;
  font-weight: 600;
  color: #1c4566;
  margin-left: 14px;
  max-width: 400px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 2px;
`;

export const Content = styled.div`
    color: #1c2243;
    display: flex;
    justify-content: center;
    align-items: center;

    & p{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: #1c4566;
        letter-spacing: -0.1px;
        margin-left: 14px;
        max-width: 400px !important;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    & div {
        margin-left: 14px;
    }
`;
