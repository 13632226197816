import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: auto;
  padding-right: 5px;
  user-select: none;
  height: 98%;
  
  > :not(:last-child){
  margin-bottom: 26px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`

  > :not(:last-child) {
     margin-bottom: 21px;
  }
`;

Content.displayName = 'Content';

export const NoFieldsText = styled.span`
  font-size: 12.5px;
  line-height: 1.76;
  color: ${colors.grey19};
  user-select: none;
`;

NoFieldsText.displayName = 'NoFieldsText';
