import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { getData } from 'Screens/KnowledgeBase/actions/Actions';

const RefreshButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => (dispatch(getData()));

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ handleClick } />
  );
};

export default RefreshButton;
