import styled from 'styled-components';

export const AddMore = styled.div`
  margin-top: 10px;
  cursor:pointer;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #0082ed;

  svg {
    margin-right: 5px;
    font-size: 0.9em;
    position: relative;
    top: -2px;
  }

  label {
    cursor:pointer;
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: #0082ed;
  }
`;
AddMore.displayName = 'AddMore';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0px;
  border: solid 1px #d9e4ef;
`;
Separator.displayName = 'Separator';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';
