import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton';

export const Wrapper = styled.div`
display:flex;
padding: 34px 0;
margin-right: 34px;
`;

export const SecondContainer = styled.div`
  margin-left: auto;
`;

export const Next = styled(BlueButton)`
  margin-left: 10px;
`;
