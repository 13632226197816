import get from 'lodash/get';

export const selectHostDetail = (state) => get(state, 'hostDetail.host', null);
export const selectHostDetailId = (state) => get(state, 'hostDetail.host._id', null);

export const selectIsFetching = (state) => get(state, 'hostDetail.vulns.isFetching', false);
export const selectVulns = (state) => get(state, 'hostDetail.vulns.data', []);
export const selectFields = (state) => get(state, 'hostDetail.vulns.fields', []);
export const selectVulnsCount = (state) => get(state, 'hostDetail.vulns.count', 0);

export const selectShowHostDetail = (state) => {
  const show = get(state, 'hostDetail.host', null);
  return !!show;
};

export const selectSelectedService = (state) => get(state, 'hostDetail.createOrEditService.selectedService', null);

export const selectIsFetchingServices = (state) => get(state, 'hostDetail.services.isFetching', false);
export const selectServices = (state) => get(state, 'hostDetail.services.data', []);
export const selectServicesFields = (state) => get(state, 'hostDetail.services.fields', []);
