/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { getCustomAttributes } from 'store/CustomAttributes/actions';
import {
  resetErrorValue,
  resetState,
  showManageLeftFilters,
  hideVulnerabilityDetail,
  loadVulnerabilityDetail
} from 'store/Manage/actions';
import {
  clearAdvancedFilter, getCustomFilters, resetFilters
} from 'store/Filters/actions';
import { getSelectedEntity } from 'store/Filters/selectors';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import selectTourFinished from 'store/Preferences/Tour/selectors';
import { selectError, selectErrorMessage, selectShowDetail, selectVulnsCount } from 'store/Manage/selectors';
import { ManageTour } from 'Common/Components/Tour';
import { Wrapper, TableContainer } from './styled';
import ManageTable from './components/ManageTable';
import VulnerabilityDetail from './components/VulnerabilityDetail';
import CustomActionBar from './components/CustomActionBar';
import FilterSideBar from './components/FilterSideBar';
import TemplateModal from './components/ManageTable/components/ContextMenu/components/TemplateModal';
import 'Screens/Manage/styles/manageStyles.scss';
import selectModal from 'store/modals/selectors';
import { MODAL_MOVE_VULN_HOST } from 'store/modals/modals';
import { MoveVulnModal } from './components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/HostData';

const Manage = ({ params }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const selectedEntity = useSelector(getSelectedEntity);
  const tourFinished = useSelector(selectTourFinished);
  const showHostFilters = useSelector(selectShowHostLeftFilters);
  const totalVulns = useSelector(selectVulnsCount);
  const showDetail = useSelector(selectShowDetail);
  const showMoveVulnModal = useSelector((state) => selectModal(state, MODAL_MOVE_VULN_HOST));

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadVulnerabilityDetail(id));
    else dispatch(hideVulnerabilityDetail());
  }, [params]);

  useEffect(() => {
    dispatch(getCustomAttributes());
    dispatch(getCustomFilters());
    if (showHostFilters) dispatch(showManageLeftFilters(true));
    else dispatch(showManageLeftFilters(false));
  }, [showHostFilters, selectedEntity, dispatch]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => () => {
    dispatch(resetState());
    dispatch(resetFilters('vulns'));
    dispatch(clearAdvancedFilter('vulns'));
  }, [dispatch]);

  const emptyState = totalVulns === 0 && tourFinished;

  return (
    <Wrapper>
      { !emptyState && <CustomActionBar /> }
      <TableContainer>
        <ManageTable />
        <FilterSideBar entity="vulns" />
        { showDetail && <VulnerabilityDetail /> }
        <TemplateModal />
      </TableContainer>
      <ErrorDialog visible={ error } message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } />
      <ManageTour />
      {showMoveVulnModal && <MoveVulnModal />}
    </Wrapper>
  );
};

Manage.propTypes = {
  params: PropTypes.object.isRequired
};

export default Manage;
