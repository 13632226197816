import styled from 'styled-components';

export const Wrapper = styled.div``;
Wrapper.displayName = 'Wrapper';

export const Fields = styled.div`
  & > * {
    margin-bottom: 20px;
  }
`;
Fields.displayName = 'Fields';
