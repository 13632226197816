import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin: 26px 0;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 0.97;
  text-align: left;
  color: ${colors.dark2};
  display: inline-block;
`;
Title.displayName = 'Title';
