import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

class CustomCheckbox extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isChecked: false
    };

    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
  }

  toggleCheckboxChange () {
    const { handleCheckboxChange, label } = this.props;
    this.setState(({ isChecked }) => (
      {
        isChecked: !isChecked
      }
    ));

    handleCheckboxChange(label);
  }

  render () {
    const { label, isChecked, id } = this.props;

    return (
      <label className="checkbox-container" id={ id }>
        <input
          type="checkbox"
          value={ label }
          checked={ isChecked }
          onChange={ this.toggleCheckboxChange }
        />
        <span className="checkmark" />
      </label>
    );
  }
}

CustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default CustomCheckbox;
