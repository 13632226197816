import styled from 'styled-components';
import colors from 'Styles/colors';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  line-height: 0.84;
  color: ${colors.dark2};
  margin-bottom: 20px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  padding: 17px 0 0 0;
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.dark2};
`;
Subtitle.displayName = 'Subtitle';

export const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  .checkbox-container {
    display: inline;
    margin-bottom: 0px;
  }
`;
WrapperCheckbox.displayName = 'WrapperCheckbox';

export const CheckboxLabel = styled.div`
  font-size: 13.5px;
  line-height: 1.04;
  text-align: left;
  font-weight: 400;
`;
CheckboxLabel.displayName = 'CheckboxLabel';

export const Wrapper = styled.div`
  width: 900px;
  height: 813px;
  background-color: ${colors.white};
  padding: 0 !important;
`;
Wrapper.displayName = 'Wrapper';
