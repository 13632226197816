import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const Swagger = () => {
  return <SwaggerUI
    url="/_api/v3/swagger"
  />;
};

export default Swagger;
