/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirect, selectShowGridView } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { stopRedirect, selectWorkspace } from 'Screens/Workspaces/actions/Actions';
import { setSelectedEntity, resetFilters } from 'store/Filters/actions';
import Table from './components/Table';
import WSActionBar from './components/WSActionBar';
import Wrapper from './styled';
import GridView from './components/GridView';
import DeactivateConfirmationModal from './components/GridView/components/ DeactivateConfirmationModal';
import ModalWarning from 'Common/Components/ModalWarning';

const Workspace = () => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const redirect = useSelector(selectRedirect);
  const showGridView = useSelector(selectShowGridView);
  const [showWarning, setShowWarning] = useState();
  const [showConfirmation, setShowConfirmation] = useState({ show: false, ws: '' });

  useEffect(() => () => {
    dispatch(resetFilters('workspaces'));
    dispatch(setSelectedEntity(''));
    dispatch(selectWorkspace({
      rowSelected: {},
      newRowsSelected: [],
      lastSelected: -1,
      lastIndexForRange: -1
    }));
  }, [dispatch]);

  if (redirect) {
    dispatch(stopRedirect());
    return <Redirect to={ `/manage/${currentWorkspace}` } />;
  }

  return (
    <Wrapper>
      <WSActionBar />
      { showGridView
        ? <GridView setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />
        : <Table setShowConfirmation={ setShowConfirmation } setShowWarning={ setShowWarning } />
      }
      { showConfirmation.show ? <DeactivateConfirmationModal onCloseCallback={ () => setShowConfirmation({ show: false, name: '' }) } show={ showConfirmation.show } ws={ showConfirmation.ws } /> : null}
      { showWarning && <ModalWarning onCloseCallback={ () => setShowWarning(false) } title={ 'Deactivate Workspace' } description={ 'The Workspace you´re currently using can´t be deactivated. Switch to a different Workspace and retry.' } /> }
    </Wrapper>
  );
};

export default Workspace;
