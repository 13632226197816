import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Image = styled.img`
  max-width: 573px;
  width: 100%;
`;
Image.displayName = 'Image';

export const Label = styled.div`
  font-size: 15px;
  color: ${colors.blueGrey};
  padding-bottom: 21px;
  padding-top: 13px;
`;
Label.displayName = 'Label';

export const ButtonLink = styled.button`
  color: ${colors.blueCerulean};
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 5px;
`;
ButtonLink.displayName = 'ButtonLink';
