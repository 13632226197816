/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FaPlus as Plus } from 'react-icons/fa';
import {
  Wrapper, DataContainer, DataItem, Text, Span, TrashIcon, Input
} from './styled';
import './styles.scss';

const isOdd = (n) => n % 2 !== 0;

class CustomList extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onAddHandler = this.onAddHandler.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      value: ''
    };
  }

  componentWillUnmount () {
    this.setState({ value: '' });
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    if (this.props.setItem) this.props.setItem(v);
  }

  onRemoveHandler (v) {
    const index = this.props.listData.indexOf(v);
    this.props.removeItem(index);
  }

  onAddHandler () {
    if (this.state.value !== '') {
      this.props.addItem(this.state.value);
      this.setState({ value: '' });
    }
  }

  handleKeyPress (event) {
    if (event.key === 'Enter') {
      this.onAddHandler();
    }
  }

  render () {
    const { value } = this.state;
    const {
      type,
      placeholder,
      title,
      width,
      listData,
      responsive,
      height,
      className
    } = this.props;

    return (
      <Wrapper className={ className || title.toLowerCase().replace(' ', '-') }>
        { title && <div className="input-list-title">{title}</div> }
        <Input
          className={ `input-list ${responsive ? 'responsive' : ''} ${value.length > 0 ? 'full' : ''}` }
          value={ value }
          placeholder={ placeholder }
          type={ type }
          onChange={ (e) => this.onChangeHandler(e.target.value) }
          onKeyPress={ this.handleKeyPress }
          height={ height }
        />
        <Span
          className="action-container"
          onClick={ () => this.onAddHandler() }
          onKeyDown={ () => { } }
          role="button"
          aria-label="Add Option"
          tabIndex="0"
          type="button"
          responsive={ responsive }
          hasTitle={ title }
        >
          <Plus color="#515a65" className="input-list-icon" />
        </Span>
        {
          listData.length > 0 &&
          (
            <DataContainer width={ width } responsive={ responsive }>
              { listData.map((item, i) => (
                item && <DataItem
                  className="data-item"
                  responsive={ responsive }
                  key={ `di_${Math.random().toString(36).substr(2, 9)}` }
                  width={ width }
                  isOdd={ isOdd(i) }
                >
                  <Text>{item}</Text>
                  <span
                    className="action-container"
                    onClick={ () => { this.onRemoveHandler(item); } }
                    onKeyDown={ () => {} }
                    role="button"
                    aria-label="Remove Option"
                    tabIndex="0"
                    type="button"
                  >
                    <TrashIcon />
                  </span>
                        </DataItem>
              )) }
            </DataContainer>
          )
        }
      </Wrapper>
    );
  }
}

CustomList.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  setItem: PropTypes.func,
  listData: PropTypes.array,
  responsive: PropTypes.bool
};

CustomList.defaultProps = {
  type: 'text',
  placeholder: 'Add a value',
  title: '',
  width: '400px',
  addItem: null,
  removeItem: null,
  setItem: null,
  listData: [],
  responsive: false
};

export default withRouter(CustomList);
