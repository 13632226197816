/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { Col, Row } from 'Common/Components/Grid/index';
import {
  VictoryPie, Slice, VictoryContainer, VictoryTooltip, Selection
} from 'victory';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import colors from 'Styles/colors';
import { redirect } from 'store/Router/actions';
import {
  Wrapper, Title, Empty, ContentWrapper, PieWrapper, References, Reference,
  FlyoutWrapper, Status, Value
} from './styled';
import formatNumber from 'Common/Functions/FormatNumber';

const StatusChart = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => get(state, 'dashboard.tools[3].data.stats', {}));
  const workspaceSelected = useSelector((state) => selectCurrentWorkspace(state));

  const data = [];
  if (stats.opened_vulns > 0) data.push({ id: 'open', name: 'Open', value: stats.opened_vulns });
  if (stats.closed_vulns > 0) data.push({ id: 'closed', name: 'Closed', value: stats.closed_vulns });
  if (stats.re_opened_vulns > 0) data.push({ id: 're-opened', name: 'Re-opened', value: stats.re_opened_vulns });
  if (stats.risk_accepted_vulns > 0) data.push({ id: 'risk-accepted', name: 'Risk Accepted', value: stats.risk_accepted_vulns });

  const sliceColor = (name) => {
    if (name === 'Open') return colors.warmPurple;
    else if (name === 'Closed') return colors.grey2;
    else if (name === 'Re-opened') return colors.redPink;
    else return colors.apple;
  };

  const handleClick = (status) => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilitiesByStatus(status)));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  const CustomSlice = (props) => {
    return (
      <Slice
        { ...props }
        style={ { ...props.style } }
        events={ {
          onClick: (e) => {
            handleClick(props.datum.id);
          },
          onMouseOver: (e) => {
            if (props.events.onMouseOver) {
              props.events.onMouseOver(e);
            }
          },
          onMouseOut: (e) => {
            if (props.events.onMouseOut) {
              props.events.onMouseOut(e);
            }
          }
        } }
      />
    );
  };

  const Flyout = ({ props }) => {
    return (
        <g>
          <foreignObject x={ props.x - 35 } y={ props.y - 60 } width="120" height="60">
            <FlyoutWrapper>
              <Status status={ props.datum.id }>{props.datum.name}</Status>
              <Value>{formatNumber(props.datum.value)}</Value>
            </FlyoutWrapper>
          </foreignObject>
        </g>
    );
  };

  const CustomLabel = (props = {}) => {
    return (
      <g>
        <VictoryTooltip
          { ...props }
          flyoutComponent={ <Flyout props={ props } /> }
        />
      </g>
    );
  };
  CustomLabel.defaultEvents = VictoryTooltip.defaultEvents;

  return (
    <Wrapper>
      <Row>
        <Col><Title>Vulnerabilities by status</Title></Col>
      </Row>
      { data.length > 0
        ? (<ContentWrapper>
          <PieWrapper>
            <VictoryPie
              dataComponent={ <CustomSlice /> }
              data={ data }
              x="name"
              y="value"
              style={ { data: { fill: ({ datum }) => sliceColor(datum.name) } } }
              height={ 220 }
              width={ 300 }
              radius={ 75 }
              innerRadius={ 55 }
              labels={ () => '' }
              labelComponent={ <CustomLabel /> }
              events={ [
                {
                  target: 'data',
                  eventHandlers: {
                    onMouseOver: evt => {
                      const { x, y } = Selection.getSVGEventCoordinates(evt);
                      return {
                        target: 'labels',
                        mutation: () => ({ x, y, active: true })
                      };
                    },
                    onMouseMove: evt => {
                      const { x, y } = Selection.getSVGEventCoordinates(evt);
                      return {
                        target: 'labels',
                        mutation: () => ({ x, y, active: true })
                      };
                    },
                    onMouseOut: () => {
                      return { target: 'labels', mutation: () => ({ active: false }) };
                    }
                  }
                }
              ] }
              containerComponent={ <VictoryContainer responsive /> }
            />
          </PieWrapper>
          <References>
            {data.map((status) => <Reference onClick={ () => handleClick(status.id) } status={ status.id } key={ status.id }>{ status.name }</Reference>)}
          </References>
           </ContentWrapper>
          )
        : <Empty>No vulnerabilities has been found yet.</Empty> }
    </Wrapper>
  );
};

export default StatusChart;
