import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export const Tabs = styled.div`
  display: flex;
  padding-right: 13px;
  & > *:not(:last-child) {
    border-style: solid;
    border-color: #e4e9ee;
    border-width: 1px 0px 1px 1px;
  }
  & > *:last-child {
    border-style: solid;
    border-color: #e4e9ee;
    border-width: 1px 1px 1px 1px;
  }

`;

Tabs.displayName = 'Tabs';

export const Tab = styled.div`
  cursor: pointer;
  font-size: 14px;
  height: 42px;
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 100%;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ active }) => active ? colors.dark3 : colors.grey5};
  background: ${({ active }) => active ? colors.white : colors.white1};
`;

Tab.displayName = 'Tab';
