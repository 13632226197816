import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  overflow: scroll;
  height: 100%;
  justify-content: center;
`;
Wrapper.displayName = 'Wrapper';
