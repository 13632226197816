import React from 'react';
import {
  getSelectedEntity, selectIsAdvancedMode, selectFilterError, selectFilters
} from 'store/Filters/selectors';
import {
  setAdvancedFilter, setAdvancedMode, clearFilters, clearAdvancedFilter
} from 'store/Filters/actions';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { ENTITIES } from 'store/Filters/entitiesConstants';
import { AdvancedModeWrapper, AdvancedModeLabel } from './styled';

const AdvancedModeSelector = () => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  const dispatch = useDispatch();
  const selectedEntity = useSelector(getSelectedEntity);
  const filterError = useSelector((state) => selectFilterError(selectedEntity, state));
  const searchFunction = get(ENTITIES, `${selectedEntity}.searchFunction`, null);
  const basicFilters = useSelector((state) => selectFilters(selectedEntity, state));

  const toggleMode = () => {
    if (selectedEntity) {
      if (isAdvancedMode) {
        dispatch(clearAdvancedFilter(selectedEntity));
      } else if (basicFilters.length > 0) {
        // We have to 'translate' basic filter into an advanced one
        dispatch(setAdvancedFilter(selectedEntity, JSON.stringify(basicFilters[0])));
        dispatch(clearFilters(selectedEntity));
      }
      dispatch(searchFunction());
    }
    dispatch(setAdvancedMode(!isAdvancedMode));
  };

  return !filterError && (
    <AdvancedModeWrapper>
      <AdvancedModeLabel onClick={ toggleMode }>
        { isAdvancedMode ? 'Basic search' : 'Advanced search' }
      </AdvancedModeLabel>
    </AdvancedModeWrapper>
  );
};

export default AdvancedModeSelector;
