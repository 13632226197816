import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { RefreshIcon } from './styled';
import { newGetVulns } from 'store/Manage/filterActions';

const RefreshButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => (dispatch(newGetVulns()));

  return (
    <IconButton icon={ <RefreshIcon /> } title="Refresh" onClick={ handleClick } />
  );
};

export default RefreshButton;
