/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FaChevronDown as ArrowDown } from 'react-icons/fa';
import {
  Wrapper,
  DataContainer,
  DataItem,
  SearchAction,
  Hostname,
  ServiceData,
  ServiceName,
  Port
} from './styled';
import './styles.scss';

class InputSearch extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.parseTitle = this.parseTitle.bind(this);

    this.state = {
      value: '',
      showData: false
    };
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    this.setState({ showData: true });
    this.props.onChange(v);
  }

  onSelectHandler (item) {
    this.setState({ value: '' });
    this.setState({ showData: false });
    this.props.onSelect(item);
  }

  parseTitle (item, raw) {
    if (item.type && item.type === 'Host') {
      const hostname = `${item.hostnames.length > 0 ? `(${item.hostnames[0]})` : ''}`;
      return `${item.name} ${hostname}`;
    }
    if (item.type && item.type === 'Service') {
      const hData = item.hostnameData.length > 0 ? `(${item.hostnameData})` : '';
      return `${item.hostname} ${hData} ${raw ? item.name : ''}`;
    }

    return item.name;
  }

  render () {
    const { value, showData } = this.state;
    const {
      type,
      data,
      placeholder,
      searchAction,
      functionAction
    } = this.props;

    const filteredData = data.filter((it) => ((it.name.toLowerCase().includes(value.toLowerCase()) ||
    (it.type === 'Host' &&
    it.hostnames && it.hostnames.filter((element) => element.includes(value.toLowerCase())).length > 0))));

    return (
      <Wrapper
        className="data-container"
        onBlur={ () => { this.setState({ showData: false }); } }
      >
        <input className={ `input-search ${value.length > 0 ? 'full' : ''}` } value={ value } placeholder={ placeholder } type={ type } onChange={ (e) => this.onChangeHandler(e.target.value) } onFocus={ () => { this.setState({ showData: true }); } } />
        <ArrowDown color="#a1b4b6" className="input-search-lupe" onClick={ () => { this.setState({ showData: true }); } } />
        {
          showData &&
          (
            <DataContainer>
              { filteredData.length > 0 && filteredData.map((item) => (
                <DataItem className="data-item" key={ `${item.type}_${item.id}` } onClick={ () => { this.onSelectHandler(item); } } onMouseDown={ (e) => { e.preventDefault(); } }>
                  <Hostname title={ this.parseTitle(item) }>{ this.parseTitle(item) }</Hostname>
                  {item.type && item.type === 'Service' &&
                    <ServiceData title={ `${item.name} (${item.port})` }>
                      <Port>{ `(${item.port})` }</Port>
                      <ServiceName>{ item.name }</ServiceName>
                    </ServiceData>
                  }
                </DataItem>
              )) }
              { value.length > 0 && <SearchAction className="new-asset" onClick={ () => functionAction(value) } onMouseDown={ (e) => { e.preventDefault(); } }>{ searchAction }</SearchAction> }
            </DataContainer>
          )
        }
      </Wrapper>
    );
  }
}

InputSearch.propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  functionAction: PropTypes.func,
  searchAction: PropTypes.object
};

InputSearch.defaultProps = {
  type: 'text',
  placeholder: 'Enter value',
  searchAction: null,
  onChange: null,
  functionAction: null
};

export default withRouter(InputSearch);
