import React from 'react';
import {
  Wrapper,
  Message
} from './styled';

const Success = ({ children }) => (
  <Wrapper>
    <Message>{children}</Message>
  </Wrapper>
);

export default Success;
