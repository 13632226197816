import fetchApi from 'services/api/connector';

export const addCustomAttribute = (data) => fetchApi('custom_fields_schema', { method: 'POST', data });

export const getCustomAttributes = () => fetchApi('custom_fields_schema');

export const bulkRemove = (data) => fetchApi('custom_fields_schema', { method: 'DELETE', data });

export const updateCustomAttribute = (data) => fetchApi(`custom_fields_schema/${data.id}`, { method: 'PATCH', data });

export const removeCustomAttribute = (data) => fetchApi(`custom_fields_schema/${data.id}`, { method: 'DELETE', data });
