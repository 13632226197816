import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0px 10px 0px;
  padding: 40px 50px 50px 50px;
  border-radius: 4px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: ${colors.iceBlue};
`;
Wrapper.displayName = 'Wrapper';

export const AgentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
  @media (min-width: 0px) and (max-width: 1099px) {
    & > * {
      width: 100%;
    }
  }

  @media (min-width: 1100px) and (max-width: 1599px) {
    & > * {
      width: calc(100% / 2 - 15px);
    }
    & >*:not(:nth-child(2n)){
      margin-right: 30px;
    }
  }
  
  @media (min-width: 1600px) {
    & > * {
      width: calc(100% / 3 - 20px);
    }
    & >*:not(:nth-child(3n)){
      margin-right: 30px;
    }
  }
`;
AgentsWrapper.displayName = 'AgentsWrapper';

export const Title = styled.div`
  user-select: none;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  color: #1c2243;
`;
Title.displayName = 'Title';
