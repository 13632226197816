import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`;
Backdrop.displayName = 'PopupBackdrop';

export const Wrapper = styled.div`
  margin-right: 13px;
  margin-left: 13px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 87px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px ${colors.grey12};
  border: ${({ disabled }) => (disabled ? `solid 1px ${colors.grey26}` : `solid 1px ${colors.darkBlueGrey}`)};
  background-color: #fff;
`;

export const ButtonLabel = styled.div`
  user-select: none;
  height: 34px;
  padding: 7px 15px 10px 15px;
  width: 87px;
  font-size: 11.5px;
  font-weight: 500;
  color: ${({ disabled }) => (disabled ? `${colors.grey26}` : `${colors.darkBlueGrey}`)};
`;

export const FilterLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  height: 13px;
`;

export const RedAster = styled.span`
    color: #ea3158;    
`;
RedAster.displayName = 'RedAster';

export const FilterLabel = styled.div`
  text-align: left;
  font-size: 12.5px;
  letter-spacing: 0.09px;
  color: #afb1bc;
`;

export const NameInput = styled.input`
  width: 265px;
  height: 34px;
  font-size: 14.5px;
  letter-spacing: 0.11px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  box-shadow: inset 0 -1px 0 0 #afafaf;
  color: ${({ isSelected, value }) => ((isSelected || value) ? '  #1c2243' : `${colors.grey12}`)};
  background-color: ${({ isSelected, value }) => ((isSelected || value) ? '#fff' : '#EDF2F7')} ;
  &::placeholder {
        color: ${colors.grey12};
        font-size: 14.5px;
    }
`;

export const ModalContainer = styled.div`
  ${({ flipPos }) => {
    if (flipPos) return 'right: 0';
    return 'left: 0';
  }};
  padding: 21px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
  background-color: #fff;
  position: absolute;
  top: 40px;
  width: 306px;
  height: 145px;
  ${fadeIn(0, 1)};

    >:nth-child(2){
      margin-top: 8px;
      margin-left: auto;
      margin-right: auto;
    }
    >:nth-child(3){
      width: 89px;
      height: 34px;
      margin-top: 13px;
      margin-left: auto;
    }
`;
