import React from 'react';
import { ReactComponent as ClipboardIcon } from 'Images/icon-clipboard.svg';
import copyToClipboard from 'utils/clipboard';
import {
  CodeWrapper, Code, PurpleColor, Flex
} from '../styled';

export const DockerTab = ({ token }) => {
  const script = `docker run -v ABSOLUTE_PATH_TO_dispatcher.yaml:/root/.faraday/config/dispatcher.yaml faradaysec/faraday_agent_dispatcher --token=${token}`;
  const copyTextToClipboard = () => copyToClipboard(script);
  return (
    <Flex>
      <CodeWrapper>
        <Code>
          $
          <PurpleColor> docker run -v </PurpleColor>
          ABSOLUTE_PATH_TO_dispatcher.yaml:/root/.faraday/config/dispatcher.yaml faradaysec/faraday_agent_dispatcher --token=
          {token}
        </Code>
      </CodeWrapper>
      <ClipboardIcon onClick={ copyTextToClipboard } />
    </Flex>
  );
};

export const PythonTab = ({ token }) => {
  const script = `cp dispatcher.yaml $HOME/.faraday/config/
                    faraday-dispatcher run --token=${token}`;
  const copyTextToClipboard = () => copyToClipboard(script);
  return (
    <Flex>
      <CodeWrapper>
        <Code>
          $
          <PurpleColor> cp </PurpleColor>
          dispatcher.yaml $HOME/.faraday/config/
          <br />
          $
          <PurpleColor> faraday-dispatcher run </PurpleColor>
          --token=
          {token}
        </Code>
      </CodeWrapper>
      <ClipboardIcon onClick={ copyTextToClipboard } />
    </Flex>
  );
};
