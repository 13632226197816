
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import DeleteButton from 'Common/Components/DeleteButton';
import { showHostModalDelete } from 'store/Host/actions';
import { selectHostsSelected, selectPermissions } from 'store/Host/selectors';
import { selectShowHostDetail } from 'store/HostDetail/selectors';

const DeleteHostButton = () => {
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const permissions = useSelector(selectPermissions);
  const hostsSelected = useSelector(selectHostsSelected);
  const isHostDetailVisible = useSelector(selectShowHostDetail);
  const dispatch = useDispatch();
  const handleClick = () => dispatch(showHostModalDelete());

  return (
    <DeleteButton permissions={ permissions } readonly={ readonly } entity="host" selectedEntities={ hostsSelected } display={ hostsSelected.length > 0 && !isHostDetailVisible } openConfirmationModal={ handleClick } />
  );
};

export default DeleteHostButton;
