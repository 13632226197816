import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Click = styled.a`
  margin-top: 5px;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left !important;
    color: #419bf9; 
    display: block;   
`;
Click.displayName = 'Click';

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0px;
  border: solid 1px #d9e4ef;
`;
Separator.displayName = 'Separator';
