/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import StringField from 'Common/Components/StringField';
import {
  Title, SubTitle, Button, ButtonsContainer
} from 'Common/Components/MainContainer/styled';
import { INITIAL_MODE } from 'Screens/Wizard/constants';
import { addWorkspace } from 'Screens/Wizard/actions/Actions';
import { Wrapper, FieldTitle, ErrorMessage } from './styled';

class StepWorkspaceName extends Component {
  constructor (props) {
    super(props);
    this.state = {
      wsName: ''
    };

    this.createWorkspace = this.createWorkspace.bind(this);
  }

  componentDidUpdate () {
    if (this.props.wizard.isSuccess) {
      this.setState({ wsName: '' });
      this.props.onStep(INITIAL_MODE);
    }
  }

  createWorkspace () {
    if (!this.props.wizard.isFetching) {
      this.props.addWorkspace(this.state.wsName);
    }
  }

  render () {
    const { wsName } = this.state;
    const { wizard, user } = this.props;
    return (
      <Wrapper>
        <Title>{`Welcome, ${user}!`}</Title>
        <SubTitle>Let&apos;s set up your Faraday instance</SubTitle>
        <FieldTitle>Your initial project name is:</FieldTitle>
        <StringField id="workspace_name" width="340px" height="34px" placeholder="Project Name" onChange={ (v) => { this.setState({ wsName: v }); } } value={ wsName } />
        <ButtonsContainer>
          <ErrorMessage>{ wizard.errorMessage }</ErrorMessage>
          <Button onClick={ () => { this.createWorkspace(); } } disabled={ wizard.isFetching || wsName === '' }>Next</Button>
        </ButtonsContainer>
      </Wrapper>
    );
  }
}

StepWorkspaceName.propTypes = {
  onStep: PropTypes.func.isRequired,
  addWorkspace: PropTypes.func.isRequired,
  wizard: PropTypes.object.isRequired
};

StepWorkspaceName.defaultProps = {

};

const mapDispatchToProps = (dispatch) => ({
  addWorkspace: (wsName) => {
    dispatch(addWorkspace(wsName));
  }
});

const mapStateToProps = (state) => ({
  wizard: state.wizard,
  user: state.sesion.user
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceName));
