/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAgents } from 'store/Agents/selectors';
import { removeAgent } from 'store/Agents/actions';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import selectModal from 'store/modals/selectors';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import UserAgent from './components/UserAgent';
import DeletionModal from './components/DeletionModal';
import { AgentsWrapper, Wrapper, Title } from './styled';
import RunUserAgentModal from './components/RunUserAgentModal';

const UserAgents = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [agentToDelete, setAgentToDelete] = useState(null);
  const userAgents = useSelector(selectUserAgents);
  const title = intl.formatMessage({ id: 'agents.userAgents.title' });
  const showRunUserAgent = useSelector((state) => selectModal(state, MODAL_RUN_USER_AGENT));

  const markToDelete = (agent) => setAgentToDelete(agent);

  const cancelDeletion = () => setAgentToDelete(null);

  const performDelete = () => {
    setAgentToDelete(null);
    dispatch(removeAgent(agentToDelete));
  };

  if (isEmpty(userAgents)) return null;
  return (
    <Wrapper>
      {showRunUserAgent && <RunUserAgentModal /> }
      <Title children={ title } />
      <AgentsWrapper>
        { userAgents.map((agent) => <UserAgent key={ agent.id } markToDelete={ markToDelete } agent={ agent } />) }
      </AgentsWrapper>
      <DeletionModal agentToDelete={ agentToDelete } onAccept={ performDelete } onClose={ cancelDeletion } />
    </Wrapper>
  );
};
export default UserAgents;
