/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { Title, SubTitle, PullLeft } from 'Common/Components/MainContainer/styled';
import { AiOutlineCloudUpload as CloudUpload, AiOutlineEdit as Edit } from 'react-icons/ai';
import { TiClipboard as List } from 'react-icons/ti';

import { FaChevronRight as ChevronRight } from 'react-icons/fa';
import { FILE_SELECTOR, WORKSPACE_SELECTION, WORKSPACE_NAME } from 'Screens/Wizard/constants';
import {
  Wrapper,
  InfoText,
  OptionsWrapper,
  Option,
  OptionText,
  OptionIcon,
  HoverIcon,
  SubTitleWs,
  OptionTextWs
} from './styled';

class StepInitialMode extends Component {
  constructor (props) {
    super(props);
    this.state = {
    };
  }

  render () {
    const { workspace } = this.props;
    return (
      <Wrapper>
        <Title>
          Welcome,&nbsp;
          { this.props.user }
          !
        </Title>
        <PullLeft>
          <SubTitle>Your workspace</SubTitle>
          <SubTitleWs>
          &nbsp;
            {workspace}
&nbsp;
          </SubTitleWs>
          <SubTitle>has been created</SubTitle>
        </PullLeft>
        <InfoText>Next up, you can:</InfoText>
        <OptionsWrapper>
          <Option onClick={ () => { this.props.onStep(FILE_SELECTOR); } }>
            <OptionIcon><CloudUpload /></OptionIcon>
            <PullLeft>
              <OptionText>Upload information to&nbsp;</OptionText>
              <OptionTextWs>
                {workspace}
&nbsp;
              </OptionTextWs>
            </PullLeft>
            <HoverIcon className="arrow"><ChevronRight size="0.6em" /></HoverIcon>
          </Option>

          <Option onClick={ () => { this.props.onStep(WORKSPACE_NAME); } }>
            <OptionIcon><Edit size="0.8em" /></OptionIcon>
            <OptionText>Create more Workspaces</OptionText>
            <HoverIcon className="arrow"><ChevronRight size="0.6em" /></HoverIcon>
          </Option>
          {
            this.props.workspaces.length > 0 &&
            (
              <Option onClick={ () => { this.props.onStep(WORKSPACE_SELECTION); } }>
                <OptionIcon><List /></OptionIcon>
                <OptionText>Go to my Workspace List</OptionText>
                <HoverIcon className="arrow"><ChevronRight size="0.6em" /></HoverIcon>
              </Option>
            )
          }
        </OptionsWrapper>
      </Wrapper>
    );
  }
}

StepInitialMode.propTypes = {
  onStep: PropTypes.func.isRequired,
  workspaces: PropTypes.array.isRequired,
  user: PropTypes.string.isRequired
};

StepInitialMode.defaultProps = {

};

const mapDispatchToProps = () => ({

});

const mapStateToProps = (state) => ({
  workspaces: state.faraday.workspaces,
  user: state.sesion.user,
  workspace: selectCurrentWorkspace(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepInitialMode));
