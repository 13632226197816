export const MOCK_VULN_ID = 0;
export const mockVuln = {
  description: 'This is a sample vulnerability',
  resolution: '',
  method: '',
  obj_id: 'MOCK_VULN_ID',
  status_code: null,
  id: MOCK_VULN_ID,
  response: '',
  _rev: '',
  metadata: {
    creator: 'Web UI',
    command_id: null,
    update_user: null,
    update_controller_action: '',
    update_action: 0,
    owner: 'faraday',
    create_time: '2021-04-12T19:56:53.512785+00:00',
    update_time: '2021-04-12T19:56:53.512795+00:00'
  },
  vulnerability_template_id: null,
  _id: MOCK_VULN_ID,
  policyviolations: [],
  query: '',
  external_id: '',
  date: '2021-04-12T19:56:53.512785+00:00',
  refs: [],
  request: '',
  desc: 'This is a sample vulnerability for your App Tour',
  params: '',
  easeofresolution: null,
  parent_type: 'Host',
  parent: 3162,
  tags: [],
  pname: '',
  impact: {
    availability: false,
    integrity: false,
    accountability: false,
    confidentiality: false
  },
  type: 'Vulnerability',
  tool: 'Web UI',
  owned: false,
  path: '',
  hostnames: [],
  data: '',
  status: 'open',
  childs: [],
  vulnerability_duplicate_id: null,
  host_os: '',
  custom_fields: {
    choice_field: null,
    test: null,
    custom_name: null,
    list_field: null
  },
  issuetracker_json: {},
  severity: 'med',
  service: null,
  name: 'Sample Vulnerability',
  owner: 'faraday',
  confirmed: true,
  _attachments: {},
  target: '168.0.0.1',
  website: ''
};
export const MOCK_VULN_LIST = [mockVuln];
