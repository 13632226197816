import React from 'react';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAgentTypes } from 'store/Agents/selectors';
import { Help, Here } from './styled';

const Website = ({ currentTool }) => {
  const tools = useSelector(selectAgentTypes);
  const currentToolName = get(currentTool, 'name', '');
  const storedTool = pickBy(tools, { name: currentToolName });
  const toolWebsite = get(storedTool, `${currentToolName}.website`, '');
  const intl = useIntl();
  const helpMessage = intl.formatMessage({ id: 'agents.triggerAgent.tool.helpMessage' });
  const hereMessage = intl.formatMessage({ id: 'agents.triggerAgent.tool.webMessage' });

  if (!toolWebsite) return null;
  return (
    <Help>
      {helpMessage}
      <Here target="_blank" href={ toolWebsite }>{hereMessage}</Here>
    </Help>
  );
};

export default Website;
