import get from 'lodash/get';
import * as types from './types';
import initialState from './initialState';

export default function filters (state = initialState, action) {
  const { entity } = action;
  let entityInitialState;
  let entityState;

  if (entity) {
    entityInitialState = get(initialState, entity, undefined);
    entityState = get(state, entity, undefined);
  }

  switch (action.type) {
    case types.SET_ADVANCED_MODE: {
      return {
        ...state,
        isAdvancedMode: action.isAdvancedMode
      };
    }
    case types.SET_SAVE_STATUS: {
      return {
        ...state,
        saveEnabled: action.saveEnabled
      };
    }
    case types.SET_SELECTED_ENTITY: {
      return {
        ...state,
        selectedEntity: action.selectedEntity
      };
    }
    case types.SET_CUSTOM_FILTERS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          customFilters: action.customFilters
        }
      };
    }
    case types.REMOVE_FILTERS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          filters: entityInitialState.filters,
          expandedFilter: entityInitialState.expandedFilter
        }
      };
    }
    case types.RESET_FILTERS: {
      return {
        ...state,
        [entity]: {
          ...entityInitialState,
          customFilters: entityState.customFilters
        }
      };
    }
    case types.SET_ADVANCED_FILTER: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          advancedFilter: action.value
        }
      };
    }
    case types.SET_FILTER_ERROR: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          saveEnabled: false,
          filterError: action.filterError
        }
      };
    }
    case types.SET_GROUP_BY: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          group_by: action.group_by,
          expandedFilter: entityInitialState.expandedFilter
        }
      };
    }
    case types.CLEAR_GROUP_BY: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          order_by: entityInitialState.order_by,
          group_by: entityInitialState.group_by,
          expandedFilter: entityInitialState.expandedFilter
        }
      };
    }
    case types.SET_ORDER_BY: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          order_by: action.order_by
        }
      };
    }
    case types.CLEAR_ORDER_BY: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          order_by: entityInitialState.order_by
        }
      };
    }
    case types.SET_PAGE_NUMBER: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          page: action.pageNumber
        }
      };
    }
    case types.SET_FILTERS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          filters: action.filters
        }
      };
    }
    case types.SET_EXPANDED_FILTER: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          expandedFilter: action.expandedFilter
        }
      };
    }
    case types.CLEAR_FILTERS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          filters: entityInitialState.filters,
          expandedFilter: entityInitialState.expandedFilter,
          filterHistory: entityInitialState.filterHistory
        }
      };
    }
    case types.ADD_FILTER_TO_HISTORY: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          filterHistory: [...entityState.filterHistory, action.filter]
        }
      };
    }
    case types.SET_FILTER_HISTORY: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          filterHistory: action.filterHistory
        }
      };
    }
    case types.SET_KEYS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          keys: action.keys
        }
      };
    }
    case types.CLEAR_KEYS: {
      return {
        ...state,
        [entity]: {
          ...entityState,
          keys: entityInitialState.keys
        }
      };
    }
    case types.SAVE_DASHBOARD_TEMPLATE: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          [action.filter]: action.status
        }
      };
    }
    default: {
      return state;
    }
  }
}
