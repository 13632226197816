import React from 'react';
import { ReactComponent as TokenIcon } from 'Images/icon-action-bar-token.svg';
import IconButton from 'Common/Components/IconButton';
import Wrapper from './styled';

const TokenButton = ({ onClick }) => (
  <Wrapper>
    <IconButton title="Token" onClick={ onClick } icon={ <TokenIcon /> } />
  </Wrapper>
);
export default TokenButton;
