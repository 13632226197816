import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`;
Wrapper.displayName = 'Wrapper';

export const ImpactItem = styled.div`
    width: 195px;
    height: 24px;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 0 #acbdd2;    
    font-size: 12.5px;
    font-weight: 400;
    line-height: 2.08;
    text-align: center;    
    margin-right: 2px;
    margin-bottom: 3px;
    background-color: ${(props) => (props.active ? '#686A76' : '#edf2f7')};
    color: ${(props) => (props.active ? 'white' : '#0b2128')};

`;
ImpactItem.displayName = 'ImpactItem';
